import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Addproblems, GetProblems } from "../../api/helper";
import { ShowToast } from "../../helpers/ToastService";

const validationSchema = Yup.object().shape({
  problem: Yup.string().required("Problem is required"),
  code: Yup.string()
    .required("Code is required")
    .matches(
      /^[A-Za-z0-9]+$/,
      "Code should only contain alphanumeric characters"
    )
    .min(3, "Code must be at least 3 characters long")
    .max(10, "Code cannot be longer than 10 characters"),
  startDate: Yup.date()
    .min(new Date(), "Start date cannot be in the past")
    .required("Start date is required"),
  endDate: Yup.date()
    .min(Yup.ref("startDate"), "End date cannot be before start date")
    .required("End date is required"),
  clinicalStatus: Yup.string().required("Clinical Status is required"),
  verificationStatus: Yup.string().required("Verification Status is required"),
  carePlan: Yup.string()
    .nullable() // It is optional but should meet other requirements if filled
    .max(500, "Care Plan cannot exceed 500 characters") // Max character validation
    .matches(/^(?!\s*$).+/, "Care Plan cannot be empty if provided"), // Prevent empty spaces
});

const PatientProblems = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [patientId, setpatientId] = useState("");
  const [problemsData, setproblemdata] = useState(null);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.patientId) {
      setpatientId(location.state.patientId);
      console.log("Patient ID:ajayyyy", location.state.patientId);
    }
  }, [location.state]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const onSubmit = async (values) => {
    console.log("Form Data", values);
    const formattedStartDate = values?.startDate
      ? moment(values.startDate).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = values?.endDate
      ? moment(values.endDate).format("YYYY-MM-DD")
      : null;
    const data = {
      Problem: values?.problem,
      Code: values?.code,
      StartDate: formattedStartDate,
      EndDate: formattedEndDate,
      ClinicalStatus: values?.clinicalStatus,
      VerificationStatus: values?.verificationStatus,
      CarePlan: values?.carePlan,
      // agencyIds: values?.,
      patientId: patientId,
      doctorId: profiledata?._id,
    };
    console.log(data, "data===ajay");
    // Addproblems
    try {
      const response = await Addproblems(data);
      ShowToast(response?.data?.message, "success");
      console.log(response?.data?.message, "add problem response ====");
      FetchProblems();
      toggleFormVisibility();
    } catch (error) {
      console.log(error);
      toggleFormVisibility();
    }
  };
  const FetchProblems = async () => {
    // console.log(patientId,"???-=-==")
    if (patientId) {
      try {
        const response = await GetProblems(patientId);
        console.log(response?.data?.data?.data, "data===problem");
        setproblemdata(response?.data?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    FetchProblems();
  }, [patientId]);
  // const problemsData = [
  //   {
  //     problem: "Autistic disorder",
  //     period: "Sep 22, 2023 - Ongoing",
  //     clinicalStatus: "Active",
  //     verificationStatus: "Provisional",
  //     carePlan: "-"
  //   },
  //   {
  //     problem: "Autistic disorder",
  //     period: "-",
  //     clinicalStatus: "Inactivddde",
  //     verificationStatus: "Differential",
  //     carePlan: "-"
  //   },
  // ];

  return (
    <div className="patient_right">
      {/* Add Button */}
      <div className="custom-problem-add-button" onClick={toggleFormVisibility}>
        Add
        <img
          src={require("../assest/Images/plus_icon.png")}
          style={{ marginLeft: 10, filter: "invert(1) brightness(5)" }}
        />
      </div>

      <div className="custom-problem-table-container">
        <div className="custom-problem-table-header">
          <div className="custom-problem-table-header-item">Problem</div>
          <div className="custom-problem-table-header-item">Period</div>
          <div className="custom-problem-table-header-item">
            Clinical Status
          </div>
          <div className="custom-problem-table-header-item">
            Verification Status
          </div>
          <div className="custom-problem-table-header-item">Care Plan</div>
        </div>
        {problemsData && problemsData.length > 0 ? (
          problemsData.map((problem, index) => (
            <div key={index} className="custom-problem-table-row">
            <div className="custom-problem-table-cell" style={{ width: '19%', whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {problem.Problem}
            </div>
            <div className="custom-problem-table-cell" style={{ width: '19%', whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {problem.StartDate} to {problem?.EndDate}
            </div>
            <div className="custom-problem-table-cell" style={{ width: '19%', whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {problem.ClinicalStatus}
            </div>
            <div className="custom-problem-table-cell" style={{ width: '19%', whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {problem.VerificationStatus}
            </div>
            <div className="custom-problem-table-cell" style={{ width: '19%', whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {problem.CarePlan}
            </div>
          </div>
          
          ))
        ) : (
          <div className="custom-problem-table-row">
            <div className="custom-problem-table-cell" colSpan="5">
              No Problems found
            </div>
          </div>
        )}
      </div>

      {/* Conditionally render the form based on isFormVisible */}
      {isFormVisible && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="add_problem_div">
            <div className="Add_problem_txtt">
              Add Problem
              {/* Close Icon */}
              <img
                src={require("../assest/Images/crossIconn.png")}
                alt="close"
                onClick={toggleFormVisibility} // Hides form when clicked
                style={{ cursor: "pointer" }}
              />
            </div>

            <div className="Record_Details_txtt">Record Details</div>

            {/* Problem */}
            <div className="problem_div_txtt" style={{}}>
              Problem
              <div
                style={{
                  width: "70%",
                  ustifyContent: "center",
                  alignItems: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Controller
                  name="problem"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <select className="select_option_style" {...field}>
                      <option value="" disabled hidden>
                        Select Problem
                      </option>
                      <option>Headache</option>
                      <option>Fever</option>
                      <option>Stomach Pain</option>
                      <option>Back Pain</option>
                      <option>Cough</option>
                      <option>Fatigue</option>
                      <option>Cold</option>
                    </select>
                  )}
                />
                {errors.problem && (
                  <p className="error_message">{errors.problem.message}</p>
                )}
              </div>
            </div>
            <div className="code_txt_div">
              Code
              <div
                style={{
                  width: "70%",
                  ustifyContent: "center",
                  alignItems: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Controller
                  name="code"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      type="text"
                      placeholder="-"
                      className="input_num_style"
                      {...field}
                    />
                  )}
                />
                {errors.code && (
                  <p className="error_message">{errors.code.message}</p>
                )}
              </div>
            </div>
            {/* Start Date */}
            <div className="code_txt_div">
              Start Date
              <div
                style={{
                  width: "70%",
                  ustifyContent: "center",
                  alignItems: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input type="date" className="input_num_style" {...field} />
                  )}
                />
                {errors.startDate && (
                  <p className="error_message">{errors.startDate.message}</p>
                )}
              </div>
            </div>

            {/* End Date */}
            <div className="code_txt_div">
              End Date
              <div
                style={{
                  width: "70%",
                  ustifyContent: "center",
                  alignItems: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Controller
                  name="endDate"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      type="date"
                      className="input_num_style"
                      {...field}
                      min={new Date().toISOString().split("T")[0]} // Disable earlier dates dynamically
                    />
                  )}
                />
                {errors.endDate && (
                  <p className="error_message">{errors.endDate.message}</p>
                )}
              </div>
            </div>

            {/* Clinical Status */}
            <div className="code_txt_div">
              Clinical Status
              <div
                style={{
                  width: "70%",
                  ustifyContent: "center",
                  alignItems: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Controller
                  name="clinicalStatus"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <select className="select_option_style" {...field}>
                      <option value="" disabled hidden>
                        Select Clinical Status
                      </option>
                      <option>Active</option>
                      <option>inActive</option>
                    </select>
                  )}
                />
                {errors.clinicalStatus && (
                  <p className="error_message">
                    {errors.clinicalStatus.message}
                  </p>
                )}
              </div>
            </div>

            {/* Verification Status */}
            <div className="code_txt_div">
              Verification Status
              <div
                style={{
                  width: "70%",
                  ustifyContent: "center",
                  alignItems: "flex-end",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Controller
                  name="verificationStatus"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <select className="select_option_style" {...field}>
                      <option value="" disabled hidden>
                        Select Verification Status
                      </option>
                      <option>Unconfirmed</option>
                      <option>Confirmed</option>
                    </select>
                  )}
                />
                {errors.verificationStatus && (
                  <p className="error_message">
                    {errors.verificationStatus.message}
                  </p>
                )}
              </div>
            </div>

            {/* Care Plan */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="care_plan_txtt">Care Plan</div>
              <div className="code_txt_div">
                <div>
                  <Controller
                    name="carePlan"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <textarea
                        placeholder="Add patient instructions Optional"
                        className="input_num_style"
                        style={{ height: "80px", width: "100%" }}
                        {...field}
                      />
                    )}
                  />
                  {errors.carePlan && (
                    <p className="error_message">{errors.carePlan.message}</p>
                  )}
                </div>
              </div>

              {/* Action Buttons */}
              <div className="action_buttons">
                <button
                  className="cancel_button"
                  onClick={toggleFormVisibility}
                >
                  Cancel
                </button>
                <button className="save_button" type="submit">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default PatientProblems;
