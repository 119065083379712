
import React, { useState } from "react";
import { format, addMonths, subMonths } from "date-fns";


const CalenderDoctor = () => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const weekdays = ["M", "T", "W", "T", "F", "S", "S"];

    console.log(new Date().getDate(),'====>>>')
  
    const renderHeader = () => {
      return (
        <div className="calendar-header1">
        <div className="border_div">
          <button style={{color:"black"}} onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}>
            &lt;
          </button></div>
          <h3>{format(currentMonth, "MMMM yyyy")}</h3>
          <div className="border_div">
          <button style={{color:"black"}} onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}>
            &gt;
          </button>
          </div>
        </div>
      );
    };
  
    const renderWeekdays = () => {
      return (
        <div className="weekdays-row">
          {weekdays.map((day) => (
            <div key={day} className="weekday1">
              {day}
            </div>
          ))}
        </div>
      );
    };
  
    const renderDays = () => {
      const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
      const startDay = startOfMonth.getDay() === 0 ? 7 : startOfMonth.getDay();
      const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
  
      const calendarDays = [];
      for (let i = 1; i < startDay; i++) {
        calendarDays.push(<div key={`empty-${i}`} className="blank-day"></div>);
      }
      for (let date = 1; date <= daysInMonth; date++) {
        calendarDays.push(
          <div key={date} className={date === new Date().getDate() ? "calendar-day highlighted" : "calendar-day1"}>
            {date}
          </div>
        );
      }
      return <div className="days-grid">{calendarDays}</div>;
    };
  
    return (
    <div className="custom-calendar">
    {renderHeader()}
    {renderWeekdays()}
    {renderDays()}
  </div>
  )
}

export default CalenderDoctor