// Dashboard.js
import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
// TodayAppointments
import TodayAppointments from './TodayAppointments'
import ClinicScreen  from'./ClinicScreen'
import OutstandingItems from './OutstandingItems'
import AddNewClinic from './AddNewClinic';
import { useNavigate } from 'react-router-dom';
import ClinicList from './ClinicList';

const Dashboard = () => {
    const [activeButton, setActiveButton] = useState('Today Appointments');

    const handleButtonClick = (buttonName) => {
      setActiveButton(buttonName);
    };
    const accountData = [
        { label: 'Insurance', value: '0' },
        { label: 'Private', value: '0' },
        { label: 'Subscription', value: '0' },
    ];

    const careTeamData = [
        { label: 'Active', value: '0' },
        { label: 'Inactive', value: '0' },
    ];

    const bookingsData = [
        { label: 'Created', value: '0' },
        { label: 'Approved', value: '0' },
        { label: 'Rejected', value: '0' },
    ];
const navigate=useNavigate()
    return (
        <div className="dashboard-container" >
            <Sidebar />
            <div className="dashboard-content" >
                <Navbar 
        onClick= {()=>{navigate("/profile")}}
        />
                <div className="dashboard-cards" style={{ paddingLeft: 30 ,paddingRight:30}}>
                    <div style={{ backgroundColor: 'rgba(103, 197, 55, 0.3)', padding: 20, width: '30%', borderRadius: 15 }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ color: 'rgba(103, 197, 55, 1)', fontSize: 20 }}>ACCOUNT</div>
                            <img src={require('../assest/Images/Accounticon.png')} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                            <div>
                                <div style={{fontSize:14,marginBottom:10}}>INSURANCE</div>
                                <div>0</div>
                            </div>
                            <div>
                                <div style={{fontSize:14,marginBottom:10}}>PRIVATE </div>
                                <div>0</div>
                            </div>
                            <div>
                                <div style={{fontSize:14,marginBottom:10}}>SUBSCRIPTION</div>
                                <div>0</div>
                            </div>
                        </div>
                    </div>

                    <div style={{ backgroundColor: 'rgba(20, 174, 209, 0.3)', padding: 20, width: '30%', borderRadius: 15 }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ color: 'rgba(20, 174, 209, 1)', fontSize: 20 }}>CARE TEAM</div>
                            <img src={require('../assest/Images/Careteamicon.png')} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: 10 }}>
                          
                            <div>
                                <div style={{fontSize:14,marginBottom:10}}>ACTIVE </div>
                                <div>0</div>
                            </div>
                            <div>
                                <div style={{fontSize:14,marginBottom:10}}>INACTIVE </div>
                                <div>0</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ backgroundColor: 'rgba(219, 0, 255, 0.3)', padding: 20, width: '30%', borderRadius: 15 }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ color: 'rgba(84, 101, 240, 1)', fontSize: 20 }}>BOOKING</div>
                            <img src={require('../assest/Images/Bookingsicon.png')} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                            <div>
                                <div style={{fontSize:14,marginBottom:10}}>CREATED </div>
                                <div>0</div>
                            </div>
                            <div>
                                <div style={{fontSize:14,marginBottom:10}}>APPROVED </div>
                                <div>0</div>
                            </div>
                            <div>
                                <div style={{fontSize:14,marginBottom:10}}>REJECTED </div>
                                <div>0</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-actions" style={{ paddingLeft: 30, marginTop: 20 }}>
        <button
          className={`action-btn ${activeButton === 'Today Appointments' ? 'active' : ''}`}
          style={{
            marginRight: 30,
            borderWidth: 1,
            borderColor: '#CCCCCC',
            borderStyle: 'solid',
          }}
          onClick={() => handleButtonClick('Today Appointments')}
        >
          Today Appointments
        </button>
        <button
          className={`action-btn ${activeButton === 'Clinic' ? 'active' : ''}`}
          style={{
            marginRight: 30,
            borderWidth: 1,
            borderColor: 'rgba(204, 204, 204, 1)',
            borderStyle: 'solid',
          }}
          onClick={() => handleButtonClick('Clinic')}
        >
          My Clinic
        </button>

        <button
          className={`action-btn ${activeButton === 'Clinic List' ? 'active' : ''}`}
          style={{
            marginRight: 30,
            borderWidth: 1,
            borderColor: 'rgba(204, 204, 204, 1)',
            borderStyle: 'solid',
          }}
          onClick={() => handleButtonClick('Clinic List')}
        >
          Clinic List
        </button>

        <button
          className={`action-btn ${activeButton === 'Outstanding Items' ? 'active' : ''}`}
          style={{
            marginRight: 30,
            borderWidth: 1,
            borderColor: 'rgba(204, 204, 204, 1)',
            borderStyle: 'solid',
          }}
          onClick={() => handleButtonClick('Outstanding Items')}
        >
          Outstanding Items
        </button>
        <button
          className={`action-btn ${activeButton === 'Clinic Request' ? 'active' : ''}`}
          style={{
            marginRight: 30,
            borderWidth: 1,
            borderColor: 'rgba(204, 204, 204, 1)',
            borderStyle: 'solid',
          }}
          onClick={() => handleButtonClick('Clinic Request')}
        >
          Clinic Request
         </button>
      </div>

      <div>
        {activeButton === 'Today Appointments' && <TodayAppointments />}
        {activeButton === 'Clinic' && <ClinicScreen />}
        {activeButton === 'Outstanding Items' && <OutstandingItems />}
        {activeButton === 'Clinic Request' && <AddNewClinic />}
        {activeButton === 'Clinic List' && <ClinicList />}

      </div>
            </div>
        </div>
    );
};

export default Dashboard;



