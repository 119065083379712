import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DropDownButton from "../../Components/DropDownButton";
import { createAvailableDays, getAvailableDays, updateAvailableDays } from "../../../api/helper";
import { ShowToast } from "../../../helpers/ToastService";
const Calendarcontant = ({ info }) => {
  // console.log(info, '=================<>>>>>')
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(true);
  const [showNotifications, setShowNotifications] = useState(false);
  const [availableDays, setAvailableDays] = useState([]);
  const [DaysData, setDaysData] = useState(null);
  // console.log(DaysData,'===DaysData')

  const toggleForm = () => {
    setShowForm(!showForm);
    setShowNotifications(false);
  };

  const showPersonalReminder = () => {
    setShowForm(false);
    setShowNotifications(true);
  }
  const [emailPreferences, setEmailPreferences] = useState({
    intakeAppointment: true,
    followUpAppointment: false,
    appointmentRescheduled: true,
    appointmentCanceled: false,
    newClientMessage: true,
    expiringInvoices: false,
    clientReferrals: true,
  });

  const toggleEmailPreference = (key) => {
    setEmailPreferences((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const getAvailableDay = async () => {
    try {
      const response = await getAvailableDays({ doctorId: info.doctorId._id })
      // console.log(response,'===response')
      setDaysData(response.data.data)
      setAvailableDays(response.data.data.availableDays)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (info.doctorId) {
      getAvailableDay()
    }
  }, [])


  const updateDays = async () => {
    if (!DaysData) {
      let payload = {
        id: info.doctorId._id,
        doctorId: info.doctorId._id,
        availableDays: availableDays
      }
      try {
        const response = await createAvailableDays(payload)
        ShowToast("AvailableDays update successfully", "success")
      } catch (error) {
        console.log(error)
      }

    } else {
      let payload = {
        id: DaysData._id,
        availableDays: availableDays
      }
      try {
        const response = await updateAvailableDays(payload)
        ShowToast("AvailableDays update successfully", "success")
      } catch (error) {
        console.log(error, '===error')
      }
    }


  }

  const handleToggle = (dayName) => {
    setAvailableDays((prev) => {
      const isActive = prev.some((day) => day.day === dayName);
      if (isActive) {
        return prev.filter((day) => day.day !== dayName);
      } else {
        return [
          ...prev,
          { day: dayName, shifts: [{ startTime: "10:00", endTime: "18:00" }] },
        ];
      }
    });
  };

  const handleTimeChange = (dayName, newStartTime, newEndTime) => {
    setAvailableDays((prev) =>
      prev.map((day) => {
        if (day.day === dayName) {
          return {
            ...day,
            shifts: [{ startTime: newStartTime, endTime: newEndTime }],
          };
        }
        return day;
      })
    );
  };


  const days = [
    { name: "Monday" },
    { name: "Tuesday" },
    { name: "Wednesday" },
    { name: "Thursday" },
    { name: "Friday" },
    { name: "Saturday" },
    { name: "Sunday" },
  ];
  const DayItem = ({
    day,
    isActive,
    onToggle,
    startTime,
    endTime,
    onTimeChange,
  }) => {
    return (
      <div className={`unique-day-item ${isActive ? "unique-active-day" : ""}`}>
        <div className="unique-day-header">
          <span>{day.name}</span>
          <label className="unique-switch">
            <input type="checkbox" checked={isActive} onChange={onToggle} />
            <span className="unique-slider unique-round"></span>
          </label>
        </div>

        {isActive && (
          <div className="unique-time-selector">
            <div className="unique-time-group" >
              <div className="unique-time-input-wrapper" >
                <input
                  type="time"
                  className="unique-time-input"
                  value={startTime}
                  onChange={(e) =>
                    onTimeChange(day.name, e.target.value, endTime)
                  }
                />
              </div>
              <div className="unique-time-input-wrapper">
                <input
                  type="time"
                  className="unique-time-input"
                  value={endTime}
                  onChange={(e) =>
                    onTimeChange(day.name, startTime, e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="calendar-working-hours-container">

        {showForm && !showNotifications ? (
          <>
            <div className="calendar-working-hours-header">
              <div>
                <h2 style={{ textAlign: "left" }}>Working Hours</h2>
                <p className="prahagraf_txt">
                  Set up your work schedule, add shifts, and specify preferred
                  appointment and visit types.
                </p>
              </div>
              <button
                className="calendar-working-hours-notification-button"
                onClick={toggleForm}
              >
                Appointment Notification
              </button>
            </div>



            {days.map((day) => {
              const isActive = availableDays.some((d) => d.day === day.name);
              const dayData = availableDays.find((d) => d.day === day.name);
              const startTime = dayData ? dayData.shifts[0].startTime : "10:00";
              const endTime = dayData ? dayData.shifts[0].endTime : "18:00";

              return (
                <DayItem
                  key={day.name}
                  day={day}
                  isActive={isActive}
                  onToggle={() => handleToggle(day.name)}
                  startTime={startTime}
                  endTime={endTime}
                  onTimeChange={handleTimeChange}
                />
              );
            })}



          </>
        ) : !showForm && showNotifications ? (
          <div className="notifications-container">
            <div className="notifications-title">Notifications</div>
            <div className="notifications-subtitle">
              Stay updated on your practice
            </div>

            <section className="notifications-section">
              {/* <h3 className="section-title__">Appointment Updated</h3>*/}
              <div className="div_h3_h4_display" style={{ paddingRight: 30 }}>
                <h3 className="Calen_Appointment_update">Appointment Updated</h3>
                <h4 className="Calen_Email_text">Email</h4>
              </div>
              <div className="notifications-row" style={{ paddingRight: 30 }}>
                <div>New intake appointment</div>
                <input
                  type="checkbox"
                  checked={emailPreferences.intakeAppointment}
                  onChange={() => toggleEmailPreference("intakeAppointment")}
                />
              </div>
              <div className="notifications-row" style={{ paddingRight: 30 }}>
                <div>New follow-up appointment</div>
                <input
                  type="checkbox"
                  checked={emailPreferences.followUpAppointment}
                  onChange={() =>
                    toggleEmailPreference("followUpAppointment")
                  }
                />
              </div>
              <div className="notifications-row" style={{ paddingRight: 30 }}>
                <div>Appointment Rescheduled</div>
                <input
                  type="checkbox"
                  checked={emailPreferences.appointmentRescheduled}
                  onChange={() =>
                    toggleEmailPreference("appointmentRescheduled")
                  }
                />
              </div>
              <div className="notifications-row" style={{ paddingRight: 30 }}>
                <div>Appointment Canceled</div>
                <input
                  type="checkbox"
                  checked={emailPreferences.appointmentCanceled}
                  onChange={() =>
                    toggleEmailPreference("appointmentCanceled")
                  }
                />
              </div>
            </section>

            <section className="notifications-section">
              {/* <h3 className="section-title__">Practice Management</h3> */}
              <div className="div_h3_h4_display" style={{ paddingRight: 30 }}>
                <h3 className="Calen_Appointment_update">Practice Management</h3>
                <h4 className="Calen_Email_text">Email</h4>
              </div>
              <div className="notifications-row" style={{ paddingRight: 30 }}>
                <div>New client message</div>
                <input
                  type="checkbox"
                  checked={emailPreferences.newClientMessage}
                  onChange={() => toggleEmailPreference("newClientMessage")}
                />
              </div>
              <div className="notifications-row" style={{ paddingRight: 30 }}>
                <div>Expiring Invoices</div>
                <input
                  type="checkbox"
                  checked={emailPreferences.expiringInvoices}
                  onChange={() => toggleEmailPreference("expiringInvoices")}
                />
              </div>
              <div className="notifications-row" style={{ paddingRight: 30 }}>
                <div>Client Referrals</div>
                <input
                  type="checkbox"
                  checked={emailPreferences.clientReferrals}
                  onChange={() => toggleEmailPreference("clientReferrals")}
                />
              </div>
            </section>
          </div>
        ) : (
          <>
            <>
              <div>
                <div className="Working_Hours_txt">Working Hours</div>
                <div className="prahagraf_txt">
                  Set up your work schedule, add shifts, and specify preferred
                  appointment and visit types.
                </div>
              </div>
              <div class="day-row_two">
                <div class="day-label" style={{ marginTop: 25 }}>
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider"></span>
                  </label>
                  <span style={{ marginTop: 6 }}>Appointment Booked</span>
                </div>
                <DropDownButton
                  placeholder="All Events "
                  dropdownicon
                  style={{ width: "25.5%" }}
                  options={["All Events", "All Events ", "All Events "]}
                />
                <div className="Calendar_checkbox_div">
                  <input type="checkbox" />
                  <div className="email_push_sms_txt">Email</div>
                </div>
                <div className="Calendar_checkbox_div">
                  <input type="checkbox" />
                  <div className="email_push_sms_txt">Push</div>
                </div>
                <div className="Calendar_checkbox_div">
                  <input type="checkbox" />
                  <div className="email_push_sms_txt">SMS</div>
                </div>
              </div>
              <div class="day-row_two">
                <div class="day-label" style={{ marginTop: 25 }}>
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider"></span>
                  </label>
                  <span style={{ marginTop: 6 }}>Patient Confirmation</span>
                </div>
                <DropDownButton
                  placeholder="All Events "
                  dropdownicon
                  style={{ width: "25.5%" }}
                  options={["All Events", "All Events ", "All Events "]}
                />
                <div className="Calendar_checkbox_div">
                  <input type="checkbox" />
                  <div className="email_push_sms_txt">Email</div>
                </div>
                <div className="Calendar_checkbox_div">
                  <input type="checkbox" />
                  <div className="email_push_sms_txt">Push</div>
                </div>
                <div className="Calendar_checkbox_div">
                  <input type="checkbox" />
                  <div className="email_push_sms_txt">SMS</div>
                </div>
              </div>
              <div className="Add_Personal_Reminder_txt" onClick={showPersonalReminder}>
                Add Personal Reminder
              </div>


            </>
          </>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 30,
          }}
        >
          <div className="cancelButton">Cancel</div>
          <div className="savebuttonn" style={{ width: 340 }} onClick={() => { updateDays() }} >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendarcontant;


