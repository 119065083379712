import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Inputbox from './Inputbox';
import { useForm } from 'react-hook-form';
import moment from 'moment';

const PatientDetailsDoctor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [info, setinfo] = useState(location.state)
  // console.log(info, '===?>>>>>>info')
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues:{
      email:info?.patientId?.email,
      phone:info?.patientId?.phone,
      gender:info?.patientId?.gender,
      address:{
        city:info?.patientId?.address?.city
      }
    }
  });

  const getAge = (birthDate) => {
    const birthMoment = moment(birthDate);
    const now = moment();
    const years = now.diff(birthMoment, 'Years');
    if (years < 1) {
      const months = now.diff(birthMoment, 'Months');
      return `${months} Month${months !== 1 ? 's' : ''}`;
    } else if (years === 1) {
      const days = now.diff(birthMoment.add(1, 'Year'), 'Days');
      return `${days} Day${days !== 1 ? 's' : ''}`;
    } else {
      return `${years} Year${years !== 1 ? 's' : ''}`;
    }
  };



  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <Navbar />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30, width: "100%" }}
        >
          <div className="provider-form-container">
            <div className="form-header">
              <div className="profile-image">
                <img
                  src={require("../assest/Images/doctorimg.png")}
                  alt="Profile"
                  style={{ height: 130, width: 130, marginLeft: 20 }}
                />
                <div>
                  <h3>{`${info.patientId.firstName} ${info.patientId.lastName}`}</h3>
                  <h4 style={{ textAlign: "left", marginTop: 10 }}>
                    Age:{getAge(info?.patientId?.DOB)}
                  </h4>
                </div>
              </div>
              <button className="close-btn_two" onClick={() => navigate(-1)}>
                <img src={require("../assest/Images/crossVector.png")} />
              </button>
            </div>

            <div className="input_space_div">
              <Inputbox
                label="Email Address"
                type="text"
                placeholder="example@gmail.com"
                name="email"
                register={register}
              />
              <div style={{ width: 40 }} />
              <Inputbox
                label="Phone Number"
                type="text"
                placeholder="9876543210"
                name="phone"
                value={info?.patientId?.phone}
                style={{height:42}}
              />
              <div style={{ width: 40 }} />
              <Inputbox label="Gender" type="text" placeholder="Male" name="gender" register={register}  />
            </div>

            {/* <div style={{ width: "32.5%" }}>
              <Inputbox label="Street" type="text" placeholder="Street Name" name="address.street" register={register}  />
            </div> */}
            <div style={{ width: "32.5%" }}>
              <Inputbox label="City" type="text" placeholder="City Name" name="address.city" register={register}  />
            </div>
            {/* <div style={{ width: "32.5%" }}>
              <Inputbox label="State" type="text" placeholder="State Name" name="address.state" register={register}  />
            </div>
            <div style={{ width: "32.5%" }}>
              <Inputbox label="Country" type="text" placeholder="Country Name" name="address.country" register={register}  />
            </div>
            <div style={{ width: "32.5%" }}>
              <Inputbox label="ZipCode" type="text" placeholder="ZipCode Name" name="address.zipCode" register={register}  />
            </div> */}

            <div className="Appointment_Info_txt">Appointment Info:</div>

            <div className="input_space_div">
              <Inputbox
                label="Appointment Type"
                type="text"
                placeholder="Follow-Up"
              />
              <div style={{ width: 40 }} />
              <Inputbox
                label="Appointment Status"
                type="text"
                placeholder="Pending"
              />
              <div style={{ width: 40 }} />
              <Inputbox
                label="Appointment Time"
                type="text"
                placeholder="Monday at 01:00 to 1:30"
              />
            </div>
            <div style={{ width: "32.5%" }}>
              <Inputbox
                label="Appointment Reason"
                type="text"
                placeholder="Root canal treatment, cracked teeth crowned, missing teeth repaired with bridges dentures, and implants today."
              />
            </div>
            <div className="form-actions" style={{ alignSelf: 'center', justifyContent: 'center', gap: "10%" }}>
              <button type="button" className="decline-btn" style={{ width: "30%" }}>
                Decline
              </button>
              <button type="submit" className="Confirm-btn" style={{ width: "30%" }}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default PatientDetailsDoctor