import React, { useEffect, useState } from "react";
import AgencySideBar from "../Components/AgencySideBar";
import Navbar from "../../Components/Navbar";
import Inputbox from "../../Components/Inputbox";
import Credentialdata from "./Credentialdata";
import Specialtiesdata from "./Specialtiesdata";
import TreatmentMethods from "./TreatmentMethods";
import Calendarcontant from "./Calendarcontant";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  DropDownManu,
  DropDownManuCustom,
} from "../../Components/DropDownButton";
import {
  GetCategory,
  getDetailsAgencyDoctor,
  updateAgencyDoctor,
} from "../../../api/helper";
import { ShowToast } from "../../../helpers/ToastService";
import moment from "moment";
import {  useLoadScript, Autocomplete } from '@react-google-maps/api';

const DoctorDetailsclinic = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("info");
  const [info, setInfo] = useState(location.state.data);
  // console.log(info,'========>info')
  const [special, setSpecial] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBTgjMWeFMxL5oe-KFnKts3YGBZJlEC6eM',
    libraries: ['places'], 
  });
  const newData = {
    id: info._id,
    email: info.email,
    gender: info.gender,
    phone: info.phone,
    experience: info.experience,
    phone: info.phone,
    gender: info.gender,
    DOB: moment(info?.DOB).format("YYYY-MM-DD"),
    info:info.info,
    address:{
      street: info?.address?.street || "",
      city: info?.address?.city || "",
      state: info?.address?.state || "",
      country: info?.address?.country || "",
      zipCode: info?.address?.zipCode || "",
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      ...newData,
    },
  });

  useEffect(() => {
    reset({
      ...newData,
    });
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const options = [
    { _id: "0-1", name: "0-1 (New or Entry Level)" },
    { _id: "2-5", name: "2-5 (Early Career)" },
    { _id: "6-10", name: "6-10 (Experienced)" },
    { _id: "11-15", name: "11-15 (Advanced)" },
    { _id: "16-20+", name: "16-20+ (Expert or Senior Level)" }
  ];

  const handleChange = (e) => {
    reset({
      ...newData,
      phone: e,
    });
  };

  const Specialization = async () => {
    try {
      const resposne = await GetCategory();
      setSpecial(resposne.data.data);
    } catch (error) {
      console.log(error, "===>>>>error");
    }
  };

  useEffect(() => {
    Specialization();
  }, []);

  const onSubmit = (data) => {
    dataToSave(data);
  };

  const dataToSave = async (data) => {
    try {
      const response = await updateAgencyDoctor(data);
      ShowToast(response.data.message, "success");
      getDteails();
    } catch (error) {
      console.log(error);
    }
  };

  const getDteails = async () => {
    const response = await getDetailsAgencyDoctor(info._id);
    console.log(response.data.data, "===response>>>>>");
    setInfo(response.data.data);
  };
  // useEffect(()=>{
  //   getDteails()
  // },[])
  const handlePlaceSelect = async () => {
    const place = autocomplete.getPlace();
    const addressComponents = place.address_components;
  
    // Function to get address component by type
    const getAddressComponent = (type) => {
      const component = addressComponents.find((c) => c.types.includes(type));
      return component ? component.long_name : "";
    };
  
    // Set country, state, and city
    setValue("address.country", getAddressComponent("country"));
    setValue("address.state", getAddressComponent("administrative_area_level_1"));
    setValue("address.city", getAddressComponent("locality"));
  
    // Extract street number, route, sublocality (for sectors), neighborhood, premise (building), establishment (business)
    const streetNumber = getAddressComponent("street_number");
    const route = getAddressComponent("route");
    const sublocality = getAddressComponent("sublocality");
    const neighborhood = getAddressComponent("neighborhood");
    const premise = getAddressComponent("premise"); // Building name or room number
    const establishment = getAddressComponent("establishment"); // Business names
  
    // Declare fullStreet and build it
    let fullStreet = "";
  
    // Priority handling: check if it's an establishment (business), premise, or standard street address
    if (establishment) {
      fullStreet = establishment; // Use business name if available
    } else if (premise) {
      fullStreet = `${premise}, ${route || sublocality || neighborhood}`; // Use building/room with route
    } else if (streetNumber && route) {
      fullStreet = `${streetNumber} ${route}`; // Standard street address
    } else if (sublocality) {
      fullStreet = sublocality; // For sectors
    } else if (neighborhood) {
      fullStreet = neighborhood; // If categorized as neighborhood
    }
  
    // Fallback: if no fullStreet found, use locality, state, country
    if (!fullStreet) {
      fullStreet = `${getAddressComponent("locality")}, ${getAddressComponent("administrative_area_level_1")}, ${getAddressComponent("country")}`;
    }
  
    // Set the full street address in the form
    setValue("address.street", fullStreet);
  
    // Set the zip code
    setValue("address.zipCode", getAddressComponent("postal_code"));
  };
  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="dashboard-container">
      <AgencySideBar />
      <div className="dashboard-content">
        <Navbar />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div className="doctor-details-container">
            <div className="Doctor_Details_txtt">Doctor Details</div>
            <div className="doctor-header">
              <img
                className="doctor-image"
                src={
                  info?.image
                    ? info?.image
                    : require("../../assest/Images/doctorimg.png")
                }
                alt="Doctor"
              />
              <div className="doctor-info">
                <h3 style={{ textAlign: "left" }}>
                  Dr. {info?.firstName + info?.lastName}
                </h3>
                <p style={{ textAlign: "left", marginBottom: 5, marginTop: 5 }}>
                  {info.categoryId.name}
                </p>
                {/* <p style={{ textAlign: 'left', marginBottom: 5, marginTop: 5 }}>Sr. Psychiatrist</p> */}

                <div className="rating">{/* <span>⭐⭐⭐⭐☆</span> */}</div>
                {/* <p style={{ textAlign: 'left', marginTop: 5 }}>(0 Reviews)</p> */}
              </div>
            </div>

            <div className="tabs-container" style={{ paddingRight: 20 }}>
              <button
                className={`tab-button ${activeTab === "info" ? "active" : ""}`}
                onClick={() => handleTabClick("info")}
              >
                Info.
              </button>
              <button
                className={`tab-button ${
                  activeTab === "credentials" ? "active" : ""
                }`}
                onClick={() => handleTabClick("credentials")}
              >
                Credentials
              </button>
              <button
                className={`tab-button ${
                  activeTab === "specialties" ? "active" : ""
                }`}
                onClick={() => handleTabClick("specialties")}
              >
                Specialties and Conditions Treated
              </button>
              <button
                className={`tab-button ${
                  activeTab === "treatment" ? "active" : ""
                }`}
                onClick={() => handleTabClick("treatment")}
              >
                Treatment Methods
              </button>
              <button
                className={`tab-button ${
                  activeTab === "calendar" ? "active" : ""
                }`}
                onClick={() => handleTabClick("calendar")}
              >
                Calendar
              </button>
            </div>

            <div className="tab-content">
              {activeTab === "info" && (
                <div>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="doctor-form"
                  >
                    <div className="input-row">
                      <Inputbox
                        label="Email Address"
                        type="text"
                        placeholder="docto123@gmail.com"
                        name="email"
                        register={register}
                        disabled
                      />
                      <div style={{ width: "40px" }} />
                      <DropDownManuCustom
                        label="Gender"
                        placeholder="Select option"
                        options={[
                          { _id: "Male", name: "Male" },
                          { _id: "Female", name: "Female" },
                          { _id: "Other", name: "Other" },
                        ]}
                        name="gender"
                        dropdownicon
                        register={register}
                        id="_id"
                        clearErrors={clearErrors}
                        defaultValue={info?.gender}
                        // onSelect={(selectedOption) => console.log(selectedOption)} // Handle selected option
                      />
                    </div>
                    <div className="input-row">
                      <Inputbox
                        style={{ width: "100%", height: 43 }}
                        label="Phone Number"
                        type="text"
                        placeholder="9878654523210"
                        name="phone"
                        value={info?.phone}
                        onChange={handleChange}
                      />
                      <div style={{ width: "40px" }} />
                      <DropDownManuCustom
                        label="Experience (Years)"
                        placeholder="Select option"
                        options={options}
                        name="experience"
                        dropdownicon
                        register={register}
                        id="_id"
                        clearErrors={clearErrors}
                        defaultValue={info?.experience}
                      />
                    </div>
                    <div className="input-row">
                   
                      <div style={{ width: "100%" }}>
                        <DropDownManu
                          label="Gender"
                          placeholder="Select option"
                          options={[
                            { name: "Male" },
                            { name: "Female" },
                            { name: "Other" },
                          ]}
                          name="gender"
                          dropdownicon
                          register={register}
                        />
                      </div>
                      <div style={{ width: "40px" }} />
                      <div style={{ width: "100%", marginTop: -20 }}>
                        <Inputbox
                          label="Date of Birth"
                          type="date"
                          name="DOB"
                          register={register}
                          style={{
                            borderColor: errors.DOB ? "red" : "initial",
                            height: 40,
                          }}
                        />
                      </div>
                    </div>
{/*  */}


<div className="input-row">
                   
                   <div style={{ width: "100%" }}>
                   
                   <Autocomplete
            onLoad={autocompleteInstance => setAutocomplete(autocompleteInstance)}
            onPlaceChanged={handlePlaceSelect}
          >
            <Inputbox
              label="Street"
              type="text"
              placeholder="Enter Address"
              name="address.street"
              register={register}
            />
          </Autocomplete>

                   </div>
                   <div style={{ width: "40px" }} />
                   <div style={{ width: "100%",  }}>
                    
                   <Inputbox
                  label="City"
                  type="text"
                  placeholder="Enter City"
                  name="address.city"
                  register={register}
                />
                
                   </div>
                 </div>


                 <div className="input-row">
                   
                   <div style={{ width: "100%" }}>
                   <Inputbox
                  label="State"
                  type="text"
                  placeholder="Enter State"
                  name="address.state"
                  register={register}
                />

                   </div>
                   <div style={{ width: "40px" }} />
                   <div style={{ width: "100%",  }}>
                   <Inputbox
                  label="Country"
                  type="text"
                  placeholder="Enter Country"
                  name="address.country"
                  register={register}
                />

                   </div>
                 </div>

{/*  */}
                 <div className="input-row">
                 <Inputbox
                  label="Zip Code"
                  type="text"
                  placeholder="Enter Zip Code"
                  name="address.zipCode"
                  register={register}
                />
                 </div>


                    {/*  */}
                    <div className="input-row">
                   
                   <div style={{ width: "100%" }}>
                   <Inputbox
                  label="Info"
                  placeholder="Enter info"
                  name="info"
                  register={register}
                  style={{ borderColor: errors.info ? "red" : "initial" }}
                />
                   </div>
                   <div style={{ width: "40px" }} />
                   <div style={{ width: "100%", }}>
                   <DropDownManuCustom
                    label="Language"
                    placeholder="Select language"
                    options={[
                      { _id: "english", name: "English" },
                      { _id: "spanish", name: "Spanish" },
                    ]}
                    name="language"
                    register={register}
                    clearErrors={clearErrors}
                    defaultValue={info?.language}
                    // Pass trigger here
                    style={{ borderColor: errors.language ? "red" : "initial" }}
                  />
                   </div>
                 </div>

















                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: 30,
                      }}
                    >
                      <div className="cancelButton">Cancel</div>
                      <button
                        type="submit"
                        className="savebuttonn"
                        style={{ width: 340 }}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {activeTab === "credentials" && (
                <Credentialdata info={info} getDteails={getDteails} />
              )}
              {activeTab === "specialties" && (
                <Specialtiesdata
                  info={info}
                  special={special}
                  getDteails={getDteails}
                />
              )}
              {activeTab === "treatment" && <TreatmentMethods info={info} />}
              {activeTab === "calendar" && <Calendarcontant info={info} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorDetailsclinic;
