import React, { useState } from "react";
import AgencySideBar from "../Components/AgencySideBar";
import Navbar from "../../Components/Navbar";
import TodayAppointmentsforclinic from "./TodayAppointmentsforclinic";
import Clinicaldata from "./Clinicaldata";
import Compliancedata from "./Compliancedata";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";
import { useDispatch, useSelector } from "react-redux";

const DashboradScreen = () => {
  const [activeButton, setActiveButton] = useState("Today Appointments");
  const { isLoading } = useSelector((store) => store.sliceReducer);
  const dispatch = useDispatch();

  
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  const accountData = [
    { label: "Insurance", value: "0" },
    { label: "Private", value: "0" },
    { label: "Subscription", value: "0" },
  ];

  const careTeamData = [
    { label: "Active", value: "0" },
    { label: "Inactive", value: "0" },
  ];

  const bookingsData = [
    { label: "Created", value: "0" },
    { label: "Approved", value: "0" },
    { label: "Rejected", value: "0" },
  ];
  const navigate = useNavigate();
  return (
    <div className="dashboard-container">
      {isLoading && <Loader/>}
      <AgencySideBar />
      <div className="dashboard-content">
        <Navbar
          onClick={() => {
            navigate("/MyProfileAgency");
          }}
        />
        {/* <Loader/> */}

        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30 }}
        >
          <div
            style={{
              backgroundColor: "rgba(103, 197, 55, 0.3)",
              padding: 20,
              width: "30%",
              borderRadius: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ color: "rgba(103, 197, 55, 1)", fontSize: 20 }}>
                ACCOUNT
              </div>
              <img src={require("../../assest/Images/Accounticon.png")} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <div>
                <div style={{ fontSize: 14, marginBottom: 10 }}>INSURANCE</div>
                <div>0</div>
              </div>
              <div>
                <div style={{ fontSize: 14, marginBottom: 10 }}>PRIVATE </div>
                <div>0</div>
              </div>
              <div>
                <div style={{ fontSize: 14, marginBottom: 10 }}>
                  SUBSCRIPTION
                </div>
                <div>0</div>
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "rgba(20, 174, 209, 0.3)",
              padding: 20,
              width: "30%",
              borderRadius: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ color: "rgba(20, 174, 209, 1)", fontSize: 20 }}>
                CARE TEAM
              </div>
              <img src={require("../../assest/Images/Careteamicon.png")} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                marginTop: 10,
              }}
            >
              <div>
                <div style={{ fontSize: 14, marginBottom: 10 }}>ACTIVE </div>
                <div>0</div>
              </div>
              <div>
                <div style={{ fontSize: 14, marginBottom: 10 }}>INACTIVE </div>
                <div>0</div>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "rgba(219, 0, 255, 0.3)",
              padding: 20,
              width: "30%",
              borderRadius: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ color: "rgba(84, 101, 240, 1)", fontSize: 20 }}>
                BOOKING
              </div>
              <img src={require("../../assest/Images/Bookingsicon.png")} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <div>
                <div style={{ fontSize: 14, marginBottom: 10 }}>CREATED </div>
                <div>0</div>
              </div>
              <div>
                <div style={{ fontSize: 14, marginBottom: 10 }}>APPROVED </div>
                <div>0</div>
              </div>
              <div>
                <div style={{ fontSize: 14, marginBottom: 10 }}>REJECTED </div>
                <div>0</div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="dashboard-actions"
          style={{ paddingLeft: 30, marginTop: 20 }}
        >
          <button
            className={`action-btn ${
              activeButton === "Today Appointments" ? "active" : ""
            }`}
            style={{
              marginRight: 30,
              borderWidth: 1,
              borderColor: "#CCCCCC",
              borderStyle: "solid",
            }}
            onClick={() => handleButtonClick("Today Appointments")}
          >
            Today Appointments
          </button>
          <button
            className={`action-btn ${
              activeButton === "Clinical" ? "active" : ""
            }`}
            style={{
              marginRight: 30,
              borderWidth: 1,
              borderColor: "rgba(204, 204, 204, 1)",
              borderStyle: "solid",
            }}
            onClick={() => handleButtonClick("Clinical")}
          >
            Clinical
          </button>
          <button
            className={`action-btn ${
              activeButton === "Compliance" ? "active" : ""
            }`}
            style={{
              marginRight: 30,
              borderWidth: 1,
              borderColor: "rgba(204, 204, 204, 1)",
              borderStyle: "solid",
            }}
            onClick={() => handleButtonClick("Compliance")}
          >
            Compliance
          </button>
        </div>

        <div>
          {activeButton === "Today Appointments" && (
            <TodayAppointmentsforclinic />
          )}
          {activeButton === "Clinical" && <Clinicaldata />}
          {activeButton === "Compliance" && <Compliancedata />}
        </div>
      </div>
    </div>
  );
};

export default DashboradScreen;
