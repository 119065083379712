import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";

const schema = yup.object().shape({
  medication: yup.string().required("Medication is required"),
  code: yup
    .string()
    .matches(/^[A-Za-z0-9]+$/, "Code should be alphanumeric")
    .required("Code is required"),
  startDate: yup.date().required("Start Date is required"),
  endDate: yup
    .date()
    .min(yup.ref("startDate"), "End Date cannot be before Start Date")
    .required("End Date is required"),
});
const Medicationspatient = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

    const {
      control,
      handleSubmit,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });
  
  const onSubmit = (values) => {
    const formattedStartDate = values?.startDate
    ? moment(values.startDate).format("YYYY-MM-DD")
    : null;
  const formattedEndDate = values?.endDate
    ? moment(values.endDate).format("YYYY-MM-DD")
    : null;
    console.log("Form data", values);
  };

  return (
    <div className="patient_right">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignSelf: "flex-end",
          marginTop: 20,
          marginRight: 20,
        }}
      >
        <img src={require("../assest/Images/ViewPrescriptions.png")} />
        <div className="view_Prescriptions_txt">View Prescriptions</div>
        <div
          className="custom-problem-add-button_two"
          onClick={toggleFormVisibility}
        >
          <img
            src={require("../assest/Images/plus_icon.png")}
            style={{ marginRight: 10, filter: "invert(1) brightness(5)" }}
          />{" "}
          Add
        </div>
      </div>
      <div className="medication-table-container">
        <div className="medication-header">
          <div className="medication-header-item">Medication</div>
          <div className="medication-header-item">|</div>
          <div className="medication-header-item">Period</div>
          <div className="medication-header-item">|</div>
          <div className="medication-header-item">Status</div>
          <div className="medication-header-item">|</div>
          <div className="medication-header-item">Refills</div>
          <div className="medication-header-item">|</div>
          <div className="medication-header-item">Dosage Instructions</div>
        </div>

        <div className="medication-empty-state">
          <div className="medication-empty-title">Nothing Here Yet</div>
          <div className="medication-empty-description">
            No health records available for this resource
          </div>
        </div>
      </div>
<div>
  {/* dummy data */}
</div>
      {isFormVisible && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="add_problem_div">
            <div className="Add_problem_txtt">
              Add Medication
              <img
                src={require("../assest/Images/crossIconn.png")}
                alt="close"
                onClick={toggleFormVisibility}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="Record_Details_txtt">Record Details</div>

            <div className="problem_div_txtt" >
              Medication
              <div>
              <Controller
          name="medication"
          control={control}
          render={({ field }) => (
            <select {...field} className="select_option_style"  style={{width:'100%'}}>
              <option value="">- Select a medication</option>
              <option>Headache</option>
                      <option>Fever</option>
                      <option>Stomach Pain</option>
                      <option>Back Pain</option>
                      <option>Cough</option>
                      <option>Fatigue</option>
                      <option>Cold</option>
            </select>
          )}
        />
        {errors.medication && <p className="error-message">{errors.medication.message}</p>}
      </div>
            </div>

            <div className="code_txt_div">
              Code
              <div>
              <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <input type="text" placeholder="-" className="input_num_style" {...field} style={{width:'60%',marginLeft:100}}/>
          )}
        />
        {errors.code && <p className="error-message">{errors.code.message}</p>}
      </div>
            </div>

            <div className="code_txt_div" >
              Start Date
              <div>
              <Controller
          name="startDate"
          control={control}
          render={({ field }) => (
            <input type="date" className="input_num_style" style={{width:'100%'}} {...field} />
          )}
        />
        {errors.startDate && <p className="error-message">{errors.startDate.message}</p>}
      </div>
            </div>

            <div className="code_txt_div">
              End Date
              <div>
              <Controller
          name="endDate"
          control={control}
          render={({ field }) => (
            <input type="date" className="input_num_style" style={{width:'100%'}} {...field} />
          )}
        />
        {errors.endDate && <p className="error-message">{errors.endDate.message}</p>}
      </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div className="Dosage_Instructions_txtt">
                Dosage Instructions
              </div>
              <div className="optional_txtt">Optional</div>
            </div>
            <div className="code_txt_div">
              Action
              <select className="select_option_style">
                <option>- Select an action</option>
              </select>
            </div>

            <div className="code_txt_div">
              Dose
              <select className="select_option_style">
                <option>- Select a dose</option>
              </select>
            </div>
            <div className="code_txt_div">
              Unit
              <select className="select_option_style">
                <option>- Select a Unit</option>
              </select>
            </div>
            <div className="code_txt_div">
              Route
              <select className="select_option_style">
                <option>- Select a Route</option>
              </select>
            </div>
            <div className="code_txt_div">
              Frequency
              <select className="select_option_style">
                <option>- Select a Frequency</option>
              </select>
            </div>
            <div className="code_txt_div">
              Directions
              <select className="select_option_style">
                <option>- Select a Directions</option>
              </select>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="care_plan_txtt">Additional Instructions</div>
              <div className="code_txt_div">
                <textarea
                  placeholder="Add patient instructions                   Optional"
                  className="input_num_style"
                  style={{ height: "80px", width: "100%" }}
                />
              </div>

              {/* Action Buttons */}
              <div className="action_buttons">
                <button
                  className="cancel_button"
                  onClick={toggleFormVisibility}
                >
                  Cancel
                </button>
                <button type="submit"  className="save_button">Save</button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Medicationspatient;
