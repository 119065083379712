import React, { useState } from 'react'

const VitalsPatient = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };
  return (
    <div className="patient_right">
    <div style={{display:'flex',alignItems:'center',alignSelf:'flex-end',marginTop:20,marginRight:20}}>
     <img src={require('../assest/Images/NKDA_icon.png')}/>
     <div className='view_Prescriptions_txt'>NKDA</div>
     <div className="custom-problem-add-button_two" onClick={toggleFormVisibility}>
     <img src={require('../assest/Images/plus_icon.png')}      style={{ marginRight: 10, filter: 'invert(1) brightness(5)' }}  />    Add
             </div>
    </div>
     <div className="medication-table-container">
     <div className="medication-header_t">
         <div className="medication-header-item_t">Record Date</div>
         <div className="medication-header-item_t">BP</div>
         <div className="medication-header-item_t">HR</div>
         <div className="medication-header-item_t">RR</div>
         <div className="medication-header-item_t">Temp</div>
         <div className="medication-header-item_t">Sp02</div>
         <div className="medication-header-item_t">WT</div>
         <div className="medication-header-item_t">HT</div>
         <div className="medication-header-item_t">BMI</div>
         <div className="medication-header-item_t">PF</div>
     </div>
 
     <div className="medication-empty-state">
         <div className="medication-empty-title">Nothing Here Yet</div>
         <div className="medication-empty-description">No health records available for this resource</div>
     </div>
 
   
 </div>
 {isFormVisible && (
   <div className="add_problem_div2">
   <div className="Add_problem_txtt1">
   Add Vitals
       {/* Close Icon */}
       <img
         src={require("../assest/Images/crossIconn.png")}
         alt="close"
         onClick={toggleFormVisibility}  // Hides form when clicked
         style={{ cursor: "pointer" }}
       />
     </div>
     <p className='Record_Details_txt'>Record Details</p>
     <div className='div_input_Blood_txt'>
     <div className='dic_Blood_txt'><p className='Blood_txt'>Blood Pressure</p></div>
     <input type='text' className='input_txt' placeholder='0  mmHg'/>
     <input type='text' className='input1_txt' placeholder='0  mmHg'/>
     </div>
     <div className='div_input_Blood_txt'>
     <div className='dic_Blood_txt'><p className='Blood_txt'>Heart Rate</p></div>
     <input type='text' className='input_txt' placeholder='0  bpm'/>
     </div>

     <div className='div_input_Blood_txt'>
     <div className='dic_Blood_txt'><p className='Blood_txt'>Respiratory Rate</p></div>
     <input type='text' className='input_txt' placeholder='0  bpm'/>
     </div>
     <div className='div_input_Blood_txt'>
     <div className='dic_Blood_txt'><p className='Blood_txt'>Temperature</p></div>
     <input type='text' className='input_txt' placeholder='0  bpm'/>
     </div>
     <div className='div_input_Blood_txt'>
     <div className='dic_Blood_txt'><p className='Blood_txt'>Sp02</p></div>
     <input type='text' className='input_txt' placeholder='0  bpm'/>
     </div>
     <div className='div_input_Blood_txt'>
     <div className='dic_Blood_txt'><p className='Blood_txt'>Weight</p></div>
     <input type='text' className='input_txt' placeholder='0  bpm'/>
     </div>
     <div className='div_input_Blood_txt'>
     <div className='dic_Blood_txt'><p className='Blood_txt'>Blood Pressure</p></div>
     <input type='text' className='input_txt' placeholder='0  ft'/>
     <input type='text' className='input1_txt' placeholder='0  in'/>
     </div>
     <div className='div_input_Blood_txt'>
     <div className='dic_Blood_txt'><p className='Blood_txt'>BMI</p></div>
     <input type='text' className='input_txt' placeholder='0  bpm'/>
     </div>
     <div className='div_input_Blood_txt'>
     <div className='dic_Blood_txt'><p className='Blood_txt'>Peak Flow</p></div>
     <input type='text' className='input_txt' placeholder='0  bpm'/>
     </div>
     <div className='div_input_Blood_txt'>
     <div className='dic_Blood_txt'><p className='Blood_txt'>Waist Circumference</p></div>
     <input type='text' className='input_txt' placeholder='0  bpm'/>
     </div>
     <div className='div_input_Blood_txt'>
     <div className='dic_Blood_txt'><p className='Blood_txt'>Head Circumference</p></div>
     <input type='text' className='input_txt' placeholder='0  bpm'/>
     </div>
     <div className='div_input_Blood_txt1'>
     <div className='dic_Blood_txt'><p className='Blood_txt'>Memo</p></div>
     <input type='text' className='input_txt1'/>
     </div>
     <p className='Recorded_On_txt'>Recorded On</p>

     <div className='div_input_Blood_txt'>
     <p className='Blood_txt'>Use Current Date and Time</p>
     <img className='Image_notificaton' src={require("../assest/Images/Notification.png")} />
     </div>

     <div className='div_input_Blood_txt'>
     <div className='dic_Blood_txt'><p className='Blood_txt'>Date</p></div>
     <input type='text' className='input_txt' placeholder='dd/mm/yy'/>
     </div>
     <div className='div_input_Blood_txt'>
     <div className='dic_Blood_txt'><p className='Blood_txt'>Time</p></div>
     <input type='text' className='input_txt' placeholder='--:-- --'/>
     </div>

     <div className='div_button'>
      <p className='button_Canclel'>Cancel</p>
      <p className='button_Save'>Save</p>
     </div>
    
     </div>
 )}
 </div>
  )
}

export default VitalsPatient