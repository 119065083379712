// import React, { useEffect, useRef, useState } from "react";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";
// import Inputbox from "../../Components/Inputbox";
// import DropDownButton, {
//   DropDownManu,
//   DropDownManuCustom,
// } from "../../Components/DropDownButton";
// import { useNavigate } from "react-router-dom";
// import {
//   Clinicrequestlist,
//   createAgencyDoctor,
//   createAgencyPatient,
//   PatientSignup,
//   uploadImage,
// } from "../../../api/helper";
// import { ShowToast } from "../../../helpers/ToastService";
// import { useDispatch } from "react-redux";
// import {
//   setHeaderToken,
//   setLoginType,
//   setUserDetails,
// } from "../../../redux/cookiesReducer";
// import { Store } from "../../../redux";
// import { Autocomplete, useLoadScript } from "@react-google-maps/api";

// const validationSchema = Yup.object().shape({
//   firstName: Yup.string()
//     .required("First Name is required")
//     .min(2, "First Name must be at least 2 characters"),

//   lastName: Yup.string()
//     .required("Last Name is required")
//     .min(2, "Last Name must be at least 2 characters"),

//   email: Yup.string()
//     .email("Invalid email format")
//     .required("Email is required"),

//   phone: Yup.string()
//     .required("Phone is required")
//     .matches(/^[0-9]+$/, "Phone number must be digits")
//     .min(7, "Phone number must be at least 6 digits")
//     .max(15, "Phone number cannot exceed 15 digits"),

//   gender: Yup.string().required("Gender is required"),
//   password: Yup.string()
//   .required("Password is required")
//   .min(12, "Password must be at least 12 characters")
//   .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
//   .matches(/[a-z]/, "Password must contain at least one lowercase letter")
//   .matches(/[0-9]/, "Password must contain at least one number")
//   .matches(/[!@#$%^&*_,]/, "Password must contain at least one special character (!, @, #, $, %, ^, &, *, _)")
//   .notOneOf(["password", "12345", "qwerty"], "Password is too common or easy to guess") // Add more common words as needed
//   .test("no-repeated-characters", "Password should not contain repeated characters like 'aaaa' or '1111'", value => {
//     return !/(.)\1{3,}/.test(value); // Adjust this to your repeated character tolerance
//   })
//   .test("no-simple-patterns", "Password should not contain common patterns like 'abcd' or '1234'", value => {
//     const patterns = ["abcd", "1234", "qwerty"]; // Add more patterns as needed
//     return !patterns.some(pattern => value.includes(pattern));
//   }),

// confirmPassword: Yup.string()
//   .oneOf([Yup.ref("password"), null], "Passwords must match")
//   .required("Confirm password is required"),
  
//   address: Yup.object().shape({
//     // street: Yup.string().required('Street is required'),
//     city: Yup.string().required("City is required"),
//     state: Yup.string().required("State is required"),
//     country: Yup.string().required("Country is required"),
//     zipCode: Yup.string()
//       .required("Zip code is required")
//       // .matches(/^[0-9]+$/, 'Zip code must be digits')
//       .min(5, "Zip code must be at least 5 digits")
//       .max(10, "Zip code cannot exceed 10 digits"),
//   }),
//   DOB: Yup.date()
//     .typeError("Date of Birth must be a valid date")
//     .required("Date of Birth is required")
//     .max(new Date(), "Date of Birth cannot be in the future"),
//   ssn: Yup.string()
//     .required("SSN is required")
//     .matches(/^[0-9]+$/, "Ssn must be digits")
//     .min(4, "Ssn must be at least 4 digits")
//     .max(4, "Ssn cannot exceed 4 digits"),
// });

// const SignupPatient = () => {
//   const {
//     register,
//     handleSubmit,
//     watch,
//     clearErrors,
//     reset,
//     setValue,
//     formState: { errors },
//   } = useForm({
//     resolver: yupResolver(validationSchema),
//   });

//   const handleChange = (e) => {
//     reset({
//       phone: e,
//     });
//   };

//   const dispatch = useDispatch();
//   const [showModal, setShowModal] = useState(false);
//   const [saveData, setSaveData] = useState();
//   const fileInputRef = useRef(null);
//   const [imaeg, setImages] = useState(null);
//   const [imagePreview, setImagePreview] = useState(null);
//   const [formSubmit, setFormSubmit] = useState(null);
//   const [cliniclistdata, setcliniclistdata] = useState(null);
//   const [autocomplete, setAutocomplete] = useState(null);
//   const navigate = useNavigate();

// console.log(cliniclistdata,"cliniclistdata====")

//   const Fetchclinic = async () => {
//     try {
//       const response = await Clinicrequestlist();
//       const data = response?.data?.data?.data?.map((e) => {
//         return {
//           name: `${e.companyName} / ${e.uniqueId}`,
//           ...e,
//         };
//       });
//       setcliniclistdata(data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   // Ensure useEffect is at the top level, not inside any condition
//   useEffect(() => {
//     Fetchclinic();
//   }, []);
//   const { isLoaded } = useLoadScript({
//     googleMapsApiKey: "AIzaSyBTgjMWeFMxL5oe-KFnKts3YGBZJlEC6eM", // Replace with your Google Maps API key
//     libraries: ["places"], // Include 'places' library for autocomplete
//   });
//   if (!isLoaded) return <div>Loading...</div>;
//   const handlePlaceSelect = async () => {
//     const place = autocomplete.getPlace();
//     const addressComponents = place.address_components;
  
//     // Function to get address component by type
//     const getAddressComponent = (type) => {
//       const component = addressComponents.find((c) => c.types.includes(type));
//       return component ? component.long_name : "";
//     };
  
//     // Set country, state, and city
//     setValue("address.country", getAddressComponent("country"));
//     setValue("address.state", getAddressComponent("administrative_area_level_1"));
//     setValue("address.city", getAddressComponent("locality"));
  
//     // Extract street number, route, sublocality (for sectors), neighborhood, premise (building), establishment (business)
//     const streetNumber = getAddressComponent("street_number");
//     const route = getAddressComponent("route");
//     const sublocality = getAddressComponent("sublocality");
//     const neighborhood = getAddressComponent("neighborhood");
//     const premise = getAddressComponent("premise"); // Building name or room number
//     const establishment = getAddressComponent("establishment"); // Business names
  
//     // Declare fullStreet and build it
//     let fullStreet = "";
  
//     // Priority handling: check if it's an establishment (business), premise, or standard street address
//     if (establishment) {
//       fullStreet = establishment; // Use business name if available
//     } else if (premise) {
//       fullStreet = `${premise}, ${route || sublocality || neighborhood}`; // Use building/room with route
//     } else if (streetNumber && route) {
//       fullStreet = `${streetNumber} ${route}`; // Standard street address
//     } else if (sublocality) {
//       fullStreet = sublocality; // For sectors
//     } else if (neighborhood) {
//       fullStreet = neighborhood; // If categorized as neighborhood
//     }
  
//     // Fallback: if no fullStreet found, use locality, state, country
//     if (!fullStreet) {
//       fullStreet = `${getAddressComponent("locality")}, ${getAddressComponent("administrative_area_level_1")}, ${getAddressComponent("country")}`;
//     }
  
//     // Set the full street address in the form
//     setValue("address.street", fullStreet);
  
//     // Set the zip code
//     setValue("address.zipCode", getAddressComponent("postal_code"));
//   };

//   const handleSignUp = async (data) => {
//     setSaveData(data);
//     formSubmit ? submitData() : setShowModal(true);
//   };

//   const handleCloseModal = async (data) => {
//     if (data == "Upload") {
//       if (!imagePreview) {
//         return ShowToast("Please Select Image", "error");
//       }
//       saveData.image = imaeg;
//     }
//     setFormSubmit(data);
//     setShowModal(false);
//     submitData();
//   };

//   const submitData = async () => {
//     try {
//       // console.log(saveData,"saveData==")
//       const resposne = await PatientSignup(saveData);
//       ShowToast(resposne.data.message, "success");
//       Store.dispatch(setHeaderToken(resposne?.data?.data));
//       dispatch(setUserDetails(resposne.data.data));

//       if (saveData.agencyId) {
//         let saveToData = {
//           patientId: resposne?.data?.data?._id,
//           agencyId: saveData.agencyId,
//           status: "pending",
//           ...saveData,
//         };
//         await createAgencyPatient(saveToData);
//       }

//       Store.dispatch(setLoginType("Patient"));
//       navigate("/Dashboradpatient");
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleLogin = () => {
//     navigate("/");
//   };

//   const clickImageData = () => {
//     if (fileInputRef.current) {
//       fileInputRef.current.click();
//     }
//   };
//   // const Fetchclinic = async () => {
//   //   try {
//   //     const response = await Clinicrequestlist();
//   //     const data = response?.data?.data?.data?.map((e) => {
//   //       return {
//   //         name: `${e.firstName} ${e.lastName}`,
//   //         ...e,
//   //       };
//   //     });
//   //     setcliniclistdata(data);
//   //   } catch (error) {
//   //     console.log(error);
//   //   }
//   // };
//   // useEffect(() => {
//   //   Fetchclinic();
//   // }, []);

//   const imagePicker = async (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const formData = new FormData();
//       formData.append("images", file);
//       try {
//         const response = await uploadImage(formData);
//         setImages(response.data.data[0].URL);
//         const reader = new FileReader();
//         reader.onloadend = () => {
//           setImagePreview(reader.result);
//         };
//         reader.readAsDataURL(file);
//       } catch (error) {
//         console.log(error, "===");
//       }
//     }
//   };

//   return (
//     <div style={{ width: "100%", display: "flex", height: "100vh" }}>
//      <div
// style={{
//   width: "50%",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   backgroundColor: "#041C32",
//   flexDirection: "column",
// }}
// >
// {/* <img
// src={require("../assest/Images/SideLoginImg.png")}
// alt="Team Working"
// className="left-image"
// /> */}
// <img
//  src={require("../../assest/Images/mindWellIcon.png")}
//   style={{ height: "30%", width: "30%" }}
// />
// <div
//   style={{
//     color: "white",
//     fontWeight: "bold",
//     fontSize: "20px",
//     marginTop: "20px",
//   }}
// >
//   Your trusted platform for mental health services.
// </div>
// </div>

//       <div className="signup-container">
//         <h2>Sign up as Patient </h2>
//         <form onSubmit={handleSubmit(handleSignUp)}>
//           <div className="signup-form">
//             <div className="input-row">
//               <div className="form_div">
//                 <Inputbox
//                   label="Full Name"
//                   type="text"
//                   placeholder="First Name"
//                   name="firstName"
//                   register={register}
//                 />
//                 {errors.firstName && (
//                   <div className="errorMessage">
//                     {errors.firstName.message}
//                   </div>
//                 )}
//               </div>
//               <div className="form_div" style={{ marginTop: 32 }}>
//                 <Inputbox
//                   type="text"
//                   label=""
//                   placeholder="Last Name"
//                   name="lastName"
//                   register={register}
//                 />
//                 {errors.lastName && (
//                   <div className="errorMessage">
//                     {errors.lastName.message}
//                   </div>
//                 )}
//               </div>
//             </div>

//             <div className="input-row">
//               <div className="form_div">
//                 <Inputbox
//                   label="Email Address"
//                   type="email"
//                   placeholder="Enter Email"
//                   name="email"
//                   register={register}
//                 />
//                 {errors.email && (
//                   <div className="errorMessage">{errors.email.message}</div>
//                 )}
//               </div>
//               <div className="form_div_two">
//                 <Inputbox
//                   style={{ width: "100%", height: 47 }}
//                   label="Phone Number"
//                   type="text"
//                   placeholder="Enter Number"
//                   name="phone"
//                   onChange={handleChange}
//                 />
//                 {errors.phone && (
//                   <div className="errorMessage">{errors.phone.message}</div>
//                 )}
//               </div>
//             </div>
//             <div className="input-row">
//               <div className="form_div" style={{ marginTop: 20 }}>
//                 <DropDownManu
//                   label="Gender"
//                   placeholder="Select option"
//                   options={[
//                     { name: "Male" },
//                     { name: "Female" },
//                     { name: "Other" },
//                   ]}
//                   name="gender"
//                   dropdownicon
//                   register={register}

//                 />

//                 {errors.gender && (
//                   <div className="errorMessage">{errors.gender.message}</div>
//                 )}
//               </div>
//               <div className="form_div">
//                 <Inputbox
//                   label="Date of Birth"
//                   type="date"
//                   name="DOB"
//                   register={register}
//                   style={{
//                     borderColor: errors.DOB ? "red" : "initial",
//                     height: 40,
//                   }}
//                 />
//                 {errors.DOB && (
//                   <p className="errorMessage">{errors.DOB.message}</p>
//                 )}
//               </div>
//             </div>
//             <div className="input-row">
//               <div className="form_div">
//                 <Autocomplete
//                   onLoad={(autocompleteInstance) =>
//                     setAutocomplete(autocompleteInstance)
//                   }
//                   onPlaceChanged={handlePlaceSelect}
//                 >
//                   <Inputbox
//                     label="Street"
//                     type="text"
//                     placeholder="Enter Street"
//                     name="address.street"
//                     register={register}
//                     // value={"dfhbhdbg"}
//                   />
               
//                 </Autocomplete>
//               </div>
//               <div className="form_div">
//                 <Inputbox
//                   label="City"
//                   type="text"
//                   placeholder="Enter City"
//                   name="address.city"
//                   register={register}
//                 />
//                 {errors.address?.city && (
//                   <div className="errorMessage">
//                     {errors.address.city.message}
//                   </div>
//                 )}
//               </div>
//             </div>
//             <div className="input-row">
//               <div className="form_div">
//                 <Inputbox
//                   label="State"
//                   type="text"
//                   placeholder="Enter State"
//                   name="address.state"
//                   register={register}
//                 />
//                 {errors.address?.state && (
//                   <div className="errorMessage">
//                     {errors.address.state.message}
//                   </div>
//                 )}
//               </div>

//               <div className="form_div">
//                 <Inputbox
//                   label="Country"
//                   type="text"
//                   placeholder="Enter Country"
//                   name="address.country"
//                   register={register}
//                 />
//                 {errors.address?.country && (
//                   <div className="errorMessage">
//                     {errors.address.country.message}
//                   </div>
//                 )}
//               </div>
//             </div>
//             <div className="input-row">
//               <div className="form_div">
//                 <Inputbox
//                   label="Zip Code"
//                   type="text"
//                   placeholder="Enter Zip Code"
//                   name="address.zipCode"
//                   register={register}
//                 />
//                 {errors.address?.zipCode && (
//                   <div className="errorMessage">
//                     {errors.address.zipCode.message}
//                   </div>
//                 )}
//               </div>
//               <div className="form_div">
//                 <Inputbox
//                   label="SSN(Last Four Digits)"
//                   type="text"
//                   placeholder="Enter SSN"
//                   name="ssn"
//                   register={register}
//                   // style={{  height: 40 }}
//                 />
//                 {errors.ssn && (
//                   <p style={{ color: "red", textAlign: "left", marginTop: 5 }}>
//                     {" "}
//                     {errors.ssn.message}
//                   </p>
//                 )}
//               </div>
//             </div>
//             <div className="form_div">
              // <DropDownManuCustom
              //   label="Associated with"
              //   placeholder="Select option"
              //   options={cliniclistdata}
              //   name="agencyId"
              //   dropdownicon
              //   register={register}
              //   id="_id"
              //   clearErrors={clearErrors}
              // />
//               {errors.AssociationRequest && (
//                 <div className="errorMessage">
//                   {errors.AssociationRequest.message}
//                 </div>
//               )}
//             </div>
            // <div className="input-row">
            //   <div className="form_div">
            //     <Inputbox
            //       label="Password"
            //       type="password"
            //       placeholder="Enter Password"
            //       name="password"
            //       register={register}
            //       isPassword
            //     />
            //     {errors.password && (
            //       <div className="errorMessage">
            //         {errors.password.message}
            //       </div>
            //     )}
            //   </div>
            //   <div className="form_div">
            //     <Inputbox
            //       label={"Confirm Password"}
            //       type="password"
            //       placeholder="Enter Confirm Password"
            //       name="confirmPassword"
            //       register={register}
            //       isPassword
            //     />
            //     {errors.confirmPassword && (
            //       <div className="errorMessage">
            //         {errors.confirmPassword.message}
            //       </div>
            //     )}
            //   </div>
            // </div>

//             <div className="display_div_dis">
//               <div>
//                 <input type="checkbox" />
//               </div>
//               <div>
//                 <p className="I_have_txt">
//                   I have read and agreed to the{" "}
//                   <a href="#" style={{ color: "#bc9c22" }}>
//                     {" "}
//                     Terms & Conditions{" "}
//                   </a>
//                   and
//                   <a href="#" style={{ color: "#bc9c22" }}>
//                     {" "}
//                     Privacy Policy
//                   </a>
//                 </p>
//               </div>
//             </div>

//             <button className="login-button" type="submit">
//               Sign Up
//             </button>
//             <p className="login-text">
//               Already have an Account?{" "}
//               <a
//                 onClick={() => {
//                   navigate("/");
//                 }}
//                 style={{ cursor: "pointer" }}
//               >
//                 Log In
//               </a>
//             </p>
//           </div>
//         </form>
//       </div>
//       {showModal && (
//         <div className="modal_div">
//           <div className="logout-modal" style={{ backgroundColor: "#D9D9D9" }}>
//             <div
//               className="logout_txt"
//               style={{ color: "#011434", borderColor: "#011434" }}
//             >
//               Upload Profile Picture
//             </div>
//             <div
//               onClick={clickImageData}
//               style={{
//                 marginTop: 20,
//                 marginBottom: 20,
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               {imagePreview ? (
//                 <img
//                   src={imagePreview}
//                   alt="Preview"
//                   style={{ height: 150, width: 150, borderRadius: 80 }}
//                 />
//               ) : (
//                 <img
//                   src={require("../../assest//Images/Cameraprofile.png")}
//                   alt="Upload"
//                 />
//               )}

//               <input
//                 type="file"
//                 ref={fileInputRef}
//                 onChange={imagePicker}
//                 style={{ display: "none" }}
//               />
//             </div>

//             <button
//               className="confirm-button"
//               onClick={() => handleCloseModal("Upload")}
//               style={{ width: 200, color: "white", backgroundColor: "#BC9C22" }}
//             >
//               Upload
//             </button>
//             <div
//               style={{ marginTop: 20, cursor: "pointer" }}
//               onClick={() => handleCloseModal("Skip")}
//             >
//               Skip for now
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default SignupPatient;

import React, { useState } from 'react';

import PatientSignupTwo from './PatientSignupTwo';
import PatientSignupfirst from './PatientSignupfirst';

const SignupPatient = () => {
  const [showDoctorSignup, setShowDoctorSignup] = useState(false);
  const [signupData, setSignupData] = useState(null);

  const handleOnSubmit = (data) => {
    setSignupData(data);          
    setShowDoctorSignup(true);     
    console.log(data, "Received data in SignUp");
  };

  return (
    <div>
      {!showDoctorSignup ? (
        <PatientSignupfirst onSubmitt={handleOnSubmit} />
      ) : (
        <PatientSignupTwo
         data={signupData} setShowDoctorSignup={setShowDoctorSignup}
          />
      )}
    </div>
  );
};

export default SignupPatient;
