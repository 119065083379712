import { Box, Modal } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  AddAgencyBooking,
  AddAppoientmenttype,
  AddInsurance,
  FetchAppointmentType,
  GetAgencyBooking,
  GetInsurance,
} from "../../../api/helper";
import { useSelector } from "react-redux";
import Inputbox from "../../Components/Inputbox";
import { ShowToast } from "../../../helpers/ToastService";

const validationSchema = Yup.object().shape({});
const ClientRequirements = () => {
  const [requirementdata, setRequirementdata] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [providertypedata, setprovidertypedata] = useState("");
  const [insuranceinput, setinsuranceinput] = useState("");
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [data, setdata] = useState([]);
  const [selectedProviderTypes, setSelectedProviderTypes] = useState([]);
  const [insurancedata, setinsurancedata] = useState([]);
  const [servicedata, setservicedata] = useState([]);
  const [serviceinput, setserviceinput] = useState("");
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "95vh",
    overflowY: "auto", // Set a fixed height for the modal
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    outline: "none",
    // Enable vertical scrolling
  };
  const GetAppoientmenttype = async () => {
    const response = await FetchAppointmentType(`?id=${profiledata?._id}`);
    console.log(response?.data?.data, "data=====");
    setdata(response?.data?.data);
  };
  const Getinsurancedata = async () => {
    const response = await GetInsurance(`?id=${profiledata?._id}`);
    // console.log(response?.data?.data, "GetInsurance=====");
    setinsurancedata(response?.data?.data);
  };
  const Getservicedata = async () => {
    const response = await GetAgencyBooking(`?id=${profiledata?._id}`);
    // console.log(response?.data?.data, "GetInsurance=====");
    setservicedata(response?.data?.data);
  };

  useEffect(() => {
    GetAppoientmenttype();
    Getinsurancedata();
    Getservicedata();
  }, []);
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data) => {
    data.selectedProviderTypes = selectedProviderTypes;
    console.log(data);
  };
  const toggleProviderType = (id) => {
    setSelectedProviderTypes((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };
  const onProvidertypeSubmit = async (data) => {
    if (data.name === "") {
      ShowToast("Please Enter Provider Type");
      return;
    }
    data.agencyId = profiledata?._id;
    console.log(data, "hiiii======");

    try {
      const response = await AddAppoientmenttype(data);
      console.log(response?.data?.data, "respones====");
      ShowToast(response?.data?.message);
      setprovidertypedata("");
      GetAppoientmenttype();
    } catch (error) {
      console.log(error);
    }
  };
  const oninsuranceSubmit = async (data) => {
    if (data.name === "") {
      ShowToast("Please Enter Provider Type");
      return;
    }
    data.agencyId = profiledata?._id;
    console.log(data, "hiiii======");

    try {
      const response = await AddInsurance(data);
      console.log(response?.data?.data, "respones====");
      ShowToast(response?.data?.message);
      setinsuranceinput("");
      Getinsurancedata();
    } catch (error) {
      console.log(error);
    }
  };
  const ondervicetypeSubmit = async (data) => {
    if (data.name === "") {
      ShowToast("Please Enter Provider Type");
      return;
    }
    data.agencyId = profiledata?._id;
    console.log(data, "hiiii======");

    try {
      const response = await AddAgencyBooking(data);
      console.log(response?.data?.data, "respones====");
      ShowToast(response?.data?.message);
      setservicedata("");
      Getservicedata();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <div></div>
        <div
          className="Add_button__"
          style={{ width: 150 }}
          onClick={openModal}
        >
          Add Client Requirements
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Provider Type</th>
            <th>Requiremente</th>
            <th>Description</th>
            <th>Issue Date</th>
            <th>Expiration Date</th>
            <th>Within Validity</th>

            {/* <th>Years of Experience</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {requirementdata.length === 0 ? (
            <tr>
              <td colSpan="11" style={{ textAlign: "center", padding: "20px" }}>
                No Requirement found.
              </td>
            </tr>
          ) : (
            requirementdata.map((row, index) => (
              <tr key={index} style={tableRowStyle}>
                <td style={tableCellStyle}>{row?.appointmentTypeId?.name}</td>
                <td style={tableCellStyle}>{row?.name}</td>
                <td style={tableCellStyle}>{row?.description}</td>
                <td style={tableCellStyle}>
                  {" "}
                  {moment(row?.issueDate).format("YYYY-MM-DD")}
                </td>
                <td style={tableCellStyle}>
                  {" "}
                  {moment(row?.expirationDate).format("YYYY-MM-DD")}{" "}
                </td>
                <td style={tableCellStyle}>
                  {row?.validity ? "True" : "False"}
                </td>
                <td style={tableCellStyle}>
                  <button
                  // onClick={() => handleDelete(row._id)}
                  // style={deleteButtonStyle}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-title"
      >
        <Box sx={modalStyle} className="Custom_servicer_modalMain">
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "100%",
              alignItems: "center",
              justifyItems: "center",
              marginBottom: 20,
            }}
          >
            <h1 style={{ textAlign: "left" }}>New Client Requirement</h1>
            <div style={{ width: "100%", marginLeft: 17, fontWeight: "bold" }}>
              Select Provider Type(s)
            </div>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  padding: "10px",
                  alignItems: "center",
                }}
              >
                {data.map((item) => (
                  <div
                    key={item._id}
                    style={{
                      borderWidth: "1px",
                      borderColor: "grey",
                      borderStyle: "solid",
                      padding: "10px",
                      borderRadius: "10px",
                      width: "auto",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                      color: selectedProviderTypes.includes(item._id)
                        ? "white"
                        : null,
                      backgroundColor: selectedProviderTypes.includes(item._id)
                        ? "#011434"
                        : "transparent",
                    }}
                    onClick={() => toggleProviderType(item._id)}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Inputbox
                  className="Custom_input"
                  type="text"
                  placeholder="Enter New Provider Type"
                  name="Providertype"
                  style={{ marginBottom: -20 }}
                  value={providertypedata}
                  onChange={(e) => setprovidertypedata(e.target.value)}
                />
                <div
                  style={{
                    alignSelf: "center",
                    width: "40%",
                    height: "50px",
                    marginLeft: 10,
                    marginBottom: 15,
                    cursor: "pointer",
                  }}
                  className="Save_buttonnn"
                  onClick={() =>
                    onProvidertypeSubmit({ name: providertypedata })
                  }
                >
                  Add New Provider Type
                </div>
              </div>
              <div
                style={{ width: "100%", marginLeft: 17, fontWeight: "bold" }}
              >
                Select Insurances or Payment Types
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  padding: "10px",
                  alignItems: "center",
                }}
              >
                {insurancedata.map((item) => (
                  <div
                    key={item._id}
                    style={{
                      borderWidth: "1px",
                      borderColor: "grey",
                      borderStyle: "solid",
                      padding: "10px",
                      borderRadius: "10px",
                      width: "auto",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                      color: selectedProviderTypes.includes(item._id)
                        ? "white"
                        : null,
                      backgroundColor: selectedProviderTypes.includes(item._id)
                        ? "#011434"
                        : "transparent",
                    }}
                    onClick={() => toggleProviderType(item._id)}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Inputbox
                  className="Custom_input"
                  type="text"
                  placeholder="Enter New Insurance "
                  name="adminInsurenceId"
                  style={{ marginBottom: -20 }}
                  value={insuranceinput}
                  onChange={(e) => setinsuranceinput(e.target.value)}
                />
                <div
                  style={{
                    alignSelf: "center",
                    width: "40%",
                    height: "50px",
                    marginLeft: 10,
                    marginBottom: 15,
                    cursor: "pointer",
                  }}
                  className="Save_buttonnn"
                  onClick={() => oninsuranceSubmit({ name: insuranceinput })}
                >
                  Add New Insurance
                </div>
              </div>
              <div
                style={{ width: "100%", marginLeft: 17, fontWeight: "bold" }}
              >
           Select Service Type(s)
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  padding: "10px",
                  alignItems: "center",
                }}
              >
                {servicedata?.map((item) => (
                  <div
                    key={item._id}
                    style={{
                      borderWidth: "1px",
                      borderColor: "grey",
                      borderStyle: "solid",
                      padding: "10px",
                      borderRadius: "10px",
                      width: "auto",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                      color: selectedProviderTypes.includes(item._id)
                        ? "white"
                        : null,
                      backgroundColor: selectedProviderTypes.includes(item._id)
                        ? "#011434"
                        : "transparent",
                    }}
                    onClick={() => toggleProviderType(item._id)}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Inputbox
                  className="Custom_input"
                  type="text"
                  placeholder="Enter New Service Type"
                  name="Providertype"
                  style={{ marginBottom: -20 }}
                  value={serviceinput}
                  onChange={(e) => setserviceinput(e.target.value)}
                />
                <div
                  style={{
                    alignSelf: "center",
                    width: "40%",
                    height: "50px",
                    marginLeft: 10,
                    marginBottom: 15,
                    cursor: "pointer",
                  }}
                  className="Save_buttonnn"
                  onClick={() => ondervicetypeSubmit({ name: serviceinput })}
                >
                  Add New Service Type
                </div>
              </div>
              <h1>
                Select Documents ( Library Depends on Agency, Clinic, or Group
                Type )
              </h1>

              <div
                style={{
                  width: "100%",
                  // borderWidth: 1,
                  // borderColor: "red",
                  // borderStyle: "solid",
                }}
                className="document-container"
              >
                <div style={{}}>
                  <div style={{fontWeight:'bold',fontSize:'18px'}}>Intake and History</div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Client Intake Form (Webform)</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Consent Form</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Treatment Authorization</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>History</div>
                  </div>
                </div>
                <div style={{}}>
                <div style={{fontWeight:'bold',fontSize:'18px'}}>Assessment and Evaluation</div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Mental Health Assessment (Webform)</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Patient Health Questionnaire-9</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>General Anxiety Disorder-7</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Mood Disorder Questionaire (MDQ)</div>
                  </div>
                </div>
                <div style={{}}>
                <div style={{fontWeight:'bold',fontSize:'18px'}}>Legal and Compliance</div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>HIPAA Agreement (PDF)</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Informed Consent (Webform)</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Informed Consent (Webform)</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Consent for Releasing Information</div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop:20,
                  // borderWidth: 1,
                  // borderColor: "red",
                  // borderStyle: "solid",
                }}
                className="document-container"
              >
                <div style={{}}>
                  <div style={{fontWeight:'bold',fontSize:'18px'}}>Risk Assessments</div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Suicide Risk Assessment (Webform)</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Violence Risk Assessment (PDF)</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Substance Abuse Risk Assessment (PDF)</div>
                  </div>
                 
                </div>
                <div style={{}}>
                <div style={{fontWeight:'bold',fontSize:'18px'}}>Treatment Plans</div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Individualized Treatment Plan (Webform)</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Care Coordination Plan (PDF)</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Crisis Management Plan</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>New Management Plan</div>
                  </div>
                </div>
                <div style={{}}>
                <div style={{fontWeight:'bold',fontSize:'18px'}}>Financial Responsibility
                </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Financial Agreement (Webform)</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Insurance Claim Agreement (Webform)</div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" className="checkbox-input" />
                    <div style={{fontWeight:'600'}}>Credit Card Authorization (Webform)</div>
                  </div>
                 
                </div>
              </div>
              <button
                type="submit"
                style={{
                  marginBottom: 20,
                  marginTop: 20,
                  alignSelf: "center",
                  width: "100%",
                  height: "50px",
                }}
                className="Save_buttonnn"
              >
                Submit
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ClientRequirements;

const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid #ddd",
};

const tableRowStyle = {
  borderBottom: "1px solid #ddd",
};

const deleteButtonStyle = {
  backgroundColor: "red",
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
};
