import React from 'react'

const TodayAppointments = () => {
  const appointmentsData = [
    {
      id: 1,
      image: 'https://via.placeholder.com/50', // Replace with the actual image URL
      clientName: 'John Doe William',
      provider: 'Dr. Kelvin Mad',
      paymentMode: 'Insurance',
      appointmentStatus: 'Pending',
      dateTime: '07.28.2024 at 1:00 pm',
    },
    {
      id: 2,
      image: 'https://via.placeholder.com/50', // Replace with the actual image URL
      clientName: 'John Doe William',
      provider: 'Dr. Kelvin Mad',
      paymentMode: 'Insurance',
      appointmentStatus: 'Pending',
      dateTime: '07.28.2024 at 1:30 pm',
    },
    {
      id: 3,
      image: 'https://via.placeholder.com/50', // Replace with the actual image URL
      clientName: 'John Doe William',
      provider: 'Dr. Kelvin Mad',
      paymentMode: 'Insurance',
      appointmentStatus: 'Pending',
      dateTime: '07.28.2024 at 2:00 pm',
    },
    {
      id: 4,
      image: 'https://via.placeholder.com/50', // Replace with the actual image URL
      clientName: 'John Doe William',
      provider: 'Dr. Kelvin Mad',
      paymentMode: 'Insurance',
      appointmentStatus: 'Pending',
      dateTime: '07.28.2024 at 2:30 pm',
    },
  ];
  return (
   <div className="appointments-table-container">
      <table className="appointments-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Image</th>
            <th>Client Name</th>
            <th>Provider</th>
            <th>Payment Mode</th>
            <th>Appointment Status</th>
            <th>Date & Time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {/* {appointmentsData.map((appointment) => (
            <tr key={appointment.id}>
              <td>{appointment.id}</td>
              <td><img src={appointment.image} alt="Client" className="client-image" /></td>
              <td>{appointment.clientName}</td>
              <td>{appointment.provider}</td>
              <td>{appointment.paymentMode}</td>
              <td>{appointment.appointmentStatus}</td>
              <td>{appointment.dateTime}</td>
              <td><button className="view-button">View</button></td>
            </tr>
          ))} */}
        <div style={{marginTop:20}}>
  No Appointments Today
</div>

        </tbody>
      </table>
    </div> 
  )
}

export default TodayAppointments