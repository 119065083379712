
import React, { useState } from 'react'
import { FaCheck } from 'react-icons/fa';

const Checkbox = () => {
    const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div className="checkbox-container" onClick={handleCheckboxClick}>
      <div className={`checkbox ${isChecked ? "checked" : ""}`}>
        {isChecked && <FaCheck className="checkmark" />}
      </div>
    </div>
  )
}

export default Checkbox