import React, { useState, useEffect, useRef } from "react";
import Navbar, {
  Navtopbar,
  ProfileNavbar,
  SettingNavbar,
} from "../../Components/Navbar";
import PatientSidebar from "./PatientSidebar";
import Inputbox from "../../Components/Inputbox";
import DropDownButton, {
  DropDownManu,
  DropDownManuCustom,
} from "../../Components/DropDownButton";
import { useDispatch, useSelector } from "react-redux";
import {
  Patientgetprofile,
  PatientUpdate,
  uploadImage,
} from "../../../api/helper";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ShowToast } from "../../../helpers/ToastService";
import { setUserDetails } from "../../../redux/cookiesReducer";
import { Store } from "../../../redux";
import { useNavigate } from "react-router-dom";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";

const serviceTypedata = [
  { _id: "Pediatrics", name: "Pediatrics" },
  { _id: "Dermatology", name: "Dermatology" },
  { _id: "Neurology", name: "Neurology" },
  { _id: "Cardiology", name: "Cardiology" },
  { _id: "Gynecology", name: "Gynecology" },
];
const paymentSource = [
  { _id: "Insurance", name: "Insurance" },
  { _id: "Credit Card", name: "Credit Card" },
  { _id: "Debit Card", name: "Debit Card" },
  { _id: "Bank Transfer", name: "Bank Transfer" },
  { _id: "Cash", name: "Cash" },
  { _id: "PayPal", name: "PayPal" },
];

const schema = yup.object().shape({
  firstName: yup.string().required("Full name is required"),
  // email: yup.string().email("Invalid email").required("Email is required"),
  phone: yup.string().required("Phone number is required"),
  address: yup.object().shape({
    city: yup.string().required("City is required"),
  }),
  gender: yup.string().required("Gender is required"),
});
const Settingspatient = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [imaeg, setImages] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null); // <-- All hooks at the top level
  const [activeImage, setactiveImage] = useState(false); // <-- Moved this out of any conditionals
  const [userData, setUserData] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const { isLoading } = useSelector((store) => store.sliceReducer);
  const [selectedPaymentSource, setSelectedPaymentSource] = useState("");

  const handlePaymentSourceChange = (selectedOption) => {
    console.log("Payment Source changed to:", selectedOption._id);
    setSelectedPaymentSource(selectedOption._id);
    setValue("paymentSource", selectedOption._id);
    clearErrors("paymentSource");
  };

  const getprofile = async (data) => {
    try {
      const response = await Patientgetprofile();
      setUserData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getprofile();
  }, []);
  useEffect(() => {
    if (userData) {
      reset({
        firstName: userData.firstName,
        fullName: `${userData.firstName} ${userData.lastName}`,
        email: userData?.email,
        phone: userData?.phone,
        address: {
          city: userData?.address?.city,
          street: userData?.address?.street,
          state: userData?.address?.state,
          country: userData?.address?.country,
          zipCode: userData?.address?.zipCode,
        },
        serviceType: userData?.serviceType,
        paymentSource: userData?.paymentSource,
        policyNo: userData?.policyNo,
        groupNumber: userData?.groupNumber,
        subscriptionName: userData?.subscriptionName,
      });
    }
  }, [userData, reset]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBTgjMWeFMxL5oe-KFnKts3YGBZJlEC6eM",
    libraries: ["places"],
  });

  if (!isLoaded) return <div>Loading...</div>; // Conditional rendering but outside of hook calls

  // const handlePlaceSelect = () => {
  //   const place = autocomplete.getPlace();
  //   const addressComponents = place.address_components;

  //   const getAddressComponent = (type) => {
  //     const component = addressComponents.find((c) => c.types.includes(type));
  //     return component ? component.long_name : '';
  //   };

  //   setValue('address.country', getAddressComponent('country'));
  //   setValue('address.state', getAddressComponent('administrative_area_level_1'));
  //   setValue('address.city', getAddressComponent('locality'));
  //   setValue('address.street', getAddressComponent('route'));
  //   setValue('address.zipCode', getAddressComponent('postal_code'));
  // };

  const handlePlaceSelect = async () => {
    const place = autocomplete.getPlace();
    const addressComponents = place.address_components;

    // Function to get address component by type
    const getAddressComponent = (type) => {
      const component = addressComponents.find((c) => c.types.includes(type));
      return component ? component.long_name : "";
    };

    // Set country, state, and city
    setValue("address.country", getAddressComponent("country"));
    setValue(
      "address.state",
      getAddressComponent("administrative_area_level_1")
    );
    setValue("address.city", getAddressComponent("locality"));

    // Extract street number, route, sublocality (for sectors), neighborhood, premise (building), establishment (business)
    const streetNumber = getAddressComponent("street_number");
    const route = getAddressComponent("route");
    const sublocality = getAddressComponent("sublocality");
    const neighborhood = getAddressComponent("neighborhood");
    const premise = getAddressComponent("premise"); // Building name or room number
    const establishment = getAddressComponent("establishment"); // Business names

    // Declare fullStreet and build it
    let fullStreet = "";

    // Priority handling: check if it's an establishment (business), premise, or standard street address
    if (establishment) {
      fullStreet = establishment; // Use business name if available
    } else if (premise) {
      fullStreet = `${premise}, ${route || sublocality || neighborhood}`; // Use building/room with route
    } else if (streetNumber && route) {
      fullStreet = `${streetNumber} ${route}`; // Standard street address
    } else if (sublocality) {
      fullStreet = sublocality; // For sectors
    } else if (neighborhood) {
      fullStreet = neighborhood; // If categorized as neighborhood
    }

    // Fallback: if no fullStreet found, use locality, state, country
    if (!fullStreet) {
      fullStreet = `${getAddressComponent("locality")}, ${getAddressComponent(
        "administrative_area_level_1"
      )}, ${getAddressComponent("country")}`;
    }

    // Set the full street address in the form
    setValue("address.street", fullStreet);

    // Set the zip code
    setValue("address.zipCode", getAddressComponent("postal_code"));
  };

  const toggleChangePassword = () => {
    setIsChangePasswordVisible(!isChangePasswordVisible);
  };

  const clickImageData = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const imagePicker = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("images", file);
      try {
        const response = await uploadImage(formData);
        setImages(response.data.data[0].URL);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const splitFullName = (fullName) => {
    const nameParts = fullName.trim().split(" ");

    if (nameParts.length > 2) {
      return {
        firstName: nameParts.slice(0, -1).join(" "),
        lastName: nameParts[nameParts.length - 1],
      };
    }

    return {
      firstName: nameParts[0] || "",
      lastName: nameParts[1] || "",
    };
  };

  const onSubmit = async (data) => {
    if (data.fullName) {
      const { firstName, lastName } = splitFullName(data.fullName);
      data.firstName = firstName;
      data.lastName = lastName;
      delete data.fullName;
    }
    if (imaeg) {
      data.image = imaeg;
    }
    delete data.email;
    updateProfile(data);
  };

  const updateProfile = async (data) => {
    try {
      const response = await PatientUpdate(data);
      Store.dispatch(setUserDetails(response?.data?.data));
      ShowToast("Profile Update successfully", "success");
      getprofile("msg");
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    reset({
      phone: e,
    });
  };

  return (
    <div className="dashboard-container">
      <PatientSidebar />
      <div className="dashboard-content">
        <Navtopbar
          onClick={() => {
            navigate("/PatientProfile");
          }}
          title={"Settings"}
        />
        {isLoading ? null : userData ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className="dashboard-cards"
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                flexDirection: "column",
              }}
            >
              <div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                ></div>
                <div className="flex-row" style={{ width: "100%" }}>
                  {imagePreview ? (
                    <img
                      src={imagePreview}
                      style={{ height: 105, width: 105, borderRadius: 60 }}
                    />
                  ) : (
                    <>
                      {userData?.image ? (
                        <img
                          src={userData?.image}
                          style={{ height: 105, width: 105, borderRadius: 60 }}
                        />
                      ) : (
                        <img
                          src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                          style={{ height: 105, width: 105, borderRadius: 60 }}
                        />
                      )}
                    </>
                  )}
                  <div style={{ marginLeft: 70, width: "80%" }}>
                    <Inputbox
                      label="Full Name"
                      type="text"
                      placeholder="John Doe William"
                      name="fullName"
                      register={register}
                      style={errors.fullName ? { borderColor: "red" } : {}}
                    />
                    {errors.fullName && <p>{errors.fullName.message}</p>}
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    marginTop: 20,
                    alignItems: "flex-start",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      borderWidth: 1,
                      borderColor: "#011434",
                      borderStyle: "solid",
                      padding: 10,
                      borderRadius: 30,
                    }}
                    className="Contact_Information_hhh"
                    onClick={() => clickImageData()}
                  >
                    {" "}
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={imagePicker}
                      style={{ display: "none" }}
                    />
                    Change Picture
                  </div>
                </div>
                <div>
                  <div
                    className="space-div"
                    style={{ width: "100%", paddingRight: "4%" }}
                  >
                    <div style={{ width: "47%" }}>
                      <Inputbox
                        label="Email Address"
                        type="text"
                        placeholder="john@gmail.com"
                        name="email"
                        register={register}
                        style={errors.email ? { borderColor: "red" } : {}}
                        disabled
                      />
                      {errors.email && <p>{errors.email.message}</p>}
                    </div>

                    <div style={{ width: "47%" }}>
                      <Inputbox
                        label="Phone Number"
                        type="text"
                        value={userData?.phone}
                        placeholder="9876543210"
                        name="phone"
                        onChange={handleChange}
                        style={
                          errors.phone
                            ? { borderColor: "red", width: "100%", height: 43 }
                            : { width: "100%", height: 43 }
                        }
                      />
                      {errors.phone && (
                        <span className="errorMessage">
                          {errors.phone.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div
                    className="space-div"
                    style={{ width: "100%", paddingRight: "4%" }}
                  >
                    <div style={{ width: "47%" }}>
                      <DropDownManu
                        label="Gender"
                        placeholder="Select option"
                        options={[
                          { value: "Male", name: "Male" },
                          { value: "Female", name: "Female" },
                          { value: "Other", name: "Other" },
                        ]}
                        name="gender"
                        register={register}
                        dropdownicon
                      />
                      {errors.gender && <p>{errors.gender.message}</p>}
                    </div>
                    {/* <div style={{ width: "47%" }}>
                    <label>City</label>
                    <input
                      type="text"
                      placeholder="Ocean Park"
                      {...register("address.city")}
                      style={errors.address?.city ? { borderColor: "red" } : {}}
                    />
                    {errors.address?.city && <p>{errors.address.city.message}</p>}
                  </div> */}
                    <div style={{ width: "47%" }}>
                      <Autocomplete
                        onLoad={(autocompleteInstance) =>
                          setAutocomplete(autocompleteInstance)
                        }
                        onPlaceChanged={handlePlaceSelect}
                      >
                        <Inputbox
                          label="Street"
                          type="text"
                          placeholder="Enter Address"
                          name="address.street"
                          register={register}
                        />
                      </Autocomplete>
                    </div>
                  </div>

                  <div
                    className="space-div"
                    style={{ width: "100%", paddingRight: "4%" }}
                  >
                    <div style={{ width: "47%" }}>
                      <label>City</label>
                      <input
                        type="text"
                        placeholder="Ocean Park"
                        {...register("address.city")}
                        style={
                          errors.address?.city ? { borderColor: "red" } : {}
                        }
                      />
                      {errors.address?.city && (
                        <p>{errors.address.city.message}</p>
                      )}
                    </div>
                    <div style={{ width: "47%" }}>
                      <Inputbox
                        label="Country"
                        type="text"
                        placeholder="Enter Country"
                        name="address.country"
                        register={register}
                      />
                      {errors.address?.country && (
                        <span className="errorMessage">
                          {errors.address.country.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div
                    className="space-div"
                    style={{ width: "100%", paddingRight: "4%" }}
                  >
                    <div style={{ width: "47%" }}>
                      <Inputbox
                        label="State"
                        type="text"
                        placeholder="Enter State"
                        name="address.state"
                        register={register}
                      />
                      {errors.address?.state && (
                        <span className="errorMessage">
                          {errors.address.state.message}
                        </span>
                      )}
                    </div>

                    {/* <div style={{ width: "47%" }}>
                <Inputbox
                  label="Country"
                  type="text"
                  placeholder="Enter Country"
                  name="address.country"
                  register={register}
                />
                {errors.address?.country && <span className="errorMessage">{errors.address.country.message}</span>}
              </div>
            */}
                    <div style={{ width: "47%" }}>
                      <Inputbox
                        label="Zip Code"
                        type="text"
                        placeholder="Enter Zip Code"
                        name="address.zipCode"
                        register={register}
                      />
                      {errors.address?.zipCode && (
                        <span className="errorMessage">
                          {errors.address.zipCode.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div style={{ width: "45%", marginTop: 30 }}>
                    <div className="Email_Address_txt">Clinic Association </div>
                    <input
                      value={
                        userData?.agencyIds?.length > 0
                          ? `${userData?.agencyIds[0].firstName} ${userData?.agencyIds[0].lastName}`
                          : "NA"
                      }
                      disabled
                      placeholder="Request"
                    />
                    {/* {errors.clinicRequest && <p>{errors.clinicRequest.message}</p>} */}
                  </div>

                  <div
                    className="space-div"
                    style={{ width: "100%", paddingRight: "4%" }}
                  >
                    <div style={{ width: "47%" }}>
                      <DropDownManuCustom
                        label="Select Service(s)"
                        placeholder="Select option"
                        options={serviceTypedata}
                        name="serviceType"
                        register={register}
                        dropdownicona
                        id="_id"
                        clearErrors={clearErrors}
                        defaultValue={userData.serviceType}
                      />
                    </div>
                    <div style={{ width: "47%" }}>
                      <DropDownManuCustom
                        label="Payment Source"
                        placeholder="Select option"
                        options={paymentSource}
                        name="paymentSource"
                        dropdownicon
                        register={register}
                        onSelect={handlePaymentSourceChange}
                        id="_id"
                        clearErrors={clearErrors}
                        defaultValue={userData.paymentSource}
                      />
                    </div>
                  </div>
                  {selectedPaymentSource === "Insurance" 
                ? (
                    <>
                      <div
                        className="space-div"
                        style={{ width: "100%", paddingRight: "4%" }}
                      >
                        <div style={{ width: "47%" }}>
                          <Inputbox
                            label="Policy/Member Number"
                            type="text"
                            placeholder="Enter Policy/Member Number"
                            name="insurance"
                            register={register}
                          />
                        </div>
                        <div style={{ width: "47%" }}>
                          <Inputbox
                            label="Group Number"
                            type="text"
                            placeholder="Enter Group Number"
                            name="groupNumber"
                            register={register}
                          />
                        </div>
                      </div>
                      <div
                        className="space-div"
                        style={{ width: "100%", paddingRight: "4%" }}
                      >
                        <div style={{ width: "47%" }}>
                          <Inputbox
                            label="Subscription Name"
                            type="text"
                            placeholder="Enter Subscriber Name"
                            name="subscriptionName"
                            register={register}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div
                    style={{
                      alignSelf: "center",
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginTop: 30,
                      marginBottom: 30,
                    }}
                  >
                    <div className="cancelButton">Cancel</div>

                    <button className="savebuttonn" type="submit">
                      {" "}
                      Save
                    </button>

                    {/* <div className="savebuttonn" type="submit">
                    Save
                  </div> */}
                  </div>
                </div>
                {isChangePasswordVisible && (
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1000,
                    }}
                  >
                    <div
                      className="HelpSupport_div"
                      style={{
                        maxWidth: "40%",
                      }}
                    >
                      <div
                        className="Contact_Us_txt"
                        style={{ marginBottom: 20 }}
                      >
                        Change Password
                      </div>
                      <Inputbox
                        label={"Old Password"}
                        type="password"
                        placeholder="**********"
                        name="oldPassword"
                        style={{ backgroundColor: "#D9D9D9", marginBottom: 30 }}
                        lebalstyle={{ color: "black" }}
                      />
                      <Inputbox
                        label={"New Password"}
                        type="password"
                        placeholder="Enter New Password"
                        name="newPassword"
                        style={{ backgroundColor: "#D9D9D9", marginBottom: 30 }}
                        lebalstyle={{ color: "black" }}
                      />
                      <Inputbox
                        label={"Confirm New Password"}
                        type="password"
                        placeholder="Confirm New Password"
                        name="confirmPassword"
                        style={{ backgroundColor: "#D9D9D9", marginBottom: 30 }}
                        lebalstyle={{ color: "black" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: 30,
                        }}
                      >
                        <div
                          className="cancelButton"
                          onClick={toggleChangePassword}
                        >
                          Cancel
                        </div>
                        <div
                          className="savebuttonn"
                          onClick={toggleChangePassword}
                        >
                          Submit
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        ) : (
          <div>No data available</div>
        )}
      </div>
    </div>
  );
};

export default Settingspatient;
