import React, { useEffect, useState } from "react";
import Navbar, { ProfileNavbar } from "./Navbar";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { getDoctorProfile } from "../../api/helper";
import Inputbox from "./Inputbox";
import moment from "moment";

const MyProfile = () => {
  const [clinicData, setclinicData] = useState();
  const { isLoading } = useSelector((store) => store.sliceReducer);
  const getprofile = async () => {
    try {
      const response = await getDoctorProfile();
      setclinicData(response.data.data);
      // console.log("Fetched Clinic Data====>>>:", response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(clinicData, "clinicDataa============");
  useEffect(() => {
    getprofile();
  }, []);
  const categoryNames =
    clinicData?.categoryId?.map((e) => e.name).join(", ") || "";

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <ProfileNavbar />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30, flexDirection: "column" }}
        >
          {isLoading ? null : clinicData ? (
            <>
              <div className="flex-row">
                {clinicData?.image ? (
                  <img
                    src={clinicData?.image}
                    style={{ height: 105, width: 105, borderRadius: 60 }}
                  />
                ) : (
                  <img
                    src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                    style={{ height: 105, width: 105, borderRadius: 60 }}
                  />
                )}
                <div style={{ marginLeft: 70 }}>
                  <div className="Email_Address_txt">Full Name</div>
                  <div className="clicnic-name">{`${clinicData?.firstName} ${clinicData?.lastName}`}</div>
                  <div className="Email_Address_txt">Unique ID</div>
                  <span style={{ display: "flex" }}>
                    P-{clinicData?.uniqueId}
                  </span>
                </div>
              </div>

              <div>
                <div className="space-div" style={{ width: "90%" }}>
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Email Address</div>
                    <div className="emaill">{clinicData?.email}</div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Phone Number</div>
                    <div className="emaill" style={{ textAlign: "left" }}>
                      <Inputbox
                        style={{
                          width: "100%",
                          height: 43,
                          fontSize: "20px",
                        }}
                        type="text"
                        placeholder="Enter Number"
                        name="phone"
                        value={clinicData?.phone}
                        hideBorders={true}
                        disabled
                        disableDropdown={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-div">
                  <div>
                    <div className="Email_Address_txt">Specialty</div>
                    <div className="emaill">{categoryNames}</div>
                  </div>
                </div>

                <div className="space-div">
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Experience ( Years)</div>
                    <div className="emaill">{clinicData?.experience} Years</div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">
                      Clinic Association Request
                    </div>
                    <div className="emaill">
                      {clinicData?.agencyIds.length > 0
                        ? `${clinicData?.agencyIds[0].firstName} ${clinicData?.agencyIds[0].lastName}`
                        : "NA"}
                    </div>
                  </div>
                </div>
                <div className="space-div">
                  <div>
                    <div className="Email_Address_txt">Available Days</div>
                    <div className="emaill">
                      {" "}
                      {clinicData?.availableDays?.availableDays
                        ?.map((e) => e.day)
                        .join(", ")}
                    </div>
                  </div>
                </div>

                <div className="space-div">
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Provider NPI</div>
                    <div className="emaill">{clinicData?.ProviderNPI}</div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Practice Location</div>
                    <div className="emaill" style={{ textAlign: "left" }}>
                      {/* {clinicData?.ServiceLocationId?.name} */}
                      {clinicData?.ServiceLocationId?.name
                        ? `${clinicData?.ServiceLocationId?.name}`
                        : "NA"}
                    </div>
                  </div>
                </div>

                <div className="space-div">
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Delivery Mode</div>
                    <div className="emaill" style={{ textAlign: "left" }}>
                      {clinicData?.DeliveryMode &&
                      Array.isArray(clinicData.DeliveryMode) &&
                      clinicData.DeliveryMode.length > 0
                        ? clinicData.DeliveryMode.map((mode, index) => (
                            <span key={mode._id || index}>
                              {mode.name}
                              {index < clinicData.DeliveryMode.length - 1 &&
                                ", "}
                            </span>
                          ))
                        : "No delivery mode available"}
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Gender</div>
                    <div className="emaill" style={{ textAlign: "left" }}>
                      {clinicData?.gender}
                    </div>
                  </div>
                </div>

                <div className="space-div">
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Date of Birth</div>
                    <div className="emaill">
                      {" "}
                      {clinicData?.DOB &&
                        moment(clinicData.DOB).format("D MMM, YYYY")}
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">
                      Treatment Type (Service Type)
                    </div>
                    <div className="emaill" style={{ textAlign: "left" }}>
                      {clinicData?.ServiceType &&
                      Array.isArray(clinicData.ServiceType) &&
                      clinicData.ServiceType.length > 0
                        ? clinicData.ServiceType.map((service, index) => (
                            <span key={service._id || index}>
                              {service.name}
                              {index < clinicData.ServiceType.length - 1 &&
                                ", "}
                            </span>
                          ))
                        : "No service type available"}
                    </div>
                  </div>
                </div>

                <div className="space-div">
                  <div style={{ width: "90%" }}>
                    <div className="Email_Address_txt">Credentials Type</div>
                    <div className="emaill" style={{ textAlign: "left" }}>
                      {clinicData?.LicenseType}
                    </div>
                  </div>
                </div>

                <div className="space-div">
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Short Biography</div>
                    <div className="emaill">{clinicData?.info}</div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Language</div>
                    <div className="emaill" style={{ textAlign: "left" }}>
                      {clinicData?.LanguagesId &&
                      Array.isArray(clinicData.LanguagesId) &&
                      clinicData.LanguagesId.length > 0
                        ? clinicData.LanguagesId.map((mode, index) => (
                            <span key={mode._id || index}>
                              {mode.name}
                              {index < clinicData.LanguagesId.length - 1 &&
                                ", "}
                            </span>
                          ))
                        : "No Language  available"}
                    </div>
                  </div>
                </div>

                <div className="space-div">
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">
                      Specialties
                    </div>
                    <div className="emaill">
                      {clinicData?.PopulationServed &&
                      clinicData.PopulationServed.length > 0 ? (
                        clinicData.PopulationServed.map((expertise, index) => (
                          <span key={expertise._id}>
                            {expertise.name}
                            {index < clinicData.PopulationServed.length - 1 &&
                              ", "}
                          </span>
                        ))
                      ) : (
                        <p>No special population expertise available</p>
                      )}
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">
                      Modalities (Treatment Approaches)
                    </div>
                    <div className="emaill">
                      {clinicData?.Modalities &&
                      clinicData?.Modalities.length > 0 ? (
                        clinicData?.Modalities.map((modality, index) => (
                          <span key={modality._id}>
                            {modality.name}
                            {index < clinicData?.Modalities?.length - 1 && ", "}
                          </span>
                        ))
                      ) : (
                        <p>No modalities available</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="space-div">
                  {/* <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">
                      Population (Ages Served)
                    </div>
                    <div className="emaill">{clinicData?.Population}</div>
                  </div> */}
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">
                      Insurance Currently Accepted
                    </div>
                    <div className="emaill">
                      {clinicData?.Insurance &&
                      clinicData.Insurance.length > 0 ? (
                        clinicData.Insurance.map((insurance, index) => (
                          <span key={insurance._id}>
                            {insurance.name}
                            {index < clinicData.Insurance.length - 1 && ", "}
                          </span>
                        ))
                      ) : (
                        <p>No Insurance available</p>
                      )}
                    </div>
                    {/* <div className="emaill">{clinicData?.Insurance}</div> */}
                  </div>
                </div>

                <div className="space-div">
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">
                      Provider Home Address
                    </div>
                    <div className="emaill">
                      {clinicData?.ProviderHomeAddress}
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Provider Type</div>
                    <div className="emaill" style={{ textAlign: "left" }}>
                      {clinicData?.ProviderType &&
                      Array.isArray(clinicData.ProviderType) &&
                      clinicData.ProviderType.length > 0
                        ? clinicData.ProviderType.map((type, index) => (
                            <span key={type._id || index}>
                              {type.name}
                              {index < clinicData.ProviderType.length - 1 &&
                                ", "}
                            </span>
                          ))
                        : "No provider types available"}
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: 20 }}>
                  <div
                    className="Contact_Information_hhh"
                    style={{ marginTop: 40, marginBottom: 30 }}
                  >
                    Address :
                  </div>
                </div>
                <div style={{ marginTop: 20 }}>
                  <div className="Email_Address_txt">Street</div>
                  <div className="emaill">{clinicData?.address?.street}</div>
                </div>
                <div className="space-div">
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">State</div>
                    <div className="emaill">{clinicData?.address?.state}</div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">City</div>
                    <div className="emaill">{clinicData?.address?.city}</div>
                  </div>
                </div>
                <div className="space-div" style={{ marginBottom: 40 }}>
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Zip Code</div>
                    <div className="emaill">{clinicData?.address?.zipCode}</div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="Email_Address_txt">Country</div>
                    <div className="emaill">{clinicData?.address?.country}</div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>No data available</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
