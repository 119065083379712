import React from "react";
import AgencySideBar from "../Components/AgencySideBar";
import Navbar from "../../Components/Navbar";
import DropDownButton, { DropDownManu } from "../../Components/DropDownButton";
import Inputbox from "../../Components/Inputbox";
import { height, width } from "@mui/system";

const ReportContentAgency = () => {
  return (
    <div className="dashboard-container">
      <AgencySideBar />
      <div className="dashboard-content">
        <Navbar />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30 }}
        >
          <div className="app" style={{ width: "100%" }}>
            <p className="Report_text_Clinic_Report" style={{marginBottom:20}}>
              Tenant/Clinic Reports Module
            </p>

            <div className="summary-cards">
              <div className="card green"
                style={{width:"32%", backgroundColor: "rgba(227, 250, 207, 1)" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ color: "rgba(103, 197, 55, 1)" }}>
                    Total Revenue
                  </h4>
                  <img
                    src={require("../../assest/Images/TotalEarningsicon.png")}
                  />
                </div>
                <p
                  className="card-value"
                  style={{width:"32%", color: "rgba(1, 20, 52, 1)", textAlign: "left" }}
                >
                  £ 12,000
                </p>
              </div>
              <div
                className="card blue"
                style={{width:"32%", backgroundColor: "rgba(207, 243, 253, 1)" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ color: "rgba(20, 174, 209, 1)" }}>
                    Approved Claims
                  </h4>
                  <img
                    src={require("../../assest/Images/NewClientsicon.png")}
                  />
                </div>
                <p
                  className="card-value"
                  style={{ color: "rgba(1, 20, 52, 1)", textAlign: "left" }}
                >
                  85%
                </p>
              </div>
              <div
                className="card purple"
                style={{ backgroundColor: "#E0EAFE" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h4 style={{ color: "#5465F0" }}>pending appointments</h4>
                  <img src={require("../../assest/Images/Sessionsicon.png")} />
                  {/*  TotalEarningsicon ,NewClientsicon ,Sessionsicon */}
                </div>
                <p
                  className="card-value"
                  style={{ color: "rgba(1, 20, 52, 1)", textAlign: "left" }}
                >
                  20
                </p>
              </div>
            </div>

          
            <div style={{alignContent:'center', display: "flex", alignItems: "center", 
                justifyContent:'space-between', gap:20
                }}>
                <p className='Date_To_Status_text'>Date Range:</p>
                <div><Inputbox placeholder={"date"} type="date" /></div>
                <p className='Date_To_Status_text'>to</p>
                <div><Inputbox placeholder={"date"} type="date" /></div>
                <p className='Date_To_Status_text'>Status:</p>
                <DropDownManu placeholder="Select option" options={[{ name: 'Male' }, { name: 'Female', } ,{ name: 'Other', }]} name="gender" dropdownicon />
                <p className='button_Apply_Filter_text'>Apply Filters</p>
            </div>

            <div className="Button_Export_PDF">
            <p className='button_Export_PDF_text'>Export to PDF</p>
            <p className='button_Export_PDF_text'>Export to CSV</p>
            <p className='button_Export_PDF_text'>Export to Excel</p>
            </div>

            <p className="Report_text_Clinic_Report" style={{marginBottom:20}}>Clinic Reports And Analytics</p>


    <div>
    <p className='Finance_Current_txt' style={{color:'#BC9C22', paddingTop:20}} >Financial Reports</p>   
    <div className='patient_input_div2' style={{marginTop:10}}>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Invoice_Report'>Invoice Reports</p>
    <p className='Patient_Summarize_txt'>Summarize all the invoices issued, including paid. unpaid and overdue invoices.</p>
    </div>
    <div className='finance_display_flex'>
    {/* <p className='patient_Feb_txt'>Overdue</p> */}
    <p className='Finance_Pay_txt'>View Report</p>
    </div>
   </div>
   <div className='patient_input_div2'>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Invoice_Report'>Revenue Report</p>
    <p className='Patient_Summarize_txt'>Track revenue generated from services offered by the clinic for a selected period.</p>
    </div>
    <div className='finance_display_flex'>
    <p className='Finance_Pay_txt'>View Report</p>
    </div>
   </div>

   <div className='patient_input_div2'>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Invoice_Report'>Payment Report</p>
    <p className='Patient_Summarize_txt'>Lists all payments received by the clinic from patients and insurance companies.</p>
    </div>
    <div className='finance_display_flex'>
    <p className='Finance_Pay_txt'>View Report</p>
    </div>
   </div>
   </div>

   <div>
    <p className='Finance_Current_txt' style={{color:'#BC9C22', paddingTop:20}} >Operational Reports</p>   
    <div className='patient_input_div2' style={{marginTop:10}}>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Invoice_Report'>Appointment Report</p>
    <p className='Patient_Summarize_txt'>Lists all scheduled appointments and cancellations, along with no-shows and rescheduled appointments.</p>
    </div>
    <div className='finance_display_flex'>
    <p className='Finance_Pay_txt'>View Report</p>
    </div>
   </div>
   <div className='patient_input_div2' style={{marginTop:10}}>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Invoice_Report'>Resource Usage Report</p>
    <p className='Patient_Summarize_txt'>Tracks the utilization of clinic resources as staff, rooms and equipment during appointments.</p>
    </div>
    <div className='finance_display_flex'>
    <p className='Finance_Pay_txt'>View Report</p>
    </div>
   </div>
   </div>

   <div>
    <p className='Finance_Current_txt' style={{color:'#BC9C22', paddingTop:20}} >Clinical Reports</p>   
    <div className='patient_input_div2' style={{marginTop:10}}>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Invoice_Report'>Patient Health Summary</p>
    <p className='Patient_Summarize_txt'>Summarizes patient health metrics such as vital signs, medications and tratment plans.</p>
    </div>
    <div className='finance_display_flex'>
    <p className='Finance_Pay_txt'>View Report</p>
    </div>
    </div>
   </div>

   <div>
    <p className='Finance_Current_txt' style={{color:'#BC9C22', paddingTop:20}} >Compliance & Regulatory Reports</p>   
    <div className='patient_input_div2' style={{marginTop:10}}>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Invoice_Report'>HIPAA Compliance Report</p>
    <p className='Patient_Summarize_txt'>Tracks clinic activities and processes to ensure HIPAA compliance.</p>
    </div>
    <div className='finance_display_flex'>
    <p className='Finance_Pay_txt'>View Report</p>
    </div>
    </div>
   </div>

   <div>
    <p className='Finance_Current_txt' style={{color:'#BC9C22', paddingTop:20}} >Custom Reports</p>   
    <div className='patient_input_div2' style={{marginTop:10}}>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Invoice_Report'>User-Defined Reports</p>
    <p className='Patient_Summarize_txt'>Allows clinic admins to create custom reports based on specific metrics or fields.</p>
    </div>
    <div className='finance_display_flex'>
    <p className='Finance_Pay_txt'>View Report</p>
    </div>
    </div>
   </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportContentAgency;
