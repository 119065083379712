import React from 'react'

const PatientIdentification = ({patientdata}) => {
  return (
<div className="UI2_Main_Div" style={{marginTop:20}}>

<div className='main_div'  style={{display:'flex', justifyContent:'space-between'}}>
  <p className="para_con_details">Identifiers</p>
  <img src={require("../assest/Images/pen_icon.png")}/>
</div>
<div className='UI_main_div'>
  <p className="UI_para_Mobile">First Name</p>
  <p className="UI_para_Mobile">{patientdata?.patientId.firstName}</p>
</div>
<div className='UI_main_div'>
  <p className="UI_para_Mobile">Middle Initial</p>
  <p className="UI_para_Mobile">-</p>
</div>
<div className='UI_main_div'>
  <p className="UI_para_Mobile">Last Name</p>
  <p className="UI_para_Mobile">{patientdata?.patientId.lastName || "-" }</p>
</div>
<div className='UI_main_div'>
  <p className="UI_para_Mobile">Preferred Name</p>
  <p className="UI_para_Mobile">{patientdata?.patientId.firstName}</p>
</div>
<div className='UI_main_div'>
  <p className="UI_para_Mobile">SSN (Last Four Digits)</p>
  <p className="UI_para_Mobile">{patientdata?.patientId.SSN || "-"}</p>
</div>
<div className="main2_div"><p className="para_Preferred">Front of ID</p></div>
<div className="div_Choose_file">
  <div className="image_para_choose">
  <img className="image" src={require("../assest/Images/AddVector.png")}/>
  <p className="para_choose">Choose file</p>
  </div>
  </div>
  <div className="main2_div"><p className="para_Preferred">Back of ID</p></div>
<div className="div_Choose_file">
<div className="image_para_choose">
  <img className="image" src={require("../assest/Images/AddVector.png")}/>
  <p className="para_choose">Choose file</p>
  </div>
  </div>
</div>
  )
}

export default PatientIdentification