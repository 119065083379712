import React, { useState } from "react";
import Navbar, { Navtopbar } from "../../Components/Navbar";
import AgencySideBar from "../Components/AgencySideBar";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useNavigate } from "react-router-dom";

const FinancialagaencyScreen = () => {
  const [activeTab, setActiveTab] = useState("Clinics Doctors");
  // const data = [
  //   { name: 'Jan 2024', value: 1000 },
  //   { name: 'Feb 2024', value: 3000 },
  //   { name: 'Mar 2024', value: 2000 },
  //   { name: 'Apr 2024', value: 4000 },
  //   { name: 'May 2024', value: 5100 },
  //   { name: 'Jun 2024', value: 3000 },
  //   { name: 'Jul 2024', value: 2000 },
  // ];
const navigate = useNavigate()
  return (
    <div className="dashboard-container">
      <AgencySideBar />
      <div className="dashboard-content">
        <Navtopbar  onClick= {()=>{navigate("/MyProfileAgency")}} title={"Financial Dashboard"} />
        <div
          className="dashboard-cards"
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            flexDirection: "column",
            paddingBottom: 0,
            paddingTop: 20,
          }}
        >
          <div>
            <p className="Finance_It_should_txt">
              It should track your invoices, your current Subscription plan if
              you have one. Here you can change plans if you want to, or
              pay/process your invoices.
            </p>
          </div>

          <div className="Finance_div_display" style={{ gap: 20 }}>
            <div className="Finance_div_text">
              <p className="Financial_Total_text" style={{ paddingBottom: 5 }}>
                Total Revenue
              </p>
              <p className="Financial_Total_text" style={{ color: "#BC9C22" }}>
                £ 12,000.00
              </p>
            </div>
            <div className="Finance_div_text">
              <p className="Financial_Total_text" style={{ paddingBottom: 5 }}>
                Pending Invoices
              </p>
              <p className="Financial_Total_text" style={{ color: "#BC9C22" }}>
                12
              </p>
            </div>
            <div className="Finance_div_text">
              <p className="Financial_Total_text" style={{ paddingBottom: 5 }}>
                Provider Payouts
              </p>
              <p className="Financial_Total_text" style={{ color: "#BC9C22" }}>
                £ 1,200.00
              </p>
            </div>
            <div className="Finance_div_text">
              <p className="Financial_Total_text" style={{ paddingBottom: 5 }}>
                Outstanding Balance
              </p>
              <p className="Financial_Total_text" style={{ color: "#BC9C22" }}>
                Sept 30, 2024
              </p>
            </div>
          </div>

          <div className="space-div1" style={{ width: "100%" }}>
            <div
              className="div_text_imag"
              style={{ width: "49%", padding: 20 }}
            >
              <p className="Financial_Provider_text">Provider Productivity</p>
              <div>
                <div className="Financial_div_display_text">
                  <p className="Financial_Avg_text">Avg Visit per Provider</p>
                  <p className="Financial_Avg_text">Avg Revenue per Provider</p>
                </div>
                <div className="Financial_div_display_text">
                  <p className="Financial_102_text">102</p>
                  <p className="Financial_102_text">£ 12,000.00</p>
                </div>
                <div className="Financial_display_image">
                  <div>
                    <img
                      className="Financial_img_doctorimg"
                      src={require("../../assest/Images/doctorimg.png")}
                    />
                    <p className="Financial_Acdemic_text">Academic</p>
                    <p className="Financial_Acdemic_text">£ 120.00</p>
                  </div>
                  <img
                    className="Financial_img_doctorimg"
                    src={require("../../assest/Images/doctorimg.png")}
                  />
                  <img
                    className="Financial_img_doctorimg"
                    src={require("../../assest/Images/doctorimg.png")}
                  />
                  <img
                    className="Financial_img_doctorimg"
                    src={require("../../assest/Images/doctorimg.png")}
                  />
                  <img
                    className="Financial_img_doctorimg"
                    src={require("../../assest/Images/doctorimg.png")}
                  />
                </div>
              </div>
            </div>

            <div
              className="div_text_imag"
              style={{ width: "49%", padding: 20 }}
            >
              <p className="Financial_Provider_text">Popular Service Code</p>
              <div>
                <div className="Financial_div_display_text">
                  <p className="Financial_Avg_text">H2014</p>
                  <p className="Financial_Avg_text">£ 12,000.00</p>
                </div>
                <div className="Financial_div_display_text">
                  <p className="Financial_Avg_text">HC5012</p>
                  <p className="Financial_Avg_text">£ 120.00</p>
                </div>
                <div className="Financial_div_display_text">
                  <p className="Financial_Avg_text">97185</p>
                  <p className="Financial_Avg_text">£ 20.00</p>
                </div>
                <p
                  className="Financial_Provider_text"
                  style={{ paddingTop: 10 }}
                >
                  Revenue Trend
                </p>
                <p className="Financial_200_text">200 * 100</p>
              </div>
            </div>
          </div>

          <div>
            <p
              className="Finance_Current_txt"
              style={{ color: "#BC9C22", paddingTop: 20 }}
            >
              Client Payment Summary
            </p>
            <div className="patient_input_div2">
              <div className="Finance_Patient_text_div">
                <p className="patient_Feb_txt">Client: Adam Johnson</p>
                <p className="Patient_Blood_txt">
                  Payment Date: Aug 15,2024 Amount: £ 120.00
                </p>
              </div>
              <div className="finance_display_flex">
                <p className="patient_Feb_txt">Paid</p>
              </div>
            </div>
            <div className="patient_input_div2">
              <div className="Finance_Patient_text_div">
                <p className="patient_Feb_txt">Client: Adam Johnson</p>
                <p className="Patient_Blood_txt">
                  Payment Date: Aug 15,2024 Amount: £ 120.00
                </p>
              </div>
              <div className="finance_display_flex">
                <p className="patient_Feb_txt">Pending</p>
              </div>
            </div>
            <div className="patient_input_div2">
              <div className="Finance_Patient_text_div">
                <p className="patient_Feb_txt">Client: Adam Johnson</p>
                <p className="Patient_Blood_txt">
                  Payment Date: Aug 15,2024 Amount: £ 120.00
                </p>
              </div>
              <div className="finance_display_flex">
                <p className="patient_Feb_txt">Overdue</p>
                <p className="Finance_Pay_txt">Send Reminder</p>
              </div>
            </div>

            <p
              className="Finance_Current_txt"
              style={{ color: "#BC9C22", paddingTop: 20 }}
            >
              Provider Invoices
            </p>
            <div className="patient_input_div2">
              <div className="Finance_Patient_text_div">
                <p className="patient_Feb_txt">Invoice #12345</p>
                <p className="Patient_Blood_txt">
                  Provider: John Doe Service Date: Aug 10, 2024
                </p>
              </div>
              <div className="finance_display_flex">
                <p className="patient_Feb_txt">Pending Approval</p>
                <p className="Finance_Pay_txt">Approve</p>
              </div>
            </div>
            <div className="patient_input_div2">
              <div className="Finance_Patient_text_div">
                <p className="patient_Feb_txt">Invoice #12345</p>
                <p className="Patient_Blood_txt">
                  Provider: John Doe Service Date: Aug 10, 2024
                </p>
              </div>
              <div className="finance_display_flex">
                <p className="patient_Feb_txt">Approved</p>
              </div>
            </div>

            <p
              className="Finance_Current_txt"
              style={{ color: "#BC9C22", paddingTop: 20 }}
            >
              Payout Management
            </p>
            <div className="finance_display_flex">
              <p className="patient_Feb_txt" style={{ paddingRight: 7 }}>
                Total Payout Amount:
              </p>
              <p className="patient_Feb_txt1"> £ 12,000.00</p>
              <p className="Finance_Pay_txt">Download</p>
            </div>

            <p
              className="Finance_Current_txt"
              style={{ color: "#BC9C22", paddingTop: 20 }}
            >
              Payment History
            </p>
            <div className="patient_input_div2">
              <div className="Finance_Patient_text_div">
                <p className="patient_Feb_txt">Payment on Aug 15, 2024</p>
                <p className="Patient_Blood_txt">
                  Amount: £ 12.00 (Paid to Provider: John Doe)
                </p>
              </div>
              <div className="finance_display_flex">
                <p className="patient_Feb_txt">Completed</p>
              </div>
            </div>
            <div className="patient_input_div2">
              <div className="Finance_Patient_text_div">
                <p className="patient_Feb_txt">Payment on July 10, 2024</p>
                <p className="Patient_Blood_txt">
                  Amount: £ 40.00 (Paid to Provider: Jane Smith)
                </p>
              </div>
              <div className="finance_display_flex">
                <p className="patient_Feb_txt">Completed</p>
              </div>
            </div>

            <p
              className="Finance_Current_txt"
              style={{ color: "#BC9C22", paddingTop: 20 }}
            >
              Tax Documents
            </p>
            <div className="finance_display_flex">
              <p className="patient_Feb_txt">
                Download Tax Documents for 2023:
              </p>
              <p className="Finance_Pay_txt">Process Payouts</p>
            </div>

          </div>



<div>
          <p className="Financial_Provider_text" style={{ color: "#000", paddingTop: 20 }}>Actions
            </p>
            <div className="buttons">
            <p className="Finance_Encounter_Ative_txt">ENCOUNTERS/VISITS</p>
            <p className="Finance_Encounter_InAtive_txt">CHARGES</p>
            <p className="Finance_Encounter_InAtive_txt">CREATE INVOICES</p>
            <p className="Finance_Encounter_InAtive_txt">INSURANCE CLAIMS</p>
            <p className="Finance_Encounter_InAtive_txt">PAYOUTS</p>
            
            </div>

</div>

        </div>
      </div>
    </div>
  );
};

export default FinancialagaencyScreen;
