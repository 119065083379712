import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Inputbox from "../../Components/Inputbox";
import {
  DropDownManuCustom,
  DropDownManuCustomMulti,
} from "../../Components/DropDownButton";
import {
  AddVisitReason,
  DeleteVisitReason,
  FetchVisitReason,
} from "../../../api/helper";
import { ShowToast } from "../../../helpers/ToastService";

const visitReasons = [
  { _id: "General Consultation", name: "General Consultation" },
  { _id: "Mental Health Evaluation", name: "Mental Health Evaluation" },
  { _id: "Routine Follow-up", name: "Routine Follow-up" },
  { _id: "Medication Management", name: "Medication Management" },
  { _id: "Physical Examination", name: "Physical Examination" },
  { _id: "Therapy Session", name: "Therapy Session" },
  { _id: "Diagnostic Testing", name: "Diagnostic Testing" },
  { _id: "Other", name: "Other" },
];

const cptCodes = [
  {
    _id: "99201",
    name: "99201  -  Office or other outpatient visit for a new patient, evaluation and management ",
    description:
      "Office or other outpatient visit for a new patient, evaluation and management",
  },
  {
    _id: "99202",
    name: "99202  -  Office or other outpatient visit for a new patient, typically 15-29 minutes",
    description:
      "Office or other outpatient visit for a new patient, typically 15-29 minutes",
  },
  {
    _id: "99203",
    name: "99203  -  Office or other outpatient visit for a new patient, typically 30-44 minutes",
    description:
      "Office or other outpatient visit for a new patient, typically 30-44 minutes",
  },
  {
    _id: "99204",
    name: "99204  -  Office or other outpatient visit for a new patient, typically 45-59 minutes",
    description:
      "Office or other outpatient visit for a new patient, typically 45-59 minutes",
  },
  {
    _id: "90834",
    name: "90834  -  Psychotherapy, 45 minutes with patient",
    description: "Psychotherapy, 45 minutes with patient",
  },
  {
    _id: "90837",
    name: "90837  -  Psychotherapy, 60 minutes with patient",
    description: "Psychotherapy, 60 minutes with patient",
  },
];

const validationSchema = Yup.object().shape({
  visitReason: Yup.string().required("Visit Reason is required"),
  cptCode: Yup.array()
    .of(Yup.string().required("A CPT Code must be selected"))
    .min(1, "At least one CPT Code is required")
    .required("CPT Code is required"),
  appointmentDuration: Yup.string().required(
    "Appointment Duration is required"
  ),

  // modifiers: Yup.array()
  //   .of(Yup.string().required("All modifiers are required"))
  //   .length(4, "There must be exactly 4 modifiers"),
});
const VisitReason = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setdata] = useState([]);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  // console.log(profiledata,'====profiledata')
  const [modifiers, setModifiers] = useState(["", "", "", ""]);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const {
    register,
    reset,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const GetVisitReason = async () => {
    const response = await FetchVisitReason(`?id=${profiledata?._id}`);
    console.log(response?.data?.data, "data=====");
    setdata(response?.data?.data);
  };
  useEffect(() => {
    GetVisitReason();
  }, []);
  const handleModifierChange = (index, value) => {
    const updatedModifiers = [...modifiers];
    updatedModifiers[index] = value.slice(0, 2);
    setModifiers(updatedModifiers);
    setValue("modifiers", updatedModifiers, { shouldValidate: true });
  };
  const onSubmit = async (data) => {
    //
    data.cptCode = data.cptCode.join(",");
    console.log(data.modifiers, "console.log(data.modifiers,");
    data.agencyId = profiledata?._id;
    console.log(data, "hiiii======");
    try {
      const response = await AddVisitReason(data);
      console.log(response?.data?.data, "respones====");
      ShowToast(response?.data?.message);
      reset();
      setModifiers(["", "", "", ""]);
      GetVisitReason();
      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    console.log("Deleted ID:", id);

    const confirmed = window.confirm(
      "Are you sure you want to delete this address?"
    );
    if (!confirmed) {
      return;
    }

    try {
      const response = await DeleteVisitReason(`?id=${id}`);
      if (response?.data?.success) {
        ShowToast(response?.data?.message);
        GetVisitReason();
      } else {
        ShowToast("Error: Unable to delete address.");
      }
    } catch (error) {
      console.error("Delete operation failed:", error);
      ShowToast("An error occurred while deleting the address.");
    }
  };
  const handlelanguage = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("cptCode", ids, { shouldValidate: true });
  };
  return (
    <div
      style={{
        width: "100%",
        alignItems: "center",
        justifyItems: "center",
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h1>Visit Reason, CPT Code, Appointment Duration, and Modifiers</h1>
        <div
          style={{
            backgroundColor: "#bc9c22",
            color: "white",
            padding: 10,
            borderRadius: 6,
          }}
          onClick={openModal}
        >
          Add Visit Reason
        </div>
      </div>

      <div style={{ overflowX: "auto", width: "100%", marginTop: 30 }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Visit Reason</th>
              <th style={tableHeaderStyle}>CPT Code</th>
              {/* <th style={tableHeaderStyle}>CPT Code Description</th> */}
              <th style={tableHeaderStyle}>Appointment Duration</th>
              <th style={tableHeaderStyle}>Default Modifiers</th>
              <th style={tableHeaderStyle}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td
                  colSpan="6"
                  style={{ textAlign: "center", padding: "10px" }}
                >
                  No Reason Found
                </td>
              </tr>
            ) : (
              data.map((row) => (
                <tr key={row._id} style={tableRowStyle}>
                  <td style={tableCellStyle}>{row.visitReason}</td>
                  <td style={tableCellStyle}>{row.cptCode}</td>
                  {/* <td style={tableCellStyle}>{row.cptDescription}</td> */}
                  <td style={tableCellStyle}>{row.appointmentDuration}</td>
                  <td style={tableCellStyle}>
                    {row.modifiers.length > 0
                      ? row.modifiers.join("")
                      : "No Modifiers"}
                  </td>
                  <td style={tableCellStyle}>
                    <button
                      style={deleteButtonStyle}
                      onClick={() => handleDelete(row._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-title"
      >
        <Box sx={modalStyle}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "100%",
              alignItems: "center",
              justifyItems: "center",
              marginBottom: 20,
            }}
          >
            <h1 style={{ textAlign: "left" }}>Visit Reasons and Codes</h1>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Inputbox
                label="Visit Reason"
                className="Custom_input"
                type="text"
                placeholder="Enter Visit Reason"
                name="visitReason"
                register={register}
              />

              <p style={{ color: "red", textAlign: "left" }}>
                {errors.visitReason?.message}
              </p>

              {/* <Inputbox
                label="CPT Code"
                type="text"
                placeholder="Enter CPT Code"
                name="cptCode"
                className="Custom_input"
                register={register}
              /> */}
              <DropDownManuCustomMulti
                label="CPT Code (Select Multiple)"
                placeholder="SelectCPT Code"
                options={cptCodes}
                name="cptCode"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                // onSelect={(value) => setValue("language", value)}
                onSelect={handlelanguage}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.cptCode?.message}
              </p>

              <Inputbox
                label="Default Appointment Duration (minutes)"
                type="number"
                placeholder="Enter Duration in Minutes"
                name="appointmentDuration"
                className="Custom_input"
                register={register}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.appointmentDuration?.message}
              </p>
              <div style={{ fontWeight: "bold", fontSize: 19 }}>
                Default Modifiers (Optional)
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: "5%",
                }}
              >
                {modifiers.map((modifier, index) => (
                  <React.Fragment key={index}>
                    <Inputbox
                      style={{ width: 50, border: "none",outline: 'none',}}
                      inputstyle={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        borderWidth: 1,
                        borderColor: "grey",
                        borderStyle: "solid",
                        borderRadius: 6,
                      }}
                      lebalstyle={{ textAlign: "center", marginTop: -10 }}
                      label={`Mod${index + 1}`}
                      type="text"
                      placeholder="XX"
                      value={modifier}
                      onChange={(e) =>
                        handleModifierChange(index, e.target.value)
                      }
                      className="Custom_input"
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
            {/* {errors.modifiers && (
              <div style={{ color: "red", marginTop: "10px" }}>
                {errors.modifiers.message}
              </div>
            )} */}
            {/* <div style={{ display: "flex", alignItems: "center" }}>
                <Inputbox
                  label="Default Modifiers"
                  type="number"
                  placeholder="XX"
                  name="modifiers"
                  className="Custom_input"
                  register={register}
                
                  
                />
                <div style={{ width: "20%" }} />
                <Inputbox
                  type="number"
                  placeholder="XX"
                  name="modifiers"
                  className="Custom_input"
                  register={register}
                  style={{ marginTop: 30 }}
                />
                <div style={{ width: "20%" }} />
                <Inputbox
                  type="number"
                  placeholder="XX"
                  name="modifiers"
                  className="Custom_input"
                  register={register}
                  style={{ marginTop: 30 }}
                />
                <div style={{ width: "20%" }} />
                <Inputbox
                  type="number"
                  placeholder="XX"
                  name="modifiers"
                  className="Custom_input"
                  register={register}
                  style={{ marginTop: 30 }}
                />
              </div> */}

            {/* <p style={{ color: "red", textAlign: "left" }}>
                {errors.modifiers?.message}
              </p> */}
            <button
              type="submit"
              style={{
                marginBottom: 20,
                marginTop: 20,
                alignSelf: "center",
                width: "100%",
                height: "50px",
              }}
              className="Save_buttonnn"
            >
              Save
            </button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default VisitReason;
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "78vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  outline: "none",
  overflowY: "auto",
};
const tableHeaderStyle = {
  padding: "10px",
  borderBottom: "2px solid #ddd",
  textAlign: "left",
  fontWeight: "bold",
  backgroundColor: "#f4f4f4",
};

const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid #ddd",
};

const tableRowStyle = {
  borderBottom: "1px solid #ddd",
};

const deleteButtonStyle = {
  backgroundColor: "red",
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
};
