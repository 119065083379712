import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setUserDetails } from "../../../redux/cookiesReducer";

const AgencySideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showloagout, setshowloagout] = useState(false)
  
  const menuItems = [
    {
      title: "Dashboard",
      icon: require("../../assest/Images/dashboradimg.png"),
      path: "/DashboradScreen",
      handler: () => navigate("/dashboradScreen"),
    },
    {
      title: "Providers",
      icon: require("../../assest/Images/Providerimg.png"),
      path: "/ProvidersScreen",
      handler: () => navigate("/ProvidersScreen"),
    },
    {
      title: "Patients",
      icon: require("../../assest/Images/patientimgicon.png"),
      path: "/Patientsagency",
      handler: () => navigate("/Patientsagency"),
    },
    {
      title: "Appointments",
      icon: require("../../assest/Images/Appointments.png"),
      path: "/Appointmentclinic",
      handler: () => navigate("/Appointmentclinic"),
    },
    {
      title: "Financial",
      icon: require("../../assest/Images/Financial.png"),
      path: "/FinancialagaencyScreen",
      handler: () => navigate("/FinancialagaencyScreen"),
    },
    {
      title: "Messages",
      icon: require("../../assest/Images/messageimg.png"),
      path: "/Message",
      handler: () => navigate("/Message"),
    },
    {
      title: "Reports",
      icon: require("../../assest/Images/reporticon.png"),
      path: "/AgencyReports",
      handler: () => navigate("/AgencyReports"),
    },
    {
      title: "My Profile",
      icon: require("../../assest/Images/usericon.png"),
      path: "/MyProfileAgency",
      handler: () => navigate("/MyProfileAgency"),
      marginTop: 40,
    },
    {
      title: "Settings",
      icon: require("../../assest/Images/SettingIcon.png"),
      path: "/Settingss",
      handler: () => navigate("/Settingss"),
    },
    {
      title: "Help & Support",
      icon: require("../../assest/Images/Helpicon.png"),
      path: "/SupportHelp",
      handler: () => navigate("/SupportHelp"),
      // marginBottom: 40,
    },
  ];

  const handleLogout = () => {
    setshowloagout(true)
};
const handleClose = () => {
    setshowloagout(false)
}
const handleLogoutYes =()=>{
  dispatch(setUserDetails(null))
  navigate('/') 
}
return (
  <div
    style={{
      width: 300,
      paddingTop: 30,
      borderRightWidth: 1,
      borderRightColor: "rgba(1, 20, 52, 1)",
      borderStyle: "solid",
      borderTopWidth: 0,
      borderBottomWidth: 0,
      height: "100vh",
      borderLeftWidth: 0,
      display: "flex",
      flexDirection: "column",
    }}
  >
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        paddingBottom: 30,
        borderBottomWidth: 1,
        borderStyle: "solid",
        borderBottomColor: "rgba(1, 20, 52, 1)",
        borderTopWidth: 0,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        marginBottom: 15,
        paddingLeft: 30,
      }}
    >
      <img
        style={{ height: 120, width: 120,}}
        src={require("../../assest/Images/newmindwelllogo.png")}
        alt="Logo"
        className="logo"
      />

    </div>

    {/* Scrollable section starts here */}
    <div
      style={{
        flex: 1, // This allows the section to grow and fill available space
        overflowY: "auto", // Enables vertical scrolling
        paddingLeft: 30,
      }}
    >
      {menuItems.map((item, index) => {
        const isActive = location.pathname === item.path;
        return (
          <div
            key={index}
            onClick={item.handler}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 15,
              marginTop: item.marginTop || 0,
              color: isActive ? "#BC9C22" : "inherit",
              cursor: "pointer",
            }}
          >
            <img
              src={item.icon}
              style={{
                height: 25,
                width: 25,
                marginRight: 10,
                filter: isActive
                  ? "brightness(0) saturate(100%) invert(55%) sepia(66%) saturate(2477%) hue-rotate(1deg) brightness(99%) contrast(101%)"
                  : "none",
              }}
            />
            <span style={{ color: isActive ? "#BC9C22" : "inherit" ,  fontSize: 20,}}>
              {item.title}
            </span>
          </div>
        );
      })}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 70,
          marginBottom:30,
          color: "rgba(239, 69, 47, 1)",
          cursor: "pointer",
        }}
        onClick={handleLogout}
      >
        <img
          src={require("../../assest/Images/logouticon.png")}
          style={{ height: 25, width: 25, marginRight: 10,  fontSize: 20 }}
        />
        Log Out
      </div>
    </div>
    {/* Scrollable section ends here */}

    {showloagout && (
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="logout-modal">
            <div className="logout_txt">Logout</div>
            <div className="logout_sure" style={{ marginTop: 20 }}>
              Are you sure to logout
            </div>
            <div className="logout_sure" style={{ marginBottom: 20 }}>
              your account?
            </div>
            <div className="logout-buttons">
              <button className="cancel-button" onClick={handleClose}>
                No
              </button>
              <button className="confirm-button" onClick={handleLogoutYes}>
                Yes
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    )}
  </div>
);
};

export default AgencySideBar;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
};