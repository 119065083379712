import React, { useState } from "react";

const AppointmentSettingsclinic = () => {
  const [activeTab, setActiveTab] = useState("workingHours");
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [smsAlerts, setSmsAlerts] = useState(false);
  const [iscancellation, setiscancellation] = useState(false);
  // Function to toggle visibility
  const toggleChangePassword = () => {
    setActiveTab("workingHours");
    setIsChangePasswordVisible(!isChangePasswordVisible);
  };
  const toggleclose = () => {
    setActiveTab("workingHours");
    setiscancellation(!iscancellation);
  };
  const timeSlots = [
    "10:00 -10:30AM",
    "10:30 -11:00AM",
    "11:00 -11:30AM",
    "11:30 -12:00PM",
    "12:00 -12:30PM",
    "12:30 -01:00PM",
    "01:00 -01:30PM",
    "01:30 -02:00PM",
    "02:00 -02:30PM",
    "02:30 -03:00PM",
    "03:00 -03:30PM",
    "03:30 -04:00PM",
    "04:00 -04:30PM",
    "04:30 -05:00PM",
    "05:00 -05:30PM",
    "05:30 -06:00PM",
  ];

  const handleSlotClick = (slot) => {
    setSelectedSlots((prev) =>
      prev.includes(slot) ? prev.filter((s) => s !== slot) : [...prev, slot]
    );
  };
  const handleCancellationPolicy = () => {
    setActiveTab("Cancellation");
    setiscancellation(!iscancellation);
  };
  const handlenotification = () => {
    setActiveTab("notifications");
    setIsChangePasswordVisible(!isChangePasswordVisible);
  };
  return (
    <div>
      <div className="tab-container" style={{ marginBottom: 80 }}>
        <div
          className={
            activeTab === "workingHours" ? "active-tab" : "inactive-tab"
          }
          onClick={() => setActiveTab("workingHours")}
        >
          Booking Slots
        </div>
        <div
          className={
            activeTab === "Cancellation" ? "active-tab" : "inactive-tab"
          }
          onClick={handleCancellationPolicy}
        >
          Cancellation Policy
        </div>
        <div
          className={
            activeTab === "notifications" ? "active-tab" : "inactive-tab"
          }
          onClick={handlenotification}
        >
          Reminder & Notification
        </div>
      </div>

      {activeTab === "workingHours" && (
        <div className="grid-container">
          {timeSlots.map((slot) => (
            <div
              key={slot}
              className={
                selectedSlots.includes(slot)
                  ? "time-slot selected"
                  : "time-slot"
              }
              onClick={() => handleSlotClick(slot)}
            >
              {slot}
            </div>
          ))}
        </div>
      )}

      {activeTab === "notifications" && (
        <div>
          {isChangePasswordVisible && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
            >
              <div
                className="HelpSupport_div"
                style={{
                  maxWidth: "30%",
                }}
              >
                <div className="Contact_Us_txt" style={{ marginBottom: 20 }}>
                  Reminder & Notification
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 30,
                  }}
                >
                  <div>
                    <h3 style={{ textAlign: "left", color: "white" }}>
                      Reminder
                    </h3>
                    {/* <div style={{ color: "white" }}>
                      Appointment reminders, new booking alerts
                    </div> */}
                  </div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={emailNotifications}
                      onChange={() =>
                        setEmailNotifications(!emailNotifications)
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 50,
                    marginBottom: 100,
                  }}
                >
                  <div>
                    <h3 style={{ textAlign: "left", color: "white" }}>
                      Notification
                    </h3>
                    {/* <div style={{ color: "white" }}>
                      Appointment reminders, new booking alerts
                    </div> */}
                  </div>
                  {/* <label className="switch">
                    <input
                      type="checkbox"
                      checked={smsAlerts}
                      onChange={() => setSmsAlerts(!smsAlerts)}
                    />
                  </label>{" "} */}
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={smsAlerts}
                      onChange={() => setSmsAlerts(!smsAlerts)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 30,
                  }}
                >
                  <div className="cancelButton" onClick={toggleChangePassword}>
                    Cancel
                  </div>
                  <div className="savebuttonn" onClick={toggleChangePassword}>
                    Save
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {activeTab === "Cancellation" && (
        <div>
          {iscancellation && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
            >
              <div
                className="HelpSupport_div"
                style={{
                  maxWidth: "30%",
                }}
              >
                <div className="Contact_Us_txt" style={{ marginBottom: 20 }}>
                  Cancellation Policy
                </div>
                <div
                  style={{
                    marginTop: 20,
                    color:'#FFFFFF',
                    marginBottom:10
                  }}
                >
                  Our agency requires a minimum of 24 hours' notice for
                  appointment cancellations. Cancellations made less than 24
                  hours in advance will incur a 50% fee of the scheduled
                  service. No-shows will be charged the full service amount.
                  Rescheduling with proper notice is allowed once without
                  penalty. Thank you for your understanding and cooperation
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 30,
                  }}
                >
                  <div className="cancelButton" onClick={toggleclose}>
                    Cancel
                  </div>
                  <div className="savebuttonn" onClick={toggleclose}>
                    Save
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div
        style={{
          alignSelf: "center",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          // bottom: 50,
          // position: "absolute",
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        <div className="cancelButton">Cancel</div>
        <div style={{ width: "10%" }}></div>
        <div className="savebuttonn">Save</div>
      </div>
    </div>
  );
};

export default AppointmentSettingsclinic;
