import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatUser:{},
  token:'',

  networkSpeed: '',
  isNetworkConnected: true,
  error:"",
  isLoading:false,
  useData:{},
  loader:false,
}

const sliceReducer = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    
    setInitialChatUser(state, action) {
      state.chatUser = action.payload
    },
    setToken(state, action) {
      state.token = action.payload
    },
   
    setNetworkSpeed: (state, action) => {
      
      state.networkSpeed = action.payload;
    },
    setNetworkConnected: (state, action) => {
      return { ...state, isNetworkConnected: action.payload };
    },
    setError:(state, action) => {
      state.error= action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setUserProfile:(state, action) => {
      state.useData = action.payload;
    },
   
  
  }
})

export const {
  setInitialChatUser,
  setToken,
  setLoading,
  setError,
  setNetworkConnected,
  setNetworkSpeed,
  setUserProfile,
  setLoader,
} = sliceReducer.actions;
export default sliceReducer.reducer;
