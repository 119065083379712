import React from 'react'
import Inputbox from '../../Components/Inputbox'
import { useForm } from 'react-hook-form';


const TreatmentMethods = ({info}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    clearErrors,
    reset
  } = useForm({
    defaultValues: {
      licenseNumber:info?.licenseNumber,
      Education:info?.Education
    }
  });
  return (
    <div>
      <div className="input-row">
        <Inputbox label="Education" type="text" placeholder="M.B.B.S" name="Education"  register={register} />

        <div style={{ width: "40px" }} />
        <Inputbox
          type="text"
          label="License "
          placeholder="Institute Name"
          register={register}
          name="licenseNumber"
        />
        <div style={{ width: "40px" }} />
        <Inputbox
          type="text"
          label="Board Certifications"
          placeholder="2005"
        />
      </div>
    </div>
  )
}

export default TreatmentMethods