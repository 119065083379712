import React from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'

const Messages = () => {

  const MessagesData = [
    { id: 1, name: 'Shelby Goode', lastmessage: 'Lorem Ipsum is simply dummy text of the printing ', time: '1 min ago', active: true, image: require('../assest/Images/usericon.png') },
    { id: 2, name: 'Robert Bacins', lastmessage: 'Lorem Ipsum is simply dummy text of the printing ', time: '10 min ago', active: false, image: require('../assest/Images/usericon.png') },
    { id: 3, name: 'John Carilo', lastmessage: 'Lorem Ipsum is simply dummy text of the printing ', time: '15 min ago', active: true, image: require('../assest/Images/usericon.png') },
    { id: 4, name: 'Adriene Watson', lastmessage: 'Lorem Ipsum is simply dummy text of the printing ', time: '1 min ago', active: false, image: require('../assest/Images/usericon.png') },
    { id: 5, name: 'Jhon Deo', lastmessage: 'Lorem Ipsum is simply dummy text of the printing ', time: '20 min ago', active: true, image: require('../assest/Images/usericon.png') },
    { id: 6, name: 'Mark Ruffalo', lastmessage: 'Lorem Ipsum is simply dummy text of the printing ', time: '31 min ago', active: true, image: require('../assest/Images/usericon.png') },
    { id: 7, name: 'Shelby Goode', lastmessage: 'Lorem Ipsum is simply dummy text of the printing ', time: '11 min ago', active: true, image: require('../assest/Images/usericon.png') },
  ]

  return (
    <div className="dashboard-container" >
      <Sidebar />
      <div className="dashboard-content" >
        <Navbar />
        <div className="dashboard-cards" style={{ paddingLeft: 30, paddingRight: 30 }}>
        <div className="chat-container">
      <div className="messagesidebar">
        <div className="chatHeader">
          <div className="chatHeader-title">MESSAGES</div>
          <button className="add-button">+</button>
        </div>
        <div className="contact-list">
          {/* Contact items */}
          <ContactItem
            name="Shelby Goode"
            message="Lorem Ipsum is simply dummy text of the printing"
            time="1 min ago"
            active={false}
          />
          <ContactItem
            name="Robert Bacins"
            message="Lorem Ipsum is simply dummy text of the printing"
            time="9 min ago"
            active={false}
          />
          <ContactItem
            name="John Carlo"
            message="Lorem Ipsum is simply dummy text of the printing"
            time="15 min ago"
            active={true}
          />
          <ContactItem
            name="Adriene Watson"
            message="Lorem Ipsum is simply dummy text of the printing"
            time="21 min ago"
            active={false}
          />
          <ContactItem
            name="Jhon Deo"
            message="Lorem Ipsum is simply dummy text of the printing"
            time="29 min ago"
            active={false}
          />
          <ContactItem
            name="Mark Ruffolo"
            message="Lorem Ipsum is simply dummy text of the printing"
            time="45 min ago"
            active={false}
          />
        </div>
      </div>
      <div className="chat-area">
        <div className="chat-header">
          <div className="chat-header-left">
            <img src="https://via.placeholder.com/40" alt="John Carlo" />
            <div className="chat-header-info">
              <h3>John Carlo</h3>
              <div style={{textAlign:'left',color:'#000000',fontSize:16}}>Online</div>
            </div>
          </div>
          <div className="more-options">
          <img style={{height:18}} src={require('../assest/Images/More.png')} />
          </div>
          {/* <button className="more-options">•••</button> More */}
        </div>
        <div className="chat-messages">
          {/* Chat bubbles */}
          <ChatBubble
            text="Lorem Ipsum is simply"
            time="09:52 PM"
            isUser={false}
          />
          <ChatBubble
            text="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            time="09:52 PM"
            isUser={false}
          />
          <ChatBubble
            text="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            time="09:54 PM"
            isUser={true}
          />
        </div>
        <div className="chat-input">
          <input type="text" placeholder="Type your message here........" />
        <div  className="chat-input">
          <img src={require('../assest/Images/cameraicon.png')} style={{height:30,width:30}}/>
          <img src={require('../assest/Images/files.png')} style={{marginLeft:30,height:30,width:30}}/>
          </div>
          <button className="send-button"  style={{marginLeft:30}}><img style={{height:30,width:30}} src={require('../assest/Images/send_button.png')} /></button>
        </div>
      </div>
    </div>

  
        </div>
      </div>
    </div>
  )
}

export default Messages

const ContactItem = ({ name, message, time, active }) => {
  return (
    <div className={`contact-item ${active ? "active" : ""}`}>
      <img src="https://via.placeholder.com/40" alt={name} />
      <div className="contact-info">
        <h4 style={{textAlign:'left',marginBottom:5}}>{name}</h4>
        <p>{message}</p>
      </div>
      <p className="time">{time}</p>
    </div>
  );
};

const ChatBubble = ({ text, time, isUser }) => {
  return (
    <div className={`chat-bubble ${isUser ? "user" : ""}`}>
      <p>{text}</p>
      <span className="time">{time}</span>
    </div>
  );
}