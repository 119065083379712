import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {  Getmyclinicpatient } from "../../api/helper";
import moment from "moment";
import { useSelector } from "react-redux";
const AllPatients = ({ addpatientpopup }) => {
  const [patientdata, setpatientdata] = useState(null);
  const profiledata = useSelector(state => state?.cookies?.userDetails);
  // console.log(profiledata?._id, "hellloo=====")

  const navigate = useNavigate();

  const GetPatients = async () => {
    try {
      const data = {
        status: "approved",
        doctorId:profiledata?._id
      }
      const response = await Getmyclinicpatient(data);
      // console.log(response?.data?.data.patientRequests, "data =-=-====patient");
      setpatientdata(response?.data.data.response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetPatients();
  }, []);

  useEffect(() => {
    GetPatients()
  }, [addpatientpopup])

  const handleView = (id) => {
    navigate("/patientChart", { state: { patientId: id } }); // Pass _id to PatientChart
  };
  const getAge = (birthDate) => {
    const birthMoment = moment(birthDate);
    const now = moment();
    const years = now.diff(birthMoment, 'Years');
    if (years < 1) {
      const months = now.diff(birthMoment, 'Months');
      return `${months} Month${months !== 1 ? 's' : ''}`;
    } else if (years === 1) {
      const days = now.diff(birthMoment.add(1, 'Year'), 'Days');
      return `${days} Day${days !== 1 ? 's' : ''}`;
    } else {
      return `${years} Year${years !== 1 ? 's' : ''}`;
    }
  };

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Full Name</th>
            <th>Preferred Name</th>
            <th>Age</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {patientdata && patientdata.length > 0 ? (
            patientdata.map((user, index) => (
              <tr key={index}>
                <td>{"Subscriber"}</td>
                <td>{`${user.patientId.firstName}  ${user.patientId.lastName}`}</td>
                <td>{user.patientId.firstName}</td>
                <td>
                  {user?.patientId?.DOB ? getAge(user.patientId.DOB) : "NA"}
                </td>
                <td>{user?.patientId.phone}</td>
                <td>{user?.patientId?.email}</td>
                <td>
                  <button className="view-button" onClick={() => handleView(user?._id)}>
                    View
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No patient found</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="results-info">
        {/* Showing 1 to {patientdata.length} of {patientdata.length} results */}
      </div>
    </div>
  );
};

export default AllPatients;
