import React, { useState } from "react";
import NewRequestspatients from "../../Components/NewRequestspatients";
import AllPatients from "../../Components/AllPatients";
import AgencySideBar from "../Components/AgencySideBar";
import Navbar from "../../Components/Navbar";
import AllPatientsagencydata from "./AllPatientsagencydata";
import Requestspatients from "./Requestspatients";
import { useNavigate } from "react-router-dom";
import AddPatientpopup from "../../Components/AddPatientpopup";
import AddPatientpopupagency from "./AddPatientpopupagency";

const Patientsagency = () => {
  const [activeTab, setActiveTab] = useState("allPatients");
  const [addpatientpopup, setaddpatientpopup] = useState(false);

  const handlepatientOpenPopup = () => {
    setaddpatientpopup(true);
  };
  const handlepatientClosePopup = () => {

    setaddpatientpopup(false);
  };
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
const navigate = useNavigate()
  return (
    <div className="dashboard-container">
      <AgencySideBar />
      <div className="dashboard-content">
        <Navbar 
        onClick= {()=>{navigate("/MyProfileAgency")}}
        />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30 }}
        >
          <div className="clinic-providers-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="headerheader">
                <div className="CLINIC-PROVIDERS-txt">Clinic Patients </div>
                <p className="clicnic-provider-paragraf">
                  Customize Mind Well Connect according to your Clinic needs
                </p>
              </div>
              <button
                className="add-provider-button"
                onClick={handlepatientOpenPopup}
              >
                Add Patient
              </button>
            </div>

            <div className="buttons">
              <button
                className={`tab-buttontwoo ${
                  activeTab === "allPatients" ? "active" : ""
                }`}
                onClick={() => handleTabClick("allPatients")}
              >
                All Patients
              </button>
              <button
                className={`tab-buttontwoo ${
                  activeTab === "newRequests" ? "active" : ""
                }`}
                onClick={() => handleTabClick("newRequests")}
              >
                New Requests
              </button>
              {/* <button
                className={`tab-buttontwoo ${
                  activeTab === "review" ? "active" : ""
                }`}
                // onClick={() => handleTabClick("review")}
              >
                Review
              </button> */}
            </div>
            {activeTab === "allPatients" && <AllPatientsagencydata />}
            {activeTab === "newRequests" && <Requestspatients/>}
            {addpatientpopup && <AddPatientpopupagency onClose={handlepatientClosePopup} />}
            {/* {activeTab === "review" && <ReviewPatient />} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Patientsagency;
