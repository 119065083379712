import React, { useState } from "react";
import PatientSidebar from "./PatientSidebar";
import Navbar from "../../Components/Navbar";
import TodayAppointmentsData from "./TodayAppointmentsData";
import PopularCategoryData from "./PopularCategoryData";
import CareTeamPatient from "./CareTeamPatient";
import ProvidersDataPatient from "./ProvidersDataPatient";
import PatientJourneyData from "./PatientJourneyData";
import { Store } from "../../../redux";
import { useNavigate } from "react-router-dom";
import MyClinicPatient from "./MyClinicPatient";
import ClinicRequestpatient from "./ClinicRequestpatient";

const Dashboradpatient = () => {
  const [activeButton, setActiveButton] = useState("TodayAppointmentsData");
  const data =Store.getState()
// console.log(data,'=====data')
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const bookingData = [
    { id: "1", label: "CREATED", value: 0 },
    { id: "2", label: "APPROVED", value: 0 },
    { id: "3", label: "REJECTED", value: 0 },
  ];
  const views = [1, 2, 3];

  // const buttonData = [
  //   { id: "1", label: "Today Appointments" },
  //   { id: "2", label: "Popular Categories " },
  //   { id: "3", label: "Care Team" },
  //   { id: "3", label: "Providers" },
  // ];
const navigate= useNavigate()
  return (
    <div className="dashboard-container" >
    < PatientSidebar/>
    <div className="dashboard-content" >
        <Navbar 
        onClick= {()=>{navigate("/PatientProfile")}}
        />
        <div className="dashboard-cards" style={{ paddingLeft: 30 ,paddingRight:30}}>
            <div style={{ backgroundColor: 'rgba(103, 197, 55, 0.3)', padding: 20, width: '30%', borderRadius: 15 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ color: 'rgba(103, 197, 55, 1)', fontSize: 20 }}>ACCOUNT</div>
                    <img src={require('../../assest/Images/Accounticon.png')} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                    <div>
                        <div style={{fontSize:14,marginBottom:10}}>INSURANCE</div>
                        <div>0</div>
                    </div>
                    <div>
                        <div style={{fontSize:14,marginBottom:10}}>PRIVATE </div>
                        <div>0</div>
                    </div>
                    <div>
                        <div style={{fontSize:14,marginBottom:10}}>SUBSCRIPTION</div>
                        <div>0</div>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: 'rgba(20, 174, 209, 0.3)', padding: 20, width: '30%', borderRadius: 15 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ color: 'rgba(20, 174, 209, 1)', fontSize: 20 }}>CARE TEAM</div>
                    <img src={require('../../assest/Images/Careteamicon.png')} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: 10 }}>
                  
                    <div>
                        <div style={{fontSize:14,marginBottom:10}}>ACTIVE </div>
                        <div>0</div>
                    </div>
                    <div>
                        <div style={{fontSize:14,marginBottom:10}}>INACTIVE </div>
                        <div>0</div>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: 'rgba(219, 0, 255, 0.3)', padding: 20, width: '30%', borderRadius: 15 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ color: 'rgba(84, 101, 240, 1)', fontSize: 20 }}>BOOKING</div>
                    <img src={require('../../assest/Images/Bookingsicon.png')} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 10 }}>
                    <div>
                        <div style={{fontSize:14,marginBottom:10}}>CREATED </div>
                        <div>0</div>
                    </div>
                    <div>
                        <div style={{fontSize:14,marginBottom:10}}>APPROVED </div>
                        <div>0</div>
                    </div>
                    <div>
                        <div style={{fontSize:14,marginBottom:10}}>REJECTED </div>
                        <div>0</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="dashboard-actions" style={{ paddingLeft: 30, marginTop: 20 }}>
<button
  className={`action-btn ${activeButton === 'TodayAppointmentsData' ? 'active' : ''}`}
  style={{
    marginRight: 30,
    borderWidth: 1,
    borderColor: '#CCCCCC',
    borderStyle: 'solid',
  }}
  onClick={() => handleButtonClick('TodayAppointmentsData')}
>
  Today Appointments
</button>
<button
  className={`action-btn ${activeButton === 'PopularCategoryData' ? 'active' : ''}`}
  style={{
    marginRight: 30,
    borderWidth: 1,
    borderColor: 'rgba(204, 204, 204, 1)',
    borderStyle: 'solid',
  }}
  onClick={() => handleButtonClick('PopularCategoryData')}
>
Popular Categories 
</button>
<button
  className={`action-btn ${activeButton === 'CareTeamPatient' ? 'active' : ''}`}
  style={{
    marginRight: 30,
    borderWidth: 1,
    borderColor: 'rgba(204, 204, 204, 1)',
    borderStyle: 'solid',
  }}
  onClick={() => handleButtonClick('CareTeamPatient')}
>
Care Team
</button>
<button
  className={`action-btn ${activeButton === 'ProvidersDataPatient' ? 'active' : ''}`}
  style={{
    marginRight: 30,
    borderWidth: 1,
    borderColor: 'rgba(204, 204, 204, 1)',
    borderStyle: 'solid',
  }}
  onClick={() => handleButtonClick('ProvidersDataPatient')}
>
Providers
</button>
<button
  className={`action-btn ${activeButton === 'PatientJourneyData' ? 'active' : ''}`}
  style={{
    marginRight: 30,
    borderWidth: 1,
    borderColor: 'rgba(204, 204, 204, 1)',
    borderStyle: 'solid',
  }}
  onClick={() => handleButtonClick('PatientJourneyData')}
>
Patient Journey
</button>
<button
  className={`action-btn ${activeButton === 'MyClinic' ? 'active' : ''}`}
  style={{
    marginRight: 30,
    borderWidth: 1,
    borderColor: 'rgba(204, 204, 204, 1)',
    borderStyle: 'solid',
  }}
  onClick={() => handleButtonClick('MyClinic')}
>
My Clinic
</button>
<button
          className={`action-btn ${activeButton === 'Clinic Request' ? 'active' : ''}`}
          style={{
            marginRight: 30,
            borderWidth: 1,
            borderColor: 'rgba(204, 204, 204, 1)',
            borderStyle: 'solid',
          }}
          onClick={() => handleButtonClick('Clinic Request')}
        >
          New Request
         </button>
</div>

<div>
{activeButton === 'TodayAppointmentsData' && <TodayAppointmentsData />}
{activeButton === 'PopularCategoryData' && <PopularCategoryData />}
{activeButton === 'CareTeamPatient' && <CareTeamPatient />}
{activeButton === 'ProvidersDataPatient' && <ProvidersDataPatient />}
{activeButton === 'PatientJourneyData' && <PatientJourneyData />}
{activeButton === 'MyClinic' && <MyClinicPatient />}
{activeButton === 'Clinic Request' && <ClinicRequestpatient />}
</div>
    </div>
</div>
  );
};

export default Dashboradpatient;
