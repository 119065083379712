import { useState, useEffect, useRef } from "react";
import Inputbox from "../../Components/Inputbox";
import DropDownButton, { DropDownManu, DropDownManuCustomMulti } from "../../Components/DropDownButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { ShowToast } from "../../../helpers/ToastService";
import {
  ClinicProfileGet,
  ClinicUpdate,
  GetCategory,
  Patientgetprofile,
  PatientUpdate,
  uploadImage,
} from "../../../api/helper";
import ClinicChangepass from "./ClinicChangepass";
import { clearEmptyData } from "../../../helpers/helper";
import { setUserDetails } from "../../../redux/cookiesReducer";
import { Store } from "../../../redux";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";

const schema = yup.object().shape({
  // clinicName: yup.string().required('Clinic name is required'),
  // email: yup.string().email('Invalid email').required('Email is required'),
  // phone: yup
  //   .string()
  //   .matches(/^[0-9]{10}$/, 'Phone number must be 10 digits')
  //   .required('Phone number is required'),
  // webLink: yup.string().url('Invalid webLink URL'),
  // street: yup.string().required('Street address is required'),
  // state: yup.string().required('State is required'),
  // city: yup.string().required('City is required'),
  // country: yup.string().required('Country is required'),
});

const GeneralSettingsAgency = () => {
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  // const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [imagePreview, setImagePreview] = useState(null);
  const [saveData, setSaveData] = useState();
  const [imaeg, setImages] = useState(null);
  const [formSubmit, setFormSubmit] = useState(null);
  const fileInputRef = useRef(null);
  const [profiledata, setUserData] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [specialtydata, setspecialtydata] = useState(null);
  const [defcat, setDefCat] = useState([]);

  const { isLoading } = useSelector((store) => store.sliceReducer);

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const getprofile = async (data) => {
    try {
      const response = await ClinicProfileGet();
      console.log(response.data);
      setUserData(response.data.data);
      Store.dispatch(setUserDetails(response?.data?.data));
    } catch (error) {
      console.log(error);
    }
  };
  const getSpecialty = async () => {
    try {
      const resposne = await GetCategory();
      setspecialtydata(resposne?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getprofile();
    getSpecialty()
  }, []);
  useEffect(() => {
    reset(profiledata);
  }, [profiledata, reset]);


  useEffect(() => {
    if (profiledata) {

      const categoryIds = profiledata?.categoryId?.map((e) => e._id) || [];
      setDefCat(categoryIds);

      reset({
        clinetName: `${profiledata.firstName} ${profiledata.lastName}`,
        companyName: profiledata.companyName || "",
        email: profiledata.email || "",
        phone: profiledata.phone || "",
        webLink: profiledata.webLink || "",
        lastName:profiledata.lastName||"",
        firstName:profiledata?.firstName||"",
        address: {
          street: profiledata?.address?.street || "",
          city: profiledata?.address?.city || "",
          state: profiledata?.address?.state || "",
          country: profiledata?.address?.country || "",
          zipCode: profiledata?.address?.zipCode || "",
        },
        EIN: profiledata.EIN || "",
        DBA: profiledata.DBA || "",
        NPI2: profiledata.NPI2 || "",
      });
    }
  }, [profiledata, reset]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBTgjMWeFMxL5oe-KFnKts3YGBZJlEC6eM", // Replace with your Google Maps API key
    libraries: ["places"], // Include 'places' library for autocomplete
  });
  if (!isLoaded) return <div>Loading...</div>;
  
  const handlePlaceSelect = async () => {
    const place = autocomplete.getPlace();
    const addressComponents = place.address_components;
  
    // Function to get address component by type
    const getAddressComponent = (type) => {
      const component = addressComponents.find((c) => c.types.includes(type));
      return component ? component.long_name : "";
    };
  
    // Set country, state, and city
    setValue("address.country", getAddressComponent("country"));
    setValue("address.state", getAddressComponent("administrative_area_level_1"));
    setValue("address.city", getAddressComponent("locality"));
  
    // Extract street number, route, sublocality (for sectors), neighborhood, premise (building), establishment (business)
    const streetNumber = getAddressComponent("street_number");
    const route = getAddressComponent("route");
    const sublocality = getAddressComponent("sublocality");
    const neighborhood = getAddressComponent("neighborhood");
    const premise = getAddressComponent("premise"); // Building name or room number
    const establishment = getAddressComponent("establishment"); // Business names
  
    // Declare fullStreet and build it
    let fullStreet = "";
  
    // Priority handling: check if it's an establishment (business), premise, or standard street address
    if (establishment) {
      fullStreet = establishment; // Use business name if available
    } else if (premise) {
      fullStreet = `${premise}, ${route || sublocality || neighborhood}`; // Use building/room with route
    } else if (streetNumber && route) {
      fullStreet = `${streetNumber} ${route}`; // Standard street address
    } else if (sublocality) {
      fullStreet = sublocality; // For sectors
    } else if (neighborhood) {
      fullStreet = neighborhood; // If categorized as neighborhood
    }
  
    // Fallback: if no fullStreet found, use locality, state, country
    if (!fullStreet) {
      fullStreet = `${getAddressComponent("locality")}, ${getAddressComponent("administrative_area_level_1")}, ${getAddressComponent("country")}`;
    }
  
    // Set the full street address in the form
    setValue("address.street", fullStreet);
  
    // Set the zip code
    setValue("address.zipCode", getAddressComponent("postal_code"));
  };
  
  const handleChange = (e) => {
    reset({
      phone: e,
    });
  };
  const handleCloseModal = async (data) => {
    if (data == "Upload") {
      if (!imagePreview) {
        return ShowToast("Please Select Image", "error");
      }
      saveData.image = imaeg;
    }
    setFormSubmit(data);
    // getprofile();
  };
  const imagePicker = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("images", file);
      try {
        const response = await uploadImage(formData);
        setImages(response.data.data[0].URL);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.log(error, "===");
      }
    }
  };
  const clickImageData = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const splitFullName = (fullName) => {
    const nameParts = fullName.trim().split(" ");

    if (nameParts.length > 2) {
      return {
        firstName: nameParts.slice(0, -1).join(" "),
        lastName: nameParts[nameParts.length - 1],
      };
    }

    return {
      firstName: nameParts[0] || "",
      lastName: nameParts[1] || "",
    };
  };

  const onSubmit = async (data) => {
    // if (data.clinetName) {
    //   const { firstName, lastName } = splitFullName(data.clinetName);
    //   data.firstName = firstName;
    //   data.lastName = lastName;
    //   delete data.clinetName;
    // }

    if (imaeg) {
      data.image = imaeg;
    }

    await updateProfile(data);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const updateProfile = async (data) => {
    try {
      const newData = clearEmptyData(data);
      const response = await ClinicUpdate(newData);
      ShowToast(response.data.message, "success");
      getprofile("msg");
    } catch (error) {
      console.log(error, "===eror");
    }
  };

  const toggleChangePassword = () => {
    setIsChangePasswordVisible(!isChangePasswordVisible);
  };
  const handleSelect = (selectedOptions) => {
    console.log("selectedOptions:", selectedOptions); // This will log the value
    let ids = Array.isArray(selectedOptions)
      ? selectedOptions.map((e) => e?._id)
      : [];
    setDefCat(ids);
    setValue("categoryId", ids);
  };


  return (
    <div>
      {isLoading ? null : profiledata ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                className="Clinical_Settingstwo"
                onClick={toggleChangePassword}
              >
                <img
                  src={require("../../assest/Images/change_pass.png")}
                  style={{ marginRight: 10 }}
                />
                Change Password{" "}
              </div>
            </div>
            <div className="flex-row" style={{ width: "100%" }}>
              {imagePreview ? (
                <img
                  src={imagePreview}
                  style={{ height: 105, width: 105, borderRadius: 60 }}
                />
              ) : (
                <>
                  {profiledata?.image ? (
                    <img
                      src={profiledata?.image}
                      style={{ height: 105, width: 105, borderRadius: 60 }}
                    />
                  ) : (
                    <img
                      src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                      style={{ height: 105, width: 105, borderRadius: 60 }}
                    />
                  )}
                </>
              )}
              <div style={{ marginLeft: 70, width: "80%" }}>
                <div className="clinicnametxtt">Clinic Name</div>
                <input
                  {...register("companyName")}
                  style={{ width: "100%" }}
                  placeholder="Birmingham Dental Clinic"
                />
                {errors.clinetName && <p>{errors.clinetName.message}</p>}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                marginTop: 20,
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              <div
                style={{
                  borderWidth: 1,
                  borderColor: "#011434",
                  borderStyle: "solid",
                  // padding: 10,
                  padding: 7,
                  borderRadius: 30,
                }}
                className="Contact_Information_hhh"
                onClick={clickImageData}
              >
                {" "}
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={imagePicker}
                  style={{ display: "none" }}
                />
                Change Picture
              </div>
            </div>
            <div>
            <div
                className="space-div"
                style={{ width: "100%", paddingRight: "4%" }}
              >
                <div style={{ width: "47%" }}>
                  <div className="Email_Address_txt"> Contact First Name</div>
                  <input
                    {...register("firstName")}
                    placeholder="Enter First Name"
                  />
                  {/* {errors.email && <p>{errors.email.message}</p>} */}
                </div>
                <div style={{ width: "47%" }}>
                  <div className="Email_Address_txt"> Contact Last Name</div>
                  <input
                    {...register("lastName")}
                    placeholder="Enter Last Name"
                  />
                  {/* {errors.email && <p>{errors.email.message}</p>} */}
                </div>
              </div>
              <div
                className="space-div"
                style={{ width: "100%", paddingRight: "4%" }}
              >
                <div style={{ width: "47%" }}>
                  <div className="Email_Address_txt">Email Address</div>
                  <input
                    {...register("email")}
                    placeholder="clinic@gmail.com"
                  />
                  {errors.email && <p>{errors.email.message}</p>}
                </div>
                <div style={{ width: "47%" }}>
                  <div
                    className="Email_Address_txt"
                    style={{ marginBottom: -20 }}
                  >
                    Phone Number
                  </div>
                  <Inputbox
                    style={{ width: "100%", height: 43 }}
                    type="text"
                    placeholder="Enter Number"
                    name="phone"
                    value={profiledata?.phone}
                    onChange={handleChange}
                  />
                  {errors.phone && (
                    <span className="errorMessage">{errors.phone.message}</span>
                  )}

                  {/* <input
          {...register('phone')}
          placeholder="9876543210"
        /> */}
                  {/* {errors.phone && <p>{errors.phone.message}</p>} */}
                </div>
              </div>

              <div
                className="space-div"
                style={{ width: "100%", paddingRight: "4%" }}
              >
                <div style={{ width: "47%" }}>
                  <div className="Email_Address_txt">Website</div>
                  <input
                    {...register("webLink")}
                    placeholder="www.wellmindconnect.com"
                  />
                  {errors.webLink && <p>{errors.webLink.message}</p>}
                </div>

                <div style={{ width: "47%" }}>
                <div className="Email_Address_txt">Specialities</div>
                <DropDownManuCustomMulti
              // label="Specialty"
              placeholder="Select option"
              options={specialtydata}
              name="categoryId"
              register={register}
              id="_id"
              defaultValue={defcat}
              clearErrors={clearErrors}
              onSelect={handleSelect}
            />
                </div>
                

              </div>
              <Autocomplete
            onLoad={autocompleteInstance => setAutocomplete(autocompleteInstance)}
            onPlaceChanged={handlePlaceSelect}
          >
              <div style={{ width: "96%", marginTop: 30, marginBottom: 20 }}>
                <div className="Email_Address_txt">Street</div>
                <input
                  {...register("address.street")}
                  placeholder="51A New Birmingham Road Oldbury, B692JQ"
                />
                {errors.street && <p>{errors.street.message}</p>}
              </div>
              </Autocomplete>
              <div
                className="space-div"
                style={{ width: "100%", paddingRight: "4%" }}
              >
                <div style={{ width: "47%" }}>
                  <div className="Email_Address_txt">City</div>
                  <input
                    {...register("address.city")}
                    style={{ borderColor: "#011434",}}
                    placeholder="Select City"
                  />
                  {errors.city && <p>{errors.city.message}</p>}
                </div>
                <div style={{ width: "47%" }}>
                  <div className="Email_Address_txt">State</div>
                  <input
                    {...register("address.state")}
                    placeholder="Select State"
                  />
                  {errors.state && <p>{errors.state.message}</p>}
                </div>
              </div>
              <div
                className="space-div"
                style={{
                  width: "100%",
                  paddingRight: "4%",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <div style={{ width: "47%" }}>
                  <div className="Email_Address_txt">Country</div>
                  <input
                    {...register("address.country")}
                    placeholder="Select Country"
                  />
                  {errors.country && <p>{errors.country.message}</p>}
                </div>
                <div style={{ width: "47%" }}>
                  <div className="Email_Address_txt">Zip Code</div>
                  <input {...register("address.zipCode")} placeholder="12345" />
                  {errors.zipCode && <p>{errors.zipCode.message}</p>}
                </div>
              </div>
              <div
                className="space-div"
                style={{
                  width: "100%",
                  paddingRight: "4%",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <div style={{ width: "47%" }}>
                <Inputbox
                  label="DBA"
                  type="text"
                  placeholder="Enter DBA"
                  name="DBA"
                  register={register}
                />
                {errors.address?.state && <span className="errorMessage">{errors.DBA.message}</span>}
              </div>
              <div style={{ width: "47%" }}>
                <Inputbox
                  label="NPI 2"
                  type="text"
                  placeholder="Enter NPI 2"
                  name="NPI2"
                  register={register}
                />
                {errors.address?.country && <span className="errorMessage">{errors.NPI2.message}</span>}
              </div>
            
            
             
           
            </div>
            <div style={{  width: "96%", }}>
                <Inputbox
                  label="EIN"
                  type="text"
                  placeholder="Enter EIN"
                  name="EIN"
                  register={register}
                />
                {errors.address?.country && <span className="errorMessage">{errors.EIN.message}</span>}
              </div>

              <div
                style={{
                  alignSelf: "center",
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: 30,
                  marginBottom: 50,
                }}
              >
                <div className="cancelButton">Cancel</div>
                <button className="savebuttonn" type="submit">
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div>No data available</div>
      )}
      {isChangePasswordVisible && (
        <ClinicChangepass
          toggleChangePassword={() => {
            toggleChangePassword();
          }}
        />
      )}
    </div>
  );
};
export default GeneralSettingsAgency;
