import React from 'react'

const PatientJourneyData = () => {
  return (
   <div className='Patient_Journey_MainDiv'>
   
   <div className='div_Patient_txt'>
      <p className='Patient_txt'>Patient Journey Timeline</p>
   </div>

   <div className='patient_div_display'>
    <img className='patient_image_Year' src={require('../../assest/Images/YearVector.png')} />
    <p className='Patient_txt' style={{fontSize:40,}}>2023</p>
   </div>
   <div className='patient_div_display'>
   <img className='patient_image1_Year' src={require('../../assest/Images/MonthVector.png')} />
   <p className='Patient_txt'  style={{fontSize:25, color: '#BC9C22'}}>February</p>
   </div>
   <div className='patient_input_div'>
    <img className='Patient_image_test' src={require('../../assest/Images/TestTubes.png')}/>
    <div className='Patient_text_div'>
    <p className='patient_Feb_txt'>February 10, 2023</p>
    <p className='Patient_Blood_txt'>Blood Tests Ordered</p>
    </div>
    <img className='Patient_image_icon' src={require('../../assest/Images/IconLastVector.png')}/>
   </div>
   <div className='patient_input_div'>
    <img className='Patient_image_test' src={require('../../assest/Images/maki_doctor.png')}/>
    <div className='Patient_text_div'>
    <p className='patient_Feb_txt'>February 10, 2023</p>
    <p className='Patient_Blood_txt'>Follow_Up Consultation: Review Lab Results</p>
    </div>
    <img className='Patient_image_icon' src={require('../../assest/Images/IconLastVector.png')}/>
   </div>

   <div className='patient_div_display'>
   <img className='patient_image1_Year' src={require('../../assest/Images/MonthVector.png')} />
   <p className='Patient_txt'  style={{fontSize:25, color: '#BC9C22'}}>January</p>
   </div>
   <div className='patient_input_div'>
    <img className='Patient_image_test' src={require('../../assest/Images/TestTubes.png')}/>
    <div className='Patient_text_div'>
    <p className='patient_Feb_txt'>February 10, 2023</p>
    <p className='Patient_Blood_txt'>Blood Tests Ordered</p>
    </div>
    <img className='Patient_image_icon' src={require('../../assest/Images/IconLastVector.png')}/>
   </div>
   <div className='patient_input_div'>
    <img className='Patient_image_test' src={require('../../assest/Images/maki_doctor.png')}/>
    <div className='Patient_text_div'>
    <p className='patient_Feb_txt'>February 10, 2023</p>
    <p className='Patient_Blood_txt'>Follow_Up Consultation: Review Lab Results</p>
    </div>
    <img className='Patient_image_icon' src={require('../../assest/Images/IconLastVector.png')}/>
   </div>


   <div className='patient_div_display'>
    <img className='patient_image_Year' src={require('../../assest/Images/YearVector.png')} />
    <p className='Patient_txt' style={{fontSize:40,}}>2022</p>
   </div>
   <div className='patient_div_display'>
   <img className='patient_image1_Year' src={require('../../assest/Images/MonthVector.png')} />
   <p className='Patient_txt'  style={{fontSize:25, color: '#BC9C22'}}>December</p>
   </div>
   <div className='patient_input_div'>
    <img className='Patient_image_test' src={require('../../assest/Images/TestTubes.png')}/>
    <div className='Patient_text_div'>
    <p className='patient_Feb_txt'>February 10, 2023</p>
    <p className='Patient_Blood_txt'>Blood Tests Ordered</p>
    </div>
    <img className='Patient_image_icon' src={require('../../assest/Images/IconLastVector.png')}/>
   </div>
   <div className='patient_input_div'>
    <img className='Patient_image_test' src={require('../../assest/Images/maki_doctor.png')}/>
    <div className='Patient_text_div'>
    <p className='patient_Feb_txt'>February 10, 2023</p>
    <p className='Patient_Blood_txt'>Follow_Up Consultation: Review Lab Results</p>
    </div>
    <img className='Patient_image_icon' src={require('../../assest/Images/IconLastVector.png')}/>
   </div>

   </div>
  )
}

export default PatientJourneyData