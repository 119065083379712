
import PatientSidebar from "./PatientSidebar";
import { Navtopbar } from "../../Components/Navbar";
import { useNavigate } from "react-router-dom";
import Checkbox from "./Checkbox";
import { useState } from "react";



const SubscriptionPlan = () => {
    const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
const [activeTab, setActiveTab] = useState("workingHours");
const handlenotification = () => {
  setActiveTab("ThankYou");
  setIsChangePasswordVisible(!isChangePasswordVisible);
};
const navigate = useNavigate();
const handleSignUp = () => {
  navigate("/SubscriptionPlan");
};
const toggleChangePassword = () => {
    setActiveTab("ThankYou");
    setIsChangePasswordVisible(!isChangePasswordVisible);
  };

  return (
    <div className="dashboard-container">
      <PatientSidebar />
      <div className="dashboard-content">
        <Navtopbar title={"Subscription Plan"} />
        <div
          className="dashboard-cards1"
          style={{ paddingLeft: 50, paddingRight: 50 }}
        >
<p className="Subscription_Individual_plans">Individual Plans</p>
<div className="Subscription_div_display">
    {/* <div className="Scrip_div_display" style={{width:"30%"}}>
        <div className="Display_Basic_Plan">
            <p className="Subscri_Basic_text">Basic Plan</p>
            <p className="Subscri_Basic_text">$99/month</p>
        </div>
        <div>
        <ul>
            <li>Monthly one-on-one therapy sessions</li>
            <li>Medication management</li>
        </ul>
        </div>     
       
    </div> */}

    <div className="pricing-card">
      <div className="card-header">
        <h3>Basic Plan</h3>
        <div className="price-tag">
          <span>$99/month</span>
        </div>
      </div>
      <ul className="features-list">
        <li>● Monthly one-on-one therapy sessions</li>
        <li>● Medication management</li>
        <li>● 24/7 crisis support</li>
        <li>● Online therapy sessions</li>
        <li>● Self-care resources</li>
        <li>● Discounted rates for additional services</li>
      </ul>
      <div className="div_display_button1">
          <div
            style={{ marginTop: 20 }}
            className={
              activeTab === "ThankYou" ? "active-tab1" : "inactive-tab1"
            }
            onClick={handlenotification}
          >
            Enroll Now
          </div>
        </div>
    </div>
    <div className="pricing-card">
      <div className="card-header">
        <h3>Basic Plan</h3>
        <div className="price-tag">
          <span>$99/month</span>
        </div>
      </div>
      <ul className="features-list">
        <li>● Monthly one-on-one therapy sessions</li>
        <li>● Medication management</li>
        <li>● 24/7 crisis support</li>
        <li>● Online therapy sessions</li>
        <li>● Self-care resources</li>
        <li>● Discounted rates for additional services</li>
      </ul>
      <div className="div_display_button1">
          <div
            style={{ marginTop: 20 }}
            className={
              activeTab === "ThankYou" ? "active-tab1" : "inactive-tab1"
            }
            onClick={handlenotification}
          >
            Enroll Now
          </div>
        </div>
    </div>
    <div className="pricing-card">
      <div className="card-header">
        <h3>Basic Plan</h3>
        <div className="price-tag">
          <span>$99/month</span>
        </div>
      </div>
      <ul className="features-list">
        <li>● Monthly one-on-one therapy sessions</li>
        <li>● Medication management</li>
        <li>● 24/7 crisis support</li>
        <li>● Online therapy sessions</li>
        <li>● Self-care resources</li>
        <li>● Discounted rates for additional services</li>
      </ul>

        <div className="div_display_button1">
          <div
            style={{ marginTop: 20 }}
            className={
              activeTab === "ThankYou" ? "active-tab1" : "inactive-tab1"
            }
            onClick={handlenotification}
          >
            Enroll Now
          </div>
        </div>
      {/* <button className="enroll-button">Enroll Now</button> */}

    </div>






</div>
</div>
</div>




{activeTab === "ThankYou" && (
        <div>
          {isChangePasswordVisible && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
            >
              <div
                className="HelpSupport_div"
                style={{
                  maxWidth: "40%",
                  paddingLeft: 100,
                  paddingRight: 100,
                  paddingTop:50,
                  paddingBottom:50,
                }}
              >



                <div style={{backgroundColor:'#fff', padding:40, borderRadius:16,alignItems:'center',justifyContent:'center',display:'flex',flexDirection:'column' }}>
                  <div style={{backgroundColor:'#011434',borderRadius:90, width:110, height:110 , alignContent:'center',alignSelf:'center'}}>
                    <img style={{height:75, width:75,}} src={require('../../assest/Images/like.png')}/>
                  </div>
                <div className="Contact_Us_txt" style={{color:'#011434'}}>Thank You !</div>

               <p className="thanku_Your_text" style={{paddingBottom:10}}>Your Appointment Successful</p>
               <p className="thanku_Your_text">You booked an appointment with Dental Clinic Stafford on February 21, at 01:00 PM</p>

                <div
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <div className="savebuttonn1" onClick={toggleChangePassword}>
                    Done
                  </div>
                </div>

                <p>Edit your appointment</p>
                </div>




              </div>
            </div>
          )}
        </div>
      )}






    </div>
  );
};

export default SubscriptionPlan;
