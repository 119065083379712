import React, { useState } from "react";

const AllergiesPatient = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };
  return (
    <div className="patient_right">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignSelf: "flex-end",
          marginTop: 20,
          marginRight: 20,
        }}
      >
        <img src={require("../assest/Images/NKDA_icon.png")} />
        <div className="view_Prescriptions_txt">NKDA</div>
        <div
          className="custom-problem-add-button_two"
          onClick={toggleFormVisibility}
        >
          <img
            src={require("../assest/Images/plus_icon.png")}
            style={{ marginRight: 10, filter: "invert(1) brightness(5)" }}
          />{" "}
          Add
        </div>
      </div>
      <div className="medication-table-container">
        <div className="medication-header_t">
          <div className="medication-header-item_t">Allergy</div>
          <div className="medication-header-item_t">|</div>
          <div className="medication-header-item_t">Recorded Date</div>
          <div className="medication-header-item_t">|</div>
          <div className="medication-header-item_t">Clinical Status</div>
          <div className="medication-header-item_t">|</div>
          <div className="medication-header-item_t">Verification Status</div>
          <div className="medication-header-item_t">|</div>
          <div className="medication-header-item_t">Critically</div>
          <div className="medication-header-item_t">|</div>
          <div className="medication-header-item_t">Management Measures</div>
        </div>

        <div className="medication-empty-state">
          <div className="medication-empty-title">Nothing Here Yet</div>
          <div className="medication-empty-description">
            No health records available for this resource
          </div>
        </div>
      </div>
      {isFormVisible && (
        <div className="add_problem_div">
          <div className="Add_problem_txtt">
            Add Allergy
            <img
              src={require("../assest/Images/crossIconn.png")}
              alt="close"
              onClick={toggleFormVisibility} // Hides form when clicked
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="Record_Details_txtt">Record Details</div>

          {/* Form Inputs */}
          <div className="problem_div_txtt">
            Allergy
            <select className="select_option_style">
              <option></option>
            </select>
          </div>

          <div className="code_txt_div">
            Code
            <input type="number" placeholder="-" className="input_num_style" />
          </div>
          <div className="code_txt_div">
            Clinical Status
            <select className="select_option_style">
              <option>Active </option>
            </select>
          </div>
          <div className="code_txt_div">
            Verification Status
            <select className="select_option_style">
              <option>Unconfirmed </option>
            </select>
          </div>
          <div className="code_txt_div">
            Criticality
            <select className="select_option_style">
              <option>Low </option>
            </select>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="care_plan_txtt">Management Instructions</div>
            <div className="code_txt_div">
              <textarea
                placeholder="Add patient instructions                   Optional"
                className="input_num_style"
                style={{ height: "80px", width: "100%" }}
              />
            </div>
          </div>

          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 20 }}
          >
            <div className="Dosage_Instructions_txtt">Last Reaction</div>
            <div className="optional_txtt">Optional</div>
          </div>
          <div className="code_txt_div">
            Date
            <input type="date" placeholder="-" className="input_num_style" />
          </div>
          <div className="code_txt_div">
            Kind
            <input type="text" className="select_option_style" />
          </div>
          <div className="code_txt_div">
            Severity
            <select className="select_option_style">
              <option>Select</option>
            </select>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* Action Buttons */}
            <div className="action_buttons">
              <button className="cancel_button" onClick={toggleFormVisibility}>
                Cancel
              </button>
              <button className="save_button">Save</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllergiesPatient;
