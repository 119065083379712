import React from 'react'
import logo from "../assest/Images/mindWellIcon.png";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { ForgetUser } from '../../api/helper';
import { ShowToast } from '../../helpers/ToastService';


function ForgetPassword() {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = async(data) => {
    try {
      const newData = await ForgetUser(data)
      reset()
      ShowToast(newData.data.message,"success")
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      <div className="login custom_login--width">
        <div className="login_content">
          <div class="modal d-block" tabindex="-1">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header border-0">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={()=>{
                      navigate('/')
                    }}
                  ></button>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div class="modal-body text-center w-100">
                    <img src={logo} alt="logo" />
                    <p className="heading_label">Forgot Password</p>
                    <p className="label">
                      Please enter your email address to reset you password
                    </p>

                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label label">
                        Email address
                      </label>
                      <input
                        type="email"
                        {...register("email", { required: true })}
                        class="form-control input"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                       {errors.email && <span className="errorMessage"  >Email is required</span>}
                    </div>

                  </div>
                  <div class="modal-footer border-0 text-center d-block w-100">
                    <button type="submit" class="btn_dark">
                      Submit
                    </button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgetPassword