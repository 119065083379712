import React, { useEffect, useState } from "react";
import Inputbox from "../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { updateAgencyDoctor } from "../../../api/helper";
import { ShowToast } from "../../../helpers/ToastService";
import { DropDownManuCustom, DropDownMultiStringSelect } from "../../Components/DropDownButton";

const licenseTypes = [
  { _id: 'Nursing License', name: 'Nursing License' },
  { _id: 'Medical License', name: 'Medical License' },
  { _id: 'Psychology License', name: 'Psychology License' },
  { _id: "Bachelor's in Nursing", name: "Bachelor's in Nursing" },
  { _id: "Master's in Psychology", name: "Master's in Psychology" },
  { _id: 'Doctorate in Psychiatry', name: 'Doctorate in Psychiatry' },
  { _id: 'CPR Certification', name: 'CPR Certification' },
  { _id: 'Mental Health Certification', name: 'Mental Health Certification' },
  { _id: 'Addiction Treatment Certification', name: 'Addiction Treatment Certification' },

  { _id: 'Cognitive Behavioral (CBT)', name: 'Cognitive Behavioral (CBT)' },
  { _id: 'Dialectical Behavioral (DBT)', name: 'Dialectical Behavioral (DBT)' },
  { _id: 'Acceptance & Commitment (ACT)', name: 'Acceptance & Commitment (ACT)' },
  { _id: 'Mindfulness-Based (MBCT)', name: 'Mindfulness-Based (MBCT)' },
  { _id: 'Motivational Interviewing', name: 'Motivational Interviewing' },
  { _id: 'Psychodynamic Therapy', name: 'Psychodynamic Therapy' },
  { _id: 'Solution Focused Brief Therapy (SFBT)', name: 'Solution Focused Brief Therapy (SFBT)' },
  { _id: 'Trauma-Focused Therapy', name: 'Trauma-Focused Therapy' },

  { _id: 'Individual Therapy', name: 'Individual Therapy' },
  { _id: 'Group Therapy', name: 'Group Therapy' },
  { _id: 'Family Therapy', name: 'Family Therapy' },
  { _id: 'Couples Therapy', name: 'Couples Therapy' },
  { _id: 'Crisis Intervention', name: 'Crisis Intervention' },
  { _id: 'Medication Management', name: 'Medication Management' },

  { _id: 'Elders (65 and above)', name: 'Elders (65 and above)' },
  { _id: 'Adults (18 to 64)', name: 'Adults (18 to 64)' },
  { _id: 'Teenagers (13 to 17)', name: 'Teenagers (13 to 17)' },
  { _id: 'Children (6 to 12)', name: 'Children (6 to 12)' },

  { _id: 'Anxiety', name: 'Anxiety' },
  { _id: 'Depression', name: 'Depression' },
  { _id: 'ADHD', name: 'ADHD' },
  { _id: 'Bipolar Disorder', name: 'Bipolar Disorder' },
  { _id: 'PTSD', name: 'PTSD' },
  { _id: 'Eating Disorders', name: 'Eating Disorders' },
  { _id: 'Substance Use Disorders', name: 'Substance Use Disorders' },
  { _id: 'Sleep Disorders', name: 'Sleep Disorders' },
  { _id: 'Anger Management', name: 'Anger Management' },
  { _id: 'Grief and Loss', name: 'Grief and Loss' },
  { _id: 'Trauma', name: 'Trauma' },
  { _id: 'Relationship Issues', name: 'Relationship Issues' },
];
const primaryStatesOfPractice = [
  { _id: "1", name: "Alabama", abbreviation: "AL" },
  { _id: "2", name: "Alaska", abbreviation: "AK" },
  { _id: "3", name: "Arizona", abbreviation: "AZ" },
  { _id: "4", name: "Arkansas", abbreviation: "AR" },
  { _id: "5", name: "California", abbreviation: "CA" },
  { _id: "6", name: "Colorado", abbreviation: "CO" },
  { _id: "7", name: "Connecticut", abbreviation: "CT" },
  { _id: "8", name: "Delaware", abbreviation: "DE" },
  { _id: "9", name: "Florida", abbreviation: "FL" },
  { _id: "10", name: "Georgia", abbreviation: "GA" },
  { _id: "11", name: "Hawaii", abbreviation: "HI" },
  { _id: "12", name: "Idaho", abbreviation: "ID" },
  { _id: "13", name: "Illinois", abbreviation: "IL" },
  { _id: "14", name: "Indiana", abbreviation: "IN" },
  { _id: "15", name: "Iowa", abbreviation: "IA" },
  { _id: "16", name: "Kansas", abbreviation: "KS" },
  { _id: "17", name: "Kentucky", abbreviation: "KY" },
  { _id: "18", name: "Louisiana", abbreviation: "LA" },
  { _id: "19", name: "Maine", abbreviation: "ME" },
  { _id: "20", name: "Maryland", abbreviation: "MD" },
  { _id: "21", name: "Massachusetts", abbreviation: "MA" },
  { _id: "22", name: "Michigan", abbreviation: "MI" },
  { _id: "23", name: "Minnesota", abbreviation: "MN" },
  { _id: "24", name: "Mississippi", abbreviation: "MS" },
  { _id: "25", name: "Missouri", abbreviation: "MO" },
  { _id: "26", name: "Montana", abbreviation: "MT" },
  { _id: "27", name: "Nebraska", abbreviation: "NE" },
  { _id: "28", name: "Nevada", abbreviation: "NV" },
  { _id: "29", name: "New Hampshire", abbreviation: "NH" },
  { _id: "30", name: "New Jersey", abbreviation: "NJ" },
  { _id: "31", name: "New Mexico", abbreviation: "NM" },
  { _id: "32", name: "New York", abbreviation: "NY" },
  { _id: "33", name: "North Carolina", abbreviation: "NC" },
  { _id: "34", name: "North Dakota", abbreviation: "ND" },
  { _id: "35", name: "Ohio", abbreviation: "OH" },
  { _id: "36", name: "Oklahoma", abbreviation: "OK" },
  { _id: "37", name: "Oregon", abbreviation: "OR" },
  { _id: "38", name: "Pennsylvania", abbreviation: "PA" },
  { _id: "39", name: "Rhode Island", abbreviation: "RI" },
  { _id: "40", name: "South Carolina", abbreviation: "SC" },
  { _id: "41", name: "South Dakota", abbreviation: "SD" },
  { _id: "42", name: "Tennessee", abbreviation: "TN" },
  { _id: "43", name: "Texas", abbreviation: "TX" },
  { _id: "44", name: "Utah", abbreviation: "UT" },
  { _id: "45", name: "Vermont", abbreviation: "VT" },
  { _id: "46", name: "Virginia", abbreviation: "VA" },
  { _id: "47", name: "Washington", abbreviation: "WA" },
  { _id: "48", name: "West Virginia", abbreviation: "WV" },
  { _id: "49", name: "Wisconsin", abbreviation: "WI" },
  { _id: "50", name: "Wyoming", abbreviation: "WY" },
];
const Credentialdata = ({ info, getDteails }) => {
  // console.log(info, "===info");
  // const [saveInfo , setSaveInfo] = useState(null)
  const [selectedValues, setSelectedValues] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    clearErrors,
    reset,
  } = useForm({
    defaultValues: {
      id: info._id,
      Education: info.Education || "",
      licenseNumber: info?.licenseNumber || "",
      BoardCertifications: info?.BoardCertifications || "",
      NPI: info?.NPI || "",
      // NPIType: info?.NPIType || "",
      FacilityAffiliation: info?.FacilityAffiliation || "",
      Reference: info?.Reference || "",
      DEA: info?.DEA || "",
      // LicenseType: info?.LicenseType || "",
      practiceLocation: info?.practiceLocation || "",
      PrimaryLicenseNo: info?.PrimaryLicenseNo || "",
      primaryStateOfPractice: info?.primaryStateOfPractice || "",
      EnumerationDate: info?.EnumerationDate || "",

    },
  });
  useEffect(() => {
    console.log(info.LicenseType, '===info.LicenseType')
    const defaultVal = info.LicenseType
      ? info.LicenseType?.split(',').map(item => item.trim())
      : [];
    setSelectedValues(defaultVal);
  }, [])

  const onSubmit = (data) => {
    data.id = info._id
    dataToSave(data);
  };

  const dataToSave = async (data) => {
    try {
      const response = await updateAgencyDoctor(data);
      ShowToast(response.data.message, "success");
      getDteails();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectlin = (value) => {
    console.log(value, '===value')
    reset({
      LicenseType: value,
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-row">
          <Inputbox
            label="Education"
            type="text"
            placeholder="Enter Education"
            name="Education"
            register={register}
          />
          <div style={{ width: "40px" }} />
          {/* <Inputbox
            type="text"
            label="License Number"
            placeholder="Enter License Number"
            name="licenseNumber"
            register={register}
          /> */}
          <div style={{ width: "40px" }} />
          <Inputbox
            type="text"
            label="Board Certifications"
            placeholder="Enter Certifications"
            name="BoardCertifications"
            register={register}
          />
        </div>
        <div className="input-row">
          <Inputbox
            label="Facility Affiliation"
            type="text"
            placeholder="Enter Facility"
            name="FacilityAffiliation"
            register={register}
          />
          <div style={{ width: "40px" }} />
          <Inputbox
            type="text"
            label="DEA"
            placeholder="Enter DEA"
            name="DEA"
            register={register}
          />
          <div style={{ width: "40px" }} />
          <Inputbox
            type="text"
            label="NPI Number"
            placeholder="Enter NPI"
            name="NPI"
            register={register}
          />
        </div>
        <div
          className="input-row"
          style={{ width: "100%", alignSelf: "flex-start" }}
        >

          <DropDownMultiStringSelect
            label="Credentials Type"
            placeholder="Select option"
            options={licenseTypes}
            name="LicenseType"
            register={register}
            id="_id"
            clearErrors={clearErrors}
            onSelect={handleSelectlin}
            defaultValue={selectedValues}
          />
          {/* <DropDownManuCustom
            label=" License Type"
            placeholder="Select option"
            options={licenseTypes}
            defaultValue={info?.LicenseType}
            name="LicenseType"
            dropdownicon
            register={register}
          /> */}
          <div style={{ width: "40px" }} />
          <Inputbox
            type="text"
            label="Reference"
            placeholder="Enter Reference"
            name="Reference"
            register={register}
          />
          <Inputbox
            label="Practice Location"
            type="text"
            placeholder="Select Practice Location"
            name="practiceLocation"
            register={register}
          />
        </div>

        <div
          className="input-row"
          style={{ width: "66%", alignSelf: "flex-start" }}
        >
          <Inputbox
            label="Primary State License Number"
            type="text"
            placeholder="Enter Primary State License Number"
            name="PrimaryLicenseNo"
            register={register}
          />
          <div style={{ width: "40px" }} />
          <DropDownManuCustom
            label="Primary State Of Practice"
            placeholder="Select option"
            options={primaryStatesOfPractice}
            name="primaryStateOfPractice"
            defaultValue={info?.primaryStateOfPractice}
            dropdownicon
            register={register}
          />
          {/* <div style={{}}> */}
          {/* <Inputbox
                label="Enumeration Date"
                type="date"
                name="EnumerationDate"
                register={register}
                style={{
                  borderColor: errors.EnumerationDate ? "red" : "initial",marginTop:-3
                }}
              /> */}
          {/* </div> */}
        </div>









        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: 30,
          }}
        >
          <div className="cancelButton">Cancel</div>
          <button className="savebuttonn" style={{ width: 340 }} type="submit">
            Save
          </button>
          {/* <div className="savebuttonn" style={{ width: 340 }}  >
          Save
        </div> */}
        </div>
      </form>
    </div>
  );
};

export default Credentialdata;
