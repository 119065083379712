import React from 'react'

const OutstandingItems = () => {
  const notifications = [
    { label: 'eRx Requests', count: 6 },
    { label: 'Labs / Studies', count: 1 },
    { label: 'Unread Messages', count: 0 },
    { label: 'Open Notes', count: 4 },
    { label: 'Appointment Requests', count: 4 },
    { label: 'Patient Intake', count: 0 },
  ];

  return (
    <div className="notification-list">
    {notifications.map((notification, index) => (
      <div key={index} className="notification-item">
        <span className="notification-label">{notification.label}</span>
        <div className="notification-count">
          <span className="count-circle">{notification.count}</span>
        </div>
      </div>
    ))}
  </div>
  )
}

export default OutstandingItems