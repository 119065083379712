import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  userDetails: null,
  LastUserData:null,
  loginType:null,
  headertoken:null,
};

const cookies = createSlice({
  name: 'cookies',
  initialState,
  reducers: {
    setUserDetails: (state, action) =>{
      state.userDetails = action.payload
    },
    setLastUserData: (state, action) =>{
     
      state.LastUserData = action.payload
    },
    setLoginType:(state, action)=>{
      state.loginType = action.payload
    },
    setHeaderToken:(state, action)=>{
      state.headertoken = action.payload
    }
  },
});

export const {setUserDetails,setLastUserData,setLoginType,setHeaderToken} = cookies.actions;
export default cookies.reducer;