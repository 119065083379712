// import React from 'react'

// const Setting = () => {
//   return (
//   <div>hll</div>
//   )
// }

// export default Setting
import React, { useState } from "react";
import Navbar, { ProfileNavbar, Navtopbar } from "./Navbar";
import Sidebar from "./Sidebar";
import { GeneralSettings } from "./GenerlSettings";
import AppointmentSettings from "./AppointmentSettings";
import { useNavigate } from "react-router-dom";
const Setting = () => {
  const [activeTab, setActiveTab] = useState('general');

  // Function to toggle active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const navigate = useNavigate()

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <Navtopbar   onClick= {()=>{navigate("/profile")}} title="Settings" />


        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30, flexDirection: "column" }}
        >
          <div className="flex-row" style={{ marginBottom: 20 }}>
            <div
              className={activeTab === 'general' ? "General_Settings" : "Clinical_Settings"}
              onClick={() => handleTabClick('general')}
            >General Settings</div>
            <div
              className={activeTab === 'appointment' ? "General_Settings" : "Clinical_Settings"}
              onClick={() => handleTabClick('appointment')}
            >Appointment Settings</div>
          </div>
          {/* <GeneralSettings /> */}
          <div className="dashboard-cards" style={{ padding: 30, paddingTop: 50, flexDirection: 'column', paddingBottom: 0 }}>

            {activeTab === 'general' && <GeneralSettings />}
            {activeTab === 'appointment' && <AppointmentSettings />}
          </div>
        </div>
      </div>

    </div>
  );
};

export default Setting;


