import React from 'react'
import AgencySideBar from '../Components/AgencySideBar'
import Navbar from '../../Components/Navbar'
import Inputbox from '../../Components/Inputbox'
import { useNavigate } from 'react-router-dom'

const SupportHelp = () => {
const navigate =useNavigate()
return (
  <div className="dashboard-container" >
    <AgencySideBar />
    <div className="dashboard-content" >
      <Navbar 
        onClick= {()=>{navigate("/MyProfileAgency")}}
        />
      <div className="dashboard-cards" style={{ padding: 30, paddingTop:50,flexDirection: 'column', }}>
        <div className='HelpSupport_div'>
<div className='Contact_Us_txt'>Contact Us</div>
<Inputbox
      label={"Email"}
        type="Email"
        placeholder="Enter Email"
        name="confirmPassword"
        style={{backgroundColor:'#D9D9D9'}}
        lebalstyle={{color:'white'}}
         />
        <Inputbox
      label={"Title"}
        type="Title"
        placeholder="Enter Title"
        name="confirmPassword"
        style={{backgroundColor:'#D9D9D9'}}
        lebalstyle={{color:'white'}}
         />
             <Inputbox
      label={"Message"}
        type="Message"
        placeholder="Write your message...."
        name="confirmPassword"
        style={{backgroundColor:'#D9D9D9',height:100}}
        lebalstyle={{color:'white'}}
         />
          <div  style={{ alignSelf: 'center', display: 'flex',width:'100%',justifyContent:'center',marginTop:30 }}>
                          <div className='cancelButton'>Cancel</div>
                          <div className='savebuttonn'>Submit</div>
                      </div>
        </div>
      </div>
      </div>
  </div>
)
}

export default SupportHelp