import React, { useEffect, useState } from "react";
import Inputbox from "./Inputbox";
import { getSlots, updateDoctorProfile } from "../../api/helper";
import moment from "moment";
import { useSelector } from "react-redux";
import { ShowToast } from "../../helpers/ToastService";

const AppointmentSettings = () => {
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  // console.log(profiledata,'===profiledata')
  const [activeTab, setActiveTab] = useState("workingHours");
  const [selectedSlots, setSelectedSlots] = useState(profiledata?.slots);
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [availableDays, setAvailableDays] = useState(profiledata?.availableDays.availableDays||[]);
  const [selecteddays, setselecteddays] = useState([]);
    const [emailNotifications, setEmailNotifications] = useState(
    profiledata?.notificationAleart?.Email
  );
  const [smsAlerts, setSmsAlerts] = useState(
    profiledata?.notificationAleart?.SMS
  );
  const [timeSlot, setTimeSlot] = useState([]);

  const handledaysclick = (slot) => {
    setselecteddays((prev) =>
      prev.includes(slot) ? prev.filter((s) => s !== slot) : [...prev, slot]
    );
  };
  // Function to toggle visibility
  const toggleChangePassword = () => {
    console.log(activeTab, "===activeTab");
    UpdateNtification();
    setIsChangePasswordVisible(false);
    setActiveTab("workingHours");
    // setIsChangePasswordVisible(!isChangePasswordVisible);
  };

  const getSlotsData = async () => {
    const response = await getSlots();
    // console.log(response.data.data,'====data')
    setTimeSlot(response.data.data);
  };

  useEffect(() => {
    getSlotsData();
  }, []);

 
  const handlenotification = () => {
    setActiveTab("notifications");
    setIsChangePasswordVisible(!isChangePasswordVisible);
  };

  const updateData = async (data) => {
    const response = await updateDoctorProfile(data);
    ShowToast(response.data.message, "success");
    //  console.log(response.data)
  };

  const UpdateNtification = async () => {
    let data = {
      notificationAleart: {
        Email: emailNotifications,
        SMS: smsAlerts,
      },
    };
    updateData(data);
  };

  const UpdateSlot = async () => {
    let data = {
      availableDays: availableDays,
    };
    console.log(data,'===>>>')
    updateData(data);
  };

  const handleclose = () => {
    setIsChangePasswordVisible(false);
    setActiveTab("workingHours");
  };
  

  const handleToggle = (dayName) => {
    setAvailableDays((prev) => {
      const isActive = prev.some((day) => day.day === dayName);
      if (isActive) {
        return prev.filter((day) => day.day !== dayName);
      } else {
        return [
          ...prev,
          { day: dayName, shifts: [{ startTime: "10:00", endTime: "18:00" }] },
        ];
      }
    });
  };

  const handleTimeChange = (dayName, newStartTime, newEndTime) => {
    setAvailableDays((prev) =>
      prev.map((day) => {
        if (day.day === dayName) {
          return {
            ...day,
            shifts: [{ startTime: newStartTime, endTime: newEndTime }],
          };
        }
        return day;
      })
    );
  };
 

  const days = [
    { name: "Monday" },
    { name: "Tuesday" },
    { name: "Wednesday" },
    { name: "Thursday" },
    { name: "Friday" },
    { name: "Saturday" },
    { name: "Sunday" },
  ];
  const DayItem = ({
    day,
    isActive,
    onToggle,
    startTime,
    endTime,
    onTimeChange,
  }) => {
    return (
      <div className={`unique-day-item ${isActive ? "unique-active-day" : ""}`}>
        <div className="unique-day-header">
          <span>{day.name}</span>
          <label className="unique-switch">
            <input type="checkbox" checked={isActive} onChange={onToggle} />
            <span className="unique-slider unique-round"></span>
          </label>
        </div>

        {isActive && (
          <div className="unique-time-selector">
            <div className="unique-time-group">
              <div className="unique-time-input-wrapper">
                <input
                  type="time"
                  className="unique-time-input"
                  value={startTime}
                  onChange={(e) =>
                    onTimeChange(day.name, e.target.value, endTime)
                  }
                />
              </div>
              <div className="unique-time-input-wrapper">
                <input
                  type="time"
                  className="unique-time-input"
                  value={endTime}
                  onChange={(e) =>
                    onTimeChange(day.name, startTime, e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="tab-container" style={{ marginBottom: 80 }}>
        <div
          className={
            activeTab === "workingHours" ? "active-tab" : "inactive-tab"
          }
          onClick={() => setActiveTab("workingHours")}
        >
          Working Hours
        </div>
      
        <div
          className={
            activeTab === "notifications" ? "active-tab" : "inactive-tab"
          }
          onClick={handlenotification}
        >
          {/* Reminder & Notification */}
          Notifications
        </div>
      </div>

      {activeTab === "workingHours" && (
<>


{days.map((day) => {
            const isActive = availableDays.some((d) => d.day === day.name);
            const dayData = availableDays.find((d) => d.day === day.name);
            const startTime = dayData ? dayData.shifts[0].startTime : "10:00";
            const endTime = dayData ? dayData.shifts[0].endTime : "18:00";

            return (
              <DayItem
                key={day.name}
                day={day}
                isActive={isActive}
                onToggle={() => handleToggle(day.name)}
                startTime={startTime}
                endTime={endTime}
                onTimeChange={handleTimeChange}
              />
            );
          })}


{/* <div className="grid-container_two_days"  style={{marginBottom:20}}>
{days.map((day) => (
      <div
        key={day.name}
        className={
          selecteddays.includes(day.name)
            ? "time-slot selected"
            : "time-slot"
        }
        onClick={() => handledaysclick(day.name)}
      >
        {day.name}
      </div>
    ))}
</div>

        <div className="grid-container_two">
          {timeSlot.map((slot) => (
            <div
              key={slot._id}
              className={
                selectedSlots.includes(slot._id)
                
                  ? "time-slot selected"
                  : "time-slot"
              }
              onClick={() => handleSlotClick(slot._id)}
            >
              {`${moment(slot.startTime, "hh:mm A").format(
                "hh:mm A"
              )} - ${moment(slot.endTime, "hh:mm A").format("hh:mm A")}`}
            </div>
          ))}
        </div> */}
        </>
      )}

      {activeTab === "notifications" && (
        <div>
          {isChangePasswordVisible && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
            >
              <div
                className="HelpSupport_div"
                style={{
                  maxWidth: "35%",
                }}
              >
                <div className="Contact_Us_txt" style={{ marginBottom: 20 }}>
                  Notifications
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 30,
                  }}
                >
                  <div>
                    <h3 style={{ textAlign: "left", color: "white" }}>
                      Email Notifications
                    </h3>
                    <div style={{ color: "white" }}>
                      Appointment reminders, new booking alerts
                    </div>
                  </div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={emailNotifications}
                      onChange={() =>
                        setEmailNotifications(!emailNotifications)
                      }
                    />
                    <span className="slider"></span>
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 50,
                    marginBottom: 100,
                  }}
                >
                  <div>
                    <h3 style={{ textAlign: "left", color: "white" }}>
                      SMS Alerts
                    </h3>
                    <div style={{ color: "white" }}>
                      For urgent updates or confirmations
                    </div>
                  </div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={smsAlerts}
                      onChange={() => setSmsAlerts(!smsAlerts)}
                    />
                    <span className="slider"></span>
                  </label>{" "}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 30,
                  }}
                >
                  <div className="cancelButton" onClick={handleclose}>
                    Cancel
                  </div>
                  <div className="savebuttonn" onClick={toggleChangePassword}>
                    Save
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div
        style={{
          alignSelf: "center",
          display: "flex",
          width: "70%",
          justifyContent: "center",
          // bottom: 50,
          // position: "absolute",
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <div className="cancelButton">Cancel</div>
        <div style={{ width: "10%" }}></div>
        <div className="savebuttonn" onClick={() => UpdateSlot()}>
          Save
        </div>
      </div>
    </div>
  );
};

export default AppointmentSettings;
