import React from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import { useNavigate } from "react-router-dom";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const Financial = () => {
  const data = [
    { name: 'Jan 2024', value: 1000, value1:300, value2:100, value3:400 },
    { name: 'Feb 2024', value: 1100, value1:390, value2:300, value3:700 },
    { name: 'Mar 2024', value: 1110, value1:450, value2:250, value3:670 },
    { name: 'Apr 2024', value: 1700, value1:350, value2:390, value3:800 },
    { name: 'May 2024', value: 1750, value1:550, value2:460, value3:610 },
    { name: 'Jun 2024', value: 1800, value1:310, value2:550, value3:950 },
    { name: 'Jul 2024', value: 1850, value1:600, value2:310, value3:570 },
  ];
  const navigate = useNavigate()
   

  return (
    <div className="dashboard-container" >
      <Sidebar />
      <div className="dashboard-content" >
        <Navbar onClick= {()=>{navigate("/profile")}} style={{ marginBottom: 0 }} />
        <div className="dashboard-cards" style={{ paddingLeft: 30, paddingRight: 30, flexDirection: 'column', paddingBottom: 0, paddingTop:20 }}>

        <div><p className='Finance_It_should_txt'>It should track your invoices, your current Subscription plan if you have one. Here you can change plans if you want to, or pay/process your invoices.</p></div>

        <div className='Finance_div_display' style={{gap:20}}>
        <div className='Finance_div_text'>
        <p className='Financial_Total_text' style={{paddingBottom:5}}>Total Revenue</p>
        <p className='Financial_Total_text' style={{color:'#BC9C22'}}>£ 12,000.00</p>
        </div>
        <div className='Finance_div_text'>
        <p className='Financial_Total_text' style={{paddingBottom:5}}>Pending Invoices</p>
        <p className='Financial_Total_text' style={{color:'#BC9C22'}}>12</p>
        </div>
        <div className='Finance_div_text'>
        <p className='Financial_Total_text' style={{paddingBottom:5}}>Provider Payouts</p>
        <p className='Financial_Total_text' style={{color:'#BC9C22'}}>£ 1,200.00</p>
        </div>
        <div className='Finance_div_text'>
        <p className='Financial_Total_text' style={{paddingBottom:5}}>Outstanding Balance</p>
        <p className='Financial_Total_text' style={{color:'#BC9C22'}}>Sept 30, 2024</p>
        </div>
      </div>

      <div className="earnings-graph">
      <p className='Finance_Current_txt' style={{color:'#BC9C22', paddingTop:20}}>Earning 12-Month Trend</p> 
      <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={(value) => `$${value / 1000}K`} />
                    <Tooltip formatter={(value) => [`$${value}`, 'Amount']} />
                    <Line type="monotone" dataKey="value" stroke="skyblue" dot={{ r: 6 }} />
                    <Line type="monotone" dataKey="value1" stroke="purple" dot={{ r: 6 }} />
                    <Line type="monotone" dataKey="value2" stroke="blue" dot={{ r: 6 }} />
                    <Line type="monotone" dataKey="value3" stroke="green" dot={{ r: 6 }} />
                  </LineChart>
                </ResponsiveContainer>
      </div>

<div>
    <div>
    <p className='Finance_Current_txt' style={{color:'#BC9C22', paddingTop:20}}>Submitted Invoices</p>   
    <div className='patient_input_div2' style={{marginTop:10}}>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Invoice_Report'>Invoice #12345</p>
    <p className='Patient_Summarize_txt'>Amount: £ 12.00</p>
    </div>
    <div className='finance_display_flex'>
    <p className='patient_Feb_txt'>Pending Approval</p>
    {/* <p className='Finance_Pay_txt'>View Report</p> */}
    </div>
   </div>
   <div className='patient_input_div2' style={{marginTop:10}}>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Invoice_Report'>Invoice #12345</p>
    <p className='Patient_Summarize_txt'>Amount: £ 40.00</p>
    </div>
    <div className='finance_display_flex'>
    <p className='patient_Feb_txt'>Paid: Aug 15, 2024</p>
    </div>
   </div>
   </div>
   <div className='div_button_Create'><p className='Create_Carge_txt'>Create a charge / Submit invoice</p></div>
   <div>
    <p className='Finance_Current_txt' style={{color:'#BC9C22', paddingTop:20}}>Payment History</p>
    <div className='patient_input_div2' style={{marginTop:10}}>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Invoice_Report'>Payment on Aug 15, 2024</p>
    <div style={{display:'flex'}}>
    <p className='Patient_Summarize_txt'>Amount:</p>
    <p className='Patient_Summarize_txt' style={{fontWeight:900, paddingLeft:5, paddingRight:5}}>£ 90.00</p>
    <p className='Patient_Summarize_txt'>(Direct Deposit)</p>
    </div>
    </div>
    <div className='finance_display_flex'>
    <p className='patient_Feb_txt'>Completed</p>
    </div>
   </div>
   <div className='patient_input_div2' style={{marginTop:10}}>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Invoice_Report'>Payment on July 10, 2024</p>
    <div style={{display:'flex'}}>
    <p className='Patient_Summarize_txt'>Amount:</p>
    <p className='Patient_Summarize_txt' style={{fontWeight:900, paddingLeft:5, paddingRight:5}}>£ 90.00</p>
    <p className='Patient_Summarize_txt'>(Direct Deposit)</p>
    </div>
    </div>
    <div className='finance_display_flex'>
    <p className='patient_Feb_txt'>Completed</p>
    </div>
   </div>
   </div>
   <p className='Finance_Current_txt' style={{color:'#BC9C22', paddingTop:20}}>Payout Management</p>   
   <div>
   <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
   <p className='patient_Invoice_Report'>Current Payout Method:</p>
   <p className='Patient_Summarize_txt' style={{ paddingLeft:15, paddingRight:15}}>Direct Deposit to Bank Account (****5678)</p>
   <div className='Create_Carge_txt1'><p className='Create_Carge_txt'>Update Payment Method</p></div>
  </div>
  <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
   <p className='patient_Invoice_Report'>Payout Frequency:</p>
   <p className='Patient_Summarize_txt' style={{ paddingLeft:15, paddingRight:15}}>Bi-Weekly</p>
   <div className='Create_Carge_txt1'><p className='Create_Carge_txt'>Change Frequency</p></div>
  </div>
  </div>
  <p className='Finance_Current_txt' style={{color:'#BC9C22', paddingTop:20}}>Tax Documents & Reports</p>   
  <div>
   <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center', marginBottom:10, marginTop:10}}>
   <p className='patient_Invoice_Report' style={{paddingRight:10}}>Download 2023 Tax Form:</p>
   <div className='Create_Carge_txt1'><p className='Create_Carge_txt'>Download</p></div>
  </div>
  <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
   <p className='patient_Invoice_Report' style={{paddingRight:10}}>Generate Earnings Report:</p>
   <div className='Create_Carge_txt1'><p className='Create_Carge_txt'>CGenerate</p></div>
  </div>
  </div>
</div>

        </div>
      </div>
    </div>
  )
}

export default Financial
