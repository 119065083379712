// import React, { useEffect, useState } from "react";
// import Inputbox from "../../Components/Inputbox";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";
// import {   AddPopulationServed, DeletePopulationServed, GetPopulationServed } from "../../../api/helper";
// import { useSelector } from "react-redux";
// import { ShowToast } from "../../../helpers/ToastService";
// import { Box, Modal } from "@mui/material";

// const validationSchema = Yup.object().shape({
//     name: Yup.string().required("Name is required"),
// });
// function PopulationServed() {
//     const [showModal, setShowModal] = useState(false);
//     const [addressData, setaddressData] = useState([])
//     const profiledata = useSelector((state) => state?.cookies?.userDetails);

//     const {
//         register,
//         handleSubmit,
//         clearErrors,
//         reset,
//         formState: { errors },
//     } = useForm({
//         resolver: yupResolver(validationSchema),
//     });

//     const onSubmit = async (data) => {
//         data.agencyId = profiledata?._id;
//         setShowModal(false)
//         try {
//             const response = await AddPopulationServed(data);
//             ShowToast(response?.data?.message);
//             reset();
//             FetchDatalist()

//         } catch (error) {
//             console.log(error);
//         }

//     };
//     const handleDelete = async (id) => {
//         console.log("Deleted ID:", id);

//         const confirmed = window.confirm("Are you sure you want to delete?");
//         if (!confirmed) {
//             return;
//         }

//         try {
//             const response = await DeletePopulationServed(`?id=${id}`);
//             if (response?.data?.success) {
//                 ShowToast(response?.data?.message);
//                 FetchDatalist()
//             } else {
//                 ShowToast("Error: Unable to delete address.");
//             }
//         } catch (error) {
//             console.error("Delete operation failed:", error);
//             ShowToast("An error occurred while deleting the address.");
//         }
//     };

//     const FetchDatalist = async () => {

//         try {
//             const response = await GetPopulationServed(`?id=${profiledata?._id}`);
//             console.log(response?.data?.data, "GetPopulationServed======");
//             setaddressData(response?.data?.data || [])

//         } catch (error) {
//             console.log(error);
//         }
//     };
//     useEffect(() => {
//         FetchDatalist()
//     }, []);
//     const openModal = () => setShowModal(true);
//     const closeModal = () => setShowModal(false);
//     const modalStyle = {
//         position: "absolute",
//         top: "50%",
//         left: "50%",
//         transform: "translate(-50%, -50%)",
//         width: "50%",
//         height: "90vh",
//         overflowY: "auto", // Set a fixed height for the modal
//         bgcolor: "background.paper",
//         boxShadow: 24,
//         p: 4,
//         borderRadius: 4,
//         outline: "none",
//         // Enable vertical scrolling
//     };
//     return (
//         <div

//             style={{
//                 width: "100%",
//                 alignItems: "center",
//                 justifyItems: "center",
//                 marginBottom: 20,
//             }}
//         >
//             <div
//                 style={{
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                     width: '100%'
//                 }}
//             >
//                 <h1>PopulationServed</h1>
//                 <div
//                     style={{
//                         backgroundColor: "#bc9c22",
//                         color: "white",
//                         padding: 10,
//                         borderRadius: 6,
//                     }}
//                     onClick={openModal}
//                 >
//                     Add PopulationServed
//                 </div>
//             </div>

//             <div style={{ overflowX: "auto", width: "100%", marginTop: 30 }}>
//                 <table style={{ width: "100%", borderCollapse: "collapse" }}>
//                     <thead>
//                         <tr>
//                             <th style={tableHeaderStyle}>Name</th>
//                             <th style={tableHeaderStyle}>Definition</th>
//                             <th style={tableHeaderStyle}>Action</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {addressData.length === 0 ? (
//                             <tr>
//                                 <td colSpan="11" style={{ textAlign: "center", padding: "20px" }}>
//                                     No PopulationServed found.
//                                 </td>
//                             </tr>
//                         ) : (
//                             addressData.map((row, index) => (
//                                 <tr key={index} style={tableRowStyle}>
//                                     <td style={tableCellStyle}>{row?.name}</td>
//                                     <td style={tableCellStyle}>{row?.description}</td>
//                                     <td style={tableCellStyle}>
//                                         <button onClick={() => handleDelete(row._id)} style={deleteButtonStyle}>
//                                             Delete
//                                         </button>
//                                     </td>
//                                 </tr>
//                             ))
//                         )}
//                     </tbody>
//                 </table>
//             </div>

//             <Modal open={showModal} onClose={closeModal} aria-labelledby="modal-title">

//                 <Box sx={modalStyle}>
//                     <form
//                         onSubmit={handleSubmit(onSubmit)}
//                         style={{
//                             width: "100%",
//                             alignItems: "center",
//                             justifyItems: "center",
//                             marginBottom: 20,
//                         }}
//                     >
//                         <h1 style={{ textAlign: 'left' }}>New PopulationServed</h1>
//                         <div
//                             style={{

//                                 alignItems: "center",
//                                 justifyContent: "center",
//                                 width:"100%"
//                             }}
//                         >
//                             <Inputbox
//                                 label="Name"
//                                 className="Custom_input"
//                                 type="text"
//                                 placeholder="Enter Service Name"
//                                 name="name"
//                                 register={register}
//                             />
//                             <p style={{ color: "red", textAlign: "left" }}>
//                                 {errors.name?.message}
//                             </p>
//                             <Inputbox
//                                 label="Definition"
//                                 type="text"
//                                 placeholder="Enter Service Definition"
//                                 name="description"
//                                 className="Custom_input"
//                                 register={register}
//                             />
//                             <p style={{ color: "red", textAlign: "left" }}>
//                                 {errors.name?.message}
//                             </p>
//                             <button
//                                 type="submit"
//                                 style={{ marginBottom: 20,marginTop: 20, alignSelf: "center",width:"100%",height:"50px" }}
//                                 className="Save_buttonnn"
//                             >
//                                 Submit
//                             </button>
//                             <p className="text-center">Please make surethe service name is unique</p>
//                         </div>
//                     </form>
//                 </Box>
//             </Modal>
//         </div>
//     );
// };

// const tableHeaderStyle = {
//     padding: "10px",
//     borderBottom: "2px solid #ddd",
//     textAlign: "left",
//     fontWeight: "bold",
//     backgroundColor: "#f4f4f4",
// };

// const tableCellStyle = {
//     padding: "8px",
//     borderBottom: "1px solid #ddd",
// };

// const tableRowStyle = {
//     borderBottom: "1px solid #ddd",
// };

// const deleteButtonStyle = {
//     backgroundColor: "red",
//     color: "white",
//     border: "none",
//     borderRadius: "4px",
//     padding: "5px 10px",
//     cursor: "pointer",
// };

// export default PopulationServed

import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import Inputbox from "../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { AddPopulationServed, DeleteModalities, DeletePopulationServed, FetchPopulationServed } from "../../../api/helper";
import { ShowToast } from "../../../helpers/ToastService";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Population Served is required")
    .min(3, "Population Served must be at least 3 characters")
    .max(50, "Population Served must not exceed 50 characters"),
});

const PopulationServed = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setdata] = useState([])
  const profiledata = useSelector((state) => state?.cookies?.userDetails);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "90vh",
    overflowY: "auto", // Set a fixed height for the modal
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    outline: "none",
    // Enable vertical scrolling
  };
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const GetPopulationServeddata = async () => {
    const response = await FetchPopulationServed(`?id=${profiledata?._id}`);
    console.log(response?.data?.data, "data=====");
    setdata(response?.data?.data);
  };
  useEffect(() => {
    GetPopulationServeddata();
  }, []);
    const onSubmit = async (data) => {
        data.agencyId = profiledata?._id;
        console.log(data,"hiiii======")
        try {
            const response = await AddPopulationServed(data);
            console.log(response?.data?.data,"respones====")
            ShowToast(response?.data?.message);
            reset();
            GetPopulationServeddata();
            setShowModal(false);
          } catch (error) {
            console.log(error);
          }
        };
        const handleDelete = async (id) => {
            console.log("Deleted ID:", id);
        
            const confirmed = window.confirm(
              "Are you sure you want to delete this address?"
            );
            if (!confirmed) {
              return;
            }
        
            try {
              const response = await DeletePopulationServed(`?id=${id}`);
              if (response?.data?.success) {
                ShowToast(response?.data?.message);
                GetPopulationServeddata();
              } else {
                ShowToast("Error: Unable to delete address.");
              }
            } catch (error) {
              console.error("Delete operation failed:", error);
              ShowToast("An error occurred while deleting the address.");
            }
          };
  return (
    <div
      style={{
        width: "100%",
        alignItems: "center",
        justifyItems: "center",
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h1>Population Served</h1>
        <div
          style={{
            backgroundColor: "#bc9c22",
            color: "white",
            padding: 10,
            borderRadius: 6,
          }}
          onClick={openModal}
        >
          Add Population Served
        </div>
      </div>

      <div style={{ overflowX: "auto", width: "100%", marginTop: 30 }}>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                        <tr>
                            <th style={tableHeaderStyle}>Name</th>
                            <th style={tableHeaderStyle}>Definition</th>
                            <th style={tableHeaderStyle}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
        {data.length === 0 ? (
          <tr>
            <td colSpan="3" style={{ textAlign: "center", padding: "10px" }}>
              No Population Served Found
            </td>
          </tr>
        ) : (
            data.map((row) => (
            <tr key={row._id} style={tableRowStyle}>
              <td style={tableCellStyle}>{row.name}</td>
              <td style={tableCellStyle}>{row.description}</td>
              <td style={tableCellStyle}>
                <button
                  style={deleteButtonStyle}
                  onClick={() => handleDelete(row._id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))
        )}
      </tbody>
                </table>
      </div>

      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-title"
      >
        <Box sx={modalStyle}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "100%",
              alignItems: "center",
              justifyItems: "center",
              marginBottom: 20,
            }}
          >
            <h1 style={{ textAlign: "left" }}>Add Population Served</h1>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Inputbox
                label="Population Served"
                className="Custom_input"
                type="text"
                placeholder="Enter Population Served"
                name="name"
                register={register}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.name?.message}
              </p>
              <Inputbox
                label="Definition"
                type="text"
                placeholder="Enter Delivery Mode Definition"
                name="description"
                className="Custom_input"
                register={register}
              />
              {/* <p style={{ color: "red", textAlign: "left" }}>
                  {errors.name?.message}
              </p> */}
              <button
                type="submit"
                style={{
                  marginBottom: 20,
                  marginTop: 20,
                  alignSelf: "center",
                  width: "100%",
                  height: "50px",
                }}
                className="Save_buttonnn"
              >
                Submit
              </button>
              {/* <p className="text-center">Please make surethe service name is unique</p> */}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default PopulationServed;
const tableHeaderStyle = {
    padding: "10px",
    borderBottom: "2px solid #ddd",
    textAlign: "left",
    fontWeight: "bold",
    backgroundColor: "#f4f4f4",
  };
  
  const tableCellStyle = {
    padding: "8px",
    borderBottom: "1px solid #ddd",
  };
  
  const tableRowStyle = {
    borderBottom: "1px solid #ddd",
  };
  
  const deleteButtonStyle = {
    backgroundColor: "red",
    color: "white",
    border: "none",
    borderRadius: "4px",
    padding: "5px 10px",
    cursor: "pointer",
  };
  