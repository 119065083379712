import React, { useState } from "react";
import AgencySideBar from "../Components/AgencySideBar";
import Navbar from "../../Components/Navbar";
import DropDownButton from "../../Components/DropDownButton";
import Calendar from "react-calendar";
import CalenderDoctor from "../../Components/CalenderDoctor";

const Appointmentclinic = () => {
  const [view, setView] = useState("list");
  const [date, setDate] = useState(new Date());
  const appointments = {
    "2024-08-16": [
      {
        time: "10:00 AM",
        name: "Gabe Woodward",
        gender: "Male",
        age: 30,
        type: "Private Pay",
      },
      {
        time: "11:00 AM",
        name: "Academic",
        gender: "Female",
        age: 35,
        type: "Subscribe",
      },
      {
        time: "12:00 PM",
        name: "Samuel O'Brian",
        gender: "Male",
        age: 27,
        type: "Insurance",
      },
    ],
    "2024-08-19": [
      {
        time: "10:00 AM",
        name: "Gabe Woodward",
        gender: "Male",
        age: 30,
        type: "Private Pay",
      },
    ],
    "2024-08-20": [
      {
        time: "11:00 AM",
        name: "Academic",
        gender: "Female",
        age: 35,
        type: "Subscribe",
      },
      {
        time: "12:00 PM",
        name: "Samuel O'Brian",
        gender: "Male",
        age: 27,
        type: "Insurance",
      },
    ],
  };

  const handleDateChange = (date) => {
    setDate(date);
  };
  const handleViewChange = (newView) => {
    setView(newView);
  };
  return (
    <div className="dashboard-container">
      <AgencySideBar />
      <div className="dashboard-content">
        <Navbar />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30 }}
        >
          <div className="container" style={{ width: "100%" }}>
            <div className="tab-buttonstwo">
              <button
                className={`tab-buttontwo ${view === "list" ? "active" : ""}`}
                onClick={() => handleViewChange("list")}
              >
                Appointment List
              </button>
              <button
                className={`tab-buttontwo ${
                  view === "calendar" ? "active" : ""
                }`}
                onClick={() => handleViewChange("calendar")}
              >
                Calendar View
              </button>
            </div>
            <div style={{ width: "100%" }}>
              {view !== "list" ? <CalendarView /> : <AppointmentListView />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Appointmentclinic;

const AppointmentListView = () => {
  const [showCreateNewMenu, setShowCreateNewMenu] = useState(null);
  const handleCreateNewClick = (index) => {
    setShowCreateNewMenu(showCreateNewMenu === index ? null : index);
  };
  const tableData = [
    {
      status: "Unavailable",
      name: "Evens Michel",
      appointmentType: "Follow-up",
      duration: "60 m",
      cptCode: "99214",
      payType: "Insurance",
    },
    {
      status: "Available",
      name: "Evens Michel",
      appointmentType: "Follow-up",
      duration: "60 m",
      cptCode: "99214",
      payType: "Insurance",
    },
    {
      status: "Available",
      name: "Evens Michel",
      appointmentType: "Follow-up",
      duration: "60 m",
      cptCode: "99214",
      payType: "Insurance",
    },
    // Add more objects for other rows as needed
  ];
  return (
    <div>
      <h2 style={{ textAlign: "left" }}>List View of Appointments</h2>
      
      <div style={{ marginBottom: 40 }}>
        <div
          style={{
            // marginTop:-40,
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <DropDownButton
            label="Clients"
            placeholder="All Clients"
            dropdownicon
            style={{ width: "22.5%" }}
            options={["Clients 1", "Clients 2", "Clients 3"]}
          />
          <DropDownButton
            label="CPT Code"
            placeholder={"All CPT Code"}
            dropdownicon
            style={{ width: "22.5%" }}
            options={["CPT 1", "CPT 2", "CPT 3"]}
          />
          <DropDownButton
            label="Appointment Status"
            placeholder={"All Status"}
            dropdownicon
            style={{ width: "22.5%" }}
            options={["Appointment 1", "Appointment 2", "Appointment 3"]}
          />
          <DropDownButton
            label="Location Type"
            placeholder="All Locations"
            dropdownicon
            style={{ width: "22.5%" }}
            options={["Location 1", "Location 2", "Location 3"]}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <DropDownButton
            label="Appointments Type"
            placeholder={"All Types"}
            dropdownicon
            style={{ width: "22.5%" }}
          />
          <div style={{ width: 40 }} />
          <DropDownButton
            label="Appointments Reason"
            placeholder={"All Reasons"}
            dropdownicon
            style={{ width: "22.5%" }}
          />
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Client Name</th>
            <th>Appointment Type</th>
            <th>Duration</th>
            <th>CPT Code</th>
            <th>Pay Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <tr key={index}>
              <td>{item.status}</td>
              <td>{item.name}</td>
              <td>{item.appointmentType}</td>
              <td>{item.duration}</td>
              <td>{item.cptCode}</td>
              <td>{item.payType}</td>
              <td>
                <button
                  className="user-table-create-new-button"
                  onClick={() => handleCreateNewClick(index)}
                >
                  Action
                </button>
                {showCreateNewMenu === index && (
                  <div className="user-table-popup-menu">
                    <ul>
                      <li>View</li>
                      <li>Edit</li>
                      <li>Message</li>
                    </ul>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* <table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Client Name</th>
            <th>Appointment Type</th>
            <th>Duration</th>
            <th>CPT Code</th>
            <th>Pay Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Unavailable</td>
            <td>Evens Michel</td>
            <td>Follow-up</td>
            <td>60 m</td>
            <td>99214</td>
            <td>Insurance</td>
            <td>
              <button className="actions-button">Actions</button>
            </td>
          </tr>
          <tr>
            <td>Available</td>
            <td>Evens Michel</td>
            <td>Follow-up</td>
            <td>60 m</td>
            <td>99214</td>
            <td>Insurance</td>
            <td>
              <button className="actions-button">Actions</button>
            </td>
          </tr>
        </tbody>
      </table> */}
    </div>
  );
};



const CalendarView = () => {
  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="schedule-app" style={{ width: "68%" }}>
          <header className="schedule-header">
            <h1 className="schedule-title">August 2024</h1>
            <div className="schedule-controls">
              {/* <button className="schedule-button">New Payment</button>
            <button className="schedule-button">Schedule a Training</button> */}
              <p className="New_Payment-button">New Payment</p>
            </div>
          </header>

          <div className="schedule-main-content">
            <div className="schedule-left-panel">
              <div className="schedule-date-picker">
                <div className="schedule-week-days">
                  {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                    (day, index) => (
                      <div
                        className={`schedule-day ${
                          index === 3 ? "schedule-day-selected" : ""
                        }`}
                        key={index}
                      >
                        <span className="schedule-day-number">
                          {13 + index}
                        </span>
                        <span className="schedule-day-name">{day}</span>
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className="schedule-appointment-list">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderTopWidth: 1,
                    borderStyle: "solid",
                    borderColor: "rgba(1, 20, 52, 1)",
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    paddingTop: 20,
                  }}
                >
                  <h3
                    className="schedule-section-title"
                    style={{
                      textAlign: "left",
                      marginTop: 20,
                      marginBottom: 20,
                      color: "rgba(1, 20, 52, 1)",
                    }}
                  >
                    Today
                  </h3>
                  <div style={{ fontSize: 25, color: "#BC9C22" }}>+</div>
                </div>
                <div className="schedule-appointment">
                  <div>10:00Am</div>
                  <img
                    src={require("../../assest/Images/doctorimg.png")}
                    style={{ height: 30, width: 30 }}
                  />
                  <div>Male</div>
                  <div>30 Years</div>
                  <div>Private Pay</div>
                  <div>
                    {" "}
                    <img src={require("../../assest/Images/rightarrow.png")} />
                  </div>
                </div>
                <div className="schedule-appointment">
                  <div>10:00Am</div>
                  <img
                    src={require("../../assest/Images/doctorimg.png")}
                    style={{ height: 30, width: 30 }}
                  />
                  <div>Male</div>
                  <div>30 Years</div>
                  <div>Private Pay</div>
                  <div>
                    {" "}
                    <img src={require("../../assest/Images/rightarrow.png")} />
                  </div>
                </div>
                <div className="schedule-appointment">
                  <div>10:00Am</div>
                  <img
                    src={require("../../assest/Images/doctorimg.png")}
                    style={{ height: 30, width: 30 }}
                  />
                  <div>Male</div>
                  <div>30 Years</div>
                  <div>Private Pay</div>
                  <div>
                    {" "}
                    <img src={require("../../assest/Images/rightarrow.png")} />
                  </div>
                </div>
                {/* <h2 className="schedule-section-title">August 18</h2> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderTopWidth: 1,
                    borderStyle: "solid",
                    borderColor: "rgba(1, 20, 52, 1)",
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    paddingTop: 20,
                  }}
                >
                  <h3
                    className="schedule-section-title"
                    style={{
                      textAlign: "left",
                      marginTop: 20,
                      marginBottom: 20,
                      color: "rgba(1, 20, 52, 1)",
                    }}
                  >
                    August 18
                  </h3>
                  <div style={{ fontSize: 25, color: "#BC9C22" }}>+</div>
                </div>{" "}
                <p
                  className="schedule-no-appointments"
                  style={{ textAlign: "left" }}
                >
                  No Appointments
                </p>
                <h3
                  className="schedule-section-title"
                  style={{
                    textAlign: "left",
                    marginTop: 20,
                    marginBottom: 20,
                    color: "rgba(1, 20, 52, 1)",
                    borderTopWidth: 1,
                    borderStyle: "solid",
                    borderColor: "rgba(1, 20, 52, 1)",
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    paddingTop: 20,
                  }}
                >
                  August 19
                </h3>
                <div className="schedule-appointment">
                  <div>10:00Am</div>
                  <img
                    src={require("../../assest/Images/doctorimg.png")}
                    style={{ height: 30, width: 30 }}
                  />
                  <div>Male</div>
                  <div>30 Years</div>
                  <div>Private Pay</div>
                  <div>
                    {" "}
                    <img src={require("../../assest/Images/rightarrow.png")} />
                  </div>
                </div>
                <h3
                  className="schedule-section-title"
                  style={{
                    textAlign: "left",
                    marginTop: 20,
                    marginBottom: 20,
                    color: "rgba(1, 20, 52, 1)",
                    borderTopWidth: 1,
                    borderStyle: "solid",
                    borderColor: "rgba(1, 20, 52, 1)",
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    paddingTop: 20,
                  }}
                >
                  August 20
                </h3>
                <div className="schedule-appointment">
                  <div>10:00Am</div>
                  <img
                    src={require("../../assest/Images/doctorimg.png")}
                    style={{ height: 30, width: 30 }}
                  />
                  <div>Male</div>
                  <div>30 Years</div>
                  <div>Private Pay</div>
                  <div>
                    {" "}
                    <img src={require("../../assest/Images/rightarrow.png")} />
                  </div>
                </div>
                <div className="schedule-appointment">
                  <div>10:00Am</div>
                  <img
                    src={require("../../assest/Images/doctorimg.png")}
                    style={{ height: 30, width: 30 }}
                  />
                  <div>Male</div>
                  <div>30 Years</div>
                  <div>Private Pay</div>
                  <div>
                    {" "}
                    <img src={require("../../assest/Images/rightarrow.png")} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="schedule-right-panel" style={{ width: "30%" }}>
          {/* <div className="schedule-calendar-header">
            <button className="schedule-calendar-nav">&lt;</button>
            <span className="schedule-calendar-title">16 July, 2024</span>
            <button className="schedule-calendar-nav">&gt;</button>
          </div> */}
          {/* <div className="schedule-calendar-grid">
            {[...Array(31).keys()].map((day) => (
              <div
                className={`schedule-calendar-day ${
                  day + 1 === 16 ? "schedule-calendar-day-selected" : ""
                }`}
                key={day}
              >
                {day + 1}
              </div>
            ))}
          </div> */}

          <CalenderDoctor/>
        </div>

      </div>
    </div>
  );
};
