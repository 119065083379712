
import { persistStore, persistReducer } from 'redux-persist';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import sliceReducer from './reducer';
import storage from 'redux-persist/lib/storage';
import cookies from "./cookiesReducer"
const rootReducer = combineReducers({
    sliceReducer,
    cookies,
  });

  

const persistConfig = {
  storage:  storage ,
  key: 'root',
  whitelist: ["cookies", "meetConfig"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
 let Store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(Store);
 
  export default Store;
 