import React, { useRef, useState, useEffect } from "react";
import Inputbox from "../Components/Inputbox";
import DropDownButton, {
  DropDownManuCustom,
  DropDownManuCustomMulti,
  DropDownMultiStringSelect,
} from "../Components/DropDownButton";
import { useNavigate } from "react-router-dom";
import {
  Clinicrequestlist,
  createAgencyDoctor,
  DoctorSignup,
  FetchLanguage,
  GetCategory,
  GetConditionTreated,
  GetProviderType,
  GetServiceParactiseaddress,
  uploadImage,
} from "../../api/helper";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Dropdowniconn } from "../assest/SvgImages";
import { Box, Modal } from "@mui/material";
import { ShowToast } from "../../helpers/ToastService";
import { Store } from "../../redux";
import {
  setHeaderToken,
  setLoginType,
  setUserDetails,
} from "../../redux/cookiesReducer";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { DoctorSignupScreen } from "./DoctorSignup";
import moment from "moment";


const eighteenYearsAgo = new Date();
eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .min(2, "First Name must be at least 2 characters"),

  lastName: Yup.string()
    .required("Last Name is required")
    .min(2, "Last Name must be at least 2 characters"),

  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),

  phone: Yup.string()
    .required("Phone Number is required")
    .matches(/^[0-9]+$/, "Phone number must be digits")
    .min(10, "Phone number must be at least 10 digits")
    .max(15, "Phone number cannot exceed 15 digits"),

  categoryId: Yup.array()
    .of(Yup.string().required("A Specialities must be selected"))
    .min(1, "At least one Specialities is required")
    .required("Specialities is required"),

  ProviderNPI: Yup.string()
    .required("Provider NPI is required")
    .matches(/^[0-9]{10}$/, "Provider NPI must be 10 digits"),
  experience: Yup.string().required("Experience is required"),
  password: Yup.string()
  .required("Password is required")
  .min(12, "Password must be at least 12 characters")
  .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
  .matches(/[a-z]/, "Password must contain at least one lowercase letter")
  .matches(/[0-9]/, "Password must contain at least one number")
  .matches(/[!@#$%^&*_,]/, "Password must contain at least one special character (!, @, #, $, %, ^, &, *, _)")
  .notOneOf(["password", "12345", "qwerty"], "Password is too common or easy to guess") // Add more common words as needed
  .test("no-repeated-characters", "Password should not contain repeated characters like 'aaaa' or '1111'", value => {
    return !/(.)\1{3,}/.test(value); // Adjust this to your repeated character tolerance
  })
  .test("no-simple-patterns", "Password should not contain common patterns like 'abcd' or '1234'", value => {
    const patterns = ["abcd", "1234", "qwerty"]; // Add more patterns as needed
    return !patterns.some(pattern => value.includes(pattern));
  }),

confirmPassword: Yup.string()
  .oneOf([Yup.ref("password"), null], "Passwords must match")
  .required("Confirm password is required"),

  LicenseType: Yup.string().required("Credentials Type is required"),

  // ProviderType: Yup.string().required("Provider Type is required"),

  ProviderHomeAddress: Yup.string().required(
    "Provider Home Address is required"
  ),

  DOB: Yup.date()
  .typeError("Date of Birth must be a valid date")
  .required("Date of Birth is required")
  .max(eighteenYearsAgo, "You must be at least 18 years old")
  .test(
    "notToday",
    "Date of Birth cannot be today's date",
    (value) => value && value < new Date().setHours(0, 0, 0, 0)
  ),
  // NPI: Yup.string()
  //   .required("NPI is required")
  //   .matches(/^[0-9]{10}$/, "NPI must be 10 digits"),
  gender: Yup.string().required("Gender is required"),

  info: Yup.string().required("Info is required"),
  // language: Yup.string().required("Please select a language"),
  LanguagesId:Yup.array()
  .of(Yup.string().required("A language must be selected"))
  .min(1, "At least one language is required")
  .required("language is required"),
  address: Yup.object().shape({
    // street: Yup.string().required('Street is required'),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    zipCode: Yup.string().required("Zip code is required"),
  }),
  agencyId: Yup.string()
    .required("Agency Name & ID is required")
});

const licenseTypes = [
  { _id: "Nursing License", name: "Nursing License" },
  { _id: "Medical License", name: "Medical License" },
  { _id: "Psychology License", name: "Psychology License" },
  { _id: "Bachelor's in Nursing", name: "Bachelor's in Nursing" },
  { _id: "Master's in Psychology", name: "Master's in Psychology" },
  { _id: "Doctorate in Psychiatry", name: "Doctorate in Psychiatry" },
  { _id: "CPR Certification", name: "CPR Certification" },
  { _id: "Mental Health Certification", name: "Mental Health Certification" },
  {
    _id: "Addiction Treatment Certification",
    name: "Addiction Treatment Certification",
  },

  { _id: "cbt", name: "Cognitive Behavioral (CBT)" },
  { _id: "dbt", name: "Dialectical Behavioral (DBT)" },
  { _id: "act", name: "Acceptance & Commitment (ACT)" },
  { _id: "mbct", name: "Mindfulness-Based (MBCT)" },
  { _id: "motivational_interviewing", name: "Motivational Interviewing" },
  { _id: "psychodynamic", name: "Psychodynamic Therapy" },
  { _id: "sbt", name: "Solution Focused Brief Therapy (SFBT)" },
  { _id: "trauma_focused", name: "Trauma-Focused Therapy" },

  { _id: "individual_therapy", name: "Individual Therapy" },
  { _id: "group_therapy", name: "Group Therapy" },
  { _id: "family_therapy", name: "Family Therapy" },
  { _id: "couples_therapy", name: "Couples Therapy" },
  { _id: "crisis_intervention", name: "Crisis Intervention" },
  { _id: "medication_management", name: "Medication Management" },

  { _id: "elders", name: "Elders (65 and above)" },
  { _id: "adults", name: "Adults (18 to 64)" },
  { _id: "teenagers", name: "Teenagers (13 to 17)" },
  { _id: "children", name: "Children (6 to 12)" },

  { _id: "anxiety", name: "Anxiety" },
  { _id: "depression", name: "Depression" },
  { _id: "adhd", name: "ADHD" },
  { _id: "bipolar_disorder", name: "Bipolar Disorder" },
  { _id: "ptsd", name: "PTSD" },
  { _id: "eating_disorders", name: "Eating Disorders" },
  { _id: "substance_use", name: "Substance Use Disorders" },
  { _id: "sleep_disorders", name: "Sleep Disorders" },
  { _id: "anger_management", name: "Anger Management" },
  { _id: "grief", name: "Grief and Loss" },
  { _id: "trauma", name: "Trauma" },
  { _id: "relationship_issues", name: "Relationship Issues" },
];

const SignupFirst = ({ onSubmitt }) => {
  const [showavailibleday, setShowavailibleday] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [imaeg, setImages] = useState(null);
  const [saveData, setSaveData] = useState();
  const fileInputRef = useRef(null);
  const [specialtydata, setspecialtydata] = useState(null);
  const [formSubmit, setFormSubmit] = useState(null);
  const [availableDays, setAvailableDays] = useState([]);
  const [cliniclistdata, setcliniclistdata] = useState(null);
  const [servicelocation, setservicelocation] = useState([]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [isAgencySelected, setIsAgencySelected] = useState(false);
  const [providerType, setproviderType] = useState([]);
  const [conditionTreated, setConditionTreated] = useState([]);
  const [addressAutocomplete, setAddressAutocomplete] = useState(null);
  const [address, setAddress] = useState("");
  const [data, setdata] = useState([])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBTgjMWeFMxL5oe-KFnKts3YGBZJlEC6eM",
    libraries: ["places"],
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const GetLanguage = async (id) => {
    const response = await FetchLanguage(`?id=${id}`);
    console.log(response?.data?.data, "data=====");
    setdata(response?.data?.data);
  };
   const handleAddressSelection = () => {
    const selectedPlace = addressAutocomplete.getPlace();
    if (selectedPlace.formatted_address) {
      // Update the address state with the selected address
      setAddress(selectedPlace.formatted_address);
    }
  };

  // console.log("==asbdkas", watch());
  const Fetchclinic = async () => {
    try {
      const response = await Clinicrequestlist();
      const data = response?.data?.data?.data?.map((e) => {
        return {
          name: `${e.companyName} /${e.uniqueId}`,
          ...e,
        };
      });
      setcliniclistdata(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Fetchclinic();
  }, []);

  const FetchAddresslist = async (id) => {
    try {
      const response = await GetServiceParactiseaddress(id);
      setservicelocation(
        Array.isArray(response?.data?.data?.data)
          ? response?.data?.data?.data
          : []
      );
      // setservicelocation(response);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProviderType = async (id) => {
    const response = await GetProviderType(`?id=${id}`);
    setproviderType(response?.data?.data || []);
  };

  const fetchConditionTreated = async (id) => {
    const response = await GetConditionTreated(`?id=${id}`);
    console.log(response.data, "====>>>");
    setConditionTreated(response?.data?.data || []);
  };

  const handlePlaceSelect = async () => {
    const place = autocomplete.getPlace();
    const addressComponents = place.address_components;

    const getAddressComponent = (type) => {
      const component = addressComponents.find((c) => c.types.includes(type));
      return component ? component.long_name : "";
    };

    // Set country, state, and city
    setValue("address.country", getAddressComponent("country"));
    setValue(
      "address.state",
      getAddressComponent("administrative_area_level_1")
    );
    setValue("address.city", getAddressComponent("locality"));

    const streetNumber = getAddressComponent("street_number");
    const route = getAddressComponent("route");
    const sublocality = getAddressComponent("sublocality");
    const neighborhood = getAddressComponent("neighborhood");
    const premise = getAddressComponent("premise"); // Building name or room number
    const establishment = getAddressComponent("establishment"); // Business names

    let fullStreet = "";

    if (establishment) {
      fullStreet = establishment;
    } else if (premise) {
      fullStreet = `${premise}, ${route || sublocality || neighborhood}`;
    } else if (streetNumber && route) {
      fullStreet = `${streetNumber} ${route}`;
    } else if (sublocality) {
      fullStreet = sublocality;
    } else if (neighborhood) {
      fullStreet = neighborhood;
    }

    if (!fullStreet) {
      fullStreet = `${getAddressComponent("locality")}, ${getAddressComponent(
        "administrative_area_level_1"
      )}, ${getAddressComponent("country")}`;
    }

    // Set the full street address in the form
    setValue("address.street", fullStreet);

    // Set the zip code
    setValue("address.zipCode", getAddressComponent("postal_code"));
  };

  const handleChange = (e) => {
    reset({
      phone: e,
    });
  };
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleCloseModal = async (data) => {
    if (data == "Upload") {
      if (!imagePreview) {
        return ShowToast("Please Select Image", "error");
      }
      // saveData.image = imaeg;
    }
    setFormSubmit(data);
    setShowModal(false);
  };

  const onSubmit = async (data) => {
    if (availableDays.length === 0) {
      return ShowToast("Please select Available Days", "error");
    }
    data.availableDays = availableDays;
    data.DOB=moment(data.DOB).format('YYYY-MM-DD');
    onSubmitt(data);
  };

  const handleavailibledays = () => {
    setShowavailibleday(true);
  };
  const handleClose = () => {
    setShowavailibleday(false);
  };

  useEffect(() => {
    getSpecialty();
  }, []);
  const getSpecialty = async () => {
    try {
      const resposne = await GetCategory();
      setspecialtydata(resposne?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: "70vh", // Updated height to 70% of the viewport height
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto", // Enables scrolling inside the modal box
  };

  const handleToggle = (dayName) => {
    setAvailableDays((prev) => {
      const isActive = prev.some((day) => day.day === dayName);
      if (isActive) {
        return prev.filter((day) => day.day !== dayName);
      } else {
        return [
          ...prev,
          { day: dayName, shifts: [{ startTime: "10:00", endTime: "18:00" }] },
        ];
      }
    });
  };

  const handleTimeChange = (dayName, newStartTime, newEndTime) => {
    setAvailableDays((prev) =>
      prev.map((day) => {
        if (day.day === dayName) {
          return {
            ...day,
            shifts: [{ startTime: newStartTime, endTime: newEndTime }],
          };
        }
        return day;
      })
    );
  };

  const handleSave = () => {
    console.log(availableDays);
    handleClose();
  };

  const days = [
    { name: "Monday" },
    { name: "Tuesday" },
    { name: "Wednesday" },
    { name: "Thursday" },
    { name: "Friday" },
    { name: "Saturday" },
    { name: "Sunday" },
  ];

  const DayItem = ({
    day,
    isActive,
    onToggle,
    startTime,
    endTime,
    onTimeChange,
  }) => {
    return (
      <div className={`unique-day-item ${isActive ? "unique-active-day" : ""}`}>
        <div className="unique-day-header">
          <span>{day.name}</span>
          <label className="unique-switch">
            <input type="checkbox" checked={isActive} onChange={onToggle} />
            <span className="unique-slider unique-round"></span>
          </label>
        </div>

        {isActive && (
          <div className="unique-time-selector">
            <div className="unique-time-group">
              <div className="unique-time-input-wrapper">
                <input
                  type="time"
                  className="unique-time-input"
                  value={startTime}
                  onChange={(e) =>
                    onTimeChange(day.name, e.target.value, endTime)
                  }
                />
              </div>
              <div className="unique-time-input-wrapper">
                <input
                  type="time"
                  className="unique-time-input"
                  value={endTime}
                  onChange={(e) =>
                    onTimeChange(day.name, startTime, e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  const options = [
    { _id: "0-1", name: "0-1 (New or Entry Level)" },
    { _id: "2-5", name: "2-5 (Early Career)" },
    { _id: "6-10", name: "6-10 (Experienced)" },
    { _id: "11-15", name: "11-15 (Advanced)" },
    { _id: "16-20+", name: "16-20+ (Expert or Senior Level)" },
  ];

  const handleSelect = (selectedOptions) => {
    let ids = selectedOptions.map((e) => e?._id);
    setValue("categoryId", ids, { shouldValidate: true });
  };

  const handleSelectProviderType = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("ProviderType", ids, { shouldValidate: true });
  };

  const handleSelectConditionTreated = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("ConditionTreatedId", ids, { shouldValidate: true });
  };
  const handlelanguage = (selectedOptions) => {
    let ids = selectedOptions?.map((e) => e?._id);
    setValue("LanguagesId", ids, { shouldValidate: true });
  };
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div>
      <div style={{ width: "100%", display: "flex", height: "100vh" }}>
        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#041C32",
            flexDirection: "column",
          }}
        >
          <img
            src={require("../assest/Images/mindwellnewlogo.png")}
            style={{ height: "50%", width: "50%" }}
          />
          <div
            style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "20px",
              marginTop: "20px",
            }}
          >
            Your trusted platform for mental health services.
          </div>
        </div>
        <div className="signup-container">
          <div className="signup-form" style={{ width: "70%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <h2>New Provider Registration</h2>
              <div className="input-row">
                <div className="form_div">
                  <Inputbox
                    label="Legal First Name"
                    type="text"
                    placeholder="Enter First Name"
                    name="firstName"
                    register={register}
                  />
                  {errors.firstName && (
                    <div className="errorMessage">
                      {errors.firstName.message}
                    </div>
                  )}
                </div>

                <div className="form_div">
                  <Inputbox
                    label="Legal Last Name"
                    type="text"
                    placeholder="Enter Last Name"
                    name="lastName"
                    register={register}
                  />
                  {errors.lastName && (
                    <div className="errorMessage">
                      {errors.lastName.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="input-row">
                {/* <div className="form_div">
             
              </div> */}
                <div className="form_div">
                  <DropDownManuCustom
                    label="Experience (Years)"
                    placeholder="Select option"
                    options={options}
                    name="experience"
                    dropdownicon
                    register={register}
                    id="_id"
                    clearErrors={clearErrors}
                  />
                  {errors.experience && (
                    <div className="errorMessage">
                      {errors.experience.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="input-row" style={{ marginTop: 15 }}>
                <div className="form_div">
                  <Inputbox
                    label="Email Address"
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    register={register}
                  />
                  {errors.email && (
                    <div className="errorMessage">{errors.email.message}</div>
                  )}
                </div>
                <div className="form_div_two">
                  <Inputbox
                    style={{ width: "100%", height: 49 }}
                    label="Phone Number"
                    type="text"
                    placeholder="Enter Number"
                    name="phone"
                    onChange={handleChange}
                  />
                  {errors.phone && (
                    <div className="errorMessage">{errors.phone.message}</div>
                  )}
                </div>
              </div>

              <div className="input-row">
                <div className="form_div">
                  <Inputbox
                    label="Provider NPI"
                    type="text"
                    placeholder="Enter Provider NPI"
                    name="ProviderNPI"
                    register={register}
                  />
                  {errors.ProviderNPI && (
                    <div className="errorMessage">
                      {errors.ProviderNPI.message}
                    </div>
                  )}
                </div>

                <div className="form_div">
                <Autocomplete
        onLoad={(autocompleteInstance) => setAddressAutocomplete(autocompleteInstance)} // Store autocomplete instance
        onPlaceChanged={handleAddressSelection} // Handle address selection
      >
        <Inputbox
          label="Provider Home Address"
          type="text"
          placeholder="Enter Provider Home Address"
          name="ProviderHomeAddress"
          id="ProviderHomeAddress"
          value={address} // Bind input value to the state
          onChange={(e) => setAddress(e.target.value)} // Update state on user input
          register={register} // Assuming this connects to react-hook-form
        />
      </Autocomplete>
                  {/* <Inputbox
                    label="Provider Home Address"
                    type="text"
                    placeholder="Enter Provider Home Address"
                    name="ProviderHomeAddress"
                    register={register}
                  /> */}
                  {errors.lastName && (
                    <div className="errorMessage">
                      {errors.ProviderHomeAddress.message}
                    </div>
                  )}
                </div>
              </div>

              <div className="input-row" style={{ marginTop: 25 }}>
                <div className="form_div" style={{ marginTop: -20 }}>
                  <Inputbox
                    label="Date of Birth"k
                    type="date"
                    name="DOB"
                    register={register}
                    style={{
                      borderColor: errors.DOB ? "red" : "initial",
                      height: 40,
                    }}
                    max={new Date().toISOString().split('T')[0]} 
                  />
                  {errors.DOB && (
                    <p className="errorMessage">{errors.DOB.message}</p>
                  )}
                </div>
                <div className="form_div" style={{ marginTop: -20 }}>
                  <DropDownManuCustom
                    label="Gender"
                    placeholder="Select option"
                    options={[
                      { _id: "Male", name: "Male" },
                      { _id: "Female", name: "Female" },
                      { _id: "Other", name: "Other" },
                    ]}
                    name="gender"
                    dropdownicon
                    register={register}
                  />

                  {errors.gender && (
                    <div className="errorMessage">{errors.gender.message}</div>
                  )}
                </div>
              </div>

              <div className="input-row">
                <div className="form_div">
                  <DropDownManuCustom
                    label="Agency Name & ID"
                    placeholder="Select option"
                    options={cliniclistdata}
                    name="agencyId"
                    dropdownicon
                    register={register}
                    id="_id"
                    clearErrors={clearErrors}
                    onSelect={(selectedOption) => {
                      console.log(selectedOption);
                      setIsAgencySelected(true);
                      FetchAddresslist(selectedOption?._id);
                      fetchProviderType(selectedOption?._id);
                      fetchConditionTreated(selectedOption?._id);
                      GetLanguage(selectedOption?._id);
                    }}
                  />
                  {errors.agencyId && (
                    <div className="errorMessage">
                      {errors.agencyId.message}
                    </div>
                  )}
                </div>
                
              </div>
              <div className="form_div">
                  <DropDownManuCustom
                    label="Provider Primary Pratice Address"
                    placeholder="Select Practice Location"
                    options={servicelocation}
                    name="ServiceLocationId"
                    dropdownicon
                    register={register}
                    id="_id"
                    clearErrors={clearErrors}
                    disabled={!isAgencySelected}
                  />
                  {errors.ServiceLocationId && (
                    <div className="errorMessage">
                      {errors.ServiceLocationId.message}
                    </div>
                  )}
                </div>
              {/* <DropDownManuCustom
                label="Provider Type"
                placeholder="Select option"
                options={providerType || []}
                name="ProviderType"
                dropdownicon
                register={register}
                id="_id"
                clearErrors={clearErrors}
              /> */}

              {/* <DropDownMultiStringSelect
                label="Provider Type"
                placeholder="Select option"
                options={providerType}
                name="ProviderType"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={handleSelectProviderType}
                disabled={!isAgencySelected}
              /> */}

             

              <div className="input-row">
                <div className="form_div">
                  <Autocomplete
                    onLoad={(autocompleteInstance) =>
                      setAutocomplete(autocompleteInstance)
                    }
                    onPlaceChanged={handlePlaceSelect}
                  >
                    <Inputbox
                      label="Street"
                      type="text"
                      placeholder="Enter Street"
                      name="address.street"
                      register={register}
                    />
                  </Autocomplete>
                </div>

                <div className="form_div">
                  <Inputbox
                    label="City"
                    type="text"
                    placeholder="Enter City"
                    name="address.city"
                    register={register}
                  />
                  {errors.address?.city && (
                    <div className="errorMessage">
                      {errors.address.city.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="input-row">
                <div className="form_div">
                  <Inputbox
                    label="State"
                    type="text"
                    placeholder="Enter State"
                    name="address.state"
                    register={register}
                  />
                  {errors.address?.state && (
                    <div className="errorMessage">
                      {errors.address.state.message}
                    </div>
                  )}
                </div>
                <div className="form_div">
                  <Inputbox
                    label="Country"
                    type="text"
                    placeholder="Enter Country"
                    name="address.country"
                    register={register}
                  />
                  {errors.address?.country && (
                    <div className="errorMessage">
                      {errors.address.country.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="form_div">
                <Inputbox
                  label="Zip Code"
                  type="text"
                  placeholder="Enter Zip Code"
                  name="address.zipCode"
                  register={register}
                />
                {errors.address?.zipCode && (
                  <div className="errorMessage">
                    {errors.address.zipCode.message}
                  </div>
                )}
              </div>
              <DropDownManuCustomMulti
                label="Provider Type"
                placeholder="Select option"
                options={providerType}
                name="ProviderType"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={handleSelectProviderType}
              />
              <div style={{ marginTop: 20 }} />
              <DropDownManuCustomMulti
                label="Condition Treated"
                placeholder="Select option"
                options={conditionTreated}
                name="ConditionTreatedId"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={handleSelectConditionTreated}
                disabled={!isAgencySelected}
              />
         
              <div className="input-row">
                <div className="form_div" style={{ marginTop: 20 }}>
                  <DropDownMultiStringSelect
                    label="Degree Type"
                    placeholder="Select option"
                    options={licenseTypes}
                    name="LicenseType"
                    register={register}
                    id="_id"
                    clearErrors={clearErrors}
                    onSelect={(value) => setValue("LicenseType", value)}
                  />
                  {errors.LicenseType && (
                    <div className="errorMessage">
                      {errors.LicenseType.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="form_div">
                <DropDownManuCustomMulti
                  label="Specialities"
                  placeholder="Select option"
                  options={specialtydata}
                  name="categoryId"
                  register={register}
                  id="_id"
                  clearErrors={clearErrors}
                  onSelect={handleSelect}
                />
                {errors.categoryId && (
                  <div className="errorMessage">
                    {errors.categoryId.message}
                  </div>
                )}
              </div>

              <lable
                style={{
                  display: "flex",
                  marginBottom: -10,
                  color: "#273f5b",
                  fontSize: "16px",
                  fontWeight: "600",
                  marginTop: 30,
                }}
              >
                Available Days
              </lable>
              <div
                className="Available_Days_divv"
                onClick={handleavailibledays}
                style={{ marginTop: 20 }}
              >
                <div>
                  {" "}
                  {(availableDays.length > 0 && (
                    <div className="selected-days">
                      {availableDays.map((e) => e.day).join(", ")}
                    </div>
                  )) ||
                    "Available Days"}{" "}
                </div>
                <Dropdowniconn />
              </div>
              <div className="input-row" style={{ marginTop: 15 }}>
                <div className="form_div">
                  <Inputbox
                    label="Short Biography "
                    placeholder="Enter Short Biography "
                    name="info"
                    register={register}
                    style={{ borderColor: errors.info ? "red" : "initial" }}
                  />
                  {errors.info && (
                    <p className="errorMessage">{errors.info.message}</p>
                  )}
                </div>
                {/* <div className="form_div"> */}
                  {/* <div> */}
                    {/* <DropDownManuCustom
                      label="Language"
                      placeholder="Select language"
                      options={[
                        { _id: "english", name: "English" },
                        { _id: "spanish", name: "Spanish" },
                      ]}
                      name="language"
                      register={register}
                      clearErrors={clearErrors}
                      style={{
                        borderColor: errors.language ? "red" : "initial",height:50
                      }}
                    /> */}
                         {/* <DropDownManuCustomMulti
                     label="Language"
                    placeholder="Select option"
                    options={data}
                  name="LanguagesId"
                    register={register}
                    id="_id"
                    clearErrors={clearErrors}
                    // onSelect={(value) => setValue("language", value)}
                    onSelect={handlelanguage}
                  />
                    {errors.LanguagesId && (
                      <p className="errorMessage">{errors.LanguagesId.message}</p>
                    )}
                  </div> */}
                {/* </div> */}
              </div>
              <div className="form_div">
                  <div>
                    {/* <DropDownManuCustom
                      label="Language"
                      placeholder="Select language"
                      options={[
                        { _id: "english", name: "English" },
                        { _id: "spanish", name: "Spanish" },
                      ]}
                      name="language"
                      register={register}
                      clearErrors={clearErrors}
                      style={{
                        borderColor: errors.language ? "red" : "initial",height:50
                      }}
                    /> */}
                         <DropDownManuCustomMulti
                     label="Language"
                    placeholder="Select option"
                    options={data}
                  name="LanguagesId"
                    register={register}
                    id="_id"
                    clearErrors={clearErrors}
                    // onSelect={(value) => setValue("language", value)}
                    onSelect={handlelanguage}
                  />
                    {errors.LanguagesId && (
                      <p className="errorMessage">{errors.LanguagesId.message}</p>
                    )}
                  </div>
                </div>
              <div className="input-row" style={{ marginTop: 15 }}>
                <div className="form_div">
                  <Inputbox
                    label="Password"
                    type="password"
                    placeholder="Enter Password"
                    name="password"
                    register={register}
                    isPassword
                  />
                  {errors.password && (
                    <div className="errorMessage">
                      {errors.password.message}
                    </div>
                  )}
                </div>
                <div className="form_div">
                  <Inputbox
                    label="Confirm Password"
                    type="password"
                    placeholder="Enter Confirm Password"
                    name="confirmPassword"
                    register={register}
                    isPassword
                  />
                  {errors.confirmPassword && (
                    <div className="errorMessage">
                      {errors.confirmPassword.message}
                    </div>
                  )}
                </div>
              </div>
              <div className="display_div_dis">
              <div>
                <input type="checkbox" />
              </div>
              <div>
                <p className="I_have_txt">
                  I have read and agreed to the{" "}
                  <a href="#" style={{ color: "#bc9c22" }}>
                    {" "}
                    Terms & Conditions{" "}
                  </a>
                  and
                  <a href="#" style={{ color: "#bc9c22" }}>
                    {" "}
                    Privacy Policy
                  </a>
                </p>
              </div>
            </div>
              {/* <button className="login-button">Next</button> */}
              <input className="login-button" type="submit" value="Next" />
              <p className="login-text">
                Already have an Account?{" "}
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Log In
                </a>
              </p>
            </form>
          </div>
        </div>

        <Modal
          open={showavailibleday}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h3>Select your opening days</h3>
            {days.map((day) => {
              const isActive = availableDays.some((d) => d.day === day.name);
              const dayData = availableDays.find((d) => d.day === day.name);
              const startTime = dayData ? dayData.shifts[0].startTime : "10:00";
              const endTime = dayData ? dayData.shifts[0].endTime : "18:00";

              return (
                <DayItem
                  key={day.name}
                  day={day}
                  isActive={isActive}
                  onToggle={() => handleToggle(day.name)}
                  startTime={startTime}
                  endTime={endTime}
                  onTimeChange={handleTimeChange}
                />
              );
            })}
            <div className="logout-buttons" style={{ marginTop: 20 }}>
              <button className="cancel-button" onClick={handleClose}>
                Cancel
              </button>
              <button className="confirm-button" onClick={handleSave}>
                Save
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default SignupFirst;
