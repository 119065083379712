import React from "react";
import Navbar from "../../Components/Navbar";
import AgencySideBar from "../Components/AgencySideBar";

const Logout = () => {
  return (
    <div className="dashboard-container">
      <AgencySideBar />
      <div className="dashboard-content">
        <Navbar />
        <div
          className="dashboard-cards"
          style={{ padding: 30, paddingTop: 140, flexDirection: "column" }}
        >
          <div className="logout-modal">
            <div className="logout_txt">Logout</div>
            <div className="logout_sure" style={{ marginTop: 20 }}>
              Are you sure to logout
            </div>
            <div className="logout_sure" style={{ marginBottom: 20 }}>
              your account?
            </div>
            <div className="logout-buttons">
              <button className="cancel-button">No</button>
              <button className="confirm-button">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
