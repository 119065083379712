import React, { useEffect, useState } from 'react'
import { GetCategory } from '../../../api/helper';

const PopularCategoryData = () => {
  const [categorydata, setcategorydata] = useState(null)

  useEffect(() => {
    getSpecialty();
  }, []);
  const getSpecialty = async () => {
    try {
      const resposne = await GetCategory();
      console.log(resposne?.data?.data,"resposne?.data?.data=-=")
      setcategorydata(resposne?.data?.data)
    } catch (error) {
      console.log(error);
    }
  }

  const clinics = [
    { id: 1, label: "Dental Clinics", icon: require('../../assest/Images/TeethVector.png') },
    { id: 2, label: "Eye Clinics", icon: require('../../assest/Images/EyeVector.png') },   
    { id: 3, label: "Dental Clinics", icon: require('../../assest/Images/TeethVector.png') },
    { id: 4, label: "Eye Clinics", icon: require('../../assest/Images/EyeVector.png') },   
    { id: 5, label: "Dental Clinics", icon: require('../../assest/Images/TeethVector.png') },
    { id: 6, label: "Eye Clinics", icon: require('../../assest/Images/EyeVector.png') },  
  ];

  // const [selectedClinic, setSelectedClinic] = useState(1);

  return (
    <div className="clinic-buttons-container">
    {
  categorydata && categorydata.length > 0 ? (
    categorydata.map((clinic) => (
      <button
        key={clinic.id}
        className="clinic-button active"
        // className={`clinic-button ${selectedClinic === clinic.id ? "active" : ""}`}
        // onClick={() => setSelectedClinic(clinic.id)}
      >
        <span className="clinic-icon">
          <img 
            src={clinic.image} 
            className="image_teeth"
            style={{
              filter: clinic.label === "Eye Clinics" ? "invert(1)" : "none", 
            }}
          />
        </span>
        <span className="clinic-label">{clinic.name}</span>
      </button>
    ))
  ) : null
}
     
    </div>
  );
}

export default PopularCategoryData;