import React, { useState } from "react";
import PatientSidebar from "./PatientSidebar";
import { Navbartwo, Navtopbar } from "../../Components/Navbar";
import Inputbox from "../../Components/Inputbox";
import DropDownButton from "../../Components/DropDownButton";
import { useNavigate } from "react-router-dom";
import Checkbox from "./Checkbox";

const InsurancePatient = () => {
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("workingHours");
  const handlenotification = () => {
    setActiveTab("PrivatePayMethod");
    setIsChangePasswordVisible(!isChangePasswordVisible);
  };

  const navigate = useNavigate();
  const handleSignUp = () => {
    navigate("/PrivatePayPatient");
  };

  return (
    <div className="dashboard-container">
      <PatientSidebar />
      <div className="dashboard-content">
        <Navtopbar title={"Insurance"} />
        <div
          className="dashboard-cards1"
          style={{ paddingLeft: 50, paddingRight: 50 }}
        >
          <div className="space-div" style={{ width: "100%" }}>
            <div className="input-row" style={{ width: "100%", gap: "6%" }}>
              <Inputbox
                label="Legal Full Name"
                type="text"
                placeholder="Enter Full Name"
                name="firstName"
                style={{ backgroundColor: "#D9D9D94D" }}
              />
              <Inputbox
                type="text"
                label="Patient Date of Birth"
                placeholder="MM/DD/YY"
                name="lastName"
                style={{ backgroundColor: "#D9D9D94D" }}
                //   value={formData.lastName}
                //   onChange={handleChange}
              />
            </div>
          </div>

          <div className="space-div" style={{ width: "100%", gap: "6%" }}>
            <DropDownButton
              label="Insurance Carrier"
              placeholder="Select Insurance Carrier"
              dropdownicon
              style={{ width: "47%" }}
              divstyle={{ backgroundColor: "#D9D9D94D" }}
              options={[
                "1199SEIU Funds",
                "ACS Benefit Service",
                "Aetna",
                "Aither Health",
                "Banner (Aetna)",
                "Benefit Management Inc.",
                "Benefits Administration Services",
                "Assurant (Cigna)",
              ]}
            />
            <DropDownButton
              label="Member ID"
              placeholder="Enter Member ID"
              style={{ width: "47%" }}
              divstyle={{ backgroundColor: "#D9D9D94D" }}
            />
          </div>

          <div className="space-div" style={{ width: "47%" }}>
            <div className="input-row" style={{ width: "100%", gap: "6%" }}>
              <Inputbox
                label="Legal Full Name"
                type="text"
                placeholder="Enter Full Name"
                name="firstName"
                style={{ backgroundColor: "#D9D9D94D" }}
              />
            </div>
          </div>
          <p className="Insurance_Card_txt">Insurance Card</p>
          <div className="Insurance_display_flex">
            <div className="Insurance_div_camera">
              <img
                className="Insurance_image_camera"
                src={require("../../assest/Images/cameraicon.png")}
              />
              <p className="Insurance_Front_txt">Front Side</p>
            </div>
            <div className="Insurance_div_camera">
              <img
                className="Insurance_image_camera"
                src={require("../../assest/Images/cameraicon.png")}
              />
              <p className="Insurance_Front_txt">Front Side</p>
            </div>
          </div>

          {/* <div className="div_display_button">
            <button className="button1" title="Save">
              Cancel
            </button>
            <button className="button" title="Save" >
              Submit
            </button>
          </div> */}
          
          
          <div className="div_display_button">
            <div
            style={{ marginTop: 20 }}
            className="inactive-tab12"
          >
            Cancel
          </div>

          <div
            style={{ marginTop: 20 }}
            className={
              activeTab === "PrivatePayMethod" ? "active-tab12" : "inactive-tab12"
            }
            onClick={handlenotification}
          >
            Submit
          </div>
          
          </div>


        </div>
      </div>

      {activeTab === "PrivatePayMethod" && (
        <div>
          {isChangePasswordVisible && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
            >
              <div
                className="HelpSupport_div"
                style={{
                  maxWidth: "40%",
                  paddingLeft: 50,
                  paddingRight: 50,
                }}
              >
                <div className="Contact_Us_txt">Payment Method</div>

                <div
                  className="div_Payment_Method"
                  style={{ backgroundColor: "#D9D9D9" }}
                >
                  <img
                    className="image_life-insurance"
                    src={require("../../assest/Images/ApplePayshopping.png")}
                  />
                  <div className="div_text_payment_method">
                    <p className="text_Insurance_payment">Apple Pay</p>
                    <p className="text_Bill_payment">Pay with Apple Pay</p>
                  </div>
                  <Checkbox />
                </div>

                <div
                  className="div_Payment_Method"
                  style={{ backgroundColor: "#D9D9D9" }}
                >
                  <img src={require("../../assest/Images/google-pay.png")} />
                  <div className="div_text_payment_method">
                    <p className="text_Insurance_payment">Google Pay</p>
                    <p className="text_Bill_payment">Pay with Google Pay</p>
                  </div>
                  <Checkbox />
                </div>

                <div
                  className="div_Payment_Method"
                  style={{ backgroundColor: "#D9D9D9" }}
                >
                  <img src={require("../../assest/Images/Ppaylogo.png")} />
                  <div className="div_text_payment_method">
                    <p className="text_Insurance_payment">Pay Pal</p>
                    <p className="text_Bill_payment">Pay with Pay Pal</p>
                  </div>
                  <Checkbox />
                </div>
                <div
                  className="div_Payment_Method"
                  style={{ backgroundColor: "#D9D9D9" }}
                >
                  <img src={require("../../assest/Images/CreditPay.png")} />
                  <div className="div_text_payment_method">
                    <p className="text_Insurance_payment">
                      Credit card or debit card
                    </p>
                    <p className="text_Bill_payment">Enter your card details</p>
                  </div>
                  <Checkbox />
                </div>

                <div
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <div className="savebuttonn1" onClick={handleSignUp}>
                    Submit
                  </div>
                </div>

              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default InsurancePatient;
