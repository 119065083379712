import React, { useState } from 'react'

const LabsPatient = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  return (
    <div className="patient_right">
    <div style={{display:'flex',alignItems:'center',alignSelf:'flex-end',marginTop:20,marginRight:20}} onClick={toggleFormVisibility}>
     <img src={require('../assest/Images/ViewPrescriptions.png')}/>
     <div className='view_Prescriptions_txt'>View Orders</div>
   
    </div>
     <div className="medication-table-container">
     <div className="medication-header_t">
         <div className="medication-header-item_t">Test</div>
         <div className="medication-header-item_t">|</div>
         <div className="medication-header-item_t">Status</div>
         <div className="medication-header-item_t">|</div>
         <div className="medication-header-item_t">Result</div>
         <div className="medication-header-item_t">|</div>
         <div className="medication-header-item_t">Prior Results</div>
   
     </div>
 
     <div className="medication-empty-state">
         <div className="medication-empty-title">Nothing Here Yet</div>
         <div className="medication-empty-description">No health records available for this resource</div>
     </div>
 
   
 </div>
 {isFormVisible && (
        <div className="add_problem_div1">
        <div className="Add_problem_txtt1">
        Orders
            {/* Close Icon */}
            <img
              src={require("../assest/Images/crossIconn.png")}
              alt="close"
              onClick={toggleFormVisibility}  // Hides form when clicked
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className='div_Draft_txt'><p className='Draft_Order_txt'>Draft Orders</p></div>
          <div className='div_Draft_txt'><p className='Draft_Order_txt'>Result Pending</p></div>
          </div>
 )}
 </div>
  )
}

export default LabsPatient