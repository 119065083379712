import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAgencyRequestList, GetDoctorrequest, UpdateRequest } from "../../api/helper";
import { useSelector } from "react-redux";
import { ShowToast } from "../../helpers/ToastService";

const AddNewClinic = () => {
  const profiledata = useSelector(state => state?.cookies?.userDetails);
  // console.log(profiledata, "hellloo=====")
  const [doctordata, setdoctordata] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);
  const Getrequest = async (page) => {
    try {
      let data = {
        limit:50,
        page:1,
        doctorId:profiledata?._id,
        status:"pending,request"
      }
      const response = await getAgencyRequestList(data);
      console.log(response?.data.data, "doctor=-=-======");
      setdoctordata(response?.data.data.requests);
      setTotalPages(response?.data?.data?.totalPages);
    } catch (error) {
      console.log(error, "error=====");
    }
  };
  useEffect(() => {
    Getrequest(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const navigate = useNavigate();
  const handleView = (id) => {
    navigate("/DoctorClinicDetails", { state: { clinicId: id } }); 
  };

  const HandleConfirm = async (status, id) => {
    const data = {
      status: status,
    };

    try {
      const response = await UpdateRequest(data, id);
      console.log(response?.data);
      ShowToast(response?.data?.message, "success");
      
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="AddNew_main_div">
      <table className="clinics-table">
        <thead>
          <tr>
            <th style={{ width: "11%", textAlign: "center" }}>Image</th>
            <th style={{ width: "25%", textAlign: "center" }}>Clinic Name</th>
            <th style={{ width: "25%", textAlign: "center" }}>Address</th>
            <th style={{ width: "25%", textAlign: "center" }}>Open Days</th>
            <th style={{ width: "14%", textAlign: "center" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
        {doctordata && doctordata.length > 0 ? (
            doctordata.map((clinic, index) => (
            <tr key={index}>
              <td style={{ textAlign: "center" }}>
                {" "}
                {clinic?.image ? (
                    <img
                      src={clinic.agencyId.image} // Correct image property
                      alt={`${clinic.agencyId.companyName} `}
                      className="clinic-image"
                    />
                  ) : (
                    <img
                      src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                      className="clinic-image"
                    />
                  )}
              </td>
              <td style={{ textAlign: "center" }}>   {clinic.agencyId.companyName}</td>
              <td style={{ textAlign: "center" }}>  {clinic?.agencyId?.address?.street}, {clinic?.agencyId?.address?.city},{" "}
              {clinic?.agencyId?.address?.state}, {clinic?.agencyId?.address?.country}</td>
              <td style={{ textAlign: "center" }}>Mon-Fri</td>
              <td style={{ textAlign: "center" }} className="userTable-td">
              {/* <button className="userTable-declineBtn" onClick={() => HandleConfirm("denied", clinic?.agencyId?._id)} >Decline</button>
              <button className="userTable-confirmBtn" onClick={() => HandleConfirm("approved", clinic?.agencyId?._id)} >Confirm</button> */}
                <button
                  className="view-button"
                  onClick={() => handleView(clinic?.agencyId?._id)}
                >
                  View
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5">No clinics found</td>
          </tr>
        )}
        </tbody>
      </table>
    </div>
  );
};

export default AddNewClinic;
