import React, { useEffect, useState } from "react";
import { GetDoctorrequest } from "../../api/helper";
import { useNavigate ,} from "react-router-dom";
import { useSelector } from "react-redux";

const ClinicScreen = () => {
  const profiledata = useSelector(state => state?.cookies?.userDetails);
  // console.log(profiledata?._id, "hellloo=====")
  const [doctordata, setdoctordata] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); 
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const [limit] = useState(10);
  const Getrequest = async (page) => {
    try {
      let data = {
        limit:50,
        page:1,
        doctorId:profiledata?._id,
        status:"approved"
      }
      const response = await GetDoctorrequest(data);
      // console.log(response?.data, "doctor=-=-======");
      setdoctordata(response?.data?.data?.data);
      setTotalPages(response?.data?.data?.totalPages);
    } catch (error) {
      console.log(error, "error=====");
    }
  };
  useEffect(() => {
    Getrequest(currentPage); 
  }, [currentPage]);

 
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleView = (id) => {
    navigate("/DoctorClinicDetails", { state: { clinicId: id } }); 
  };
  return (
    <div className="clinics-table-container">
      <table className="clinics-table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Clinic Name</th>
            <th>Address</th>
            <th>Open Days</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>

          {doctordata && doctordata.length > 0 ? (
            doctordata.map((clinic, index) => (
              <tr key={index}>
                <td>
                  {clinic?.image ? (
                    <img
                      src={clinic.image} 
                      alt={`${clinic.companyName} `}
                      className="clinic-image"
                    />
                  ) : (
                    <img
                      src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                      className="clinic-image"
                    />
                  )}
                  {/* <img
                  src={clinic.image} // Correct image property
                  alt={`${clinic.firstName} ${clinic.lastName}`}
                  className="clinic-image"
                /> */}
                </td>
                <td>
                  {clinic.companyName}
                </td>{" "}
                {/* Clinic name */}
                <td>
                  {clinic?.address?.street}, {clinic?.address?.city},{" "}
                  {clinic?.address?.state}, {clinic?.address?.country}
                </td>{" "}
                {/* Address */}
                <td>Mon-Fri</td> {/* Open days placeholder */}
                <td>
                <button
                  className="view-button"
                  onClick={() => handleView(clinic?._id)}
                >
                  View
                </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No clinics found</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>

      <div className="dashborad_plus">+</div>
    </div>
  );
};

export default ClinicScreen;
