// Navbar.js
import React, { useEffect } from "react";
import { Notificationsicon } from "../assest/SvgImages";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const Navbar = ({ style, onClick }) => {
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const navigate = useNavigate()
  useEffect(() => {
    if (!profiledata) {
      navigate('/')
    }
  }, [])

  return (
    <div className="navbar-container" style={style}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "rgba(217, 217, 217, 0.3)",
          paddingLeft: 10,
          paddingRight: 10,
          width: 400,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "rgba(1, 20, 52, 0.2)",
          borderRadius: 10,
        }}
      >
        <img src={require("../assest/Images/searchicon.png")} />
        <input
          placeholder="search"
          style={{
            borderWidth: 0,
            backgroundColor: "rgba(217, 217, 217, 0.3)",
          }}
        />
        <img
          src={require("../assest/Images/filtericon.png")}
          style={{ height: 20, width: 20 }}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Notificationsicon />
        <div
          style={{
            marginLeft: 20,
            marginRight: 20,
            color: "rgba(188, 156, 34, 1)",
          }}
        >
          {" "}
          {profiledata?.firstName + " " + profiledata?.lastName}
        </div>
        <div style={{ cursor: 'pointer' }}
          onClick={onClick}
        >
          {profiledata?.image ? (
            <img
              src={profiledata?.image}
              style={{ height: 35, width: 35, borderRadius: 60 }}
            />
          ) : (
            <img
              src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
              style={{ height: 35, width: 35, borderRadius: 60 }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;

export const ProfileNavbar = ({ onClick }) => {

  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const navigate = useNavigate()

  useEffect(() => {
    if (!profiledata) {
      navigate('/')
    }
  }, [])
  return (
    <div style={{
      display: 'flex', flexDirection: 'column',
      marginBottom: 20,
    }}>
      <div
        style={{

          paddingBottom: 20,
          paddingTop: 20,
          backgroundColor: "white",
          height: "12vh",

          display: "flex",
          alignItems: "center",
          paddingLeft: 20,
          paddingRight: 20,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className="Profile_txtt">Profile</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Notificationsicon />
          <div
            style={{
              marginLeft: 20,
              marginRight: 20,
              color: "rgba(188, 156, 34, 1)",
            }}
          >
            {" "}
            {profiledata?.firstName + " " + profiledata?.lastName}
          </div>
          <div style={{ cursor: 'pointer' }}
            onClick={onClick}
          >
            {profiledata?.image ? (
              <img
                src={profiledata?.image}
                style={{ height: 35, width: 35, borderRadius: 60 }}
              />
            ) : (
              <img
                src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                style={{ height: 35, width: 35, borderRadius: 60 }}
              />
            )}
          </div>
        </div>

      </div>
      <div

        style={{
          // opacity:0.2,
          borderBottomWidth: 1,
          borderStyle: "solid",
          borderColor: "rgba(1, 20, 52, 1)",

          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderTopWidth: 0,
        }}
      />
    </div>
  );
};
export const Navtopbar = ({ title, onClick }) => {
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const navigate = useNavigate()

  useEffect(() => {
    if (!profiledata) {
      navigate('/')
    }
  }, [])
  return (
    <div
      style={{
        paddingBottom: 20,
        paddingTop: 20,
        backgroundColor: "white",
        height: "10vh",
        borderBottomWidth: 1,
        borderStyle: "solid",
        borderColor: "rgba(1, 20, 52, 1)",
        marginBottom: 20,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0,
        display: "flex",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 20,
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div className="Profile_txtt">{title}</div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Notificationsicon />
        <div
          style={{
            marginLeft: 20,
            marginRight: 20,
            color: "rgba(188, 156, 34, 1)",
          }}
        >
          {" "}
          {profiledata?.firstName + " " + profiledata?.lastName}
        </div>
        <div style={{ cursor: 'pointer' }}
          onClick={onClick}
        >
          {profiledata?.image ? (
            <img
              src={profiledata?.image}
              style={{ height: 35, width: 35, borderRadius: 60 }}
            />
          ) : (
            <img
              src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
              style={{ height: 35, width: 35, borderRadius: 60 }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export const Navbartwo = ({ style, onClick }) => {
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const navigate = useNavigate()

  useEffect(() => {
    if (!profiledata) {
      navigate('/')
    }
  }, [])
    return (
    <div className="navbar-containertwo" style={style}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "rgba(217, 217, 217, 0.3)",
          paddingLeft: 10,
          paddingRight: 10,
          width: 400,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "rgba(1, 20, 52, 0.2)",
          borderRadius: 10,
        }}
      >
        <img src={require("../assest/Images/searchicon.png")} />
        <input
          placeholder="search"
          style={{
            borderWidth: 0,
            backgroundColor: "rgba(217, 217, 217, 0.3)",
          }}
        />
        <img
          src={require("../assest/Images/filtericon.png")}
          style={{ height: 20, width: 20 }}
        />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Notificationsicon />
        <div
          style={{
            marginLeft: 20,
            marginRight: 20,
            color: "rgba(188, 156, 34, 1)",
          }}
        >
          Dental Clinic
        </div>
        <div style={{ cursor: 'pointer' }}
          onClick={onClick}
        >


          {profiledata?.image ? (
            <img
              src={profiledata?.image}
              style={{ height: 35, width: 35, borderRadius: 60 }}
            />
          ) : (
            <img
              src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
              style={{ height: 35, width: 35, borderRadius: 60 }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
