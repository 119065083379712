import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area } from 'recharts';
import DropDownButton from './DropDownButton';
import { useNavigate } from 'react-router-dom';

const Report = () => {
    const data = [
        { name: '1 Aug', earnings: 3000 },
        { name: '5 Aug', earnings: 3200 },
        { name: '10 Aug', earnings: 3500 },
        { name: '15 Aug', earnings: 4000 },
        { name: '20 Aug', earnings: 5100 },
        { name: '25 Aug', earnings: 4600 },
        { name: '30 Aug', earnings: 4300 },
      ];

      const navigate = useNavigate()
  return (
    <div className="dashboard-container" >
    <Sidebar />
    <div className="dashboard-content" >
        <Navbar 
        onClick= {()=>{navigate("/profile")}}
        />
        <div className="dashboard-cards" style={{ paddingLeft: 30 ,paddingRight:30,}}>
        <div className="app" style={{width:'100%'}}>
      {/* <header className="header">
        <h1>Report</h1>
        <div className="header-controls">
          <button className="button">Download as PDF</button>
          <button className="button">Download as PNG</button>
          <button className="button">Download as Excel</button>
        </div>
      </header> */}
       <header className="header">
              {/* <h1>Report</h1> */}
              <p className="Report_text_Clinic_Report">Report</p>
              {/* <div className="header-controls"> */}
                <button className="button_Download">Download as PDF</button>
                <button className="button_Download">Download as PNG</button>
                <button className="button_Download">Download as Excel</button>
              {/* </div> */}
            </header>

            <div className="space-div1" style={{ width: "100%", paddingRight:50}}>
      <div className="space-div2" >
        <img className="Report_Arrow_size" src={require('../assest/Images/LeftArrow.png')}/>
        <p className="Report_August_text">August 2024</p>
        <img src={require('../assest/Images/rightarrow.png')}/>
      </div>
      <div className="space-div2" style={{gap:"10%"}} >
      <DropDownButton
              // label="Insurance Carrier"
              placeholder="Monthly"
              dropdownicon
              // style={{height:20}}
              divstyle={{width:150, height:32}}
              options={[
                "1199SEIU Funds",
                "ACS Benefit Service",
                "Aetna",
              ]}
            />
      <img className="Report_image_search" src={require('../assest/Images/searchicon.png')}/>
      <img className="Report_image_more" src={require('../assest/Images/Filter.png')}/>
      </div>
</div>




      <div className="summary-cards">
        <div className="card green" style={{backgroundColor:'rgba(227, 250, 207, 1)'}}>
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
          <h3 style={{color:'rgba(103, 197, 55, 1)'}}>Total Earnings</h3>
          <img src={require('../assest/Images/TotalEarningsicon.png')}/>
          </div>
          <p className="card-value" style={{color:'rgba(1, 20, 52, 1)',textAlign:'left'}}>$7,216</p>
        </div>
        <div className="card blue" style={{backgroundColor:'rgba(207, 243, 253, 1)'}}>
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
          <h3 style={{color:'rgba(20, 174, 209, 1)'}}>New Clients</h3>
          <img src={require('../assest/Images/NewClientsicon.png')}/>
          </div>
          <p className="card-value" style={{color:'rgba(1, 20, 52, 1)',textAlign:'left'}}>165</p>
        </div>
        <div className="card purple" style={{backgroundColor:'#E0EAFE'}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
          <h3 style={{color:'#5465F0'}}>Sessions</h3>
          <img src={require('../assest/Images/Sessionsicon.png')}/>
          {/*  TotalEarningsicon ,NewClientsicon ,Sessionsicon */}
          </div>
          <p className="card-value" style={{color:'rgba(1, 20, 52, 1)',textAlign:'left'}}>20</p>
        </div>
      </div>

      <div className="earnings-graph">
        <h5 style={{textAlign:'left',marginBottom:10}}>Earnings</h5>
     
        <ResponsiveContainer width="100%" height={200}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#e0e0e0"/>
            <XAxis dataKey="name" tick={{ fill: '#8c8c8c', fontSize: 12 }} />
            <YAxis tick={{ fill: '#8c8c8c', fontSize: 12 }} />
            <Tooltip formatter={(value) => `$${value}`} />
            <Line 
              type="monotone" 
              dataKey="earnings" 
              stroke="rgba(188, 156, 34, 1)" 
              strokeWidth={3} 
              dot={{ r: 5, fill: "rgba(188, 156, 34, 1)" }}
              activeDot={{ r: 8 }} 
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="payments-table">
      <h5 style={{textAlign:'left',marginBottom:10}}>Payments</h5>
        <table>
          <thead>
            <tr>
               <th><input type='checkbox'/></th> 
              <th>Name</th>
              <th>Txn ID</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td><input type='checkbox'/></td>
              <td>Franky Williamson</td>
              <td>79122063</td>
              <td>May 31, 2015</td>
              <td>$79.00</td>
              <td  style={{color:'rgba(53, 156, 0, 1)',backgroundColor:'rgba(227, 250, 207, 1)',heigh:20,width:60,textAlign:'center',borderRadius:30,fontSize:14}}>Success</td>
            </tr>
            <tr>
            <td><input type='checkbox'/></td>
              <td>Bronson Glass</td>
              <td>79106631</td>
              <td>May 31, 2015</td>
              <td>$90.00</td>
              <td  style={{color:'rgba(254, 71, 38, 1)',backgroundColor:'rgba(255, 230, 215, 1)',heigh:20,width:60,textAlign:'center',borderRadius:30,fontSize:14}}>Failed</td>
            </tr>
            <tr>
            <td><input type='checkbox'/></td>
              <td>Chris Craig</td>
              <td>79188788</td>
              <td>May 31, 2015</td>
              <td>$18.00</td>
              <td  style={{color:'rgba(53, 156, 0, 1)',backgroundColor:'rgba(227, 250, 207, 1)',heigh:20,width:60,textAlign:'center',borderRadius:30,fontSize:14}}>Success</td>
            </tr>
            <tr>
            <td><input type='checkbox'/></td>
              <td>Declan Mcgowan</td>
              <td>79124079</td>
              <td>May 31, 2015</td>
              <td>$63.00</td>
              <td  style={{color:'rgba(254, 71, 38, 1)',backgroundColor:'rgba(255, 230, 215, 1)',heigh:20,width:60,textAlign:'center',borderRadius:30,fontSize:14}}>Failed</td>
            </tr>
            <tr>
            <td><input type='checkbox'/></td>
              <td>Kris Davis</td>
              <td>79123231</td>
              <td>May 31, 2015</td>
              <td>$2.00</td>
              <td  style={{color:'rgba(53, 156, 0, 1)',backgroundColor:'rgba(227, 250, 207, 1)',heigh:20,width:60,textAlign:'center',borderRadius:30,fontSize:14}}>Success</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
            </div>
            </div>
            </div>
  );
};

export default Report;

