import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';


const SelectorBox = () => {
  const [selectedValue, setSelectedValue] = useState('credit');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <FormControl component="fieldset">
    <RadioGroup
      row
      aria-label="card-type"
      name="card-type-group"
      value={selectedValue}
      onChange={handleChange}
    >
      <FormControlLabel
        value="credit"
        control={<Radio sx={{ color: '#011434', '&.Mui-checked': { color: '#011434' } }} />}
        label="Credit Card"
        className={selectedValue === 'credit' ? 'selected-label' : ''}
      />
      <FormControlLabel
        value="debit"
        control={<Radio sx={{ color: 'black', '&.Mui-checked': { color: 'black' } }} />}
        label="Debit Card"
        className={selectedValue === 'debit' ? 'selected-label' : ''}
      />
    </RadioGroup>
  </FormControl>
  )
}

export default SelectorBox