import React from 'react'

const PatientContact = ({patientdata}) => {
  // console.log('patientdata=====',
  //   patientdata,"patientdata===="
  // )
  return (
<div style={{marginTop:20}}>
<div className="Main_Div">

<div className='main_div' style={{display:'flex', justifyContent:'space-between'}}>
  <p className="para_con_details">Contact Details</p>
  <img src={require("../assest/Images/pen_icon.png")}/>
</div>
<div className='main_div'>
  <p className="para_Mobile">Mobile Number</p>
  <p className="para_Mobile" style={{textAlign:'right'}}> {patientdata?.patientId?.phone}</p>
</div>
<div className='main_div'>
  <p className="para_Mobile">Email</p>
  <p className="para_Mobile" style={{textAlign:'right'}}>{patientdata?.patientId?.email}</p>
</div>
</div>

<div className="Main_Div">
<div className='main_div1'>
  <p className="para_con_details">Addresses</p>
  <img src={require("../assest/Images/plus_icon.png")}/>
</div>
<div className="main2_div"><p className="para_Preferred">Preferred Address</p></div>
<div className='main_div'>
  <p className="para_Mobile">Nickname</p>
  <p className="para_Mobile" style={{textAlign:'right'}}>{patientdata?.patientId?.firstName}</p>
</div>
<div className='main_div'>
  <p className="para_Mobile">Address</p>
  
  <p className="para_Mobile" style={{textAlign:'right'}}>{patientdata?.patientId?.address?.city||"NA"}</p>
</div>
</div>

<div className="Main_Div">
<div className='main_div1'>
  <p className="para_con_details">Emergency Contacts</p>
  <img src={require("../assest/Images/plus_icon.png")}/>
</div>
<div className="main_div2"><p className="para_No">No emergency contacts found.</p></div>
</div>
</div> 

  )
}

export default PatientContact