import React from 'react'

const CareTeamPatient = () => {
  return (
    <div>
        <div className='display_image_txt'>
        <p className='txxt_hope'>Care Team</p>
        <img className='img_icon' src={require('../../assest/Images/Bookingsicon.png')} />
        </div>
  
    <div className='Care_Team_Main_Div'>
    <div className='Care_main_div'>
        <div className='display_pic'>
          <img src={require('../../assest/Images/doctorimg.png')} className='imageGirl'/>
          {/* <img src={require('../../assest/Images/doctorimg.png')} className='imageGirl'/> */}
          {/* <img src={require('../../assest/Images/doctorimg.png')} className='imageGirl'/> */}
        </div>
        <p className='text_hope'>Hope</p>
        <p className='text_urgent'>Care Advocate</p>
        <div className='care_team_display'>
          <img className='img_icon' src={require('../../assest/Images/book_icon.png')}/>
          <p className='txt_Ask'>Ask Me Anything</p>
        </div>
    </div>
    <div className='Care_main_div'>
        <div className='display_pic'>
          <img src={require('../../assest/Images/doctorimg.png')} className='imageGirl'/>
          <img src={require('../../assest/Images/doctorimg.png')} className='imageGirl'/>
          <img src={require('../../assest/Images/doctorimg.png')} className='imageGirl'/>
        </div>
        <p className='text_hope'>24/7 Virtual</p>
        <p className='text_urgent'>Urgent Care</p>
        <div className='care_team_display'>
          <img className='img_icon' src={require('../../assest/Images/book_icon.png')}/>
          <p className='txt_Ask'>Available 9/18</p>
        </div>
    </div>
    <div className='Care_main_div'>
        <div className='display_pic'>
          <img src={require('../../assest/Images/doctorimg.png')} className='imageGirl'/>
        </div>
        <p className='text_hope'>Abu Hurairah, MD</p>
        <p className='text_urgent'>Gastroenterology</p>
        <div className='care_team_display'>
          <img className='img_icon' src={require('../../assest/Images/book_icon.png')}/>
          <p className='txt_Ask'>Ask Me Anything</p>
        </div>
    </div>
    <div className='Care_main_div'>
        <div className='display_pic'>
          <img src={require('../../assest/Images/doctorimg.png')} className='imageGirl'/>
        </div>
        <p className='text_hope'>Abu Hurairah, MD</p>
        <p className='text_urgent'>Gastroenterology</p>
    </div>
    </div>
   




    </div>
  )
}

export default CareTeamPatient