import React, { useState } from 'react';
import SignupFirst from './SignupFirst';
import { DoctorSignupScreen } from './DoctorSignup';

const SignUp = () => {
  const [showDoctorSignup, setShowDoctorSignup] = useState(false);
  const [signupData, setSignupData] = useState(null);

  const handleOnSubmit = (data) => {
    setSignupData(data);          
    setShowDoctorSignup(true);     
    console.log(data, "Received data in SignUp");
  };

  return (
    <div>
      {!showDoctorSignup ? (
        <SignupFirst onSubmitt={handleOnSubmit} />
      ) : (
        <DoctorSignupScreen data={signupData} setShowDoctorSignup={setShowDoctorSignup} />
      )}
    </div>
  );
};

export default SignUp;
