import React from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'

const LogoutDoctor = () => {
  return (
   <div>
        <div className="logout-modal">
          <div className="logout_txt">Logout</div>
          <div className="logout_sure" style={{ marginTop: 20 }}>
            Are you sure to logout
          </div>
          <div className="logout_sure" style={{ marginBottom: 20 }}>
            your account?
          </div>
          <div className="logout-buttons">
            <button className="cancel-button">No</button>
            <button className="confirm-button">Yes</button>
          </div>
        </div>
        </div>
  )
}

export default LogoutDoctor