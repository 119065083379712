import React from "react";

const Compliancedata = () => {
  return (
    <div className="compliance-summary">
      <h2 className="header">Staff Compliance Summary</h2>

      <div className="compliance-sections">
        {/* HR Compliance Section */}
        <div style={{ width: "45%" }}>
          <h3 className="section-title">Hr Compliance</h3>

          <div className="compliance-box hr-compliance">
            <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
              <ul className="compliance-list">
                <li className="compliance-item">
                  <div style={{ display: "flex", width: "70%" }}>
                    <div className="item-icon">  <img src={require('../../assest/Images/files_iconn.png')}/></div>
                    <div className="item-details">
                      <p className="item-title">Liab. Insurance</p>
                      <p className="item-name">Deane Bunck(Status: Expired)</p>
                    </div>
                  </div>
                  <p className="item-expiry">Exp Date: 27 Aug 2024</p>
                </li>
                <li className="compliance-item">
                <div style={{ display: "flex", width: "70%" }}>
                    <div className="item-icon">  <img src={require('../../assest/Images/files_iconn.png')}/></div>
                    <div className="item-details">
                      <p className="item-title">Liab. Insurance</p>
                      <p className="item-name">Deane Bunck(Status: Expired)</p>
                    </div>
                  </div>
                  <p className="item-expiry">Exp Date: 27 Aug 2024</p>
                </li>
                <li className="compliance-item">
                <div style={{ display: "flex", width: "70%" }}>
                    <div className="item-icon">  <img src={require('../../assest/Images/files_iconn.png')}/></div>
                    <div className="item-details">
                      <p className="item-title">Liab. Insurance</p>
                      <p className="item-name">Deane Bunck(Status: Expired)</p>
                    </div>
                  </div>
                  <p className="item-expiry">Exp Date: 27 Aug 2024</p>
                </li>
                <li className="compliance-item">
                <div style={{ display: "flex", width: "70%" }}>
                    <div className="item-icon">  <img src={require('../../assest/Images/files_iconn.png')}/></div>
                    <div className="item-details">
                      <p className="item-title">Liab. Insurance</p>
                      <p className="item-name">Deane Bunck(Status: Expired)</p>
                    </div>
                  </div>
                  <p className="item-expiry">Exp Date: 27 Aug 2024</p>
                </li>
              </ul>
            </div>
            <div className="view_all_div_">
              <div className="view-all">
                View All
              </div>
              <img src={require('../../assest/Images/rightarroww.png')}/>
            </div>
          </div>
        </div>
        <div style={{ width: "45%" }}>
          <h3 className="section-title">Clinical Compliance</h3>

          <div className="compliance-box hr-compliance">
            <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
              <ul className="compliance-list">
                <li className="compliance-item">
                  <div style={{ display: "flex", width: "70%" }}>
                    <div className="item-icon">  <img src={require('../../assest/Images/files_iconn.png')}/></div>
                    <div className="item-details">
                      <p className="item-title">Liab. Insurance</p>
                      <p className="item-name">Deane Bunck(Status: Expired)</p>
                    </div>
                  </div>
                  <p className="item-expiry">Exp Date: 27 Aug 2024</p>
                </li>
                <li className="compliance-item">
                <div style={{ display: "flex", width: "70%" }}>
                    <div className="item-icon">  <img src={require('../../assest/Images/files_iconn.png')}/></div>
                    <div className="item-details">
                      <p className="item-title">Liab. Insurance</p>
                      <p className="item-name">Deane Bunck(Status: Expired)</p>
                    </div>
                  </div>
                  <p className="item-expiry">Exp Date: 27 Aug 2024</p>
                </li>
                <li className="compliance-item">
                <div style={{ display: "flex", width: "70%" }}>
                    <div className="item-icon">  <img src={require('../../assest/Images/files_iconn.png')}/></div>
                    <div className="item-details">
                      <p className="item-title">Liab. Insurance</p>
                      <p className="item-name">Deane Bunck(Status: Expired)</p>
                    </div>
                  </div>
                  <p className="item-expiry">Exp Date: 27 Aug 2024</p>
                </li>
                <li className="compliance-item">
                <div style={{ display: "flex", width: "70%" }}>
                    <div className="item-icon">  <img src={require('../../assest/Images/files_iconn.png')}/></div>
                    <div className="item-details">
                      <p className="item-title">Liab. Insurance</p>
                      <p className="item-name">Deane Bunck(Status: Expired)</p>
                    </div>
                  </div>
                  <p className="item-expiry">Exp Date: 27 Aug 2024</p>
                </li>
              </ul>
            </div>
            <div className="view_all_div_">
              <div className="view-all">
                View All
              </div>
              <img src={require('../../assest/Images/rightarroww.png')}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Compliancedata;
