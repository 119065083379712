import React, { useEffect, useState } from "react";
import { Dropdowniconn } from "../assest/SvgImages";
import moment from "moment/moment";
import { Switch, FormControlLabel } from '@mui/material';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Box,
  Checkbox,
  ListItemText,
} from '@mui/material';

const DropDownButton = ({
  label,
  placeholder,
  dropdownicon,
  style,
  divstyle,
  lebalstyle,
  options = [],
  onChange, // Add onChange to update form state
  name, // Add name to link with register
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(placeholder);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange({ target: { name, value: option } }); // Trigger onChange to update form state
  };

  return (
    <div className="input-container" style={style}>
      {label ? <label style={lebalstyle}>{label}</label> : null}
      <div
        className="input-wrapper"
        onClick={toggleDropdown}
        style={{
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "rgba(1, 20, 52, 0.5)",
          height: 42,
          borderRadius: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: 20,
          cursor: "pointer",
          ...divstyle,
        }}
      >
        <div
          style={{
            color: "rgba(1, 20, 52, 1)",
            textAlign: "left",
            fontSize: 16,
            padding: 12,
          }}
        >
          {selectedOption || "Select"}{" "}
        </div>
        {dropdownicon && <Dropdowniconn />}
      </div>

      {isOpen && (
        <div
          className="dropdown-options"
          style={{
            position: "absolute",
            backgroundColor: "#fff",
            border: "1px solid rgba(1, 20, 52, 0.5)",
            borderRadius: 5,
            marginTop: 5,
            zIndex: 1,
            width: "100%",
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className="dropdown-option"
              onClick={() => handleOptionClick(option)}
              style={{
                padding: 10,
                cursor: "pointer",
                borderBottom: index !== options.length - 1 ? "1px solid #ddd" : "none",
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default DropDownButton;

export const SearchDropDown = ({
  label,
  placeholder,
  dropdownicon,
  style,
  divstyle,
  lebalstyle,
  options = [],
  register,
  name
}) => {
  return (
    <div className="input-container" style={divstyle}>
      <label style={lebalstyle}>{label}</label>
      <div className="custom-select" style={{ position: 'relative', width: '100%' }}>
        <select
          {...(register ? register(name, { required: true }) : {})}
          className="input-wrapper"
          defaultValue="" // Ensure no default selection
          style={{
            appearance: 'none',
            WebkitAppearance: 'none',
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "rgba(1, 20, 52, 0.5)",
            height: 42,
            width: "100%",
            borderRadius: 5,
            paddingRight: 30,
            cursor: "pointer",
            paddingLeft: 10,
            ...style,
          }}
        >
          <option value="" disabled>{placeholder || "Select option"}</option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
        {dropdownicon && (
          <span style={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            pointerEvents: 'none'
          }}>
            {dropdownicon}
          </span>
        )}
      </div>
    </div>
  );
};


export const DropDownManuCustom = ({
  label,
  placeholder,
  dropdownicon,
  style,
  divstyle,
  lebalstyle,
  options = [],
  register,
  name,
  id,
  onSelect,
  clearErrors, // Added clearErrors prop
  defaultValue,
  disabled
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || "");


  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  
  }, [defaultValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);

  

    const selectedOption = options.find(option => option[id] === value);
    if (selectedOption) {
      clearErrors(name);
      if (onSelect) {
        onSelect(selectedOption);
      }
    }
  };

  return (
    <div className="input-container" style={divstyle}>
      <label htmlFor={id || name} style={lebalstyle}>{label}</label>
      <div className="custom-select" style={{ position: 'relative', width: '100%' }}>
        <select
          id={id || name}
          {...register(name, { required: true })}
          className="input-wrapper"
          value={selectedValue}
          onChange={handleChange}
          disabled={disabled}
          style={{
            appearance: 'none',
            WebkitAppearance: 'none',
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: selectedValue ? "rgba(1, 20, 52, 0.5)" : "",
            height: 42,
            width: "100%",
            borderRadius: 5,
            fontSize: `17px`,
            paddingRight: 30,
            cursor: "pointer",
            paddingLeft: 10,
            ...style,
          }}
        >
          <option value="" disabled>{placeholder || "Select option"}</option>
          {(options || []).map((option) => (
            <option key={option._id} value={option[id]}>
              {option.name}
            </option>
          ))}
        </select>
        {dropdownicon && (
          <span style={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            pointerEvents: 'none'
          }}>
            {dropdownicon && <Dropdowniconn />}
          </span>
        )}
      </div>
    </div>
  );
};

export const DropDownMultiStringSelect = ({
  label,
  placeholder,
  options = [],
  register,
  name,
  id,
  onSelect,
  clearErrors,
  defaultValue,
  disabled,
}) => {
  const [selectedValues, setSelectedValues] = useState(defaultValue || []);

  useEffect(() => {
    if (defaultValue) {
      setSelectedValues(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValues(value);

    const selectedOptions = options
      .filter(option => value.includes(option[id]))
      .map(option => option[id])
      .join(','); // Convert selected values to comma-separated string

    clearErrors(name); // Clear any existing errors

    if (onSelect) {
      onSelect(selectedOptions); // Send comma-separated string to onSelect handler
    }
  };

  return (
    <FormControl variant="outlined" fullWidth disabled={disabled}>
      <div style={{ textAlign: "left" }}>
        <label
          style={{
            textAlign: "left",
            fontFamily: 'Urbanist',
            fontSize: '16px',
            fontWeight: "700",
            color: "#273f5b",
          }}
        >
          {label}
        </label>
      </div>
      <Select
        labelId={`${id || name}-label`}
        multiple
        value={selectedValues}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => {
              const selectedOption = options.find(option => option[id] === value);
              return selectedOption ? (
                <Chip
                  key={value}
                  label={selectedOption.name}
                  style={{ fontFamily: 'Arial', fontSize: '16px' }}
                />
              ) : null;
            })}
          </Box>
        )}
        style={{ marginTop: '8px', fontFamily: 'Arial', fontSize: '16px' }}
      >
        {Array.isArray(options) && options.length > 0 ? (
          options.map((option) => (
            <MenuItem key={option._id} value={option[id]} style={{ fontFamily: 'Arial', fontSize: '16px' }}>
              <Checkbox checked={selectedValues.indexOf(option[id]) > -1} />
              <ListItemText primary={option.name} />
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>{placeholder || "No options available"}</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};


export const DropDownManuCustomMulti = ({
  label,
  placeholder,
  options = [],
  register,
  name,
  id,
  onSelect,
  clearErrors,
  defaultValue,
  disabled,
}) => {
  const [selectedValues, setSelectedValues] = useState(defaultValue || []);

  useEffect(() => {
    if (defaultValue) {
      setSelectedValues(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValues(value);
    const selectedOptions = options.filter(option => value.includes(option[id]));
    clearErrors(name);
    if (onSelect) {
      onSelect(selectedOptions);
    }
  };

  return (
    <FormControl variant="outlined" fullWidth disabled={disabled} >
      <div style={{ textAlign: "left", }}>
        <div style={{ textAlign: "left", fontFamily: 'Urbanist', fontSize: '16px', fontWeight: "700", color: "#273f5b" }}>{label}</div>
      </div>
      <Select
        labelId={`${id || name}-label`}
        multiple
        value={selectedValues}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => {
              const selectedOption = options?.find(option => option[id] === value);
              return selectedOption ? (
                <Chip key={value} label={selectedOption.name} style={{ fontFamily: 'Arial', fontSize: '16px' }} />
              ) : null;
            })}
          </Box>
        )}
        style={{ marginTop: '8px', fontFamily: 'Arial', fontSize: '16px' }}
      >
        {Array.isArray(options) && options.length > 0 ? (
          options.map((option) => (
            <MenuItem key={option._id} value={option[id]} style={{ fontFamily: 'Arial', fontSize: '16px' }}>
              <Checkbox checked={selectedValues.indexOf(option[id]) > -1} />
              <ListItemText primary={option.name} />
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>{placeholder || "No options available"}</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};



const SwitchButton = ({ isChecked, onChange }) => {
  return (
    <label style={styles.switchLabel}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        style={styles.switchInput}
      />
      <span style={styles.slider}></span>
    </label>
  );
};
export const DaysDropDownButton = ({
  label,
  placeholder,
  dropdownicon,
  style,
  divstyle,
  lebalstyle,
  options = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(placeholder);
  const [switchStates, setSwitchStates] = useState(
    options.reduce((acc, option) => {
      acc[option] = false;
      return acc;
    }, {})
  );

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleSwitchChange = (option) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [option]: !prevState[option],
    }));
  };

  return (
    <div className="input-container" style={style}>
      {label ? <label style={lebalstyle}>{label}</label> : <div style={{ height: 25 }} />}
      <div
        className="input-wrapper"
        onClick={toggleDropdown}
        style={{
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "rgba(1, 20, 52, 0.5)",
          height: 42,
          borderRadius: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: 20,
          cursor: "pointer",
          ...divstyle,
        }}
      >
        <div
          style={{
            color: "rgba(1, 20, 52, 1)",
            textAlign: "left",
            fontSize: 16,
            padding: 12,
          }}
        >
          {selectedOption || "Select"}{" "}
        </div>
        {dropdownicon && <Dropdowniconn />}
      </div>

      {isOpen && (
        <div
          className="dropdown-options"
          style={{
            position: "absolute",
            backgroundColor: "#fff",
            border: "1px solid rgba(1, 20, 52, 0.5)",
            borderRadius: 5,
            marginTop: 5,
            zIndex: 1,
            width: "100%", // Align dropdown with the button
          }}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className="dropdown-option"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 15px",
                cursor: "pointer",
                borderBottom: index !== options.length - 1 ? "1px solid #ddd" : "none",
              }}
            >
              <span >{option}</span>

              <FormControlLabel
                control={
                  <Switch
                    checked={options.includes(option)}
                    // onChange={handleChange}
                    onChange={() => handleOptionClick(option)}
                    name="toggle"
                    color="primary"
                  />
                }
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};


const styles = {
  switchLabel: {
    display: 'inline-block',
    width: '34px',
    height: '20px',
    position: 'relative',
  },
  switchInput: {
    opacity: 0,
    width: 0,
    height: 0,
  },
  slider: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#ccc',
    transition: '.4s',
    borderRadius: '20px',
  },
  sliderChecked: {
    backgroundColor: '#ff9800',
  },
  sliderBefore: {
    position: 'absolute',
    content: '""',
    height: '14px',
    width: '14px',
    left: '4px',
    bottom: '3px',
    backgroundColor: 'white',
    transition: '.4s',
    borderRadius: '50%',
  },
};

export const DropDownManu = ({
  label,
  placeholder,
  dropdownicon,
  style,
  divstyle,
  lebalstyle,
  options = [],
  register,
  name
}) => {
  return (
    <div style={divstyle}>
      {label ? <label style={lebalstyle}>{label}</label> : null}
      {/* <label style={lebalstyle}>{label}</label> */}
      <div className="custom-select" style={{ position: 'relative', width: '100%' }}>
        <select
          {...(register ? register(name, { required: true }) : {})}
          className="input-wrapper"
          style={{
            appearance: 'none',
            WebkitAppearance: 'none',
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "rgba(1, 20, 52, 0.5)",
            height: 42,
            width: "100%",
            borderRadius: 5,
            paddingRight: 30,
            cursor: "pointer",
            fontSize: 17,
            paddingLeft: 10,
            ...style,
          }}
        >
          <option value="" disabled>{placeholder || "Select option"}</option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
        {dropdownicon && (
          <span style={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            pointerEvents: 'none'
          }}>
            {dropdownicon && <Dropdowniconn />}
          </span>
        )}
      </div>
    </div>
  );
};

export const DobSelectComponent = ({
  label,
  placeholder,
  dobicon,
  style,
  divstyle,
  lebalstyle,
  register,
  name,
  setValue,
  clearErrors,
}) => {
  const [inputType, setInputType] = useState('text');
  const today = new Date().toISOString().split('T')[0];

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setValue(name, selectedDate);
    if (selectedDate) {
      clearErrors(name);
    }
  };

  return (
    <div style={divstyle}>
      {label && <label style={lebalstyle}>{label}</label>}
      <div className="custom-select" style={{ position: 'relative', width: '100%' }}>
        <input
          type={inputType}
          {...register(name, { required: true })}
          className="input-wrapper"
          style={{
            appearance: 'none',
            WebkitAppearance: 'none',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'rgba(1, 20, 52, 0.5)',
            height: 42,
            width: '100%',
            borderRadius: 5,
            paddingRight: 30,
            cursor: 'pointer',
            fontSize: 17,
            paddingLeft: 10,
            ...style,
          }}
          placeholder={placeholder || 'Select date of birth'}
          onFocus={() => setInputType('date')} // Change to date type on focus
          onBlur={() => setInputType('text')} // Optionally reset to text on blur
          max={today} // Set max date to today
          onChange={handleDateChange} // Handle date change and set it
        />
      </div>
    </div>
  );
};

