
import React from "react";
import AgencySideBar from "../Components/AgencySideBar";
import Navbar from "../../Components/Navbar";
import { useNavigate } from "react-router-dom";
import Inputbox from "../../Components/Inputbox";


const ClientDetails = () => {
  const navigate = useNavigate();
  return (
<div className="dashboard-container">
      <AgencySideBar />
      <div className="dashboard-content">
        <Navbar />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30, width: "100%" }}
        >
          <div className="provider-form-container">
            <div className="form-header">
              <div className="profile-image">
                <img
                  src={require("../../assest/Images/doctorimg.png")}
                  alt="Profile"
                  style={{ height: 130, width: 130, marginLeft: 20 }}
                />
                <div>
                  <h3>John Doe William</h3>
                  <h4 style={{ textAlign: "left", marginTop: 10 }}>
                    Age: 30 years
                  </h4>
                </div>
              </div>
              <button className="close-btn_two" onClick={() => navigate(-1)}>
                <img src={require("../../assest/Images/crossVector.png")} />
              </button>
            </div>

            <div className="input_space_div">
              <Inputbox
                label="Email Address"
                type="text"
                placeholder="example@gmail.com"
              />
              {/* <div style={{ width: "4%" }} /> */}
              <Inputbox
                label="Phone Number"
                type="text"
                placeholder="9876543210"
              />
              {/* <div style={{ width: "4%" }} /> */}
              <Inputbox label="Gender" type="text" placeholder="Male" />
            </div>
            <div className="input_space_div">
            <div style={{ width: "32.5%" }}>
              <Inputbox label="City" type="text" placeholder="City Name" />
            </div>
            {/* <div style={{ width: "2%", }} /> */}
            <div style={{ width: "32.5%" }}>
              <Inputbox label="City" type="text" placeholder="City Name" />
            </div>
            </div>

            <div className="Appointment_Info_txt">Appointment Info:</div>

            <div className="input_space_div">
              <Inputbox
                label="Appointment Type"
                type="text"
                placeholder="Follow-Up"
              />
              <div style={{ width: 40 }} />
              <Inputbox
                label="Appointment Status"
                type="text"
                placeholder="Pending"
              />
              <div style={{ width: 40 }} />
              <Inputbox
                label="Appointment Time"
                type="text"
                placeholder="Today at 01:00 to 1:30"
              />
            </div>
            <div style={{ width: "32.5%" }}>
              <Inputbox
                label="Appointment Reason"
                type="text"
                placeholder="Root canal treatment, cracked teeth crowned, missing teeth repaired with bridges dentures, and implants today."
              />
            </div>
            {/* <div className="form-actions">
              <button type="button" className="decline-btn">
                Decline
              </button>
              <button type="submit" className="Confirm-btn">
                Confirm
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientDetails