
export const AppleIcon = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M50.636 58.0009C46.7592 61.1672 42.5263 60.6673 38.4517 59.1674C34.1398 57.6343 30.1838 57.5677 25.6345 59.1674C19.9379 61.2339 16.9314 60.6339 13.5293 58.0009C-5.77565 41.2364 -2.92737 15.7063 18.9885 14.7731C24.329 15.0064 28.0476 17.2394 31.1728 17.4394C35.8408 16.6395 40.311 14.3398 45.2955 14.6397C51.269 15.0397 55.7787 17.0394 58.7457 20.639C46.4032 26.8715 49.3306 40.5698 60.6445 44.4027C58.3896 49.402 55.4623 54.3681 50.5965 58.0343L50.636 58.0009ZM30.7772 14.5731C30.1838 7.14069 37.3441 1.00813 45.5724 0.408203C46.7196 9.00712 36.3155 15.4063 30.7772 14.5731Z" fill="black"/>
</svg>

  )
}


export const Notificationsicon  = () => {
  return (
<svg width="30" height="30" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_81_5125)">
<path fillRule="evenodd" clipRule="evenodd" d="M10.4167 18.7503C10.4167 14.8826 11.9532 11.1733 14.6881 8.43835C17.423 5.70345 21.1323 4.16699 25.0001 4.16699C28.8678 4.16699 32.5771 5.70345 35.3121 8.43835C38.047 11.1733 39.5834 14.8826 39.5834 18.7503V26.592L43.3792 34.1837C43.554 34.5331 43.6365 34.9214 43.6189 35.3116C43.6014 35.7019 43.4844 36.0812 43.2789 36.4135C43.0735 36.7459 42.7866 37.0201 42.4454 37.2104C42.1041 37.4006 41.7199 37.5004 41.3293 37.5003H33.0709C32.6075 39.2883 31.5635 40.8718 30.1027 42.0023C28.6419 43.1327 26.8472 43.746 25.0001 43.746C23.153 43.746 21.3582 43.1327 19.8974 42.0023C18.4367 40.8718 17.3927 39.2883 16.9292 37.5003H8.67091C8.28024 37.5004 7.89604 37.4006 7.5548 37.2104C7.21356 37.0201 6.92662 36.7459 6.72121 36.4135C6.51581 36.0812 6.39878 35.7019 6.38122 35.3116C6.36367 34.9214 6.44618 34.5331 6.62091 34.1837L10.4167 26.592V18.7503ZM21.3917 37.5003C21.7575 38.1337 22.2834 38.6596 22.9168 39.0253C23.5502 39.391 24.2687 39.5835 25.0001 39.5835C25.7314 39.5835 26.4499 39.391 27.0833 39.0253C27.7167 38.6596 28.2427 38.1337 28.6084 37.5003H21.3917ZM25.0001 8.33366C22.2374 8.33366 19.5879 9.43113 17.6344 11.3846C15.6809 13.3381 14.5834 15.9877 14.5834 18.7503V26.592C14.5834 27.2385 14.4329 27.8762 14.1438 28.4545L11.7063 33.3337H38.2959L35.8584 28.4545C35.5686 27.8763 35.4174 27.2387 35.4167 26.592V18.7503C35.4167 15.9877 34.3193 13.3381 32.3658 11.3846C30.4123 9.43113 27.7628 8.33366 25.0001 8.33366Z" fill="#011434"/>
<circle cx="36.1016" cy="10.002" r="9.375" fill="#EF452F"/>
</g>
<defs>
<clipPath id="clip0_81_5125">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>

  )}

export const Dropdowniconn  = () => {
  return (
<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.7">
<path d="M1.08008 8.02344L8.58008 15.5234L16.0801 8.02344" stroke="#011434" strokeWidth="1.5" strokeLinecap="round"/>
</g>
</svg>

  )}

  export const Eyecloseicon  = () => {
    return (
      <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7">
      <path d="M20 14.8784C21.3082 13.3767 22 12.0449 22 12.0449C22 12.0449 18.3636 5.04492 12 5.04492C11.6588 5.04492 11.3254 5.06505 11 5.10314C10.6578 5.1432 10.3244 5.20314 10 5.28044M12 9.04492C12.3506 9.04492 12.6872 9.10508 13 9.21563C13.8524 9.51691 14.528 10.1925 14.8293 11.0449C14.9398 11.3577 15 11.6943 15 12.0449M3 3.04492L21 21.0449M12 15.0449C11.6494 15.0449 11.3128 14.9848 11 14.8742C10.1476 14.5729 9.47198 13.8973 9.1707 13.0449C9.11386 12.8841 9.07034 12.717 9.04147 12.5449M4.14701 9.04492C3.83877 9.38943 3.56234 9.72734 3.31864 10.0449C2.45286 11.1732 2 12.0449 2 12.0449C2 12.0449 5.63636 19.0449 12 19.0449C12.3412 19.0449 12.6746 19.0248 13 18.9867" stroke="#011434" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      </svg>
      
  )}

  export const Eyeopenicon = () => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7">
      <path d="M23.0139 11.7722C22.982 11.6991 22.202 9.96938 20.4601 8.2275C18.8439 6.61313 16.067 4.6875 12.0001 4.6875C7.93323 4.6875 5.15636 6.61313 3.54011 8.2275C1.79823 9.96938 1.01823 11.6962 0.986357 11.7722C0.954306 11.8442 0.937744 11.9221 0.937744 12.0009C0.937744 12.0798 0.954306 12.1577 0.986357 12.2297C1.01823 12.3019 1.79823 14.0316 3.54011 15.7734C5.15636 17.3878 7.93323 19.3125 12.0001 19.3125C16.067 19.3125 18.8439 17.3878 20.4601 15.7734C22.202 14.0316 22.982 12.3047 23.0139 12.2297C23.0459 12.1577 23.0625 12.0798 23.0625 12.0009C23.0625 11.9221 23.0459 11.8442 23.0139 11.7722ZM12.0001 18.1875C9.05823 18.1875 6.48948 17.1169 4.36417 15.0066C3.47327 14.1211 2.71936 13.1078 2.12729 12C2.7192 10.8924 3.47314 9.87936 4.36417 8.99438C6.48948 6.88313 9.05823 5.8125 12.0001 5.8125C14.942 5.8125 17.5107 6.88313 19.636 8.99438C20.5271 9.87936 21.281 10.8924 21.8729 12C21.2757 13.1447 18.2814 18.1875 12.0001 18.1875ZM12.0001 7.6875C11.1472 7.6875 10.3134 7.94042 9.60421 8.41429C8.89502 8.88815 8.34228 9.56167 8.01588 10.3497C7.68947 11.1377 7.60407 12.0048 7.77047 12.8413C7.93687 13.6779 8.3476 14.4463 8.95071 15.0494C9.55382 15.6525 10.3222 16.0632 11.1588 16.2296C11.9953 16.396 12.8624 16.3106 13.6504 15.9842C14.4384 15.6578 15.112 15.1051 15.5858 14.3959C16.0597 13.6867 16.3126 12.8529 16.3126 12C16.3111 10.8567 15.8563 9.76067 15.0479 8.95225C14.2394 8.14382 13.1434 7.68899 12.0001 7.6875ZM12.0001 15.1875C11.3697 15.1875 10.7534 15.0006 10.2292 14.6503C9.70505 14.3001 9.2965 13.8022 9.05524 13.2198C8.81399 12.6374 8.75086 11.9965 8.87385 11.3781C8.99684 10.7598 9.30042 10.1919 9.7462 9.7461C10.192 9.30032 10.7599 8.99674 11.3783 8.87375C11.9966 8.75076 12.6375 8.81388 13.2199 9.05513C13.8023 9.29639 14.3002 9.70494 14.6504 10.2291C15.0007 10.7533 15.1876 11.3696 15.1876 12C15.1876 12.8454 14.8518 13.6561 14.254 14.2539C13.6562 14.8517 12.8455 15.1875 12.0001 15.1875Z" fill="#011434"/>
      </g>
      </svg>
      
    )}

//     export const  = () => {
//       return (
      
//       )}