import React, { useEffect, useState } from "react";
import DropDownButton, {
  DaysDropDownButton,
  DropDownManuCustom,
  DropDownManuCustomMulti,
} from "../../Components/DropDownButton";
import Inputbox from "../../Components/Inputbox";
import { ClinicProfileGet, ClinicUpdate, GetCategory } from "../../../api/helper";
import { setUserDetails } from "../../../redux/cookiesReducer";
import { useDispatch } from "react-redux";
import { Store } from "../../../redux";
import { Box, Modal } from "@mui/material";
import { Dropdowniconn } from "../../assest/SvgImages";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ShowToast } from "../../../helpers/ToastService";
import { clearEmptyData } from "../../../helpers/helper";

const days = [
  { name: "Monday" },
  { name: "Tuesday" },
  { name: "Wednesday" },
  { name: "Thursday" },
  { name: "Friday" },
  { name: "Saturday" },
  { name: "Sunday" },
];
const validationSchema = yup.object().shape({

  // 
});
const ClinicalSettings = () => {
  const [profiledata, setUserData] = useState(null);
  const [showavailibleday, setShowavailibleday] = useState(false);
  const [OpeningDays, setAvailableDays] = useState([]);
  const [specialtydata, setspecialtydata] = useState([]); 
  const dispatch = useDispatch();
  const [activeImage, setactiveImage] = useState(false);
  const [defcat , setDefCat]=useState([])
console.log(defcat,"defcat====")
  const handleTerm = () => {
    setactiveImage(!activeImage);
  };
  const [activePrivacy, setactivePrivacy] = useState(false);
  const handlePrivacy = () => {
    setactivePrivacy(!activePrivacy);
  };
  useEffect(() => {
    getSpecialty();
  }, []);
  const getSpecialty = async () => {
    try {
      const resposne = await GetCategory();
      setspecialtydata(resposne?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };
  const getprofile = async (data) => {
    try {
      const response = await ClinicProfileGet();
      console.log(response.data?.data);
      setUserData(response.data.data);
      Store.dispatch(setUserDetails(response?.data?.data));
      setAvailableDays(response.data.data.OpeningDays);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getprofile();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: "70vh", // Updated height to 70% of the viewport height
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    overflowY: "auto", // Enables scrolling inside the modal box
  };
  const handleClose = () => {
    setShowavailibleday(false);
  };
  const handleavailibledays = () => {
    setShowavailibleday(true);
  };
  const handleToggle = (dayName) => {
    setAvailableDays((prev) => {
      const isActive = prev.some((day) => day.day === dayName);
      if (isActive) {
        return prev.filter((day) => day.day !== dayName);
      } else {
        return [
          ...prev,
          { day: dayName, shifts: [{ startTime: "10:00", endTime: "18:00" }] },
        ];
      }
    });
  };

  const handleTimeChange = (dayName, newStartTime, newEndTime) => {
    setAvailableDays((prev) =>
      prev?.map((day) => {
        if (day.day === dayName) {
          return {
            ...day,
            shifts: [{ startTime: newStartTime, endTime: newEndTime }],
          };
        }
        return day;
      })
    );
  };
  const handleSave = () => {
    console.log(OpeningDays);
    handleClose();
  };

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  
  
  useEffect(() => {
    if (profiledata) {
      const categoryIds = profiledata?.categoryId?.map((e) => e._id) || [];
      console.log(categoryIds,'===categoryIds')
      setDefCat(categoryIds); 
     
      reset({
        OpeningDays:profiledata.OpeningDays ||"",
        companyName: profiledata.companyName || "",
        Specialty: profiledata.Specialty || "",
        NPI: profiledata.NPI || "",
        NPIType: profiledata.NPIType || "",
        ClinicType: profiledata.ClinicType || "",
        LicenseNumber:profiledata.LicenseNumber ||"",
        PrivacyPolicy:profiledata?.PrivacyPolicy||"",
        TermsConditions:profiledata?.TermsConditions || " ",
        categoryId: categoryIds,

      });
    }
  }, [profiledata, reset]);
  useEffect(() => {
    reset(profiledata);
  }, [profiledata, reset]);
  const onSubmit = async (data) => {
    const newData = clearEmptyData(data); 
    console.log(newData, "newData=====");
  
    try {
      const response = await ClinicUpdate(newData); 
      console.log(response?.data?.data, "response------");
      ShowToast(response.data.message, "success");
      getprofile("msg"); 
    } catch (error) {
      console.log(error, '===error');
    }
  };
  // console.log(profiledata,"profiledata====")
  const updateProfile = async (data) => {
    try {
      // if (OpeningDays) {
      //   data.OpeningDays = OpeningDays;
      // }
      const newData = clearEmptyData(data)
      console.log(newData,"newData=====")
      const response = await ClinicUpdate(newData)
      console.log(response?.data?.data,"response------")
      ShowToast(response.data.message, "success")
      getprofile("msg")
    } catch (error) {
      console.log(error, '===eror')
    }
  }
  const handleSelect = (selectedOptions) => {
    console.log("selectedOptions:", selectedOptions); // This will log the value
    let ids = Array.isArray(selectedOptions) ? selectedOptions.map((e) => e?._id) : [];
    setDefCat(ids);
    setValue("categoryId", ids);
  };
      
  return (
    <div>
        <form onSubmit={handleSubmit(onSubmit)}>
      <label>Opening Days</label>
      <div className="Available_Days_divv" onClick={handleavailibledays}>
        <div>
          {" "}
          {(OpeningDays?.length > 0 && (
            <div className="selected-days">
              {OpeningDays?.map((e) => e.day).join(", ")}
            </div>
          )) ||
            ","}{" "}
        </div>
        <Dropdowniconn />
      </div>
      {/* <div className="input-row" style={{ gap: "6%" }}>
        <Inputbox
          label="License Number"
          type="Number"
          placeholder="asdfgh789654"
        />
        <Inputbox
          label={"Company Name"}
          type="text"
          placeholder="Enter Company Name"
        />
      </div> */}
      <Inputbox
        label={"Company Name"}
        type="text"
        placeholder="Enter Company Name"
        name="companyName"
        register={register}
      />
      <div className="input-row" style={{ gap: "6%" }}>
        <Inputbox label="NPI" type="text" placeholder="...." name="NPI"  register={register}/>
        <Inputbox label={"NPI Type"} type="text" placeholder="...." name="NPIType"  register={register}/>
      </div>
      <div className="input-row" style={{ gap: "6%" }}>
        <Inputbox label="Clinic Type" type="text" placeholder="Dental Clinic" name="ClinicType"  register={register}/>
        
        <DropDownManuCustomMulti
                  label="Specialty"
                   placeholder="Select option"
                  options={specialtydata &&
                    specialtydata?.map((e) => ({
                      _id: e._id,
                      name: e.name
                    }))
                  }
                  name="categoryId"
                  register={register}
                  id="_id"
                  clearErrors={clearErrors}
                  onSelect={handleSelect}
                  defaultValue={defcat}
                />
      </div>

      <div className="space-div12" style={{ width: "100%" }}>
        <div
          onClick={handlePrivacy}
          className={`${
            activePrivacy
              ? "Privacy_div_display_text"
              : "Privacy_div_display_active_text"
          }`}
          style={{ width: "47%" }}
        >
          <div className="display_text_image">
            <p className="Privacty_Setting_text">Privacy Policy</p>
            {activePrivacy ? (
              <img
                className="image_arrow_setting"
                src={require("../../assest/Images/Upper_Arrow.png")}
                alt="Up Arrow"
              />
            ) : (
              <img
                className="image_arrow_setting"
                src={require("../../assest/Images/Down_Arrow.png")}
                alt="Down Arrow"
              />
            )}
          </div>
          {activePrivacy ? (
            <div>
              {/* <p className="Last_text">Last update: 01/03/2024</p> */}
              <p className="But_text" style={{marginTop:10}}>
                But I must explain to you how all this mistaken idea of
                denouncing pleasure and praising pain was born and I will give
                you a complete account of the system
              </p>
            </div>
          ) : null}
        </div>

        <div
          onClick={handleTerm}
          className={`${
            activeImage
              ? "Privacy_div_display_text"
              : "Privacy_div_display_active_text"
          }`}
          style={{ width: "47%" }}
        >
          <div className="display_text_image">
            <p className="Privacty_Setting_text">Terms & Conditions</p>
            {activeImage ? (
              <img
                className="image_arrow_setting"
                src={require("../../assest/Images/Upper_Arrow.png")}
                alt="Up"
              />
            ) : (
              <img
                className="image_arrow_setting"
                src={require("../../assest/Images/Down_Arrow.png")}
                alt="Down"
              />
            )}
          </div>
          {activeImage ? (
            <div>
              {/* <p className="Last_text">Last update: 01/03/2024</p> */}
              <p className="But_text" style={{marginTop:10}}>
                But I must explain to you how all this mistaken idea of
                denouncing pleasure and praising pain was born and I will give
                you a complete account of the system
              </p>
            </div>
          ) : null}
        </div>
      </div>

      <div className="form-actions-container">
        <button className="form-cancel-btn">Cancel</button>
        <button className="form-save-btn" type="submit">Save</button>
      </div>
      </form>
      <Modal
        open={showavailibleday}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>Select your opening days</h3>
          {days?.map((day) => {
            const isActive = OpeningDays.some((d) => d.day === day.name);
            const dayData = OpeningDays.find((d) => d.day === day.name);
            const startTime = dayData ? dayData.shifts[0].startTime : "10:00";
            const endTime = dayData ? dayData.shifts[0].endTime : "18:00";

            return (
              <DayItem
                key={day.name}
                day={day}
                isActive={isActive}
                onToggle={() => handleToggle(day.name)}
                startTime={startTime}
                endTime={endTime}
                onTimeChange={handleTimeChange}
              />
            );
          })}
          <div className="logout-buttons" style={{ marginTop: 20 }}>
            <button className="cancel-button" onClick={handleClose}>
              Cancel
            </button>
            <button className="confirm-button" onClick={handleSave}>
              Save
            </button>
          </div>
        </Box>
      </Modal>
      
    </div>
  );
};

export default ClinicalSettings;
const DayItem = ({
  day,
  isActive,
  onToggle,
  startTime,
  endTime,
  onTimeChange,
}) => {
  return (
    <div className={`unique-day-item ${isActive ? "unique-active-day" : ""}`}>
      <div className="unique-day-header">
        <span>{day.name}</span>
        <label className="unique-switch">
          <input type="checkbox" checked={isActive} onChange={onToggle} />
          <span className="unique-slider unique-round"></span>
        </label>
      </div>

      {isActive && (
        <div className="unique-time-selector">
          <div className="unique-time-group">
            <div className="unique-time-input-wrapper">
              <input
                type="time"
                className="unique-time-input"
                value={startTime}
                onChange={(e) =>
                  onTimeChange(day.name, e.target.value, endTime)
                }
              />
            </div>
            <div className="unique-time-input-wrapper">
              <input
                type="time"
                className="unique-time-input"
                value={endTime}
                onChange={(e) =>
                  onTimeChange(day.name, startTime, e.target.value)
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
