
import './App.css';
import ChooseRole from './SourceCode/AuthStack/ChooseRole'
import LoginScreen from './SourceCode/AuthStack/LoginPage'
import SignUp from './SourceCode/AuthStack/SignUp'
import Dashboard from './SourceCode/Components/Dashboard';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Appointments from './SourceCode/Components/Appointments';
import Financial from './SourceCode/Components/Financial';
import Messages from './SourceCode/Components/Messages';
import Patients from './SourceCode/Components/Patients';
import Report from './SourceCode/Components/Report';
import MyProfile from './SourceCode/Components/MyProfile';
import Setting from './SourceCode/Components/Setting';
import HelpSupport from './SourceCode/Components/HelpSupport';
import PatientChart from './SourceCode/Components/PatientChart';
import { SignupPatient } from './SourceCode/PatientFlow/PatientAuthStack';
import {
  AgencyReports, Appointmentclinic, DashboradScreen,
  FinancialagaencyScreen, Message, MyProfileAgency,
  Patientsagency, ProvidersScreen, Settingss, SupportHelp
} from './SourceCode/AgencyFlow/AgencyScreens';
import AgencyRegistration from './SourceCode/AgencyFlow/AgencyAuth/AgencyRegistration';
import AddProvider from './SourceCode/AgencyFlow/AgencyScreens/AddProvider';
import Dashboradpatient from './SourceCode/PatientFlow/PatientScreens/Dashboradpatient';
import Appointmentspatients from './SourceCode/PatientFlow/PatientScreens/Appointmentspatients';
import Financialpatient from './SourceCode/PatientFlow/PatientScreens/Financialpatient';
import Messagespatient from './SourceCode/PatientFlow/PatientScreens/Messagespatient';
import PatientProfile from './SourceCode/PatientFlow/PatientScreens/PatientProfile';
import MyChart from './SourceCode/PatientFlow/PatientScreens/MyChart';
import Settingspatient from './SourceCode/PatientFlow/PatientScreens/Settingspatient';
import HelpSupportpatient from './SourceCode/PatientFlow/PatientScreens/HelpSupportpatient';
import AddNewUser from './SourceCode/AgencyFlow/AgencyScreens/AddNewUser';
import DoctorDetailsclinic from './SourceCode/AgencyFlow/AgencyScreens/DoctorDetailsclinic';
import PatientDetails from './SourceCode/AgencyFlow/AgencyScreens/PatientDetails';
import InsurancePatient from './SourceCode/PatientFlow/PatientScreens/InsurancePatient';
import PrivatePayPatient from './SourceCode/PatientFlow/PatientScreens/PrivatePayPatient';
import SubscriptionPlan from './SourceCode/PatientFlow/PatientScreens/SubscriptionPlan';
import Logout from "./SourceCode/AgencyFlow/AgencyScreens/Logout";
import ClientDetails from "./SourceCode/AgencyFlow/AgencyScreens/ClientDetails";
import PatientDetailsDoctor from './SourceCode/Components/PatientDetailsDoctor';
import LogoutPatient from './SourceCode/PatientFlow/PatientScreens/LogoutPatient';
import LogoutDoctor from './SourceCode/Components/LogoutDoctor';
import { Provider, useSelector } from 'react-redux';
import { Store } from './redux';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


import ReportContentAgency from './SourceCode/AgencyFlow/AgencyScreens/ReportContentAgency';
import CalenderComponent from './SourceCode/PatientFlow/PatientScreens/CalenderComponent';
import AddNewClinic from './SourceCode/Components/AddNewClinic';
import DoctorClinicDetails from './SourceCode/Components/DoctorClinicDetails';
import BookAppointments from './SourceCode/PatientFlow/PatientScreens/BookAppointments';
import Loader from './SourceCode/AgencyFlow/Components/Loader';
import PatientsDetails from './SourceCode/AgencyFlow/AgencyScreens/PatientsDetails';
import ForgetPassword from './SourceCode/AuthStack/ForgetPassword';
import ResetPassword from './SourceCode/AuthStack/ResetPassword';
import Newpasswordsuccess from './SourceCode/AuthStack/Newpasswordsuccess';
import Planpreview from './SourceCode/AgencyFlow/AgencyScreens/Planpreview';


function App() {

  return (
    <Provider store={Store}>
    <AppContent />
  </Provider>

  );
}
{/* <ToastContainer
position="top-center"
className="custom-toast-container"
bodyClassName="custom-toast-body"
/> */}

function AppContent() {
  const { isLoading } = useSelector((store) => store.sliceReducer); // Move useSelector here

  return (
    <>
      {isLoading && <Loader />}
      <Router>
        <div className="App">
        <Routes>
            
            <Route path="/" element={<LoginScreen />} />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/forget" element={<ForgetPassword />} />
            <Route path="/ResetPassword" element={<ResetPassword />} />
            <Route path="/success" element={<Newpasswordsuccess />} />
            {/* doctor  */}
            <Route path="/signup" element={<SignUp />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/appointments" element={<Appointments />} />
            <Route path="/financial" element={<Financial />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/patients" element={<Patients />} />
            <Route path="/report" element={<Report />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/profile" element={<MyProfile />} />
            <Route path="/helpSupport" element={<HelpSupport />} />
            <Route path="/patientChart" element={<PatientChart />} />
            <Route path='/PatientDetailsDoctor' element={<PatientDetailsDoctor/>}/>
            <Route path='/LogoutDoctor' element={<LogoutDoctor/>}/>      
            <Route path='/AddNewClinic' element={<AddNewClinic/>}/>  
            <Route path='/DoctorClinicDetails' element={<DoctorClinicDetails/>}/>            

            {/* patient  */}
            <Route path="/signupPatient" element={<SignupPatient />} />
            <Route path="/agencyRegistration" element={<AgencyRegistration />} />
            <Route path="/dashboradScreen" element={<DashboradScreen />} />
            <Route path="/ProvidersScreen" element={<ProvidersScreen />} />
            {/* DashboradScreen */}
            <Route path="/Patientsagency" element={<Patientsagency />} />
            <Route path="/AgencyReports" element={<AgencyReports />} />
            <Route path="/Appointmentclinic" element={<Appointmentclinic />} />
            <Route path="/Message" element={<Message />} />
            <Route path="/SupportHelp" element={<SupportHelp />} />
            <Route path="/MyProfileAgency" element={<MyProfileAgency />} />
            <Route path="/FinancialagaencyScreen" element={<FinancialagaencyScreen />} />
            <Route path="/Settingss" element={<Settingss />} />
            <Route path="/AddProvider" element={<AddProvider />} />
            <Route path='/InsurancePatient' element={<InsurancePatient />} />
            <Route path='/PrivatePayPatient' element={<PrivatePayPatient />} />
            <Route path='/SubscriptionPlan' element={<SubscriptionPlan />} />
            <Route path='/Logout' element={<Logout />} />
            <Route path='/ClientDetails' element={<ClientDetails />} />
            <Route path='/PatientDetailsDoctor' element={<PatientDetailsDoctor />} />
            <Route path='/LogoutPatient' element={<LogoutPatient />} />
            <Route path='/LogoutDoctor' element={<LogoutDoctor />} />
            {/* MyChart */}
            <Route path="/InsurancePatient" element={<InsurancePatient />} />
            <Route path="/PrivatePayPatient" element={<PrivatePayPatient />} />
            <Route path="/SubscriptionPlan" element={<SubscriptionPlan />} />
            <Route path='/LogoutPatient' element={<LogoutPatient/>}/>
            <Route path="/Dashboradpatient" element={<Dashboradpatient />} />
            <Route path="/Appointmentspatients" element={<Appointmentspatients />} />
            <Route path="/Financialpatient" element={<Financialpatient />} />
            <Route path="/Messagespatient" element={<Messagespatient />} />
            <Route path="/PatientProfile" element={<PatientProfile />} />
            <Route path="/MyChart" element={<MyChart />} />
            <Route path="/Settingspatient" element={<Settingspatient />} />
            <Route path="/HelpSupportpatient" element={<HelpSupportpatient />} />
            
            {/* agency */}
            <Route path="/agencyRegistration" element={<AgencyRegistration />} />
            <Route path="/dashboradScreen" element={<DashboradScreen />} />
            <Route path="/AddNewUser" element={<AddNewUser />} />
            <Route path="/AddProvider" element={<AddProvider />} />
            <Route path="/AgencyReports" element={<AgencyReports />} />
            <Route path="/Appointmentclinic" element={<Appointmentclinic />} />
            <Route path='/ClientDetails' element={<ClientDetails/>}/>
            <Route path="/DoctorDetailsclinic" element={<DoctorDetailsclinic />} />
            <Route path="/FinancialagaencyScreen" element={<FinancialagaencyScreen />} />
            <Route path="/Logout" element={<Logout />} />
            <Route path="/Patientsagency" element={<Patientsagency />} />
            <Route path="/Message" element={<Message />} />
            <Route path="/MyProfileAgency" element={<MyProfileAgency />} />
            <Route path="/PatientDetails" element={<PatientDetails />} />
            <Route path="/ProvidersScreen" element={<ProvidersScreen />} />
            <Route path="/ReportContentAgency" element={<ReportContentAgency />} />
            <Route path="/Settingss" element={<Settingss />} />
            <Route path="/SupportHelp" element={<SupportHelp />} />

            
            <Route path="/CalenderComponent" element={<CalenderComponent />} />
            <Route path="/BookAppointments" element={<BookAppointments />} />
            <Route path="/PatientsDetails" element={<PatientsDetails />} />
            
            <Route path="/Planpreview" element={<Planpreview />} />
          </Routes>
        </div>
      </Router>
      <ToastContainer
        position="top-center"
        className="custom-toast-container"
        bodyClassName="custom-toast-body"
      />
    </>
  );
}


export default App;
