import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import DropDownButton, { DropDownManu } from "./DropDownButton";
import CalenderDoctor from "./CalenderDoctor";
import { useNavigate } from "react-router-dom";

const Appointments = () => {
  const [view, setView] = useState("list");
  const [date, setDate] = useState(new Date());
  const navigate= useNavigate()
  const appointments = {
    "2024-08-16": [
      {
        time: "10:00 AM",
        name: "Gabe Woodward",
        gender: "Male",
        age: 30,
        type: "Private Pay",
      },
      {
        time: "11:00 AM",
        name: "Academic",
        gender: "Female",
        age: 35,
        type: "Subscribe",
      },
      {
        time: "12:00 PM",
        name: "Samuel O'Brian",
        gender: "Male",
        age: 27,
        type: "Insurance",
      },
    ],
    "2024-08-19": [
      {
        time: "10:00 AM",
        name: "Gabe Woodward",
        gender: "Male",
        age: 30,
        type: "Private Pay",
      },
    ],
    "2024-08-20": [
      {
        time: "11:00 AM",
        name: "Academic",
        gender: "Female",
        age: 35,
        type: "Subscribe",
      },
      {
        time: "12:00 PM",
        name: "Samuel O'Brian",
        gender: "Male",
        age: 27,
        type: "Insurance",
      },
    ],
  };
  const handleDateChange = (date) => {
    setDate(date);
  };
  const handleViewChange = (newView) => {
    setView(newView);
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <Navbar 
        onClick= {()=>{navigate("/profile")}}
        />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30 }}
        >
          <div className="container" style={{ width: "100%" }}>
            <div className="tab-buttonstwo">
              <button
                className={`tab-buttontwo ${view === "list" ? "active" : ""}`}
                onClick={() => handleViewChange("list")}
              >
                Appointment List
              </button>
              <button
                className={`tab-buttontwo ${
                  view === "calendar" ? "active" : ""
                }`}
                onClick={() => handleViewChange("calendar")}
              >
                Calendar View
              </button>
            </div>
            <div style={{ width: "100%" }}>
              {view === "list" ? <AppointmentListView /> : <CalendarView />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointments;

const AppointmentListView = () => {
  return (
    <div>
      <h2 style={{ textAlign: "left" }}>List View of Appointments</h2>
      <div className="filters" style={{ marginBottom: 40 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <DropDownManu
            label="Clients"
            divstyle={{ width: "22.5%" }}
            options={[
              { name: "All Providers" },
              { name: "Procedure" },
              { name: "Encounters" },
            ]}
            name="gender"
            dropdownicon
          />
          <DropDownManu
            label="CPT Code"
            divstyle={{ width: "22.5%" }}
            options={[
              { name: "All Providers" },
              { name: "Procedure" },
              { name: "Encounters" },
            ]}
            name="gender"
            dropdownicon
          />
          <DropDownManu
            label="Appointment Status"
            divstyle={{ width: "22.5%" }}
            options={[
              { name: "All Providers" },
              { name: "Procedure" },
              { name: "Encounters" },
            ]}
            name="gender"
            dropdownicon
          />
          <DropDownManu
            label="Location Type"
            divstyle={{ width: "22.5%" }}
            options={[
              { name: "All Providers" },
              { name: "Procedure" },
              { name: "Encounters" },
            ]}
            name="gender"
            dropdownicon
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            marginTop: 20,
          }}
        >
          <DropDownManu
            label="Appointments Type"
            divstyle={{ width: "22.5%" }}
            options={[
              { name: "All Providers" },
              { name: "Procedure" },
              { name: "Encounters" },
            ]}
            name="gender"
            dropdownicon
          />
          <div style={{ width: 40 }} />
          <DropDownManu
            label="Appointments Reason"
            divstyle={{ width: "22.5%" }}
            options={[
              { name: "All Providers" },
              { name: "Procedure" },
              { name: "Encounters" },
            ]}
            name="gender"
            dropdownicon
          />
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Client Name</th>
            <th>Appointment Type</th>
            <th>Duration</th>
            <th>CPT Code</th>
            <th>Pay Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Unavailable</td>
            <td>Evens Michel</td>
            <td>Follow-up</td>
            <td>60 m</td>
            <td>99214</td>
            <td>Insurance</td>
            <td>
              <button className="actions-button">Actions</button>
            </td>
          </tr>
          <tr>
            <td>Unavailable</td>
            <td>Evens Michel</td>
            <td>Follow-up</td>
            <td>60 m</td>
            <td>99214</td>
            <td>Insurance</td>
            <td>
              <button className="actions-button">Actions</button>
            </td>
          </tr>
          <tr>
            <td>Available</td>
            <td>Evens Michel</td>
            <td>Follow-up</td>
            <td>60 m</td>
            <td>99214</td>
            <td>Insurance</td>
            <td>
              <button className="actions-button">Actions</button>
            </td>
          </tr>
          {/* Add more rows as needed */}
        </tbody>
      </table>
    </div>
  );
};

const CalendarView = () => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div className="schedule-app" style={{ width: "68%" }}>
        <header className="schedule-header">
          <h1 className="schedule-title">August 2024</h1>
          <div className="schedule-controls">
            {/* <button className="New_Payment-button">New Payment</button> */}
            {/* <button className="New_Payment-button">Schedule a Training</button> */}
            <p className="New_Payment-button">New Payment</p>
          </div>
        </header>

        <div className="schedule-main-content">
          <div className="schedule-left-panel">
            <div className="schedule-date-picker">
              <div className="schedule-week-days">
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                  (day, index) => (
                    <div
                      className={`schedule-day ${
                        index === 3 ? "schedule-day-selected" : ""
                      }`}
                      key={index}
                    >
                      <span className="schedule-day-number">{13 + index}</span>
                      <span className="schedule-day-name">{day}</span>
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="schedule-appointment-list">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTopWidth: 1,
                  borderStyle: "solid",
                  borderColor: "rgba(1, 20, 52, 1)",
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                  paddingTop: 20,
                }}
              >
                <h3
                  className="schedule-section-title"
                  style={{
                    textAlign: "left",
                    marginTop: 20,
                    marginBottom: 20,
                    color: "rgba(1, 20, 52, 1)",
                  }}
                >
                  Today
                </h3>
                <div style={{ fontSize: 25, color: "#BC9C22" }}>+</div>
              </div>
              <div className="schedule-appointment">
                <div>10:00Am</div>
                <div
                  style={{
                    display: "flex",
                    gap: 15,
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={require("../assest/Images/doctorimg.png")}
                    style={{ height: 30, width: 30 }}
                  />
                  <p className="Gabe_Woodward_text">Gabe Woodward</p>
                  <img src={require("../assest/Images/rightarrow.png")} />
                </div>
                <div>Male</div>
                <div>30 Years</div>
                <div>Private Pay</div>
                <div>
                  {" "}
                  <img src={require("../assest/Images/rightarrow.png")} />
                </div>
              </div>

              <div className="schedule-appointment">
                <div>10:00Am</div>
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    gap: 15,
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={require("../assest/Images/doctorimg.png")}
                    style={{ height: 30, width: 30 }}
                  />
                  <p className="Gabe_Woodward_text">Academic</p>
                  {/* <img src={require('../assest/Images/rightarrow.png')}/> */}
                </div>
                <div>Male</div>
                <div>30 Years</div>
                <div>Private Pay</div>
                <div>
                  {" "}
                  <img src={require("../assest/Images/rightarrow.png")} />
                </div>
              </div>

              <div className="schedule-appointment">
                <div>10:00Am</div>
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    gap: 15,
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={require("../assest/Images/doctorimg.png")}
                    style={{ height: 30, width: 30 }}
                  />
                  <p className="Gabe_Woodward_text">Samuel O'brien</p>
                </div>
                <div>Male</div>
                <div>30 Years</div>
                <div>Private Pay</div>
                <div>
                  {" "}
                  <img src={require("../assest/Images/rightarrow.png")} />
                </div>
              </div>

              {/* <h2 className="schedule-section-title">August 18</h2> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTopWidth: 1,
                  borderStyle: "solid",
                  borderColor: "rgba(1, 20, 52, 1)",
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                  paddingTop: 20,
                }}
              >
                <h3
                  className="schedule-section-title"
                  style={{
                    textAlign: "left",
                    marginTop: 20,
                    marginBottom: 20,
                    color: "rgba(1, 20, 52, 1)",
                  }}
                >
                  August 18
                </h3>
                <div style={{ fontSize: 25, color: "#BC9C22" }}>+</div>
              </div>
              <p
                className="schedule-no-appointments"
                style={{ textAlign: "left" }}
              >
                No Appointments
              </p>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTopWidth: 1,
                  borderStyle: "solid",
                  borderColor: "rgba(1, 20, 52, 1)",
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                  paddingTop: 20,
                }}
              >
                <h3
                  className="schedule-section-title"
                  style={{
                    textAlign: "left",
                    marginTop: 20,
                    marginBottom: 20,
                    color: "rgba(1, 20, 52, 1)",
                  }}
                >
                  August 19
                </h3>
                <div style={{ fontSize: 25, color: "#BC9C22" }}>+</div>
              </div>
              <div className="schedule-appointment">
                <div>10:00Am</div>
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    gap: 15,
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={require("../assest/Images/doctorimg.png")}
                    style={{ height: 30, width: 30 }}
                  />
                  <p className="Gabe_Woodward_text">Gabe Woodward</p>
                </div>
                <div>Male</div>
                <div>30 Years</div>
                <div>Private Pay</div>
                <div>
                  {" "}
                  <img src={require("../assest/Images/rightarrow.png")} />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTopWidth: 1,
                  borderStyle: "solid",
                  borderColor: "rgba(1, 20, 52, 1)",
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                  paddingTop: 20,
                }}
              >
                <h3
                  className="schedule-section-title"
                  style={{
                    textAlign: "left",
                    marginTop: 20,
                    marginBottom: 20,
                    color: "rgba(1, 20, 52, 1)",
                  }}
                >
                  August 18
                </h3>
                <div style={{ fontSize: 25, color: "#BC9C22" }}>+</div>
              </div>

              <div className="schedule-appointment">
                <div>10:00Am</div>
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    gap: 15,
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={require("../assest/Images/doctorimg.png")}
                    style={{ height: 30, width: 30 }}
                  />
                  <p className="Gabe_Woodward_text">Academic</p>
                </div>
                <div>Male</div>
                <div>30 Years</div>
                <div>Private Pay</div>
                <div>
                  {" "}
                  <img src={require("../assest/Images/rightarrow.png")} />
                </div>
              </div>
              <div className="schedule-appointment">
                <div>10:00Am</div>
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    gap: 15,
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={require("../assest/Images/doctorimg.png")}
                    style={{ height: 30, width: 30 }}
                  />
                  <p className="Gabe_Woodward_text">Samuel O'brien</p>
                </div>
                <div>Male</div>
                <div>30 Years</div>
                <div>Private Pay</div>
                <div>
                  {" "}
                  <img src={require("../assest/Images/rightarrow.png")} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="schedule-right-panel" style={{ width: "30%" }}>
        {/* <div className="schedule-calendar-header">
            <button className="schedule-calendar-nav">&lt;</button>
            <span className="schedule-calendar-title">16 July, 2024</span>
            <button className="schedule-calendar-nav">&gt;</button>
          </div> */}
        {/* <div className="schedule-calendar-grid">
            {[...Array(31).keys()].map(day => (
              <div
                className={`schedule-calendar-day ${day + 1 === 16 ? 'schedule-calendar-day-selected' : ''}`}
                key={day}
              >
                {day + 1}
              </div>
            ))}
          </div> */}

          <CalenderDoctor/>
      </div>
    </div>
  );
};
