import React, { useState } from "react";
import Inputbox from "../../Components/Inputbox";

const AddNewUser = () => {
  const [userType, setUserType] = useState("provider");

  const handleUserTypeChange = (type) => {
    setUserType(type);
  };
  return (
    <div className="add-new-user-wrapper">
      <div className="user-type-selection-section">
        <span style={{ marginRight: 10 }}>Select User Type:</span>
        <div
          style={{
            borderWidth: 1,
            borderColor: "#011434",
            borderStyle: "solid",
            borderRadius: "5px",
          }}
        >
          <button
            className={
              userType === "provider"
                ? "user-type-active-btn"
                : "user-type-inactive-btn"
            }
            onClick={() => handleUserTypeChange("provider")}
          >
            PROVIDER
          </button>
          <button
            className={
              userType === "non-provider"
                ? "user-type-active-btn"
                : "user-type-inactive-btn"
            }
            onClick={() => handleUserTypeChange("non-provider")}
          >
            NON-PROVIDER
          </button>
        </div>
      </div>

      {userType === "provider" && <Provider />}
      {userType === "non-provider" && <Nonprovider />}
    </div>
  );
};

export default AddNewUser;

const Nonprovider = () => {
  return (
    <div>
      <div className="account-info-title">Account Information:</div>
      <div style={{ marginTop: 40 }}>
        <div className="input-row">
          <Inputbox
            label="First Name"
            type="text"
            placeholder="Enter First Name"
            name="firstName"
          />
          <div style={{ width: "10%" }} />
          <Inputbox
            type="text"
            label="Last Name"
            placeholder="Enter Last Name"
            name="lastName"
          />
        </div>
        <div className="input-row">
          <Inputbox
            label="Email Address"
            type="text"
            placeholder="Enter Email"
            name="firstName"
          />
          <div style={{ width: "10%" }} />
          <Inputbox
            type="text"
            label="Phone Number"
            placeholder="Enter Phone Number"
            name="lastName"
          />
        </div>
        <div className="input-row">
          <Inputbox
            label="Password"
            type="text"
            placeholder="Enter Password"
            name="firstName"
          />
          <div style={{ width: "10%" }} />
          <Inputbox
            type="text"
            label="Confirm Password"
            placeholder="Enter Confirm Password"
            name="lastName"
          />
        </div>
      </div>
      <div className="roles-selection-container">
        <div className="roles-title">
          Roles(at least one required) Learn more
        </div>
        <div className="roles-grid-container">
          <label className="checkbox-label">
            <input type="checkbox" className="custom-checkbox" />
            <div style={{ marginLeft: 10 }}>System Admin</div>
          </label>
          <label className="checkbox-label">
            <input type="checkbox" className="custom-checkbox" />
            <div style={{ marginLeft: 10 }}>Provider</div>
          </label>
          <label className="checkbox-label">
            <input type="checkbox" className="custom-checkbox" />
            <div style={{ marginLeft: 10 }}>Clinical Assistant</div>
          </label>
          <label className="checkbox-label">
            <input type="checkbox" className="custom-checkbox" />
            <div style={{ marginLeft: 10 }}>Office Staff</div>
          </label>
          <label className="checkbox-label">
            <input type="checkbox" className="custom-checkbox" />
            <div style={{ marginLeft: 10 }}>Biller</div>
          </label>
          <label className="checkbox-label">
            <input type="checkbox" className="custom-checkbox" />
            <div style={{ marginLeft: 10 }}>Business Manager</div>
          </label>
        </div>
      </div>

      <div className="form-actions-container">
        <button className="form-cancel-btn">Cancel</button>
        <button className="form-save-btn">Save</button>
      </div>
    </div>
  );
};


const Provider = () => {
  return (
    <div className="provider-screen-container">
      {/* Products for this Provider */}
      <div className="products-section">
        <div className="ProductsforthisProvider">
          Products for this Provider
        </div>
        <p style={{ textAlign: "left" }}>
          Your practice's solution comes with the selected modules. To access
          other modules, upgrade your plan.
        </p>
      </div>
      {/* */}

      <div className="product_div_box">
  <div className="product_div_">
    <div className="card_product_div">
      <div className="product_content">
        <img src={require('../../assest/Images/Telehealthicon.png')} alt="Telehealth icon" />
        <div>Telehealth</div>
      </div>
      <input type="checkbox" className="checkbox_input" />
    </div>
    <div className="card_product_div" style={{border:'none'}}>
      <div className="product_content">
        <img src={require('../../assest/Images/heart_plus_icon.png')} alt="Telehealth icon" />
        <div>Engage</div>
      </div>
      <input type="checkbox" className="checkbox_input" />
    </div>
  </div>

   <div className="product_div_">
    <div className="card_product_div">
      <div className="product_content">
        <img src={require('../../assest/Images/hospital-plus.png')} alt="Telehealth icon" />
        <div>Clinical</div>
      </div>
      <input type="checkbox" className="checkbox_input" />
    </div>
    <div className="card_product_div" style={{border:'none'}}>
      <div className="product_content">
        <img src={require('../../assest/Images/dollarIcon.png')} alt="Telehealth icon" />
        <div>Billing</div>
      </div>
      <input type="checkbox" className="checkbox_input" />
    </div>
  </div>
  <div className="product_div_">
    <div className="card_product_div">
      <div className="product_content">
        <img src={require('../../assest/Images/Message-icons.png')} alt="Telehealth icon" />
        <div>Messaging</div>
      </div>
      <input type="checkbox" className="checkbox_input" />
    </div>
    <div className="card_product_div" style={{border:'none'}}>
      <div className="product_content">
        <img src={require('../../assest/Images/dollarIcon.png')} alt="Telehealth icon" />
        <div>Client Subscription</div>
      </div>
      <input type="checkbox" className="checkbox_input" />
    </div>
  </div>
  <div className="product_div_" style={{margin: 0, border: 'none'}}>
    <div className="card_product_div">
      <div className="product_content">
        <img src={require('../../assest/Images/Network.png')} alt="Telehealth icon" />
        <div>AI Health Co-Pilot </div>
      </div>
      <input type="checkbox" className="checkbox_input" />
    </div>
    <div className="card_product_div" style={{border:'none'}}>
      <div className="product_content">
        <img src={require('../../assest/Images/logos_effect-icon.png')} alt="Telehealth icon" />
        <div>E-Priscribing</div>
      </div>
      <input type="checkbox" className="checkbox_input" />
    </div>
  </div> 
</div>


      <div style={{marginTop:20}}>
        <div className="ProductsforthisProvider">Account Information</div>
        <div className="input-container">
          <label htmlFor="npi">Individual NPI</label>
          <p style={{textAlign:'left'}}>
            Enter the individual NPI number for this provider. To automatically
            update their details from the NPI registry database, click "Look
            Up".
          </p>
        </div>

        {/* Input for Phone Number */}
        <div className="input-container">
          <label htmlFor="phone">Phone Number</label>
          <input id="phone" type="text" placeholder="Enter 10-digit number" />
        </div>
      </div>

      {/* Action Buttons */}
      <div className="form-actions-container">
        <button className="form-cancel-btn">Cancel</button>
        <button className="form-save-btn">Save</button>
      </div>
    </div>
  );
};
