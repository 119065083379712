import React from 'react'
import Navbar from '../../Components/Navbar'
import PatientSidebar from './PatientSidebar'
import { useNavigate } from 'react-router-dom'

const Financialpatient = () => {

  const navigate = useNavigate()
  return (
    <div className="dashboard-container">
    <PatientSidebar />
    <div className="dashboard-content">
      <Navbar 
        onClick= {()=>{navigate("/PatientProfile")}}
        />
      <div
        className="dashboard-cardss"
        style={{ paddingLeft: 30, paddingRight: 30,}}>
          <div>
            <p className='Finance_It_should_txt'>It should track your invoices, your current Subscription plan if you have one. Here you can change plans if you want to, or pay/process your invoices.</p>
          </div>
        
        
      <div className='Finance_div_display'>
        <div className='Finance_div_text'>
        <p className='Visit_Count_txt' style={{paddingBottom:5}}>Visit Counts</p>
        <p className='Visit_Count_txt' style={{color:'#BC9C22'}}>5</p>
        </div>
        <div className='Finance_div_text'>
        <p className='Visit_Count_txt' style={{paddingBottom:5}}>Total Balance Due</p>
        <p className='Visit_Count_txt' style={{color:'#BC9C22'}}>£ 12.00</p>
        </div>
        <div className='Finance_div_text'>
        <p className='Visit_Count_txt' style={{paddingBottom:5}}>Payment Source</p>
        <p className='Visit_Count_txt' style={{color:'#BC9C22'}}>Private Pay</p>
        </div>
      </div>

        <p className='Finance_Current_txt' style={{color:'#BC9C22'}} >Invoices</p>   
    <div className='patient_input_div1'>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Feb_txt'>Invoice #12345</p>
    <p className='Patient_Blood_txt'>Amount: £ 12.00</p>
    </div>
    <div className='finance_display_flex'>
    <p className='patient_Feb_txt'>Due: Sept 30, 2024</p>
    <p className='Finance_Pay_txt'>Pay Now</p>
    </div>
   </div>
   <div className='patient_input_div1'>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Feb_txt'>Invoice #12345</p>
    <p className='Patient_Blood_txt'>Amount: £ 40.00</p>
    </div>
    <div className='finance_display_flex'>
    <p className='patient_Feb_txt'>Paid: Aug 15, 2024</p>
    {/* <p className='Finance_Pay_txt'>Pay Now</p> */}
    </div>
   </div>

      <p className='Finance_Current_txt' style={{color:'#BC9C22'}}>Current Subscription Plan</p>
      <div className='patient_input_div1'>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Feb_txt'>Plan: Premium Monthly</p>
    <p className='Patient_Blood_txt'>Next Billing Date: Oct 15, 2024</p>
    </div>
    <div className='finance_display_flex'>
    <p className='Finance_Change_txt'>Change Plan</p>
    </div>
   </div>

   <p className='Finance_Current_txt' style={{color:'#BC9C22'}}>Payment  History</p>
   <div className='patient_input_div1'>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Feb_txt'>Payment on Aug 15, 2024</p>
    <p className='Patient_Blood_txt'>Amount: £ 12.00 (Private Pay)</p>
    </div>
    <div className='finance_display_flex'>
    <p className='patient_Feb_txt'>Completed</p>
    </div>
   </div>
   <div className='patient_input_div1'>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Feb_txt'>Payment on July 10, 2024</p>
    <p className='Patient_Blood_txt'>Amount: £ 40.00 (HSA)</p>
    </div>
    <div className='finance_display_flex'>
    <p className='patient_Feb_txt'>Completed</p>
    </div>
   </div>



   <p className='Finance_Current_txt' style={{color:'#BC9C22'}}>Insurance Claims</p>
   <div className='patient_input_div1'>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Feb_txt'>Claim #456789</p>
    <p className='Patient_Blood_txt'>Amount: £ 12.00</p>
    </div>
    <div className='finance_display_flex'>
    <p className='patient_Feb_txt'>Pending</p>
    </div>
   </div>
   <div className='patient_input_div1'>
    <div className='Finance_Patient_text_div'>
    <p className='patient_Feb_txt'>Claim #123456</p>
    <p className='Patient_Blood_txt'>Amount: £ 40.00</p>
    </div>
    <div className='finance_display_flex'>
    <p className='patient_Feb_txt'>Approved</p>
    </div>
   </div>

   <p className='Finance_Current_txt' style={{color:'#BC9C22'}}>Notifications</p>
   <div className='div_Finance_text'>
   <p className='patient_Feb_txt' style={{paddingTop:10, paddingBottom:10, textAlign:'left'}}>Reminder: Payment Due for Invoice #12345 on Sept 30, 2024</p>
   <p className='patient_Feb_txt' style={{textAlign:'left'}}>Subscription Plan Renewal on Oct 15, 2024</p>
   </div>
        </div>
       
        </div>
        </div>
  )
}

export default Financialpatient