import React from "react";

const Clinicaldata = () => {
  return (
    <div>
    <div className="custom-dashboard-container">
  <div className="card_div_style">
    <div className="image_background_div">
      <img src={require("../../assest/Images/NotesVector.png")} alt="Notes Icon" />
    </div>
    <div style={{ marginLeft: '4%', width: '95%', marginTop: 25 }}>
      <div className="Assessments_txtt">Assessments</div>
      <div className="space_div_style">
        <div className="Inactive_txtttt">INACTIVE</div>
        <div className="Inactive_txtttt_count">0</div>
      </div>
      <div className="space_div_style">
        <div className="Inactive_txtttt">ACTIVE</div>
        <div className="Inactive_txtttt_count">0</div>
      </div>
    </div>
  </div>
  <div className="card_div_style_two">
    <div className="image_background_div_two">
      <img src={require("../../assest/Images/heartIconn.png")} alt="Notes Icon" />
    </div>
    <div style={{ marginLeft: '4%', width: '95%', marginTop: 25 }}>
      <div className="Assessments_txtt">Treatments Plans</div>
      <div className="space_div_style">
        <div className="Inactive_txtttt">INACTIVE</div>
        <div className="Inactive_txtttt_count">0</div>
      </div>
      <div className="space_div_style">
        <div className="Inactive_txtttt">ACTIVE</div>
        <div className="Inactive_txtttt_count">0</div>
      </div>
    </div>
  </div>
</div>

    </div>
  );
};

export default Clinicaldata;
