import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  DropDownManuCustom,
  DropDownManuCustomMulti,
} from "../../Components/DropDownButton";
import Inputbox from "../../Components/Inputbox";
import {
  AddAgencyPlanManagement,
  FetchAgencyPlanManagement,
  GetCategory,
} from "../../../api/helper";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { ShowToast } from "../../../helpers/ToastService";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  PlanName: Yup.string().required("Plan Name is required"),
  fee: Yup.number()
    .typeError("Fee must be a number")
    .required("Fee is required")
    .positive("Fee must be a positive number")
    .integer("Fee must be an integer"),
  Status: Yup.string()
    .oneOf(["Active", "Inactive"], "Status must be either Active or Inactive")
    .required("Status is required"),
  Benefits: Yup.string().required("Benefits is required"),
  AddOnServices: Yup.string(),
  AddIndividualItems: Yup.string(),
  categoryId: Yup.array()
    .of(Yup.string().required("Category ID is required"))
    .min(1, "At least one Category ID is required")
    .required("Category ID is required"),
});

const AgencyPlanManagement = () => {
  const [plans, setPlans] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [payFrequency, setPayFrequency] = useState("Biweekly");
  const [specialtydata, setspecialtydata] = useState(null);
  const [def, setDef] = useState(null);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);

  const navigate=useNavigate()
  useEffect(() => {
    getSpecialty();
  }, []);
  const getSpecialty = async () => {
    try {
      const resposne = await GetCategory();
      setspecialtydata(resposne?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const fetchData = async () => {
    try {
      const respones = await FetchAgencyPlanManagement(profiledata?._id);
      setPlans(respones.data.data);
    } catch (error) {
      console.log(error, "===>>");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data) => {
    data.planType = payFrequency;
    data.agencyId = profiledata?._id;
    try {
      const response = await AddAgencyPlanManagement(data);
      ShowToast(response.data.message, "success");
      reset();
      fetchData();
      closeModal();
    } catch (error) {
      console.log(error, "====>>>");
    }
  };

  const handleSelect = (selectedOptions) => {
    let ids = selectedOptions.map((e) => e?._id);
    setValue("categoryId", ids, { shouldValidate: true });
  };

  const handelClone = (data) => {
    setPayFrequency(data.planType);
    setDef(data.categoryId ? data.categoryId.map((e) => e._id) : []);
    reset({
      PlanName: `${data.PlanName} Copy`,
      fee: data.fee,
      Status: data.Status,
      Benefits: data.Benefits,
      AddOnServices: data.AddOnServices,
      AddIndividualItems: data.AddIndividualItems,
      agencyId: data.agencyId,
    });
    openModal();
  };

  return (
    <div
      style={{
        width: "100%",
        alignItems: "center",
        justifyItems: "center",
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h1>Agency Plan Management</h1>
        <div
          style={{
            backgroundColor: "#bc9c22",
            color: "white",
            padding: 10,
            borderRadius: 6,
          }}
          onClick={openModal}
        >
          Add Plan
        </div>
      </div>

      <div style={{ overflowX: "auto", width: "100%", marginTop: 30 }}>
        <div className="plan-table-container">
          <table className="plan-table">
            <thead>
              <tr>
                <th>Plan Name</th>
                <th>Specialty</th>
                <th>Fee</th>
                <th>Status</th>
                <th>Benefits</th>
                <th>Preview</th>  
                <th>Clone</th>
              </tr>
            </thead>
            <tbody>
              {plans.length > 0 ? (
                plans.map((plan, index) => (
                  <tr key={index}>
                    <td>{plan.PlanName}</td>
                    <td>
                      {plan.categoryId
                        .map((category) => category.name)       
                        .join(", ")}
                    </td>
                    <td>${plan.fee}</td>
                    <td>{plan.Status}</td>
                    <td>{plan.Benefits}</td>
                    <td>
                      <button
                        className="preview-btn"
                        style={{ color: "black" }}
                        // Planpreview 
                        onClick={() => navigate("/planpreview", { state: { plan } })}
                      >
                        Preview
                      </button>
                    </td>
                    <td>
                      <button
                        className="clone-btn"
                        style={{ color: "black" }}
                        onClick={() => {
                          handelClone(plan);
                        }}
                      >
                        Clone
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="no-plans-message">
                    No Plan Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-title"
      >
        <Box sx={modalStyle}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="unique-form-container">
              <h1>Agency Plan Management</h1>
              <div className="unique-pay-frequency">
                {["Biweekly", "Monthly", "Biannually", "Annually"].map(
                  (freq) => (
                    <button
                      key={freq}
                      className={`unique-pay-frequency-btn ${
                        payFrequency === freq ? "unique-active" : ""
                      }`}
                      onClick={() => setPayFrequency(freq)}
                    >
                      {freq}
                    </button>
                  )
                )}
              </div>

              <Inputbox
                label="Plan Name"
                type="text"
                placeholder="Enter Plan Name "
                name="PlanName"
                register={register}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.PlanName?.message}
              </p>

              <DropDownManuCustomMulti
                label="Specialty"
                placeholder="Select option"
                options={specialtydata}
                name="categoryId"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={handleSelect}
                defaultValue={def}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.categoryId?.message}
              </p>

              <Inputbox
                label="Fee"
                type="text"
                placeholder="Enter Fee"
                name="fee"
                register={register}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.fee?.message}
              </p>

              <DropDownManuCustom
                label="Status"
                placeholder="Select option"
                options={[
                  { _id: "Active", name: "Active" },
                  { _id: "inActive", name: "inActive" },
                ]}
                name="Status"
                dropdownicon
                register={register}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.Status?.message}
              </p>

              <Inputbox
                label="Benefits"
                type="text"
                placeholder="Enter Benefits"
                name="Benefits"
                register={register}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.Benefits?.message}
              </p>

              <Inputbox
                label="Add-On Services (Optional)"
                type="text"
                placeholder="Enter List the benefits (e.g., primary care visits, telehealth, lab tests)"
                name="AddOnServices"
                register={register}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {errors.AddOnServices?.message}
              </p>

              <Inputbox
                label="Add Individual Items"
                type="text"
                placeholder="Enter Individual Items"
                name="AddIndividualItems"
                register={register}
              />

              <p style={{ color: "red", textAlign: "left" }}>
                {errors.AddIndividualItems?.message}
              </p>

              <button
                type="submit"
                style={{ marginTop: 30 }}
                className="unique-create-plan-btn"
              >
                Create Plan
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AgencyPlanManagement;
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Increased width for smaller screens
  maxWidth: 600, // Sets a maximum width for larger screens
  maxHeight: "90vh", // Limits the height to 90% of the viewport height
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  outline: "none",
  overflowY: "auto", // Enables vertical scrolling when content overflows
};
