import React from 'react'
import AgencySideBar from '../Components/AgencySideBar'
import Navbar from '../../Components/Navbar'
import { useLocation, useNavigate } from 'react-router-dom'

const PatientsDetails = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const clinicData = location.state?.clinic; 

  return (
    <div className="dashboard-container">
      <AgencySideBar />
      <div className="dashboard-content">
        <Navbar
        onClick= {()=>{navigate("/MyProfileAgency")}}
        />
 <div
          className="dashboard-cards"
          style={{ paddingLeft: 50, paddingRight: 30, flexDirection: "column" }}
        >
          <div className="flex-row">
            {clinicData.patientId?.image ? (
              <img
                src={clinicData.patientId?.image}
                style={{ height: 105, width: 105, borderRadius: 60 }}
              />
            ) : (
              <img
                src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                style={{ height: 105, width: 105, borderRadius: 60 }}
              />
            )}
            {/* <img src={clinicData.patientId?.image} style={{ height: 105, width: 105 }} /> */}
            <div style={{ marginLeft: 70 }}>
              <div className="Email_Address_txt">Full Name</div>
              <div className="clicnic-name">{clinicData.patientId?.firstName +' '+ clinicData.patientId?.lastName}</div>
            </div>
          </div>
          {/* <div style={{width:'100%',marginTop:20,alignItems:'flex-start',display:'flex'}}>
                    <p className='Contact_Information_hhh'>Contact Information :</p>
                    </div> */}
          <div style={{ paddingLeft: 5 }}>

            <div className="space-div">
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Email Address</div>
                <div className="emaill">{clinicData.patientId?.email}</div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt" >Phone Number</div>
                <div className="emaill">{clinicData.patientId?.phone}</div>
              </div>
            </div>

            <div className="space-div">
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Gender</div>
                <div className="emaill">{clinicData.patientId?.gender}</div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt" >City</div>
                <div className="emaill">{clinicData.patientId?.address?.city}</div>
              </div>
            </div>

                      
                  
            <div className="space-div">
            <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Country</div>
                <div className="emaill">{clinicData.patientId?.address?.country}</div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt" >State</div>
                <div className="emaill">{clinicData.patientId?.address?.state}</div>
              </div>
            </div>
            <div className="space-div">
            <div style={{ width: "50%" }}>
                <div className="Email_Address_txt">Street</div>
                <div className="emaill">{clinicData.patientId?.address?.street}</div>
              </div>
              <div style={{ width: "50%" }}>
                <div className="Email_Address_txt" >ZipCode</div>
                <div className="emaill">{clinicData.patientId?.address?.zipCode}</div>
              </div>
            </div>
            <div style={{ width: "50%",marginTop:30 }}>
                <div className="Email_Address_txt">
                  Clinic Association Request
                </div>
                <div className="emaill">
                  {clinicData.patientId?.agencyIds.length > 0
                    ? `${clinicData.patientId?.agencyIds[0].firstName} ${clinicData.patientId?.agencyIds[0].lastName}`
                    : "NA"}
                </div>
              </div>
          </div>
        </div>
 </div>
 </div>
  )
}

export default PatientsDetails