import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Inputbox from "../../Components/Inputbox";
import DropDownButton, { DropDownManuCustomMulti } from "../../Components/DropDownButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { agencySignup, GetCategory, uploadImage } from "../../../api/helper";
import { ShowToast } from "../../../helpers/ToastService";
import { Store } from "../../../redux";
import {
  setHeaderToken,
  setLoginType,
  setUserDetails,
} from "../../../redux/cookiesReducer";
import { useDispatch } from "react-redux";
import { GoogleMap, useLoadScript, Autocomplete } from "@react-google-maps/api";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters"),
  lastName: Yup.string()
    .required("Last name is required")
    .min(2, "Last name must be at least 2 characters"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),

  phone: Yup.string()
    .required("Phone is required")
    .matches(/^[0-9]+$/, "Phone number must be digits")
    .min(10, "Phone number must be at least 10 digits")
    .max(15, "Phone number cannot exceed 15 digits"),
    password: Yup.string()
    .required("Password is required")
    .min(12, "Password must be at least 12 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[!@#$%^&*_,]/, "Password must contain at least one special character (!, @, #, $, %, ^, &, *, _)")
    .notOneOf(["password", "12345", "qwerty"], "Password is too common or easy to guess") // Add more common words as needed
    .test("no-repeated-characters", "Password should not contain repeated characters like 'aaaa' or '1111'", value => {
      return !/(.)\1{3,}/.test(value); // Adjust this to your repeated character tolerance
    })
    .test("no-simple-patterns", "Password should not contain common patterns like 'abcd' or '1234'", value => {
      const patterns = ["abcd", "1234", "qwerty"]; // Add more patterns as needed
      return !patterns.some(pattern => value.includes(pattern));
    }),
  
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
    

  companyName: Yup.string()
    .required("Company name is required")
    .min(2, "Company name must be at least 2 characters"),

  address: Yup.object().shape({
    // street: Yup.string().required('Street is required'),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    zipCode: Yup.string()
      .required("Zip code is required")
      .matches(/^[0-9]+$/, "Zip code must be digits")
      .min(5, "Zip code must be at least 5 digits")
      .max(10, "Zip code cannot exceed 10 digits"),
  }),
  NPI2: Yup.string().required("NPI2 is required"),
  DBA: Yup.string().required("DBA is required"),
  EIN: Yup.string().required("EIN is required"),
  categoryId: Yup.array()
    .of(Yup.string().required("A Specialities must be selected"))
    .min(1, "At least one Specialities is required")
    .required("Specialities is required"),
});

const AgencyRegistration = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useDispatch();
  const [autocomplete, setAutocomplete] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [saveData, setSaveData] = useState();
  const fileInputRef = useRef(null);
  const [imaeg, setImages] = useState(null);
  const [formSubmit, setFormSubmit] = useState(null);
  const [specialtydata, setspecialtydata] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBTgjMWeFMxL5oe-KFnKts3YGBZJlEC6eM", // Replace with your Google Maps API key
    libraries: ["places"], // Include 'places' library for autocomplete
  });
  const getSpecialty = async () => {
    try {
      const resposne = await GetCategory();
      setspecialtydata(resposne?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSpecialty()
  }, [])


  const handlePlaceSelect = async () => {
    const place = autocomplete.getPlace();
    const addressComponents = place.address_components;
    const getAddressComponent = (type) => {
      const component = addressComponents.find((c) => c.types.includes(type));
      return component ? component.long_name : "";
    };
    setValue("address.country", getAddressComponent("country"));
    setValue(
      "address.state",
      getAddressComponent("administrative_area_level_1")
    );
    setValue("address.city", getAddressComponent("locality"));

    const streetNumber = getAddressComponent("street_number");
    const route = getAddressComponent("route");
    const sublocality = getAddressComponent("sublocality");
    const neighborhood = getAddressComponent("neighborhood");
    const premise = getAddressComponent("premise"); // Building name or room number
    const establishment = getAddressComponent("establishment"); // Business names

    let fullStreet = "";

    if (establishment) {
      fullStreet = establishment; // Use business name if available
    } else if (premise) {
      fullStreet = `${premise}, ${route || sublocality || neighborhood}`; // Use building/room with route
    } else if (streetNumber && route) {
      fullStreet = `${streetNumber} ${route}`; // Standard street address
    } else if (sublocality) {
      fullStreet = sublocality; // For sectors
    } else if (neighborhood) {
      fullStreet = neighborhood; // If categorized as neighborhood
    }
    if (!fullStreet) {
      fullStreet = `${getAddressComponent("locality")}, ${getAddressComponent(
        "administrative_area_level_1"
      )}, ${getAddressComponent("country")}`;
    }
    setValue("address.street", fullStreet);
    setValue("address.zipCode", getAddressComponent("postal_code"));
  };


  const handleChange = (e) => {
    reset({
      phone: e,
    });
  };

  const handleCloseModal = async (data) => {
    if (data == "Upload") {
      if (!imagePreview) {
        return ShowToast("Please Select Image", "error");
      }
      saveData.image = imaeg;
    }
    setFormSubmit(data);
    setShowModal(false);
    submitData();
  };

  const onSubmit = async (data) => {
    // console.log(data, '======data')
    setSaveData(data);
    formSubmit ? submitData() : setShowModal(true);
  };

  const submitData = async () => {
    try {
      const resposne = await agencySignup(saveData);
      ShowToast(resposne.data.message, "success");
      Store.dispatch(setHeaderToken(resposne?.data?.data));
      dispatch(setUserDetails(resposne.data.data));
      Store.dispatch(setLoginType("Clinic"));
      navigate("/dashboradScreen");
    } catch (error) {
      console.log(error);
    }
  };

  const clickImageData = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const imagePicker = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("images", file);
      try {
        const response = await uploadImage(formData);
        // console.log(response.data.data[0].URL,'====response')
        setImages(response.data.data[0].URL);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.log(error, "===");
      }
    }
  };

  const handleSelect = (selectedOptions) => {
    console.log("selectedOptions:", selectedOptions); // This will log the value
    let ids = Array.isArray(selectedOptions)
      ? selectedOptions.map((e) => e?._id)
      : [];
    setValue("categoryId", ids);
  };

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div style={{ width: "100%", display: "flex", height: "100vh" }}>
      <div
        style={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#041C32",
          flexDirection: "column",
        }}
      >
        {/* <img
src={require("../assest/Images/SideLoginImg.png")}
alt="Team Working"
className="left-image"
/> */}
        <img
          src={require("../../assest/Images/mindwellnewlogo.png")}
          style={{ height: "50%", width: "50%" }}
        />
        <div
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "20px",
            marginTop: "20px",
          }}
        >
          Your trusted platform for mental health services.
        </div>
      </div>

      {/* <div className="signup-container">
        <h2>New Agency Registration</h2>
        <form onSubmit={handleSubmit(onSubmit)}> */}
         <div className="signup-container" style={{ padding: 50 }}>
         <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
         <h2>New Agency Registration</h2>
          <div className="signup-form">
            <div className="input-row_two">
              <div className="form_div">
                <Inputbox
                  label="Contact Name"
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  register={register}
                />
                {errors.firstName && (
                  <div className="errorMessage">{errors.firstName.message}</div>
                )}
              </div>
              <div className="form_div" style={{ marginTop: 32 }}>
                <Inputbox
                  type="text"
                  label=""
                  placeholder="Last Name"
                  name="lastName"
                  register={register}
                />
                {errors.lastName && (
                  <div className="errorMessage">{errors.lastName.message}</div>
                )}
              </div>
            </div>

            <div className="input-row_two">
              <div className="form_div">
                <Inputbox
                  label="Email Address"
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  register={register}
                />
                {errors.email && (
                  <div className="errorMessage">{errors.email.message}</div>
                )}
              </div>
              <div className="form_div_two">
                <Inputbox
                  style={{ width: "100%", height: 49 }}
                  label="Phone Number"
                  type="text"
                  placeholder="Enter Number"
                  name="phone"
                  onChange={handleChange}
                />
                {errors.phone && (
                  <div className="errorMessage">{errors.phone.message}</div>
                )}
              </div>
            </div>

            <div className="input-row_two">
              <div className="form_div">
                <Inputbox
                  label="Password"
                  type="password"
                  placeholder="Enter Password"
                  name="password"
                  register={register}
                  isPassword
                />
                {errors.password && (
                  <div className="errorMessage">{errors.password.message}</div>
                )}
              </div>
              <div className="form_div">
                <Inputbox
                  label="Confirm Password"
                  type="password"
                  placeholder="Enter Confirm Password"
                  name="confirmPassword"
                  register={register}
                  isPassword
                />
                {errors.confirmPassword && (
                  <div className="errorMessage">
                    {errors.confirmPassword.message}
                  </div>
                )}
              </div>
            </div>

            <div className="Company_Details_txt">Company Details :</div>
            <div className="form_div">
              <Inputbox
                label="Company Legal Name"
                type="text"
                placeholder="Enter Company Legal Name"
                name="companyName"
                register={register}
              />
              {errors.companyName && (
                <div className="errorMessage">{errors.companyName.message}</div>
              )}
            </div>
            <div style={{height:10}}/>
            <div className="input-row_two">
            <div className="form_div">
            <DropDownManuCustomMulti
              label="Specialities"
              placeholder="Select option"
              options={specialtydata}
              name="categoryId"
              register={register}
              id="_id"
              clearErrors={clearErrors}
              onSelect={handleSelect}
            />

{errors.categoryId && (
                  <div className="errorMessage">
                    {errors.categoryId.message}
                  </div>
                )}
                </div>
</div>
            <div className="input-row_two">
              <div className="form_div">
                <Inputbox
                  label="DBA"
                  type="text"
                  placeholder="Enter DBA"
                  name="DBA"
                  register={register}
                />
                {errors.DBA && (
                  <div className="errorMessage">{errors.DBA.message}</div>
                )}
              </div>
              <div className="form_div">
                <Inputbox
                  label="NPI 2"
                  type="text"
                  placeholder="Enter NPI 2"
                  name="NPI2"
                  register={register}
                />
                {errors.NPI2 && (
                  <div className="errorMessage">{errors.NPI2.message}</div>
                )}
              </div>
            </div>

            <div className="form_div">
              <Inputbox
                label="EIN"
                type="text"
                placeholder="Enter EIN"
                name="EIN"
                register={register}
              />
              {errors.EIN && (
                <div className="errorMessage">{errors.EIN.message}</div>
              )}
            </div>

            <div className="input-row_two" >
              <div className="form_div">
                <Autocomplete
                  onLoad={(autocompleteInstance) =>
                    setAutocomplete(autocompleteInstance)
                  }
                  onPlaceChanged={handlePlaceSelect}
                >
                  <Inputbox
                    label="Street"
                    type="text"
                    placeholder="Enter Street"
                    name="address.street"
                    register={register}
                  />
                </Autocomplete>
              </div>

              <div className="form_div">
                <Inputbox
                  label="City"
                  type="text"
                  placeholder="Enter City"
                  name="address.city"
                  register={register}
                />
                {errors.address?.city && (
                  <div className="errorMessage">
                    {errors.address.city.message}
                  </div>
                )}
              </div>
            </div>
            <div className="input-row_two" >
              <div className="form_div">
                <Inputbox
                  label="State"
                  type="text"
                  placeholder="Enter State"
                  name="address.state"
                  register={register}
                />
                {errors.address?.state && (
                  <div className="errorMessage">
                    {errors.address.state.message}
                  </div>
                )}
              </div>
              <div className="form_div">
                <Inputbox
                  label="Country"
                  type="text"
                  placeholder="Enter Country"
                  name="address.country"
                  register={register}
                />
                {errors.address?.country && (
                  <div className="errorMessage">
                    {errors.address.country.message}
                  </div>
                )}
              </div>
            </div>

            <div className="input-row_two">
              <div className="form_div">
                <Inputbox
                  label="Zip Code"
                  type="text"
                  placeholder="Enter Zip Code"
                  name="address.zipCode"
                  register={register}
                />
                {errors.address?.zipCode && (
                  <div className="errorMessage">
                    {errors.address.zipCode.message}
                  </div>
                )}
              </div>
            </div>

            <button className="login-button" type="submit">
              Sign Up
            </button>
            <p className="login-text">
              Already have an Account?{" "}
              <a
                onClick={() => {
                  navigate("/");
                }}
                style={{ cursor: "pointer" }}
              >
                Log In
              </a>
            </p>
          </div>
        </form>
      </div>
      {showModal && (
        <div className="modal_div">
          <div className="logout-modal" style={{ backgroundColor: "#D9D9D9" }}>
            <div
              className="logout_txt"
              style={{ color: "#011434", borderColor: "#011434" }}
            >
              Upload Profile Picture
            </div>
            <div
              onClick={clickImageData}
              style={{
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ height: 150, width: 150, borderRadius: 80 }}
                />
              ) : (
                <img
                  src={require("../../assest/Images/Cameraprofile.png")}
                  alt="Upload"
                />
              )}

              <input
                type="file"
                ref={fileInputRef}
                onChange={imagePicker}
                style={{ display: "none" }}
              />
            </div>

            <button
              className="confirm-button"
              onClick={() => handleCloseModal("Upload")}
              style={{ width: 200, color: "white", backgroundColor: "#BC9C22" }}
            >
              Upload
            </button>
            <div
              style={{ marginTop: 20, cursor: "pointer" }}
              onClick={() => handleCloseModal("Skip")}
            >
              Skip for now
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AgencyRegistration;
