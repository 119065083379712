import React, { useState } from "react";
import Navbar, { Navbartwo } from "../../Components/Navbar";
import PatientSidebar from "./PatientSidebar";
import Inputbox from "../../Components/Inputbox";
import Checkbox from './Checkbox'
import DropDownButton from "../../Components/DropDownButton";
import PatientContact from "../../Components/PatientContact";
import PatientIdentification from "../../Components/PatientIdentification";
import OverviewPatient from "../../Components/OverviewPatient";
import PatientProblems from "../../Components/PatientProblems";
import Medicationspatient from "../../Components/Medicationspatient";
import AllergiesPatient from "../../Components/AllergiesPatient";
import LabsPatient from "../../Components/LabsPatient";
import VitalsPatient from "../../Components/VitalsPatient";
import { useNavigate } from "react-router-dom";

const MyChart = () => {
  const [activeTab, setActiveTab] = useState("Overview");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [activeSection, setActiveSection] = useState("contact");
  const navigate = useNavigate()
  const data = [
    { member: 'Evens Michel', source: 'Internal', type: 'MD' },
    { member: 'Soondy Smith', source: 'External', type: 'NP' },
    { member: 'Evens Michel', source: 'Facility', type: 'MD' },
    { member: 'Soondy Smith', source: 'Family', type: 'NP' }
  ];

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };
  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Overview":
        return <OverviewPatient />;
      case "Problems":
        return <PatientProblems />;
      case "Medications":
        return <Medicationspatient />;
      case "Allergies":
        return <AllergiesPatient />;
      case "Labs":
        return <LabsPatient />;
      case "Vitals":
        return <VitalsPatient />;
      default:
        return <OverviewPatient />;
    }
  };
  return (
    <div className="dashboard-container">
      <PatientSidebar />
      <div className="dashboard-content">
        <Navbartwo  onClick= {()=>{navigate("/PatientProfile")}}/>
        <div
          className="dashboard-cards"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="patientchartheader">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "75%",
              }}
            >
              <div
                className={
                  activeTab === "Overview"
                    ? "patientchartheaderbuttonactive"
                    : "patientchartheaderbutton"
                }
                onClick={() => setActiveTab("Overview")}
              >
                Overview
              </div>
              <div
                className={
                  activeTab === "Problems"
                    ? "patientchartheaderbuttonactive"
                    : "patientchartheaderbutton"
                }
                onClick={() => setActiveTab("Problems")}
              >
                Problems
              </div>
              <div
                className={
                  activeTab === "Medications"
                    ? "patientchartheaderbuttonactive"
                    : "patientchartheaderbutton"
                }
                onClick={() => setActiveTab("Medications")}
              >
                Medications
              </div>
              <div
                className={
                  activeTab === "Allergies"
                    ? "patientchartheaderbuttonactive"
                    : "patientchartheaderbutton"
                }
                onClick={() => setActiveTab("Allergies")}
              >
                Allergies
              </div>
              <div
                className={
                  activeTab === "Labs"
                    ? "patientchartheaderbuttonactive"
                    : "patientchartheaderbutton"
                }
                onClick={() => setActiveTab("Labs")}
              >
                Labs
              </div>
              <div
                className={
                  activeTab === "Vitals"
                    ? "patientchartheaderbuttonactive"
                    : "patientchartheaderbutton"
                }
                onClick={() => setActiveTab("Vitals")}
              >
                Vitals
              </div>
              <DropDownButton
       
        placeholder="Other Resources"
        dropdownicon
        style={{ width: "17.5%" }}
        options={["Initial Assessment", "Progress Notes", "Non-Billable Notes"]}
      />
             
            </div>
            
            <div>
              <img
                src={require("../../assest/Images/patientchartimg.png")}
                alt="Patient Chart"
              />
            </div>
            
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div className="patient_left">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="Patient_Chart_txt">Patient Chart</div>

                <img
                  src={require("../../assest/Images/fluent_arrow.png")}
                  style={{ cursor: "pointer" }}
                  onClick={toggleFormVisibility}
                />
              </div>
              <div className="client_id_txt">Client ID: C-ep4ssu</div>
              <div className="paitient_name_div" style={{ display: "flex" }}>
                <img
                  src={require("../../assest/Images/usericon.png")}
                  style={{ height: 70, width: 70 }}
                  alt="User Icon"
                />
                <div style={{ marginLeft: 10 }}>
                  <div className="patient_name_txt">Declan Mcgowan</div>
                  <div className="Payment_Insurance_txt">
                    {" "}
                    Payment: Insurance
                  </div>
                  <div className="Payment_Insurance_txt">
                    Mobile: 407-792-972
                  </div>
                  <div className="Payment_Insurance_txt">
                    Primary Dx: F84.02
                  </div>
                  <div
                    className="Payment_Insurance_txt"
                    style={{ marginTop: 10, fontSize: 16 }}
                  >
                    He/Him/His - 35 years old
                  </div>
                </div>
              </div>
              <div className="active_txt_div">
                <div style={{ display: "flex", width: "30%" }}></div>
                <div>
                  <div className="active_txttt">
                    <img
                      src={require("../../assest/Images/checkIcon.png")}
                      style={{ marginRight: 10 }}
                      alt="Check Icon"
                    />
                    Active
                  </div>
                  <div className="active_txttt">
                    <img
                      src={require("../../assest/Images/checkIcon.png")}
                      style={{ marginRight: 10 }}
                      alt="Check Icon"
                    />
                    Nageley Michel
                  </div>
                </div>
              </div>
              <div className="space_between" style={{ marginTop: 20 }}>
                <div className="Demographics_txtt">Demographics</div>
                <img
                  src={require("../../assest/Images/pen_icon.png")}
                  alt="Pen Icon"
                />
              </div>
              <div
                style={{ marginTop: 20, display: "flex", alignItems: "center" }}
              >
                <div className="date_of_birth_txtt">Date of Birth</div>
                <div className="date_of_birth_">Dec 12,1988</div>
              </div>
              <div
                style={{ marginTop: 20, display: "flex", alignItems: "center" }}
              >
                <div className="date_of_birth_txtt" style={{ marginRight: 36 }}>
                  Sex at Birth
                </div>
                <div className="date_of_birth_">Male</div>
              </div>
              {/* nis */}
              <div className="space_between_width" style={{ marginTop: 20,}}>
                <div className="Demographics_txtt">Care Team</div>
                <img
                  src={require("../../assest/Images/pen_icon.png")}
                  alt="Pen Icon"
                />
              </div>
              <div className="grid-container">
      <div className="grid-header">Member</div>
      <div className="grid-header">Source</div>
      <div className="grid-header">Type</div>

      {data.map((row, index) => (
        <React.Fragment key={index}>
          <div className="grid-item">{row.member}</div>
          <div className="grid-item">{row.source}</div>
          <div className="grid-item">{row.type}</div>
        </React.Fragment>
      ))}
    </div>
    <div className="space_between_width" style={{ marginTop: 20,}}>
    <div className="Demographics_txtt">Clinical Recommendations</div>
                <img
                  src={require("../../assest/Images/pen_icon.png")}
                  alt="Pen Icon"
                />
      </div>
      <div className="hiv_txtt" style={{marginTop:10}}>(HIV) Infection Exam</div>
      <div className="hiv_txtt">Immunization</div>
      <div className="hiv_txtt">PTSD Test</div>
      <div className="hiv_txtt">Drug Addition Test</div>
      <div className="hiv_txtt">Anxiety Disorder Screening</div>
      <div className="hiv_txtt">Tabaco Dependency Screening</div>

            </div>
            {renderContent()}
          </div>
          {isFormVisible && (
            <div className="Patient_Chart_popup">
              <div className="Add_problem_txtt_two">
                Even Michel
                {/* Close Icon */}
                <img
                  src={require("../../assest/Images/crossIconn.png")}
                  alt="close"
                  onClick={toggleFormVisibility}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div className="left_chart_popup">
                  <div
                    className="Clinical_Details_txtt"
                    style={{ paddingLeft: 20, paddingRight: 20, marginTop: 30 }}
                  >
                    Clinical Details
                  </div>
                  <div
                    className="code_txt_div"
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    Assigned Clinician
                    <select className="select_option_style">
                      <option>Nageley Michel</option>
                    </select>
                  </div>
                  <div
                    className="code_txt_div"
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    Status
                    <select className="select_option_style">
                      <option>Active</option>
                    </select>
                  </div>

                  <div className="MRN_style_div">
                    <div
                      className="code_txt_div"
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        display: "flex",
                        justifyContent: "space-between",
                        width: "57%",
                        marginTop: 10,
                      }}
                    >
                      Status
                      <div>EMI000001</div>
                    </div>
                  </div>
                  <div className="Save_buttonnn">Save</div>
                </div>
                <div className="right_chart_popup">
                <div className="main_div1" style={{ paddingLeft: 45, paddingRight: 45 }}>
  <div
    className={
      activeSection === "contact" ? "para_border" : "para_border1"
    }
    onClick={() => handleSectionClick("contact")}
    style={{ cursor: "pointer" }}
  >
    <p
      className={
        activeSection === "contact" ? "para_Contact" : "Identification"
      }
    >
      Contact
    </p>
  </div>
  <div
    className={
      activeSection === "identification" ? "para_border" : "para_border1"
    }
    onClick={() => handleSectionClick("identification")}
    style={{ cursor: "pointer" }}
  >
    <p
      className={
        activeSection === "identification" ? "para_Contact" : "Identification"
      }
    >
      Identification
    </p>
  </div>
</div>

                  <div>
                    {activeSection === "contact" && (
                      <>
                        <PatientContact />
                      </>
                    )}
                    {activeSection === "identification" && (
                      <>
                        <PatientIdentification />
                        {/* <p>Identification section is active</p> */}
                      </>
                    )}
                  </div>
                  {/* <PatientContact/> */}
                  {/* <PatientIdentification/> */}
                </div>
              </div>
            </div>
          )}
    
        </div>
      </div>
    </div>
  );
};
export default MyChart;
