import React, { useState } from "react";
import DropDownButton, {
  DropDownManuCustom,
} from "../../Components/DropDownButton";
import { useNavigate } from "react-router-dom";
import AddNewUser from "./AddNewUser";

const UserSettings = () => {
  const profiles = [
    {
      id: 1,
      name: "Dr. Kelvin Mad",
      email: "kelvin@gmail.com",
      roles:
        "System Administrator, Provider, Clinical Assistant, Office Staff, Biller, Business Manager",
      image: require("../../assest/Images/doctorimg.png"),
    },
    {
      id: 2,
      name: "Robert Bacins",
      email: "robert@gmail.com",
      roles: "Clinical Assistant, Office Staff",
      image: require("../../assest/Images/doctorimg.png"),
    },
  ];
  const [isAddingNewUser, setIsAddingNewUser] = useState(false);
  const navigate = useNavigate();
  return (
    <div>
      {!isAddingNewUser ? (
        <div>
          <div className="usersetting_div_dropdown">
            <DropDownButton
              placeholder=" User Type"
              dropdownicon
              style={{ width: "22.5%" }}
              // options={[" User Type 1", " User Type 2", " User Type 3"]}
            />
            {/* <DropDownManuCustom
                    label="Language"
                    placeholder="Select language"
                    options={[
                      { _id: "english", name: "English" },
                      { _id: "spanish", name: "Spanish" },
                    ]}
                 
                  /> */}
            <div
              className="New_User_div_style"
              onClick={() => setIsAddingNewUser(true)}
            >
              <img
                src={require("../../assest/Images/gg_add.png")}
                style={{ marginRight: 6 }}
              />
              New User
            </div>
          </div>
          <div className="profileContainer">
            {profiles.map((profile) => (
              <div key={profile.id} className="profileCard">
                <div className="profileImage">
                  <img
                    src={profile.image}
                    alt={profile.name}
                    style={{ height: 120, width: 120 }}
                  />
                </div>
                <div className="profileInfo">
                  <h3 className="profileName">{profile.name}</h3>
                  <p className="profileEmail">{profile.email}</p>
                  <p className="profileRoles">{profile.roles}</p>
                </div>
                <div className="editSection">
                  {/* <FaEdit className="editIcon" /> */}
                  <img src={require("../../assest/Images/pen_icon.png")} />
                  <span className="editText">Edit</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        // Render AddNewUser form
        <AddNewUser />
      )}
    </div>
  );
};

export default UserSettings;
