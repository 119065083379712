import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { useLocation } from "react-router-dom";
import { ClinicDetail, createAgencyDoctor, JoinClinic, JoinClinicUpdate } from "../../api/helper";
import { useSelector } from "react-redux";
import { ShowToast } from "../../helpers/ToastService";

const DoctorClinicDetails = () => {
  const location = useLocation();
  const [clinicId, setclinicId] = useState("");
  const [clinicdata, setclinicdata] = useState(null);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const { isLoading } = useSelector((store) => store.sliceReducer); 
  // console.log(profiledata?._id, "helloooo=========",isLoading);
  useEffect(() => {
    if (location.state && location.state.clinicId) {
      // console.log("clinicId :", location.state.clinicId);
      setclinicId(location.state.clinicId);
      fetchclinicDetails();
    }
  }, [location.state]);

  const fetchclinicDetails = async () => {
    if (clinicId) {
      try {
        const response = await ClinicDetail(clinicId, profiledata?._id);
        // console.log(response?.data?.data, "data =-=-====patientdetail");
        setclinicdata(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    fetchclinicDetails();
  }, [clinicId])
  const handlejoin = async () => {
    const data = {
      ...profiledata,
      status: "request",
      fullName: `${profiledata.firstName} ${profiledata.lastName}`,
      doctorId: profiledata?._id,
      agencyId: clinicId,
    };
    delete data._id
    // console.log(data,'===data')
    if (clinicId) {
      try {
        const response = await createAgencyDoctor(data);
        ShowToast(response.data.message, "success");
        fetchclinicDetails();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const pendingJoinClinic = async (data, status) => {
    try {
      const dataToSave = {
        requestId: data._id,
        status: status
      }
      // console.log("changes====>>", dataToSave)
      const response = await JoinClinicUpdate(dataToSave)
      ShowToast(response.data.message,"success")
      fetchclinicDetails()
    } catch (error) {
      console.log(error, '=======eror')
    }
  }

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <Navbar />
        <div
          className="dashboard-cards"
          style={{
            width: "100%",
            // paddingLeft: 30, paddingRight: 30
          }}
        >
          
          <div className="doctor_clinic_main_div" style={{ width: "100%" }}>
            <p
              style={{ paddingLeft: 30, paddingRight: 30 }}
              className="Clinic_details_text"
            >
              Clinic Details
            </p>
            <div
              className="Donctor_display_image_text"
              style={{ paddingLeft: 30, paddingRight: 30 }}
            >
              <div>
                {clinicdata?.image ? (
                  <img
                    style={{ height: 300, width: 500 }}
                    src={clinicdata?.image}
                  />
                ) : (
                  <img
                    style={{ height: 300, width: 500 }}
                    src={require("../assest/Images/PictureRectangle.png")}
                  />
                )}
                {/* <img style={{height:300, width:500, }} src={require("../assest/Images/PictureRectangle.png")} /> */}
              </div>
              <div style={{ width: "100%" }}>
                <p className="Birmingham_text">
                  {clinicdata?.firstName + " " + clinicdata?.lastName}
                </p>
                <div className="Donctor_display_image">
                  <div style={{ display: "flex", gap: "5%" }}>
                    <img
                      className="starimage"
                      src={require("../assest/Images/blackstar.png")}
                    />
                    <img
                      className="starimage"
                      src={require("../assest/Images/blackstar.png")}
                    />
                    <img
                      className="starimage"
                      src={require("../assest/Images/blackstar.png")}
                    />
                    <img
                      className="starimage"
                      src={require("../assest/Images/blackstar.png")}
                    />
                    <img
                      className="starimage"
                      src={require("../assest/Images/blackstar.png")}
                    />
                  </div>
                  <div className="div_message_image">
                    <img
                      className="messageimage"
                      src={require("../assest/Images/message.png")}
                    />
                  </div>
                </div>
                <p className="Review_text">(0 Reviews)</p>
              </div>
            </div>
            {clinicdata && clinicdata?.requests && clinicdata?.requests?.status == "pending" &&
              <>
                <div
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    paddingRight: 30,
                    cursor: "pointer",
                  }}
                  onClick={() => pendingJoinClinic(clinicdata?.requests,"approved")}
                >
                  <p className="Join_Clinic_text">Confirm</p>
                </div>
                <div
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    paddingRight: 30,
                    cursor: "pointer",
                  }}
                  onClick={() => pendingJoinClinic(clinicdata?.requests,"denied")}
                >
                  <p className="Join_Clinic_text">Decline</p>
                </div>
              </>
              
            }

            {!clinicdata?.requests && !clinicdata?.requests &&
              <div
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  paddingRight: 30,
                  cursor: "pointer",
                }}
                onClick={() => handlejoin()}
              >
                <p className="Join_Clinic_text">Join Clinic</p>
              </div>
            }


            <div
              className="clinic_doctor_div_text"
              style={{ marginBottom: 50, display: "flex", paddingTop: 20 }}
            >
              <div
                className="Doctor_div_text"
                style={{ width: "33%", textAlign: "left", paddingLeft: 30 }}
              >
                <p className="Doctor_Email_Address_text">Email Address</p>
                <p className="doctor_email_text">{clinicdata?.email}</p>
                <p className="Doctor_Email_Address_text">Clinic Address</p>
                <p className="doctor_email_text">
                  {clinicdata?.address?.street +
                    "," +
                    clinicdata?.address?.state}
                </p>
                <p className="Doctor_Email_Address_text">City</p>
                <p className="doctor_email_text">{clinicdata?.address?.city}</p>
              </div>
              <div
                className="Doctor_div_text"
                style={{ width: "32%", textAlign: "left", paddingLeft: 30 }}
              >
                <p className="Doctor_Email_Address_text">Clinic Phone Number</p>
                <p className="doctor_email_text">{clinicdata?.phone}</p>
                <p className="Doctor_Email_Address_text">Website</p>
                <p className="doctor_email_text" style={{ color: "#1565C0" }}>
                  {clinicdata && clinicdata?.webLink ? clinicdata?.webLink : " Not Available"}
                </p>
                {/* <p className="Doctor_Email_Address_text">License Number</p>
                <p className="doctor_email_text">Not Available</p> */}
              </div>
              <div style={{ width: "32%", textAlign: "left", paddingLeft: 30 }}>
                <p className="Doctor_Email_Address_text">Opening Days</p>
                <p className="doctor_email_text1">Not Available</p>
                {/* <p className="doctor_email_text1">Tuesday</p>
                <p className="doctor_email_text1">Wednesday</p>
                <p className="doctor_email_text1">Friday</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorClinicDetails;
