import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AddInsurance,
  AddProviderType,
  DeleteInsurance,
  DeleteProviderType,
  GetAdminInsurence,
  GetInsurance,
} from "../../../api/helper";
import { Box, Modal } from "@mui/material";
import Inputbox from "../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ShowToast } from "../../../helpers/ToastService";
import { DropDownManuCustom } from "../../Components/DropDownButton";

const InsurancenameOption = [
  { _id: "Blue Cross Blue Shield", name: "Blue Cross Blue Shield" },
  { _id: "United Healthcare", name: "United Healthcare" },
  { _id: "Aetna ", name: "Aetna " },
  { _id: "Cigna ", name: "Cigna " },
  { _id: "Humana", name: "Humana" },
];
const InsuranceTypedata = [
  { _id: "Medicare", name: "Medicare" },
  { _id: "Medicaid", name: "Medicaid" },
  {
    _id: "Preferred Provider Organization (PPO)",
    name: "Preferred Provider Organization (PPO)",
  },
  {
    _id: "Health Maintenance Organization (HMO)",
    name: "Health Maintenance Organization (HMO)",
  },
  { _id: "Point of Service (POS)", name: "Point of Service (POS)" },
  {
    _id: "Exclusive Provider Organization (EPO)",
    name: "HumanExclusive Provider Organization (EPO)a",
  },
  { _id: "Tricare", name: "Tricare" },
  { _id: "Veterans Affairs (VA)", name: "Veterans Affairs (VA)" },
  { _id: "CHAMPVA", name: "CHAMPVA" },
];

const validationSchema = Yup.object().shape({
  adminInsurenceId: Yup.string().required("Insurance Name is required"),

  InsuranceType: Yup.string().required("Insurance Type is required"),

  InsuranceAddress: Yup.string().required("Insurance Address is required"),

  ContactNumber: Yup.string()
    .required("Contact Number is required")
    .matches(/^[0-9]+$/, "Contact Number must be numeric"),

  Email: Yup.string()
    .required("Email is required")
    .email("Invalid Email format"),

  CPID: Yup.string().required("CPID is required"),

  PayerID: Yup.string().required("Payer ID is required"),
});

const InsuranceScreen = () => {
  const [insurance, setInsurance] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [inNetwork, setInNetwork] = useState(false);
  const [claims, setClaims] = useState(false);
  const [eligibility, setEligibility] = useState(false);
  const [eras, setEras] = useState(false);
  const [adminInsurence, setAdminInsurence]= useState()
  const [defType , setDefType]= useState()
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
// console.log(profiledata?._id,"profiledata?._id=======")
  const Getinsurancedata = async () => {
    const response = await GetInsurance(`?id=${profiledata?._id}`);
    // console.log(response?.data?.data, "GetInsurance=====");
    setInsurance(response?.data?.data);
  };

  const getadminInsurence = async()=>{
    try {
      const respones = await GetAdminInsurence()
    
      setAdminInsurence(respones.data.data)
    } catch (error) {
      console.log(error,'===>>')
    }
  }

  useEffect(() => {
    Getinsurancedata();
    getadminInsurence()
  }, []);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  

  const {
    register,
    reset,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {

    data.agencyId = profiledata?._id;
    data.InNetwork = String(inNetwork);
    data.Claims = String(claims);
    data.Eligibility = String(eligibility);
    data.ERAs = String(eras);

    // console.log(data,'===>>>data')
    // return
    try {
      const response = await AddInsurance(data);
      // console.log(response?.data?.data, "respones====");
      ShowToast(response?.data?.message);
      reset();
      Getinsurancedata();
      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = async (id) => {
    // console.log("Deleted ID:", id);

    const confirmed = window.confirm(
      "Are you sure you want to delete this address?"
    );
    if (!confirmed) {
      return;
    }

    try {
      const response = await DeleteInsurance(`?id=${id}`);
      if (response?.data?.success) {
        ShowToast(response?.data?.message);
        Getinsurancedata();
      } else {
        ShowToast("Error: Unable to delete address.");
      }
    } catch (error) {
      console.error("Delete operation failed:", error);
      ShowToast("An error occurred while deleting the address.");
    }
  };


  const onSelect =(data)=>{
    setDefType(data.type)
    reset({
      CPID:data.CPID ,
      PayerID: data.playerId,
      InsuranceType:data.type ,
    });
  }

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "95vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    outline: "none",
    overflowY: "auto", 
  };

  return (
    <div
      style={{
        width: "100%",
        alignItems: "center",
        justifyItems: "center",
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h1>Insurance</h1>
        <div
          style={{
            backgroundColor: "#bc9c22",
            color: "white",
            padding: 10,
            borderRadius: 6,
          }}
          onClick={openModal}
        >
          Add Insurance
        </div>
      </div>

      <div style={{ overflowX: "auto", width: "100%", marginTop: 30 }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Insurance Name </th>
              <th style={tableHeaderStyle}>Insurance Type </th>
              <th style={tableHeaderStyle}>InsuranceAddress </th>
              <th style={tableHeaderStyle}>Claims </th>
              {/* <th style={tableHeaderStyle}>Eligibility</th> */}
              {/* <th style={tableHeaderStyle}>Email</th> */}
              <th style={tableHeaderStyle}>CPID</th>
              <th style={tableHeaderStyle}>Payer ID </th>
              <th style={tableHeaderStyle}>Action</th>
            </tr>
          </thead>
          <tbody>
            {insurance.length === 0 ? (
              <tr>
                <td
                  colSpan="3"
                  style={{ textAlign: "center", padding: "10px" }}
                >
                  No Insurance Found
                </td>
              </tr>
            ) : (
              insurance.map((row) => (
                <tr key={row._id} style={tableRowStyle}>
                  <td style={tableCellStyle}>{ row.adminInsurenceId ? row.adminInsurenceId.name: row?.name}</td>
                  <td style={tableCellStyle}>{row?.InsuranceType?row.InsuranceType:row.description}</td>
                  <td style={tableCellStyle}>{row?.InsuranceAddress?row.InsuranceAddress:"Not Available"}</td>
                  <td style={tableCellStyle}>{row?.ContactNumber?row?.ContactNumber:"Not Available"}</td>
                
                  {/* <td style={tableCellStyle}>{row?.Email?row.Email:"Not Available"}</td> */}
                  <td style={tableCellStyle}>{row?.CPID?row.CPID:"Not Available"}</td>
                  <td style={tableCellStyle}>{row?.PayerID ? row?.PayerID : "Not Available"}</td>

                  <td style={tableCellStyle}>
                    <button
                      style={deleteButtonStyle}
                      onClick={() => handleDelete(row._id)}
                    >
                      Delete
                    </button>

                  { !row.ERAs && !row.Eligibility  && !row.Claims  && <>
                    <button
                       style={deleteButtonStyle}
                    >
                      Enorll
                    </button>
                  </> }
                   

                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-title"
      >
        <Box sx={modalStyle}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "100%",
              alignItems: "center",
              justifyItems: "center",
              marginBottom: 20,
            }}
          >
            <h1 style={{ textAlign: "left" }}>New Insurance</h1>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <DropDownManuCustom
                label="Insurance Name"
                placeholder="Select option"
                options={adminInsurence}
                name="adminInsurenceId"
                dropdownicon
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={onSelect}

              />
              {errors.name && (
                <p style={{ color: "red" }}>{errors.name.message}</p>
              )}

              <DropDownManuCustom
                label="Insurance Type"
                placeholder="Select option"
                options={adminInsurence && adminInsurence?.map((e) => ({ name: e.type,_id:e._id }))}
                name="InsuranceType"
                dropdownicon
                register={register}
                id="_id"
                clearErrors={clearErrors}
                defaultValue={defType}
              />
              {errors.InsuranceType && (
                <p style={{ color: "red" }}>{errors.InsuranceType.message}</p>
              )}

              <Inputbox
                label="Insurance Address"
                className="Custom_input"
                type="text"
                placeholder="Enter Insurance Address"
                name="InsuranceAddress"
                register={register}
              />
              {errors.InsuranceAddress && (
                <p style={{ color: "red" }}>
                  {errors.InsuranceAddress.message}
                </p>
              )}

              <Inputbox
                label="Contact Number"
                type="text"
                placeholder="Enter Contact Number"
                name="ContactNumber"
                className="Custom_input"
                register={register}
              />
              {errors.ContactNumber && (
                <p style={{ color: "red" }}>{errors.ContactNumber.message}</p>
              )}

              <Inputbox
                label="Email"
                type="email"
                placeholder="Enter Email"
                name="Email"
                className="Custom_input"
                register={register}
              />
              {errors.Email && (
                <p style={{ color: "red" }}>{errors.Email.message}</p>
              )}

              <div
                className="form-group_two"
                style={{ marginTop: 20, marginBottom: 0 }}
              >
                <label style={{ width: 450 }}>
                  In Network (Check if this agency is in-network with this
                  insurance)
                </label>
                <input type="checkbox"  id="inNetwork" 
                checked={inNetwork} 
                onChange={(e) => setInNetwork(e.target.checked)} 
                className="form-checkbox" />
              </div>

              <Inputbox
                label="CPID"
                type="text"
                placeholder="Enter CPID"
                name="CPID"
                className="Custom_input"
                register={register}
              />
              {errors.CPID && (
                <p style={{ color: "red" }}>{errors.CPID.message}</p>
              )}

              <Inputbox
                label="Payer ID"
                type="text"
                placeholder="Enter Payer ID"
                name="PayerID"
                className="Custom_input"
                register={register}
              />
              {errors.PayerID && (
                <p style={{ color: "red" }}>{errors.PayerID.message}</p>
              )}

              <div className="checkbox-form">
                <div className="form-group_two">
                  <label style={{ width: 550 }}>
                    Claims Allowed Automatically
                  </label>
                  <input
                    type="checkbox"
                    id="claims" 
                    className="form-checkbox" 
                    checked={claims} 
                    onChange={(e) => setClaims(e.target.checked)} 
                  />
                </div>
                <div className="form-group_two">
                  <label style={{ width: 550 }}>
                    Eligibility Allowed Automatically
                  </label>
                  <input
                    type="checkbox"
                    id="eligibility" 
            className="form-checkbox" 
            checked={eligibility} 
            onChange={(e) => setEligibility(e.target.checked)} 
                  />
                </div>
                <div className="form-group_two">
                  <label style={{ width: 550 }}>
                    ERAs Allowed Automatically
                  </label>
                  <input type="checkbox" id="eras" 
            className="form-checkbox" 
            checked={eras} 
            onChange={(e) => setEras(e.target.checked)}  />
                </div>
              </div>

              <button
                type="submit"
                style={{
                  marginBottom: 20,
                  marginTop: 20,
                  alignSelf: "center",
                  width: "100%",
                  height: "50px",
                }}
                className="Save_buttonnn"
              >
                Submit
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default InsuranceScreen;
const tableHeaderStyle = {
  padding: "10px",
  borderBottom: "2px solid #ddd",
  textAlign: "left",
  fontWeight: "bold",
  backgroundColor: "#f4f4f4",
};

const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid #ddd",
};

const tableRowStyle = {
  borderBottom: "1px solid #ddd",
  // width:'60%'
};

const deleteButtonStyle = {
  backgroundColor: "red",
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
};
