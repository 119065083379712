import React, { useState } from "react";

const ReviewPopup = ({ onClose }) => {
  return (
    <div className="review-popup-overlay">
      <div className="review-popup-container">
        <div className="service_rating_txt">Service Rating</div>
        <div className="review-stars">
      <img src={require('../assest/Images/Vector.png')} style={{marginLeft:5}}/>
      <img src={require('../assest/Images/Vector.png')} style={{marginLeft:5}}/>
      <img src={require('../assest/Images/Vector.png')} style={{marginLeft:5}}/>
      <img src={require('../assest/Images/Vector.png')} style={{marginLeft:5}}/>
      <img src={require('../assest/Images/Vector.png')} style={{marginLeft:5}}/>
      
        </div>
        <div className="Review_txtt">Review</div>
        <div className="review-textarea">
          <textarea placeholder="Write a review..." />
        </div>
        <div className="review-popup-buttons">
          <button className="review-cancel-btn" onClick={onClose}>
            Cancel
          </button>
          <button className="review-save-btn">Save</button>
        </div>
      </div>
    </div>
  );
};

export default ReviewPopup;
