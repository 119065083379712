import axios from "axios";
import { Store } from "../redux";
import { setError, setLoading, setNetworkConnected, setNetworkSpeed } from "../redux/reducer";
import { ShowToast } from "../helpers/ToastService";

export const isWeb =  'web';

// Common axios instance
export const apiClient = axios.create({
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

// Axios instance for uploads
export const apiClientUpload = axios.create({
    headers: {
        "Content-Type": "multipart/form-data",
    },
});

// Request interceptor
apiClient.interceptors.request.use(
    (config) => {
        const state = Store.getState();
      
        Store.dispatch(setLoading(true));
        const token = state?.cookies?.headertoken?.token;
        // console.log(token)
        const isNetworkConnected = state.sliceReducer.isNetworkConnected;

        // if (!isNetworkConnected) {
        //     Alert.alert("Please check your Internet Connection");
        //     Store.dispatch(setLoading(false));
        //     return Promise.reject(new Error("No network connection"));
        // }

        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {

        Store.dispatch(setLoading(false));
        return Promise.reject(error);
    }
);

// Response interceptor
apiClient.interceptors.response.use(
    (response) => {
        Store.dispatch(setLoading(false));
        return response;
    },
    async (error) => {
        Store.dispatch(setLoading(false));
        const { response } = error;
        if (response) {
            console.log("Error Response:", response);  // Log the error response for debugging
            const errorMessage = response.data.message || "An error occurred";
            console.log(errorMessage,"errorMessage======>")
             ShowToast(errorMessage);
            Store.dispatch(setError(errorMessage));
            
        } else {
            ShowToast("Network error. Please try again.");
        }
        return Promise.reject(error);
    }
);

// Upload request interceptor
apiClientUpload.interceptors.request.use(
    (config) => {
        const state = Store.getState();
        const token = state?.cookies?.headertoken?.data?.token;
        console.log("Upload Token:", token);  // Log the token for debugging
        Store.dispatch(setLoading(true));

        if (token) {
            config.headers["Authorization"] = `${token}`;
        }

        return config;
    },
    (error) => {
        Store.dispatch(setLoading(false));
        return Promise.reject(error);
    }
);

// Upload response interceptor
apiClientUpload.interceptors.response.use(
    (response) => {
        Store.dispatch(setLoading(false));
        return response;
    },
    async (error) => {
        Store.dispatch(setLoading(false));
        const { response } = error;

        if (response) {
            console.log("Upload Error Response:", response);  // Log the error response for debugging
            const errorMessage = response.data.message || "An error occurred";
            Store.dispatch(setError(errorMessage));
            // ShowToast(errorMessage);
        } else {
            ShowToast("Network error. Please try again.");
        }

        return Promise.reject(error);
    }
);

// Network check initialization
export const NetWorkCheckInit = (NetInfo, Connection) => {
    return (dispatch) => {
      
            const connect = new Connection();
            connect.init();
            if (connect.info) {
                dispatch(setNetworkSpeed(connect.info.rtt));
            } else {
                dispatch(setNetworkSpeed("not supported"));
            }
            dispatch(setNetworkConnected(window.navigator.onLine));

            window.addEventListener('offline', () => {
                dispatch(setNetworkConnected(false));
            });

            window.addEventListener('online', () => {
                dispatch(setNetworkConnected(true));
            });
       
            // Uncomment this block if you are using NetInfo for native platforms
            /*
            NetInfo.addEventListener(state => {
                dispatch(setNetworkConnected(state.isConnected));
                if (state.type === "wifi" && state?.details) {
                    dispatch(setNetworkSpeed(state?.details?.strength !== undefined ? state?.details?.strength : "not supported"));
                } else {
                    dispatch(setNetworkSpeed("not supported"));
                }
            });
            */
        
    };
};
