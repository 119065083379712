import React from 'react'

const TodayAppointmentsData = () => {
    const appointmentsData = [
        {
        //   id: 1,
        //   image: 'https://via.placeholder.com/50',
          clinicName: 'Birmingham Dental Clinic',
          email: 'clinic@gmail.com',
          phoneNumber: '9876543210',
          city: 'Ocean Park',
          timing: '11:00 - 11:30 AM',
        },
      ];
  return (
    <div className="dashboard-cards" style={{ paddingLeft: 30 ,paddingRight:30}}>
    <div className="appointments-table-container1">
    <table className="appointments-table1">
      <thead>
        <tr>
          {/* <th>ID</th> */}
          {/* <th>Image</th> */}
          <th>Clinic Name</th>
          <th>Email</th>
          <th>Phone Number</th>
          <th>City</th>
          <th>Timing</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {appointmentsData.map((appointment) => (
          <tr key={appointment.id}>
            {/* <td>{appointment.id}</td> */}
            {/* <td><img src={appointment.image} alt="Client" className="client-image" /></td> */}
            <td>{appointment.clinicName}</td>
            <td>{appointment.email}</td>
            <td>{appointment.phoneNumber}</td>
            <td>{appointment.city}</td>
            <td>{appointment.timing}</td>
            <td><button className="view-button">View</button></td>
          </tr>
        ))}
      </tbody>
    </table>
  </div> 
  </div>
  )
}

export default TodayAppointmentsData

