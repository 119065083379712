// import React from 'react'

// const MyClinicPatient = () => {
//   return (
// <div>
//     hello
// </div>
//   )
// }

// export default MyClinicPatient
import React, { useEffect, useState } from "react";
import { useNavigate ,} from "react-router-dom";
import { Getmyclinicpatient } from "../../../api/helper";
import { useSelector } from "react-redux";

function MyClinicPatient() {
    const [doctordata, setdoctordata] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); 
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const [limit] = useState(10);
    const profiledata = useSelector(state => state?.cookies?.userDetails);
// console.log(profiledata?._id,"id=====")
    const Getrequest = async (page) => {
    //   try {
    //     let data = {
    //       limit:50,
    //       page:1,
    //     }
    //     const response = await GetDoctorrequest(data);
    //     setdoctordata(response?.data?.data?.data);
    //     setTotalPages(response?.data?.data?.totalPages);
    //   } catch (error) {
    //     console.log(error, "error=====");
    //   }
    try {
        let data = {
          limit:50,
          page:1,
          patientId:profiledata?._id,
          status:"approved"
        }
        const response = await Getmyclinicpatient(data);
        console.log(response?.data.data?.response, "doctor=-=-======");
        setdoctordata(response?.data.data?.response);
        setTotalPages(response?.data?.data?.totalPages);
      } catch (error) {
        console.log(error, "error=====");
      }
    };
    useEffect(() => {
      Getrequest(currentPage); 
    }, [currentPage]);
  
   
    const handlePageChange = (newPage) => {
      if (newPage > 0 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    };
  
    const handleView = (id) => {
        console.log('helloo===')
    //   navigate("/DoctorClinicDetails", { state: { clinicId: id } }); 
    };
    return (
      <div className="clinics-table-container">
        <table className="clinics-table">
          <thead>
            <tr>
              <th>Image</th>
              <th>Clinic Name</th>
              <th>Address</th>
              <th>Open Days</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
  
            {doctordata && doctordata.length > 0 ? (
              doctordata.map((clinic, index) => (
                <tr key={index}>
                  <td>
                    {clinic?.agencyId?.image ? (
                      <img
                        src={clinic?.agencyId?.image} 
                        alt={`${clinic?.agencyId?.companyName} `}
                        className="clinic-image"
                      />
                    ) : (
                      <img
                        src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                        className="clinic-image"
                      />
                    )}
                    {/* <img
                    src={clinic.image} // Correct image property
                    alt={`${clinic.firstName} ${clinic.lastName}`}
                    className="clinic-image"
                  /> */}
                  </td>
                  <td>
                    {clinic?.agencyId?.companyName}
                  </td>{" "}
                  {/* Clinic name */}
                  <td>
                    {clinic?.agencyId?.address?.street}, {clinic?.agencyId?.address?.city},{" "}
                    {clinic?.agencyId?.address?.state}, {clinic?.agencyId?.address?.country}
                  </td>{" "}
                  {/* Address */}
                  <td>Mon-Fri</td> {/* Open days placeholder */}
                  <td>
                  <button
                    className="view-button"
                    onClick={() => handleView(clinic?.agencyId?._id)}
                  >
                    View
                  </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No clinics found</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="pagination">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
  
        <div className="dashborad_plus">+</div>
      </div>
    );
}

export default MyClinicPatient