import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import Inputbox from "../../Components/Inputbox";
import { Addsubscription, Deletesubscription, Fetchsubscription, GetCategory } from "../../../api/helper";
import { DropDownManuCustomMulti } from "../../Components/DropDownButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { ShowToast } from "../../../helpers/ToastService";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Plan Name is required"),
    categoryId: Yup.array()
    .of(Yup.string().required("A Specialty must be selected"))
    .min(1, "At least one Specialty is required")
    .required("Specialty is required"),

    price: Yup.number()
    .typeError("Monthly Fee must be a number")
    .positive("Monthly Fee must be greater than zero")
    .required("Monthly Fee is required"),
    Benefits: Yup.string().required("Benefits are required"),
  AddOnServices: Yup.string(), // Optional field
  AddIndividualItems: Yup.string().required("Individual Items are required"),
});

const SubscriptionScreen = () => {
  const [showModal, setShowModal] = useState(false);
  const [specialtydata, setspecialtydata] = useState(null);
  const [data, setdata] = useState([])
  const profiledata = useSelector((state) => state?.cookies?.userDetails);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const getSpecialty = async () => {
    try {
      const resposne = await GetCategory();
      setspecialtydata(resposne?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getsubscription = async () => {
    try {
      const resposne = await Fetchsubscription(profiledata?._id);
    //  console.log(resposne?.data?.data,"helloo-=====")
    setdata(resposne?.data?.data)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSpecialty();
    getsubscription()
  }, []);
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit =async (data) => {
    data.agencyId =profiledata?._id
    console.log(data,"hello");
    try {
        const response = await Addsubscription(data)
        // console.log(response?.data?.data,"response======")
        ShowToast(response?.data?.message)
        getsubscription()
    } catch (error) {
        console.log(error)
    }
   
    closeModal();
    reset();
  };

  const handleSelect = (selectedOptions) => {
    console.log("selectedOptions:", selectedOptions);
    let ids = Array.isArray(selectedOptions)
      ? selectedOptions.map((e) => e?._id)
      : [];

    setValue("categoryId", ids);
  };
  const handleDelete = async (id) => {
    console.log("Deleted ID:", id);

    const confirmed = window.confirm(
      "Are you sure you want to delete this address?"
    );
    if (!confirmed) {
      return;
    }

    try {
      const response = await Deletesubscription(id);
      if (response?.data?.success) {
        ShowToast(response?.data?.message);
      getsubscription()
      } else {
        ShowToast("Error: Unable to delete address.");
      }
    } catch (error) {
      console.error("Delete operation failed:", error);
      ShowToast("An error occurred while deleting the address.");
    }
  };
  return (
    <div
      style={{
        width: "100%",
        alignItems: "center",
        justifyItems: "center",
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <h1>Available Plans for Clients</h1>
        <div
          style={{
            backgroundColor: "#bc9c22",
            color: "white",
            padding: 10,
            borderRadius: 6,
          }}
          onClick={openModal}
        >
          Add subscription
        </div>
      </div>

      <div style={{ overflowX: "auto", width: "100%", marginTop: 30 }}>
      {data && data.length > 0 ? (
        <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <thead>
            <tr>
              <th style={{ padding: "8px", border: "1px solid #ddd" }}>Name</th>
              <th style={{ padding: "8px", border: "1px solid #ddd" }}>Price</th>
              <th style={{ padding: "8px", border: "1px solid #ddd" }}>Categories</th>
              <th style={{ padding: "8px", border: "1px solid #ddd" }}>Add-On Services</th>
              <th style={{ padding: "8px", border: "1px solid #ddd" }}>Benefits</th>
              <th style={{ padding: "8px", border: "1px solid #ddd" }}>Added Items</th>
              <th style={{ padding: "8px", border: "1px solid #ddd" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item._id}>
                <td style={{ padding: "8px", border: "1px solid #ddd" }}>{item.name}</td>
                <td style={{ padding: "8px", border: "1px solid #ddd" }}>{item.price}</td>
                <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                  {item.categoryId.map((category) => category.name).join(", ")}
                </td>
                <td style={{ padding: "8px", border: "1px solid #ddd" }}>{item.AddOnServices}</td>
                <td style={{ padding: "8px", border: "1px solid #ddd" }}>{item.Benefits}</td>
                <td style={{ padding: "8px", border: "1px solid #ddd" }}>{item.AddIndividualItems}</td>
                <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                <button
                  style={deleteButtonStyle}
                  onClick={() => handleDelete(item._id)}
                >
                  Delete
                </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div style={{ textAlign: "center", marginTop: 20 }}>No subscription found</div>
      )}

      </div>
      <Modal
        open={showModal}
        onClose={closeModal}
        aria-labelledby="modal-title"
      >
        <Box sx={modalStyle}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "100%",
              alignItems: "center",
              justifyItems: "center",
              marginBottom: 20,
            }}
          >
            <h1 style={{ textAlign: "left" }}>Add Subscription</h1>
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Inputbox
                label="Plan Name"
                className="Custom_input"
                type="text"
                placeholder="Enter Plan Name"
                name="name"
                register={register}
              />
              {errors.name && (
                <div className="errorMessage">{errors.name.message}</div>
              )}

              <DropDownManuCustomMulti
                label="Specialities"
                placeholder="Select option"
                options={specialtydata}
                name="categoryId"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={handleSelect}
              />

              {errors.categoryId && (
                <div className="errorMessage">{errors.categoryId.message}</div>
              )}
              <Inputbox
                label="Monthly Fee"
                className="Custom_input"
                type="text"
                placeholder="Enter Monthly Fee"
                name="price"
                register={register}
              />
              {errors.price && (
                <div className="errorMessage">{errors.price.message}</div>
              )}

              <Inputbox
                label="Benefits"
                className="Custom_input"
                type="text"
                placeholder="Enter Benefits"
                name="Benefits"
                register={register}
              />
              {errors.Benefits && (
                <div className="errorMessage">{errors.Benefits.message}</div>
              )}

              <Inputbox
                label="Add-On Services (Optional)"
                className="Custom_input"
                type="text"
                placeholder="Enter Add-On Services"
                name="AddOnServices"
                register={register}
              />

              <Inputbox
                label="Add Individual Items"
                className="Custom_input"
                type="text"
                placeholder="Enter Items"
                name="AddIndividualItems"
                register={register}
              />
              {errors.AddIndividualItems && (
                <div className="errorMessage">
                  {errors.AddIndividualItems.message}
                </div>
              )}
              <button
                type="submit"
                style={{
                  marginBottom: 20,
                  marginTop: 20,
                  alignSelf: "center",
                  width: "100%",
                  height: "50px",
                }}
                className="Save_buttonnn"
              >
                Add Item
              </button>
              <button
                type="submit"
                style={{
                  marginBottom: 20,
                  marginTop: 20,
                  alignSelf: "center",
                  width: "100%",
                  height: "50px",
                }}
                className="Save_buttonnn"
              >
                Create Plan
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default SubscriptionScreen;
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Increased width for smaller screens
  maxWidth: 600, // Sets a maximum width for larger screens
  maxHeight: "90vh", // Limits the height to 90% of the viewport height
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  outline: "none",
  overflowY: "auto", // Enables vertical scrolling when content overflows
};
  
const deleteButtonStyle = {
    backgroundColor: "red",
    color: "white",
    border: "none",
    borderRadius: "4px",
    padding: "5px 10px",
    cursor: "pointer",
  };
  