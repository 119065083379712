import React from 'react'
import Inputbox from '../../Components/Inputbox'
import DropDownButton, { DropDownManuCustom, DropDownManuCustomMulti } from '../../Components/DropDownButton'
import { useForm } from 'react-hook-form';
const Specialtiesdata = ({ info, special ,getDteails}) => {
  // console.log(special,'===special')
  // console.log(info, '===info')
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    clearErrors,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      licenseNumber:info?.licenseNumber,
      Education:info?.Education,
      categoryId:info?.categoryId
    }
  });
  const handleSelect = (selectedOptions) => {
    // console.log('helloooo===',selectedOptions)
        let ids = selectedOptions.map((e) => e?._id);
      
        setValue("categoryId", ids, { shouldValidate: true });
      };
      
  return (
    <div>
      <form>
      <div className="input-row">
    
           <DropDownManuCustomMulti
                label="Specialty"
                placeholder="Select option"
                options={special}
                name="categoryId"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={handleSelect} 
              />

        <div style={{ width: "40px" }} />
        <Inputbox
          type="text"
          label="License "
          placeholder="Institute Name"
          register={register}
          name="licenseNumber"
        />
        <div style={{ width: "40px" }} />
        <Inputbox
          type="text"
          label="Board Certifications"
          placeholder="Enter Certifications"
          register={register}
          name="BoardCertifications"
        />
      </div>
      </form>
    </div>
  )
}

export default Specialtiesdata