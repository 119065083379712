import React, { useEffect, useState } from "react";
import { Providerlist } from "../../../api/helper";
import { useNavigate } from "react-router-dom";

const ProvidersDataPatient = () => {
  const [providerdata, setproviderdata] = useState(null);
  const navigate = useNavigate();


  const GetProvider = async () => {
    try {
      const response = await Providerlist();
      console.log(response?.data?.data?.data,'===>>>>');
      setproviderdata(response?.data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetProvider();
  }, []);

  console.log(providerdata,'====providerdata')
  return (
    <div
      className="dashboard-cards"
      style={{ paddingLeft: 30, paddingRight: 30 }}
    >
      <div className="appointments-table-container12">
        <table className="appointments-table12">
          <thead>
            <tr>
              {/* <th>ID</th> */}
              <th>Image</th>
              <th>Full Name</th>
              <th>Specialization</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Address</th>
              <th>Years of Experience</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {providerdata && providerdata?.length > 0 ? (
              providerdata.map((appointment) => (
                <tr key={appointment._id}>
                  <td>
                    {appointment?.image ? (
                      <img src={appointment?.image} className="client-image1" />
                    ) : (
                      <img
                        src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                        className="client-image1"
                      />
                    )}
                  </td>
                  <td>{appointment?.firstName + " " + appointment?.lastName}</td>
                  <td>{appointment?.categoryId[0]?.name}</td>
                  <td>{appointment?.phone}</td>
                  <td>{appointment?.email}</td>
                  <td>{appointment?.practiceLocation}</td>
                  <td>{appointment?.experience}</td>
                  <td>
                    <div
                      onClick={() => {
                        console.log(appointment?._id); 
                        navigate("/BookAppointments", {
                          state: { providerId: appointment?._id },
                        }); 
                      }}
                      className="book_now_class"
                    >
                      Book Now
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <div>No Providers Found</div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProvidersDataPatient;
