import React, { useState } from "react";
import AgencySideBar from "../Components/AgencySideBar";
import Navbar from "../../Components/Navbar";
import AllPatients from "../../Components/AllPatients";
import NewRequestspatients from "../../Components/NewRequestspatients";
import ClinicsDoctorsdata from "./ClinicsDoctorsdata";
import DoctorRequestsdata from "./DoctorRequestsdata";
import { useNavigate } from "react-router-dom";

const ProvidersScreen = () => {
  const [activeTab, setActiveTab] = useState("Clinics Doctors");
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const navigate = useNavigate();
  return (
    <div className="dashboard-container">
      <AgencySideBar />
      <div className="dashboard-content">
        <Navbar 
        onClick= {()=>{navigate("/MyProfileAgency")}}
        />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30 }}
        >
          <div className="clinic-providers-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="headerheader">
                <div className="CLINIC-PROVIDERS-txt">Clinic Providers </div>
                <p className="clicnic-provider-paragraf">
                  Customize Mind Well Connect according to your Clinic needs
                </p>
              </div>
              <button
                onClick={() => navigate("/AddProvider")}
                className="add-provider-button"
              >
                Add a New Provider
              </button>
            </div>


            <div className="buttons">
              <button
                className={`tab-buttontwoo ${
                  activeTab === "Clinics Doctors" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Clinics Doctors")}
              >
                Clinics Doctors
              </button>
              <button
                className={`tab-buttontwoo ${
                  activeTab === "Doctor Requests" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Doctor Requests")}
              >
                Doctor Requests
              </button>
              {/* <button
                className={`tab-buttontwoo ${
                  activeTab === "" ? "active" : ""
                }`}
                // onClick={() => handleTabClick("Doctor Requests")}
              >
             Remove Doctors
              </button> */}
            </div>



            {activeTab === "Clinics Doctors" && <ClinicsDoctorsdata />}
            {activeTab === "Doctor Requests" && <DoctorRequestsdata />}
            {/* {activeTab === "review" && <ReviewPatient />} */}
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvidersScreen;
