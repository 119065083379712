import React, { useState } from "react";
import DropDownButton from "../Components/DropDownButton"
import { height } from "@mui/system";
// import DropDownButton from "../../Components/DropDownButton";

const OverviewPatient = () => {
    const [isintakeFormVisible, setIsintKEFormVisible] = useState(false);
    const toggleintakeFormVisibility = () => {
      setIsintKEFormVisible(!isintakeFormVisible);
    };
  return (
    <div className="patient_right">
      <div className="paitent_Head_buttons_div">
        <div>
          <div className="patient_education_button">
            <div className="notes_img_background">
              <img
                src={require("../assest/Images/notes_Icon.png")}
                alt="Notes Icon"
              />
            </div>
            <div className="last_signed_txt">Last Signed Note</div>
            <img
              src={require("../assest/Images/twosidearrow.png")}
              alt="Arrow"
            />
          </div>
          <div className="patient_education_button">
            <div className="notes_img_background">
              <img
                src={require("../assest/Images/book_icon.png")}
                alt="Book Icon"
              />
            </div>
            <div className="last_signed_txt">Education</div>
            <img
              src={require("../assest/Images/twosidearrow.png")}
              alt="Arrow"
            />
          </div>
        </div>
        <div style={{ marginLeft: 30 }} onClick={toggleintakeFormVisibility}>
          <div className="patient_education_button">
            <div className="notes_img_background">
              <img
                src={require("../assest/Images/notes_Icon.png")}
                alt="Notes Icon"
              />
            </div>
            <div className="last_signed_txt" >Intake Forms</div>
            <img
              src={require("../assest/Images/twosidearrow.png")}
              alt="Arrow"
            />
          </div>
          <div className="patient_education_button">
            <div className="notes_img_background">
              <img
                src={require("../assest/Images/book_icon.png")}
                alt="Notes Icon"
              />
            </div>
            <div className="last_signed_txt">Create New Appointment</div>
          </div>
        </div>
      </div>

      
      <div className="Lifestyle_Determinants_main_div1">
        <div className="Lifestyle_Determinants_chart1">
          <div className="Lifestyle_Determinants_div1">
            Lifestyle Determinants
            <img
              src={require("../assest/Images/pen_icon.png")}
              alt="Pen Icon"
            />
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Smoking</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Alcohol</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Caffeine</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Recreational Drugs</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Special Diet</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Regularly</div>
            <div className="smoking_txt1">Yes</div>
          </div>
        </div>


        <div className="Lifestyle_Determinants_chart1">
          <div className="Lifestyle_Determinants_div1">
            Family History
            <img
              src={require("../assest/Images/plus_icon.png")}
              alt="Plus Icon"
            />
          </div>
          <p className="Overview_Mother_text">Mother</p>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Date of Birth</div>
            <div className="smoking_txt1">Jan 17, 1975 (49 years)</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Problems</div>
            <div className="smoking_txt1">-</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Life Status</div>
            <div className="smoking_txt1">Deceased</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Date of Death</div>
            <div className="smoking_txt1">Jul 17,2024</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Cause of Death</div>
            <div className="smoking_txt1">-</div>
          </div>
        </div>

        <div className="Lifestyle_Determinants_chart1">
          <div className="Lifestyle_Determinants_div1">
            Medical History
            <img
              src={require("../assest/Images/plus_icon.png")}
              alt="Plus Icon"
            />
          </div>
          <p className="Blood_txt1">Blood type & RAH</p>
          <div className="OverView_space-div" style={{ width: "100%", gap: "6%" , marginTop:-15}}>
            <DropDownButton
              // label="Select"
              placeholder="Select"
              dropdownicon
              style={{ width: "47%" }}
              divstyle={{ backgroundColor: "#fff",height:35 }}
              options={[
                "1199SEIU Funds",
                "ACS Benefit Service",
                "Aetna",
                "Aither Health",
                "Banner (Aetna)",
                "Benefit Management Inc.",
                "Benefits Administration Services",
                "Assurant (Cigna)",
              ]}
            />
           <DropDownButton
              // label="Select"
              placeholder="Select"
              dropdownicon
              style={{ width: "47%" }}
              divstyle={{ backgroundColor: "#fff",height:35 }}
              options={[
                "1199SEIU Funds",
                "ACS Benefit Service",
                "Aetna",
                "Aither Health",
                "Banner (Aetna)",
                "Benefit Management Inc.",
                "Benefits Administration Services",
                "Assurant (Cigna)",
              ]}
            />
          </div>
          <p className="Blood_txt1">Custom Items</p>
          <div className="OverView_space-div" style={{ width: "100%", gap: "6%" , marginTop:-15}}>
          <DropDownButton
              // label="Member ID"
              placeholder=" "
              style={{ width: "47%"}}
              divstyle={{ backgroundColor: "#fff", height:35}}
            />
            <DropDownButton
              // label="Member ID"
              placeholder=" "
              style={{ width: "47%"}}
              divstyle={{ backgroundColor: "#fff", height:35}}
            />
          </div>
        </div>
  
      </div>



      <div className="Lifestyle_Determinants_main_div_two1">
        <div className="Lifestyle_Determinants_chart1">
          <div className="Lifestyle_Determinants_div1">
            Mental Health History
            <img
              src={require("../assest/Images/pen_icon.png")}
              alt="Pen Icon"
            />
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Mood Disorder</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Psychotic Disorder</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Eating Disorder</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1"> Anxiety Disorder</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Personality Disorder</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Other Conditions</div>
            <div className="smoking_txt1">Yes</div>
          </div>
        </div>
        <div
          className="Lifestyle_Determinants_chart1"
          style={{ marginLeft: 20 }}
        >
          <div className="Lifestyle_Determinants_div1">
            Substance Use History
            <img
              src={require("../assest/Images/plus_icon.png")}
              alt="Plus Icon"
            />
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Ecstasy</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Benzodiazepines</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Amphetamines</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1"> Opioids</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Buprenorphines</div>
            <div className="smoking_txt1">Yes</div>
          </div>
          <div className="space_between" style={{ marginTop: 19 }}>
            <div className="smoking_txt1">Cannabis</div>
            <div className="smoking_txt1">Yes</div>
          </div>
        </div>
      </div>
      {isintakeFormVisible && (
        <div className="INTAKE_Chart_popup">
            
            <div className="custom-table-container">
      <table className="custom-table">
        <thead>
          <tr className="custom-table-header">
            <th className="custom-select-column">Select</th>
            <th className="custom-form-name-column">Form Name</th>
            <th className="custom-status-column">Status</th>
            <th className="custom-sent-column">Sent</th>
            <th className="custom-completed-column">Completed</th>
            <th className="custom-actions-column">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr className="custom-table-row">
            <td>
              <input type="checkbox" className="custom-checkbox" />
            </td>
            <td className="custom-form-name">PHQ9</td>
            <td className="custom-form-name">Sent</td>
            <td className="custom-form-name">8/2</td>
            <td className="custom-form-name">-</td>
            <td>
              <button className="custom-edit-button">Edit</button>
            </td>
          </tr>
          <tr className="custom-table-row">
            <td>
              <input type="checkbox" className="custom-checkbox" />
            </td>
            <td className="custom-form-name">PHQ9</td>
            <td className="custom-form-name">Sent</td>
            <td className="custom-form-name">8/2</td>
            <td className="custom-form-name">-</td>
            <td>
              <button className="custom-edit-button">Edit</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

        </div>
    )}
    </div>
  );
};

export default OverviewPatient;
