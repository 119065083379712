import React, { useEffect, useRef, useState } from "react";
import AgencySideBar from "../Components/AgencySideBar";
import Navbar from "../../Components/Navbar";
import { useNavigate } from "react-router-dom";
import { DropDownManuCustom, DropDownManuCustomMulti, DropDownMultiStringSelect } from "../../Components/DropDownButton";
import {
  ClinicProfileGet,
  Clinicrequestlist,
  createAgencyDoctor,
  GetCategory,
  JoinClinic,
  uploadImage,
} from "../../../api/helper";
import Inputbox from "../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ShowToast } from "../../../helpers/ToastService";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { Dropdowniconn } from "../../assest/SvgImages";
import { Box, Modal } from "@mui/material";
import Loader from "../Components/Loader";
import {  useLoadScript, Autocomplete } from '@react-google-maps/api';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .min(2, "First Name must be at least 2 characters"),

  lastName: Yup.string()
    .required("Last Name is required")
    .min(2, "Last Name must be at least 2 characters"),

  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  phone: Yup.string().required("Phone number is required"),
  DOB: Yup.date().required("Date of birth is required"),
  experience: Yup.string().required("Experience is required"),
  NPI: Yup.string().required("NPI is required"),
  // NPIType: Yup.string().required("NPI type is required"),
  // EnumerationDate: Yup.date()
  // .typeError("Enumeration date must be a valid date")
  // .required("Enumeration date is required"),

  categoryId: Yup.array()
  .of(Yup.string().required("A specialty must be selected"))
  .min(1, "At least one specialty is required")
  .required("Specialty is required"),

  // licenseNumber: Yup.string().required("License number is required"),
  info: Yup.string().required("Info is required"),
  gender: Yup.string().required("Please select a gender"),
  language: Yup.string().required("Please select a language"),
  practiceLocation: Yup.string().required("Practice Location is required"),
  PrimaryLicenseNo: Yup.string().required("Primary License Number is required"),
  primaryStateOfPractice: Yup.string().required(
    "Primary State of Practice is required",
  ),
  LicenseType: Yup.string()
    .required("Credentials Type is required")   ,
  address: Yup.object().shape({
    // street: Yup.string().required('Street is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    zipCode: Yup.string()
      .required('Zip code is required')
      .matches(/^[0-9]+$/, 'Zip code must be digits')
      .min(5, 'Zip code must be at least 5 digits')
      .max(10, 'Zip code cannot exceed 10 digits'),
  }),
  // status: Yup.string().required("Status is required"),
});

const licenseTypes = [


  { _id: 'Nursing License', name: 'Nursing License' },
  { _id: 'Medical License', name: 'Medical License' },
  { _id: 'Psychology License', name: 'Psychology License' },
  { _id: "Bachelor's in Nursing", name: "Bachelor's in Nursing" },
  { _id: "Master's in Psychology", name: "Master's in Psychology" },
  { _id: 'Doctorate in Psychiatry', name: 'Doctorate in Psychiatry' },
  { _id: 'CPR Certification', name: 'CPR Certification' },
  { _id: 'Mental Health Certification', name: 'Mental Health Certification' },
  { _id: 'Addiction Treatment Certification', name: 'Addiction Treatment Certification' },

  { _id: 'cbt', name: 'Cognitive Behavioral (CBT)' },
  { _id: 'dbt', name: 'Dialectical Behavioral (DBT)' },
  { _id: 'act', name: 'Acceptance & Commitment (ACT)' },
  { _id: 'mbct', name: 'Mindfulness-Based (MBCT)' },
  { _id: 'motivational_interviewing', name: 'Motivational Interviewing' },
  { _id: 'psychodynamic', name: 'Psychodynamic Therapy' },
  { _id: 'sbt', name: 'Solution Focused Brief Therapy (SFBT)' },
  { _id: 'trauma_focused', name: 'Trauma-Focused Therapy' },

  { _id: 'individual_therapy', name: 'Individual Therapy' },
  { _id: 'group_therapy', name: 'Group Therapy' },
  { _id: 'family_therapy', name: 'Family Therapy' },
  { _id: 'couples_therapy', name: 'Couples Therapy' },
  { _id: 'crisis_intervention', name: 'Crisis Intervention' },
  { _id: 'medication_management', name: 'Medication Management' },


  { _id: 'elders', name: 'Elders (65 and above)' },
  { _id: 'adults', name: 'Adults (18 to 64)' },
  { _id: 'teenagers', name: 'Teenagers (13 to 17)' },
  { _id: 'children', name: 'Children (6 to 12)' },

  { _id: 'anxiety', name: 'Anxiety' },
  { _id: 'depression', name: 'Depression' },
  { _id: 'adhd', name: 'ADHD' },
  { _id: 'bipolar_disorder', name: 'Bipolar Disorder' },
  { _id: 'ptsd', name: 'PTSD' },
  { _id: 'eating_disorders', name: 'Eating Disorders' },
  { _id: 'substance_use', name: 'Substance Use Disorders' },
  { _id: 'sleep_disorders', name: 'Sleep Disorders' },
  { _id: 'anger_management', name: 'Anger Management' },
  { _id: 'grief', name: 'Grief and Loss' },
  { _id: 'trauma', name: 'Trauma' },
  { _id: 'relationship_issues', name: 'Relationship Issues' },

];

const primaryStatesOfPractice = [
  { _id: "1", name: "Alabama", abbreviation: "AL" },
  { _id: "2", name: "Alaska", abbreviation: "AK" },
  { _id: "3", name: "Arizona", abbreviation: "AZ" },
  { _id: "4", name: "Arkansas", abbreviation: "AR" },
  { _id: "5", name: "California", abbreviation: "CA" },
  { _id: "6", name: "Colorado", abbreviation: "CO" },
  { _id: "7", name: "Connecticut", abbreviation: "CT" },
  { _id: "8", name: "Delaware", abbreviation: "DE" },
  { _id: "9", name: "Florida", abbreviation: "FL" },
  { _id: "10", name: "Georgia", abbreviation: "GA" },
  { _id: "11", name: "Hawaii", abbreviation: "HI" },
  { _id: "12", name: "Idaho", abbreviation: "ID" },
  { _id: "13", name: "Illinois", abbreviation: "IL" },
  { _id: "14", name: "Indiana", abbreviation: "IN" },
  { _id: "15", name: "Iowa", abbreviation: "IA" },
  { _id: "16", name: "Kansas", abbreviation: "KS" },
  { _id: "17", name: "Kentucky", abbreviation: "KY" },
  { _id: "18", name: "Louisiana", abbreviation: "LA" },
  { _id: "19", name: "Maine", abbreviation: "ME" },
  { _id: "20", name: "Maryland", abbreviation: "MD" },
  { _id: "21", name: "Massachusetts", abbreviation: "MA" },
  { _id: "22", name: "Michigan", abbreviation: "MI" },
  { _id: "23", name: "Minnesota", abbreviation: "MN" },
  { _id: "24", name: "Mississippi", abbreviation: "MS" },
  { _id: "25", name: "Missouri", abbreviation: "MO" },
  { _id: "26", name: "Montana", abbreviation: "MT" },
  { _id: "27", name: "Nebraska", abbreviation: "NE" },
  { _id: "28", name: "Nevada", abbreviation: "NV" },
  { _id: "29", name: "New Hampshire", abbreviation: "NH" },
  { _id: "30", name: "New Jersey", abbreviation: "NJ" },
  { _id: "31", name: "New Mexico", abbreviation: "NM" },
  { _id: "32", name: "New York", abbreviation: "NY" },
  { _id: "33", name: "North Carolina", abbreviation: "NC" },
  { _id: "34", name: "North Dakota", abbreviation: "ND" },
  { _id: "35", name: "Ohio", abbreviation: "OH" },
  { _id: "36", name: "Oklahoma", abbreviation: "OK" },
  { _id: "37", name: "Oregon", abbreviation: "OR" },
  { _id: "38", name: "Pennsylvania", abbreviation: "PA" },
  { _id: "39", name: "Rhode Island", abbreviation: "RI" },
  { _id: "40", name: "South Carolina", abbreviation: "SC" },
  { _id: "41", name: "South Dakota", abbreviation: "SD" },
  { _id: "42", name: "Tennessee", abbreviation: "TN" },
  { _id: "43", name: "Texas", abbreviation: "TX" },
  { _id: "44", name: "Utah", abbreviation: "UT" },
  { _id: "45", name: "Vermont", abbreviation: "VT" },
  { _id: "46", name: "Virginia", abbreviation: "VA" },
  { _id: "47", name: "Washington", abbreviation: "WA" },
  { _id: "48", name: "West Virginia", abbreviation: "WV" },
  { _id: "49", name: "Wisconsin", abbreviation: "WI" },
  { _id: "50", name: "Wyoming", abbreviation: "WY" },
];

const AddProvider = () => {
  const navigate = useNavigate();
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  // console.log(profiledata._id,'===profiledata')

  const [specialtydata, setspecialtydata] = useState(null);
  const [imaeg, setImages] = useState(null);
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [cliniclistdata, setcliniclistdata] = useState(null);
  const [showavailibleday, setShowavailibleday] = useState(false);
  const [availableDays, setAvailableDays] = useState([]);
  const { isLoading } = useSelector((store) => store.sliceReducer);
  const [autocomplete, setAutocomplete] = useState(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBTgjMWeFMxL5oe-KFnKts3YGBZJlEC6eM',
    libraries: ['places'], 
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const handleChange = (e) => {
    reset({
      phone: e,
    });
  };
  const handleSelect = (selectedOptions) => {
console.log(selectedOptions,"helloooooooo==o==o")
    let ids = selectedOptions.map((e) => e?._id);
  
    setValue("categoryId", ids, { shouldValidate: true });
  };
  const handlePlaceSelect = async () => {
    const place = autocomplete.getPlace();
    const addressComponents = place.address_components;
  
    // Function to get address component by type
    const getAddressComponent = (type) => {
      const component = addressComponents.find((c) => c.types.includes(type));
      return component ? component.long_name : "";
    };
  
    // Set country, state, and city
    setValue("address.country", getAddressComponent("country"));
    setValue("address.state", getAddressComponent("administrative_area_level_1"));
    setValue("address.city", getAddressComponent("locality"));
  
    // Extract street number, route, sublocality (for sectors), neighborhood, premise (building), establishment (business)
    const streetNumber = getAddressComponent("street_number");
    const route = getAddressComponent("route");
    const sublocality = getAddressComponent("sublocality");
    const neighborhood = getAddressComponent("neighborhood");
    const premise = getAddressComponent("premise"); // Building name or room number
    const establishment = getAddressComponent("establishment"); // Business names
  
    // Declare fullStreet and build it
    let fullStreet = "";
  
    // Priority handling: check if it's an establishment (business), premise, or standard street address
    if (establishment) {
      fullStreet = establishment; // Use business name if available
    } else if (premise) {
      fullStreet = `${premise}, ${route || sublocality || neighborhood}`; // Use building/room with route
    } else if (streetNumber && route) {
      fullStreet = `${streetNumber} ${route}`; // Standard street address
    } else if (sublocality) {
      fullStreet = sublocality; // For sectors
    } else if (neighborhood) {
      fullStreet = neighborhood; // If categorized as neighborhood
    }
  
    // Fallback: if no fullStreet found, use locality, state, country
    if (!fullStreet) {
      fullStreet = `${getAddressComponent("locality")}, ${getAddressComponent("administrative_area_level_1")}, ${getAddressComponent("country")}`;
    }
  
    // Set the full street address in the form
    setValue("address.street", fullStreet);
  
    // Set the zip code
    setValue("address.zipCode", getAddressComponent("postal_code"));
  };

  const Fetchclinic = async () => {
    try {
      const response = await Clinicrequestlist();
      const data = response?.data?.data?.data?.map((e) => {
        return {
          name: `${e.companyName} / ${e.uniqueId}`,
          ...e,
        };
      });
      setcliniclistdata(data);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(cliniclistdata,"cliniclistdata====")
  useEffect(() => {
    Fetchclinic();
  }, []);
  useEffect(() => {
    getSpecialty();
  }, []);
  const getSpecialty = async () => {
    try {
      const resposne = await GetCategory();
      setspecialtydata(resposne?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleavailibledays = () => {
    setShowavailibleday(true);
  };
  const handleClose = () => {
    setShowavailibleday(false);
  };
  const imagePicker = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("images", file);
      try {
        const response = await uploadImage(formData);
        setImages(response.data.data[0].URL);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.log(error, "===");
      }
    }
  };
  const clickImageData = () => {
    // console.log(fileInputRef, "click=====");
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const DayItem = ({
    day,
    isActive,
    onToggle,
    startTime,
    endTime,
    onTimeChange,
  }) => {
    return (
      <div className={`unique-day-item ${isActive ? "unique-active-day" : ""}`}>
        <div className="unique-day-header">
          <span>{day.name}</span>
          <label className="unique-switch">
            <input type="checkbox" checked={isActive} onChange={onToggle} />
            <span className="unique-slider unique-round"></span>
          </label>
        </div>

        {isActive && (
          <div className="unique-time-selector">
            <div className="unique-time-group">
              <div className="unique-time-input-wrapper">
                <input
                  type="time"
                  className="unique-time-input"
                  value={startTime}
                  onChange={(e) =>
                    onTimeChange(day.name, e.target.value, endTime)
                  }
                />
              </div>
              <div className="unique-time-input-wrapper">
                <input
                  type="time"
                  className="unique-time-input"
                  value={endTime}
                  onChange={(e) =>
                    onTimeChange(day.name, startTime, e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  const handleToggle = (dayName) => {
    setAvailableDays((prev) => {
      const isActive = prev.some((day) => day.day === dayName);
      if (isActive) {
        return prev.filter((day) => day.day !== dayName);
      } else {
        return [
          ...prev,
          { day: dayName, shifts: [{ startTime: "10:00", endTime: "18:00" }] },
        ];
      }
    });
  };

  const handleTimeChange = (dayName, newStartTime, newEndTime) => {
    setAvailableDays((prev) =>
      prev.map((day) => {
        if (day.day === dayName) {
          return {
            ...day,
            shifts: [{ startTime: newStartTime, endTime: newEndTime }],
          };
        }
        return day;
      })
    );
  };
  const handleSave = () => {
    console.log(availableDays);
    handleClose();
  };
  const onSubmit = async (data) => {
    console.log(data,"data======")
    data.EnumerationDate = moment(data.EnumerationDate).format("YYYY-MM-DD");
    data.DOB = moment(data.DOB).format("YYYY-MM-DD");
    if (imaeg) {
      data.image = imaeg;
    }
    data = {
      ...data,
      agencyId: profiledata._id,
    };
    try {
      const response = await createAgencyDoctor(data);
      ShowToast(response.data.message, "success");
      reset();
      navigate(-1);
    } catch (error) {
      console.log(error, "===error");
    }
  };

  const days = [
    { name: "Monday" },
    { name: "Tuesday" },
    { name: "Wednesday" },
    { name: "Thursday" },
    { name: "Friday" },
    { name: "Saturday" },
    { name: "Sunday" },
  ];

  const options = [
    { _id: "0-1", name: "0-1 (New or Entry Level)" },
    { _id: "2-5", name: "2-5 (Early Career)" },
    { _id: "6-10", name: "6-10 (Experienced)" },
    { _id: "11-15", name: "11-15 (Advanced)" },
    { _id: "16-20+", name: "16-20+ (Expert or Senior Level)" }
  ];
  // const getprofile = async (data) => {
  //   try {
  //     const response = await ClinicProfileGet();
  //     console.log(response.data,"hellooo=====")
  //     // setUserData(response.data.data)
     
  //   } catch (error) {
  //     console.log(error)
  //   }
  // };

  // useEffect(() => {
  //   getprofile()
  // }, [])

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="dashboard-container">
        {isLoading && <Loader/>}
      <AgencySideBar />
      <div className="dashboard-content">
        <Navbar />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30, width: "100%" }}
        >
          <div className="provider-form-container">
            <div className="form-header">
              <div className="profile-image">
                {/* <img
                  src={require("../../assest/Images/doctorimg.png")}
                  alt="Profile"
                  style={{ height: 130, width: 130, marginLeft: 20 }}
                /> */}
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    style={{
                      height: 130,
                      width: 130,
                      marginLeft: 20,
                      borderRadius: 60,
                      marginRight: 20,
                    }}
                  />
                ) : (
                  <>
                    <img
                      src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                      style={{
                        height: 130,
                        width: 130,
                        marginLeft: 20,
                        borderRadius: 60,
                        marginRight: 20,
                      }}
                    />
                  </>
                )}
                <button className="upload-btn" onClick={clickImageData}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={imagePicker}
                    style={{ display: "none" }}
                  />
                  Upload Image
                </button>
              </div>
              <button className="close-btn" onClick={() => navigate(-1)}>
                <img src={require("../../assest/Images/crossVector.png")} />
              </button>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="provider-form">
                <div>
                  <Inputbox
                    label="Legal First Name"
                    placeholder="Enter First Name"
                    name="firstName"
                    register={register}
                    style={{ borderColor: errors.firstName ? "red" : "initial" }}
                  />
                  {errors.firstName && (
                    <p className="errorMessage">{errors.firstName.message}</p>
                  )}
                </div>
                <div>
                  <Inputbox
                    label="Legal Last Name"
                    placeholder="Enter Last Name"
                    name="lastName"
                    register={register}
                    style={{ borderColor: errors.lastName ? "red" : "initial" }}
                  />
                  {errors.lastName && (
                    <p className="errorMessage">{errors.lastName.message}</p>
                  )}
                </div>
                <div>
                  <Inputbox
                    label="Email Address"
                    placeholder="Enter email address"
                    name="email"
                    register={register}
                    style={{ borderColor: errors.email ? "red" : "initial" }}
                  />
                  {errors.email && (
                    <p className="errorMessage">{errors.email.message}</p>
                  )}
                </div>

                {/* Phone Number Input */}
                <div>
                  <Inputbox
                    label="Phone Number"
                    placeholder="Enter phone number"
                    name="phone"
                    onChange={handleChange}
                    style={{
                      borderColor: errors.phone ? "red" : "initial",
                      width: "100%",
                      height: 43,
                    }}
                  />
                  {errors.phone && (
                    <p className="errorMessage">{errors.phone.message}</p>
                  )}
                </div>

                {/* Gender Dropdown */}
                <div>
                  <DropDownManuCustom
                    label="Gender"
                    placeholder="Select Gender"
                    options={[
                      { _id: "Male", name: "Male" },
                      { _id: "Female", name: "Female" },
                      { _id: "Other", name: "Other" },
                    ]}
                    name="gender"
                    register={register}
                    clearErrors={clearErrors}
                    // Pass trigger here
                    style={{ borderColor: errors.gender ? "red" : "initial" }}
                  />
                  {errors.gender && (
                    <p className="errorMessage">{errors.gender.message}</p>
                  )}
                </div>

                {/* Date of Birth Input */}
                <div>
                  <Inputbox
                    label="Date of Birth"
                    type="date"
                    name="DOB"
                    register={register}
                    style={{ borderColor: errors.DOB ? "red" : "initial" }}
                  />
                  {errors.DOB && (
                    <p className="errorMessage">{errors.DOB.message}</p>
                  )}
                </div>

                {/* Experience Input */}
                <div>
                  <DropDownManuCustom
                    label="Experience (Years)"
                    placeholder="Select option"
                    options={options}
                    name="experience"
                    dropdownicon
                    register={register}
                    id="_id"
                    clearErrors={clearErrors}
                    // defaultValue={"66ebb1facdb02686f39afb11"}
                    // onSelect={(selectedOption) => console.log(selectedOption)} // Handle selected option
                  />
                  {errors.experience && (
                    <p className="errorMessage">{errors.experience.message}</p>
                  )}
                </div>
                <div className="form_div">
                  <Inputbox
                    label="Practice Location"
                    type="text"
                    placeholder="Select Practice Location"
                    name="practiceLocation"
                    register={register}
                  />
                  {errors.practiceLocation && (
                    <span className="errorMessage">
                      {errors.practiceLocation.message}
                    </span>
                  )}
                </div>
                <div className="form_div">
                  <Inputbox
                    label="Primary State License Number"
                    type="text"
                    placeholder="Enter Primary State License Number"
                    name="PrimaryLicenseNo"
                    register={register}
                  />
                  {errors.PrimaryLicenseNo && (
                    <span className="errorMessage">
                      {errors.PrimaryLicenseNo.message}
                    </span>
                  )}
                </div>
                <div className="form_div">
                  <DropDownManuCustom
                    label="Primary State Of Practice"
                    placeholder="Select option"
                    options={primaryStatesOfPractice}
                    name="primaryStateOfPractice"
                    dropdownicon
                    register={register}
                  />

                  {errors.primaryStateOfPractice && (
                    <span className="errorMessage">
                      {errors.primaryStateOfPractice.message}
                    </span>
                  )}
                </div>
                <div className="form_div" style={{marginTop:-10}}>
                <lable
                  style={{
                    display: "flex",
                    marginBottom: -10,
                    color: "#273f5b",
                    fontSize: "16px",
                    fontWeight: "600",
                    marginTop: 30,
                  }}
                >
                  Available Days
                </lable>
                <div
                  className="Available_Days_divv"
                  onClick={handleavailibledays}
                  style={{ marginTop: 20 }}
                >
                  <div>
                    {" "}
                    {(availableDays.length > 0 && (
                      <div className="selected-days">
                        {availableDays.map((e) => e.day).join(", ")}
                      </div>
                    )) ||
                      "Available Days"}{" "}
                  </div>
                  <Dropdowniconn />
                </div>
</div>
                {/* NPI Input */}
                <div>
                  <Inputbox
                    label="NPI Number"
                    placeholder="Enter NPI"
                    name="NPI"
                    register={register}
                    style={{ borderColor: errors.NPI ? "red" : "initial" }}
                  />
                  {errors.NPI && (
                    <p className="errorMessage">{errors.NPI.message}</p>
                  )}
                </div>
                <div className="form_div">
                  <DropDownManuCustom
                    label="Associated with"
                    placeholder="Select option"
                    options={cliniclistdata}
                    name="agencyId"
                    dropdownicon
                    register={register}
                    id="_id"
                    clearErrors={clearErrors}
                    disabled={true}
                    defaultValue={profiledata._id}
                    // onSelect={(selectedOption) => console.log(selectedOption)} // Handle selected option
                  />
                  {errors.AssociationRequest && (
                    <span className="errorMessage">
                      {errors.AssociationRequest.message}
                    </span>
                  )}
                </div>
               
                {/* <div>
                  <Inputbox
                    label="Enumeration Date"
                    type="date"
                    name="EnumerationDate"
                    register={register}
                    style={{
                      borderColor: errors.EnumerationDate ? "red" : "initial",
                    }}
                  />
                  {errors.EnumerationDate && (
                    <p className="errorMessage">
                      {errors.EnumerationDate.message}
                    </p>
                  )}
                </div> */}

               
           
                {/* <div>
                  <Inputbox
                    label="License Number"
                    placeholder="Enter license number"
                    name="licenseNumber"
                    register={register}
                    style={{
                      borderColor: errors.licenseNumber ? "red" : "initial",
                    }}
                  />
                  {errors.licenseNumber && (
                    <p className="errorMessage">
                      {errors.licenseNumber.message}
                    </p>
                  )}
                </div> */}

                {/* Info Input */}
                <div className="form_div" >
              <Autocomplete
            onLoad={autocompleteInstance => setAutocomplete(autocompleteInstance)}
            onPlaceChanged={handlePlaceSelect}
          >
            <Inputbox
              label="Street"
              type="text"
              placeholder="Enter Address"
              name="address.street"
              register={register}
            />
          </Autocomplete>
              </div>
              <div className="form_div" >
                <Inputbox
                  label="City"
                  type="text"
                  placeholder="Enter City"
                  name="address.city"
                  register={register}
                />
                {errors.address?.city && <span className="errorMessage">{errors.address.city.message}</span>}
              </div>
              <div className="form_div" >
                <Inputbox
                  label="State"
                  type="text"
                  placeholder="Enter State"
                  name="address.state"
                  register={register}
                />
                {errors.address?.state && <span className="errorMessage">{errors.address.state.message}</span>}
              </div>
            <div className="form_div" >
                <Inputbox
                  label="Country"
                  type="text"
                  placeholder="Enter Country"
                  name="address.country"
                  register={register}
                />
                {errors.address?.country && <span className="errorMessage">{errors.address.country.message}</span>}
              </div>
              <div className="form_div" >
                <Inputbox
                  label="Zip Code"
                  type="text"
                  placeholder="Enter Zip Code"
                  name="address.zipCode"
                  register={register}
                />
                {errors.address?.zipCode && <span className="errorMessage">{errors.address.zipCode.message}</span>}
              </div>
                <div>
                  <Inputbox
                    label="Info"
                    placeholder="Enter info"
                    name="info"
                    register={register}
                    style={{ borderColor: errors.info ? "red" : "initial" }}
                  />
                  {errors.info && (
                    <p className="errorMessage">{errors.info.message}</p>
                  )}
                </div>

                
                <div>
                  <DropDownManuCustom
                    label="Language"
                    placeholder="Select language"
                    options={[
                      { _id: "english", name: "English" },
                      { _id: "spanish", name: "Spanish" },
                    ]}
                    name="language"
                    register={register}
                    clearErrors={clearErrors}
                    // Pass trigger here
                    style={{ borderColor: errors.language ? "red" : "initial" }}
                  />
                  {errors.language && (
                    <p className="errorMessage">{errors.language.message}</p>
                  )}
                </div>
                <div>
                <DropDownManuCustomMulti
                label="Specialty"
                placeholder="Select option"
                options={specialtydata}
                name="categoryId"
                register={register}
                id="_id"
                clearErrors={clearErrors}
                onSelect={handleSelect} 
              />
                  {errors.categoryId && (
                    <p className="errorMessage">{errors.categoryId.message}</p>
                  )}
                </div>
                <div className="form_div" >
                <DropDownMultiStringSelect
                  label="Credentials Type"
                  placeholder="Select option"
                  options={licenseTypes}
                  name="LicenseType"
                  register={register}
                  id="_id"
                  clearErrors={clearErrors}
                  onSelect={(value) => setValue("LicenseType", value)} // Set comma-separated string in form value
                />

              {errors.LicenseType && (
                <span className="errorMessage">
                  {errors.LicenseType.message}
                </span>
              )}
            </div>
              </div>
              <div className="form-actions">
                <button type="button" className="cancel-btn">
                  Cancel
                </button>
                <button type="submit" className="submit-btn">
                  Create Provider
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        open={showavailibleday}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>Select your opening days</h3>
          {days.map((day) => {
            const isActive = availableDays.some((d) => d.day === day.name);
            const dayData = availableDays.find((d) => d.day === day.name);
            const startTime = dayData ? dayData.shifts[0].startTime : "10:00";
            const endTime = dayData ? dayData.shifts[0].endTime : "18:00";

            return (
              <DayItem
                key={day.name}
                day={day}
                isActive={isActive}
                onToggle={() => handleToggle(day.name)}
                startTime={startTime}
                endTime={endTime}
                onTimeChange={handleTimeChange}
              />
            );
          })}
          <div className="logout-buttons" style={{ marginTop: 20 }}>
            <button className="cancel-button" onClick={handleClose}>
              Cancel
            </button>
            <button className="confirm-button" onClick={handleSave}>
              Save
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AddProvider;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "70vh", // Updated height to 70% of the viewport height
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  overflowY: "auto", // Enables scrolling inside the modal box
};
