import React, { useEffect, useState } from "react";
import Navbar, { Navbartwo } from "./Navbar";
import Sidebar from "./Sidebar";
import OverviewPatient from "./OverviewPatient";
import PatientProblems from "./PatientProblems";
import Medicationspatient from "./Medicationspatient";
import AllergiesPatient from "./AllergiesPatient";
import LabsPatient from "./LabsPatient";
import VitalsPatient from "./VitalsPatient";
import PatientContact from "./PatientContact";
import PatientIdentification from "./PatientIdentification";
import DropDownButton, { DropDownManu, SearchDropDown } from "./DropDownButton";
import Inputbox from "./Inputbox";
import { useLocation } from "react-router-dom";
import { PatientDetails } from "../../api/helper";
import moment from "moment/moment";

const PatientChart = () => {
  const [patientdata, setpatientdata] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.patientId) {
      console.log("Patient ID:", location.state.patientId);

      const fetchPatientDetails = async () => {
        try {
          const response = await PatientDetails(location.state.patientId);
          console.log(response?.data?.data, "data =-=-====patientdetail");
          setpatientdata(response?.data?.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchPatientDetails();
    }
  }, [location.state]);
 
  const [activeTab, setActiveTab] = useState("Overview");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [activeSection, setActiveSection] = useState("contact");
  const data = [
    { member: "Evens Michel", source: "Internal", type: "MD" },
    { member: "Soondy Smith", source: "External", type: "NP" },
    { member: "Evens Michel", source: "Facility", type: "MD" },
    { member: "Soondy Smith", source: "Family", type: "NP" },
  ];
  const handleSectionClick = (section) => {
    setActiveSection(section);
  };
  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };
  const renderContent = () => {
    switch (activeTab) {
      case "Overview":
        return <OverviewPatient />;
      case "Problems":
        return <PatientProblems />;
      case "Medications":
        return <Medicationspatient />;
      case "Allergies":
        return <AllergiesPatient />;
      case "Labs":
        return <LabsPatient />;
      case "Vitals":
        return <VitalsPatient />;
      default:
        return <OverviewPatient />;
    }
  };

  const [Careteampopup, setCareteampopup] = useState(false);
  const handlenotification = () => {
    setCareteampopup(true);
  };
  const [CareActive, setCareActive] = useState('Internal');
  const handleTabClick = (tabName) => {
    setCareActive(tabName);
  };
  const [Recommendations, setRecommendations] = useState(false);
  const handleRecommendations = () => {
    setRecommendations(true);
  }
  const dob = patientdata?.patientId?.DOB;
  const age = dob ? moment().diff(moment(dob), 'years') : null;
  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-content">
        <Navbartwo />
        <div
          className="dashboard-cards"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className="patientchartheader">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "75%",
              }}
            >
              <div
                className={
                  activeTab === "Overview"
                    ? "patientchartheaderbuttonactive"
                    : "patientchartheaderbutton"
                }
                onClick={() => setActiveTab("Overview")}
              >
                Overview
              </div>
              <div
                className={
                  activeTab === "Problems"
                    ? "patientchartheaderbuttonactive"
                    : "patientchartheaderbutton"
                }
                onClick={() => setActiveTab("Problems")}
              >
                Problems
              </div>
              <div
                className={
                  activeTab === "Medications"
                    ? "patientchartheaderbuttonactive"
                    : "patientchartheaderbutton"
                }
                onClick={() => setActiveTab("Medications")}
              >
                Medications
              </div>
              <div
                className={
                  activeTab === "Allergies"
                    ? "patientchartheaderbuttonactive"
                    : "patientchartheaderbutton"
                }
                onClick={() => setActiveTab("Allergies")}
              >
                Allergies
              </div>
              <div
                className={
                  activeTab === "Labs"
                    ? "patientchartheaderbuttonactive"
                    : "patientchartheaderbutton"
                }
                onClick={() => setActiveTab("Labs")}
              >
                Labs
              </div>
              <div
                className={
                  activeTab === "Vitals"
                    ? "patientchartheaderbuttonactive"
                    : "patientchartheaderbutton"
                }
                onClick={() => setActiveTab("Vitals")}
              >
                Vitals
              </div>
              <DropDownManu placeholder="Other Resources" 
              style={{height:36, }} 
              options={[
                { name: 'Vaccines' },
                { name: 'Procedure' },
                { name: 'Encounters' },
                { name: 'Devices' },
                { name: 'Goals' },
                { name: 'Service Requests' },
              ]}
              name="gender" dropdownicon />
              
          
            </div>

            <div>
              <img
                src={require("../assest/Images/patientchartimg.png")}
                alt="Patient Chart"
              />
            </div>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div className="patient_left">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div className="Patient_Chart_txt">Patient Chart</div>

                <img
                  src={require("../assest/Images/fluent_arrow.png")}
                  style={{ cursor: "pointer" }}
                  onClick={toggleFormVisibility}
                />
              </div>
              <div className="client_id_txt">Client ID: C-ep4ssu</div>
              <div className="paitient_name_div" style={{ display: "flex" }}>
              {patientdata?.patientId?.image ? (
                    <img
                      src={patientdata?.patientId?.image}
                      alt={`${patientdata.name}`}
                      style={{ height: 70, width: 70 }}
                    />
                  ) : (
                    <img
                      src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                      alt="Default Avatar"
                      style={{ height: 70, width: 70 }}
                    />
                  )}
                {/* <img
                  src={require("../assest/Images/usericon.png")}
                  style={{ height: 70, width: 70 }}
                  alt="User Icon"
                /> */}
                <div style={{ marginLeft: 10 }}>
                  <div className="patient_name_txt">{patientdata?.patientId.firstName+" "+patientdata?.patientId.lastName }</div>
                  <div className="Payment_Insurance_txt">
                    {" "}
                    Payment: Insurance
                  </div>
                  <div className="Payment_Insurance_txt">
                    Mobile: {patientdata?.patientId?.phone}
                  </div>
                  <div className="Payment_Insurance_txt">
                    Primary Dx: F84.02
                  </div>
                  <div
                    className="Payment_Insurance_txt"
                    style={{ marginTop: 10, fontSize: 16 }}
                  >
                    He/Him/His -  {age ? `${age} years old` : 'Age not available'}
                  </div>
                </div>
              </div>
              <div className="active_txt_div">
                <div style={{ display: "flex", width: "30%" }}></div>
                <div>
                  <div className="active_txttt">
                    <img
                      src={require("../assest/Images/checkIcon.png")}
                      style={{ marginRight: 10 }}
                      alt="Check Icon"
                    />
                    Active
                  </div>
                  <div className="active_txttt">
                    <img
                      src={require("../assest/Images/checkIcon.png")}
                      style={{ marginRight: 10 }}
                      alt="Check Icon"
                    />
                    Nageley Michel
                  </div>
                </div>
              </div>
              <div className="space_between" style={{ marginTop: 20 }}>
                <div className="Demographics_txtt">Demographics</div>
                <img
                  src={require("../assest/Images/pen_icon.png")}
                  alt="Pen Icon"
                />
              </div>
              <div
                style={{ marginTop: 20, display: "flex", alignItems: "center" }}
              >
                <div className="date_of_birth_txtt">Date of Birth</div>
                <div className="date_of_birth_">  {patientdata?.patientId.DOB ? moment(patientdata.DOB).format('MMM DD, YYYY') : 'Not Available'}</div>
              </div>
              <div
                style={{ marginTop: 20, display: "flex", alignItems: "center" }}
              >
                <div className="date_of_birth_txtt" style={{ marginRight: 36 }}>
                  Sex at Birth
                </div>
                <div className="date_of_birth_">{patientdata?.patientId?.gender}</div>
              </div>

              <div className="space_between_width" style={{ marginTop: 20 }}>
                <div className="Demographics_txtt">Care Team</div>

                <img
                  src={require("../assest/Images/pen_icon.png")}
                  alt="Pen Icon"
                  style={{ cursor: "pointer" }}
                  onClick={handlenotification}
                />
              </div>
              <div className="grid-container">
                <div className="grid-header">Member</div>
                <div className="grid-header">Source</div>
                <div className="grid-header">Type</div>

                {data.map((row, index) => (
                  <React.Fragment key={index}>
                    <div className="grid-item">{row.member}</div>
                    <div className="grid-item">{row.source}</div>
                    <div className="grid-item">{row.type}</div>
                  </React.Fragment>
                ))}
              </div>
              <div className="space_between_width" style={{ marginTop: 20 }}>
                <div className="Demographics_txtt">
                  Clinical Recommendations
                </div>
                <img
                  src={require("../assest/Images/pen_icon.png")}
                  alt="Pen Icon"
                  style={{ cursor: "pointer" }}
                  onClick={handleRecommendations}
                />
              </div>
              <div className="hiv_txtt" style={{ marginTop: 10 }}>
                (HIV) Infection Exam
              </div>
              <div className="hiv_txtt">Immunization</div>
              <div className="hiv_txtt">PTSD Test</div>
              <div className="hiv_txtt">Drug Addition Test</div>
              <div className="hiv_txtt">Anxiety Disorder Screening</div>
              <div className="hiv_txtt">Tabaco Dependency Screening</div>
            </div>
            {renderContent()}
          </div>
          {isFormVisible && (
            <div className="Patient_Chart_popup">
              <div className="Add_problem_txtt_two">
                {/* Even Michel */}
                {`${patientdata.patientId.firstName} ${patientdata.patientId.lastName}`}
                {/* Close Icon */}
                <img
                  src={require("../assest/Images/crossIconn.png")}
                  alt="close"
                  onClick={toggleFormVisibility}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <div className="left_chart_popup">
                  <div
                    className="Clinical_Details_txtt"
                    style={{ paddingLeft: 20, paddingRight: 20, marginTop: 30 }}
                  >
                    Clinical Details
                  </div>
                  <div
                    className="code_txt_div"
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    Assigned Clinician
                    <select className="select_option_style">
                      <option>Nageley Michel</option>
                    </select>
                  </div>
                  <div
                    className="code_txt_div"
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    Status
                    <select className="select_option_style">
                      <option>Active</option>
                    </select>
                  </div>

                  <div className="MRN_style_div">
                    <div
                      className="code_txt_div"
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        display: "flex",
                        justifyContent: "space-between",
                        width: "57%",
                        marginTop: 10,
                      }}
                    >
                      Status
                      <div>EMI000001</div>
                    </div>
                  </div>
                  <div className="Save_buttonnn">Save</div>
                </div>
                <div className="right_chart_popup">
                  <div
                    className="main_div1"
                    style={{ paddingLeft: 45, paddingRight: 45 }}
                  >
                    <div
                      className={
                        activeSection === "contact"
                          ? "para_border"
                          : "para_border1"
                      }
                      onClick={() => handleSectionClick("contact")}
                      style={{ cursor: "pointer" }}
                    >
                      <p
                        className={
                          activeSection === "contact"
                            ? "para_Contact"
                            : "Identification"
                        }
                      >
                        Contact
                      </p>
                    </div>
                    <div
                      className={
                        activeSection === "identification"
                          ? "para_border"
                          : "para_border1"
                      }
                      onClick={() => handleSectionClick("identification")}
                      style={{ cursor: "pointer" }}
                    >
                      <p
                        className={
                          activeSection === "identification"
                            ? "para_Contact"
                            : "Identification"
                        }
                      >
                        Identification
                      </p>
                    </div>
                  </div>

                  <div>
                    {activeSection === "contact" && (
                      <>
                        <PatientContact patientdata={patientdata}/>
                      </>
                    )}
                    {activeSection === "identification" && (
                      <>
                        <PatientIdentification patientdata={patientdata}/>
                        {/* <p>Identification section is active</p> */}
                      </>
                    )}
                  </div>
                  {/* <PatientContact/> */}
                  {/* <PatientIdentification/> */}
                </div>
              </div>
            </div>
          )}

          {Careteampopup && (
            <div className="review-popup-overlay">
              <div
                className="review-popup-container_two"
                style={{
                  paddingLeft: 40,
                  paddingRight: 40,
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="service_rating_txt2">Care Team</div>
                  <div>
                    <img
                      src={require("../assest/Images/CloseVector.png")}
                      onClick={() => setCareteampopup(false)}
                    />
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                <div className="service_rating_txt1" style={{ fontSize: 22 }}>
                  Add Team Member
                </div>
                <div>
                  <img className="Pop_Up_add_icon" src={require('../assest/Images/Add_PlusVector.png')}/>
                  </div>
                </div>
                

                <div style={{ display: "flex", gap: "4%" }}>
                  <div
                    className={
                      CareActive === "Internal"
                        ? "CareTeamButtonActive"
                        : "CareTeamButton"
                    }
                    onClick={() => handleTabClick("Internal")}
                  >
                    Internal
                  </div>
                  <div
                    className={
                      CareActive === "External"
                        ? "CareTeamButtonActive"
                        : "CareTeamButton"
                    }
                    onClick={() => handleTabClick("External")}
                  >
                    External
                  </div>
                  <div
                    className={
                      CareActive === "Facility"
                        ? "CareTeamButtonActive"
                        : "CareTeamButton"
                    }
                    onClick={() => handleTabClick("Facility")}
                  >
                    Facility
                  </div>
                </div>

                {CareActive === "Internal" && (
                  <>
                    <div>
                      <div className="input-row1">
                        <SearchDropDown
                          label="Facility"
                          placeholder=" "
                          dropdownicon
                          style={{ width: "47%" }}
                          options={["State 1", "State 2", "State 3"]}
                          lebalstyle={{ color: "#fff" }}
                        />
                        <SearchDropDown
                          label="Provider Type"
                          placeholder=" "
                          dropdownicon
                          style={{ width: "47%" }}
                          options={["State 1", "State 2", "State 3"]}
                          lebalstyle={{ color: "#fff" }}
                        />
                      </div>
                      <div
                        className="input-row1"
                        style={{ width: "100%", gap: "6%" }}
                      >
                        <SearchDropDown
                          label="Team Member"
                          placeholder=" "
                          dropdownicon
                          style={{ width: "47%" }}
                          options={["State 1", "State 2", "State 3"]}
                          lebalstyle={{ color: "#fff" }}
                        />
                      </div>
                      <div className="input-row1" style={{ gap: "6%" }}>
                        <Inputbox
                          label="From Date"
                          type="date"
                          placeholder="DD/MM/YY"
                          name="firstName"
                          style={{ backgroundColor: "#D9D9D9", height: 40 }}
                          lebalstyle={{ color: "white" }}
                        />
                        <Inputbox
                          label="To Date"
                          type="date"
                          placeholder="DD/MM/YY"
                          name="firstName"
                          style={{ backgroundColor: "#D9D9D9", height: 40 }}
                          lebalstyle={{ color: "white" }}
                        />
                      </div>
                      <div
                        className="input-row1"
                        style={{ width: "100%", gap: "6%", width: "47%" }}
                      >
                        <Inputbox
                          label="Last Seen Date"
                          type="date"
                          placeholder="DD/MM/YY"
                          name="firstName"
                          style={{ backgroundColor: "#D9D9D9", height: 40 }}
                          lebalstyle={{ color: "white" }}
                        />
                      </div>
                      <div
                        className="review-popup-buttons1"
                        style={{ marginTop: 50 }}
                      >
                        <button
                          className="review-cancel-btn1"
                          style={{ height: 40 }}
                          onClick={() => setCareteampopup(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="review-save-btn1"
                          style={{ height: 40 }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </>
                )}


                {CareActive === "External" && (
                  <>
                    <div>
                      <div>
                        <Inputbox
                        label={'Practice'}
                        lebalstyle={{color:'#fff'}}
                        style={{backgroundColor:'#D9D9D9'}}
                        />
                      </div>
                      <div className="input-row1">
                        <SearchDropDown
                          label="Facility"
                          placeholder=" "
                          dropdownicon
                          style={{ width: "47%" }}
                          options={["State 1", "State 2", "State 3"]}
                          lebalstyle={{ color: "#fff" }}
                        />
                        <SearchDropDown
                          label="Provider Type"
                          placeholder=" "
                          dropdownicon
                          style={{ width: "47%" }}
                          options={["State 1", "State 2", "State 3"]}
                          lebalstyle={{ color: "#fff" }}
                        />
                      </div>
  
                      <div className="input-row1" style={{ gap: "6%" }}>
                        <Inputbox
                          label="From Date"
                          type="date"
                          placeholder="DD/MM/YY"
                          name="firstName"
                          style={{ backgroundColor: "#D9D9D9", height: 40 }}
                          lebalstyle={{ color: "white" }}
                        />
                        <Inputbox
                          label="To Date"
                          type="date"
                          placeholder="DD/MM/YY"
                          name="firstName"
                          style={{ backgroundColor: "#D9D9D9", height: 40 }}
                          lebalstyle={{ color: "white" }}
                        />
                      </div>
                      <div
                        className="input-row1"
                        style={{ width: "100%", gap: "6%", width: "47%" }}
                      >
                        <Inputbox
                          label="Last Seen Date"
                          type="date"
                          placeholder="DD/MM/YY"
                          name="firstName"
                          style={{ backgroundColor: "#D9D9D9", height: 40 }}
                          lebalstyle={{ color: "white" }}
                        />
                      </div>
                      <div
                        className="review-popup-buttons1"
                        style={{ marginTop: 50 }}
                      >
                        <button
                          className="review-cancel-btn1"
                          style={{ height: 40 }}
                          onClick={() => setCareteampopup(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="review-save-btn1"
                          style={{ height: 40 }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </>
                )}



{CareActive === "Facility" && (
                  <>
                    <div>
                      {/* <div>
                        <Inputbox
                        label={'Practice'}
                        lebalstyle={{color:'#fff'}}
                        style={{backgroundColor:'#D9D9D9'}}
                        />
                      </div> */}
                       <SearchDropDown
                          label="Facility"
                          placeholder=" "
                          dropdownicon
                          // style={{ width: "47%" }}
                          options={["State 1", "State 2", "State 3"]}
                          lebalstyle={{ color: "#fff" }}
                        />
                     
                      <div className="input-row1" style={{ gap: "6%" }}>
                        <Inputbox
                          label="From Date"
                          type="date"
                          placeholder="DD/MM/YY"
                          name="firstName"
                          style={{ backgroundColor: "#D9D9D9", height: 40 }}
                          lebalstyle={{ color: "white" }}
                        />
                        <Inputbox
                          label="To Date"
                          type="date"
                          placeholder="DD/MM/YY"
                          name="firstName"
                          style={{ backgroundColor: "#D9D9D9", height: 40 }}
                          lebalstyle={{ color: "white" }}
                        />
                      </div>
                      <div
                        className="input-row1"
                        style={{ width: "100%", gap: "6%", width: "47%" }}
                      >
                        <Inputbox
                          label="Last Seen Date"
                          type="date"
                          placeholder="DD/MM/YY"
                          name="firstName"
                          style={{ backgroundColor: "#D9D9D9", height: 40 }}
                          lebalstyle={{ color: "white" }}
                        />
                      </div>
                      <div
                        className="review-popup-buttons1"
                        style={{ marginTop: 50 }}
                      >
                        <button
                          className="review-cancel-btn1"
                          style={{ height: 40 }}
                          onClick={() => setCareteampopup(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="review-save-btn1"
                          style={{ height: 40 }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}


{Recommendations && (
            <div className="review-popup-overlay">
              <div
                className="review-popup-container_two"
                style={{
                  paddingLeft: 40,
                  paddingRight: 40,
                  marginTop: 20,
                  marginBottom: 20,
                }}>
                   <div
                  style={{ display: "flex", justifyContent: "space-between", paddingTop:20}}
                >
                  <div className="service_rating_txt2">Clinical Recommendations</div>
                  <div>
                    <img
                      src={require("../assest/Images/CloseVector.png")}
                      onClick={() => setRecommendations(false)}
                    />
                  </div>
                </div>
                <div>
                <div className="Popup_display_div">
                  <div style={{marginRight:20}}>
                  <img className="Pop_Up_add_icon" src={require('../assest/Images/IconLastVector.png')}/>
                  </div>
                  <div>
                  <p className="Popup_Preventive_text">Preventive Care and Screening:</p>
                  <p className="Popup_Colorectal_text">Colorectal Cancer Screening </p>
                  </div>
                </div>
                <div className="Popup_display_div">
                  <div style={{marginRight:20}}>
                  <img className="Pop_Up_add_icon" src={require('../assest/Images/IconLastVector.png')}/>
                  </div>
                  <div>
                  <p className="Popup_Preventive_text">Preventive Care and Screening:</p>
                  <p className="Popup_Colorectal_text">Colorectal Cancer Screening </p>
                  </div>
                </div>
                <div className="Popup_display_div">
                  <div style={{marginRight:20}}>
                  <img className="Pop_Up_add_icon" src={require('../assest/Images/IconLastVector.png')}/>
                  </div>
                  <div>
                  <p className="Popup_Preventive_text">Human Immunodeficiency Virus (HIV) Infection: Screening</p>
                  <p className="Popup_Colorectal_text">Human Immunodeficiency Virus (HIV) Infection: Screening-- Adolescents and adults aged 15 to 65 years</p>
                  </div>
                </div>
                <div className="Popup_display_div" style={{marginBottom:40}}>
                  <div style={{marginRight:20}}>
                  <img className="Pop_Up_add_icon" src={require('../assest/Images/IconLastVector.png')}/>
                  </div>
                  <div>
                  <p className="Popup_Preventive_text">Hypertension in Adults: Screening</p>
                  <p className="Popup_Colorectal_text">Hypertension in Adults: Screening -- Adults 18 years or older without known hypertension</p>
                  </div>
                </div>
                </div>
</div>
</div>
)}
        </div>
      </div>
    </div>
  );
};

export default PatientChart;
