import React, { useEffect, useState } from "react";
import Inputbox from "../../Components/Inputbox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DropDownManuCustom } from "../../Components/DropDownButton";
import { AddAddressbyagency, Deleteserviceaddress, GetPlaceservice, Getserviceaddress } from "../../../api/helper";
import { useSelector } from "react-redux";
import { ShowToast } from "../../../helpers/ToastService";
import { Box, Modal } from "@mui/material";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),

  address1: Yup.string().required("Address 1 is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zip: Yup.string()
    .required("Zip is required")
    .matches(/^\d{5}$/, "Zip must be 5 digits"),
  country: Yup.string().required("Country is required"),

  serviceCode: Yup.string().required("Place of Service Code is required"), // Added validation
  locationTomeZone: Yup.string().required("Location Timezone is required"),
  phone: Yup.string().required("Phone is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email format"),
  npi: Yup.string()
    .required("NPI is required")
    .matches(/^\d{10}$/, "NPI must be 10 digits"),
});
const AddAddress = () => {
  const [Placedata, setPlacedata] = useState(null);
  const [showModal, setShowModal] = useState(false);
const [addressData, setaddressData] = useState([])
  const profiledata = useSelector((state) => state?.cookies?.userDetails);

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const handleChange = (e) => {
    reset({
      phone: e,
    });
  };
  const onSubmit = async (data) => {
    console.log("form data ", data);
    data.agencyId = profiledata?._id; // Add agencyId to data

    try {
      const response = await AddAddressbyagency(data);
      ShowToast(response?.data?.message);
      reset();
      FetchAddresslist()
      setShowModal(false)
      console.log(response?.data?.data, "response=====");
    } catch (error) {
      console.log(error);
    }
 
  };
  const handleDelete = async (id) => {
    console.log("Deleted ID:", id);
  
    const confirmed = window.confirm("Are you sure you want to delete this address?");
    if (!confirmed) {
      return;
    }
  const data={
    id : id
  }
    try {
    
      const response = await Deleteserviceaddress(data);
  
      if (response?.data?.success) {
        ShowToast(response?.data?.message);
        FetchAddresslist()
      } else {
        ShowToast("Error: Unable to delete address."); 
      }
    } catch (error) {
      console.error("Delete operation failed:", error);
      ShowToast("An error occurred while deleting the address."); 
    }
  };
  
  const Fetchclinic = async () => {
    try {
      const response = await GetPlaceservice();
      // console.log(response?.data?.data, "hello======");
      const data = response?.data?.data?.map((e) => {
        return {
          name: `${e.ServiceName} `,
          ...e,
        };
      });
      setPlacedata(data);
    } catch (error) {
      console.log(error);
    }
  };
  const FetchAddresslist = async () => {
    const data ={
      id:profiledata?._id
    }
    try {
      const response = await Getserviceaddress(data);
      console.log(response?.data?.data?.data, "hello======");
      setaddressData(response?.data?.data?.data || [])
     
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Fetchclinic();
    FetchAddresslist()
  }, []);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "90vh", 
    overflowY: "auto", // Set a fixed height for the modal
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    outline: "none",
   // Enable vertical scrolling
  };
  return (
    <div
     
      style={{
        width: "100%",
        alignItems: "center",
        justifyItems: "center",
        marginBottom: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width:'100%'
        }}
      >
        <h1> Service Locations</h1>
        <div
          style={{
            backgroundColor: "#bc9c22",
            color: "white",
            padding: 10,
            borderRadius: 6,
          }}
          onClick={openModal}
        >
          Add Address
        </div>
      </div>


      <div style={{ overflowX: "auto", width: "100%", marginTop: 30 }}>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>Name</th>
            <th style={tableHeaderStyle}>Address 1</th>
            <th style={tableHeaderStyle}>Address 2</th>
            <th style={tableHeaderStyle}>City, State, Zip, Country</th>
            <th style={tableHeaderStyle}>Place of Service Code</th>
            <th style={tableHeaderStyle}>Location Timezone</th>
            <th style={tableHeaderStyle}>Phone</th>
            <th style={tableHeaderStyle}>Fax</th>
            <th style={tableHeaderStyle}>Email</th>
            <th style={tableHeaderStyle}>NPI</th>
            <th style={tableHeaderStyle}>Action</th>
          </tr>
        </thead>
        <tbody>
          {addressData.length === 0 ? (
            <tr>
              <td colSpan="11" style={{ textAlign: "center", padding: "20px" }}>
                No addresses found.
              </td>
            </tr>
          ) : (
            addressData.map((row, index) => (
              <tr key={index} style={tableRowStyle}>
                <td style={tableCellStyle}>{row.name}</td>
                <td style={tableCellStyle}>{row.address1}</td>
                <td style={tableCellStyle}>{row.address2 || "N/A"}</td>
                <td style={tableCellStyle}>
                  {row.city}, {row.state}, {row.zip}, {row.country}
                </td>
                <td style={tableCellStyle}>
                  <div style={{ whiteSpace: "pre-line" }}>
                    {row.serviceCode?.ServiceCodeNo} - {row.serviceCode?.ServiceName}
                  </div>
                </td>
                <td style={tableCellStyle}>{row.locationTomeZone}</td>
                <td style={tableCellStyle}>{row.phone}</td>
                <td style={tableCellStyle}>{row.fax || "N/A"}</td>
                <td style={tableCellStyle}>{row.email}</td>
                <td style={tableCellStyle}>{row.npi}</td>
                <td style={tableCellStyle}>
                  <button onClick={() => handleDelete(row._id)} style={deleteButtonStyle}>
                    Delete
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>

      <Modal open={showModal} onClose={closeModal} aria-labelledby="modal-title">
        
      <Box sx={modalStyle} className="Custom_servicer_modalMain" >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
          alignItems: "center",
          justifyItems: "center",
          marginBottom: 20,
        }}
      >
            <h1 style={{textAlign:'left'}}>New Service Location</h1>
        <div
          style={{
            
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Inputbox
            label="Name"
            type="text"
            placeholder="Enter Name"
            name="name"
            register={register}
          />
          <p style={{ color: "red", textAlign: "left" }}>
            {errors.name?.message}
          </p>
          <Inputbox
            label="Address 1"
            type="text"
            placeholder="Enter Address 1"
            name="address1"
            register={register}
          />
          <p style={{ color: "red", textAlign: "left" }}>
            {errors?.address1?.message}
          </p>
          <Inputbox
            label="Address 2 (Optional)"
            type="text"
            placeholder="Enter Address 2"
            name="address2"
            register={register}
          />
          <Inputbox
            label="City"
            type="text"
            placeholder="Enter City"
            name="city"
            register={register}
          />
          <p style={{ color: "red", textAlign: "left" }}>
            {errors?.city?.message}
          </p>
          <Inputbox
            label="State"
            type="text"
            placeholder="Enter State"
            name="state"
            register={register}
          />
          <p style={{ color: "red", textAlign: "left" }}>
            {errors?.state?.message}
          </p>
          <Inputbox
            label="Zip"
            type="text"
            placeholder="Enter Zip"
            name="zip"
            register={register}
          />
          <p style={{ color: "red", textAlign: "left" }}>
            {errors?.zip?.message}
          </p>
          <Inputbox
            label="Country"
            type="text"
            placeholder="Enter Country"
            name="country"
            register={register}
          />
          <p style={{ color: "red", textAlign: "left" }}>
            {errors?.country?.message}
          </p>
          <DropDownManuCustom
            label="Place of Service Code (Type/Code)"
            placeholder="Select option"
            options={Placedata}
            name="serviceCode"
            dropdownicon
            register={register}
            id="_id"
            clearErrors={clearErrors}
          />
          <p style={{ color: "red", textAlign: "left" }}>
            {errors.serviceCode?.message}
          </p>{" "}
          {/* Error message for Place of Service Code */}
          <Inputbox
            label="Location Timezone"
            type="text"
            placeholder="Enter Location Timezone"
            name="locationTomeZone"
            register={register}
          />
          <p style={{ color: "red", textAlign: "left" }}>
            {errors.locationTomeZone?.message}
          </p>
          {/* <div style={{borderWidth:1,borderColor:'red',borderStyle:'solid'}}> */}
          <Inputbox
            label="Phone"
            type="text"
            placeholder="Enter Phone"
            name="phone"
            register={register}
            onChange={handleChange}
            style={{ width: "100%" }}
          />
          <p style={{ color: "red", textAlign: "left" }}>
            {errors.phone?.message}
          </p>
          <Inputbox
            label="Fax (Optional)"
            type="text"
            placeholder="Enter Fax"
            name="fax"
            register={register}
          />
          <Inputbox
            label="Email"
            type="text"
            placeholder="Enter Email"
            name="email"
            register={register}
          />
          <p style={{ color: "red", textAlign: "left" }}>
            {errors.email?.message}
          </p>
          <Inputbox
            label="NPI"
            type="text"
            placeholder="Enter NPI"
            name="npi"
            register={register}
          />
          <p style={{ color: "red", textAlign: "left" }}>
            {errors.npi?.message}
          </p>
          <button
            type="submit"
            style={{ marginBottom: 50, alignSelf: "center" }}
            className="Save_buttonnn"
          >
            Submit
          </button>
        </div>
      </form>
      </Box>
      </Modal>
    </div>
  );
};

export default AddAddress;
const tableHeaderStyle = {
  padding: "10px",
  borderBottom: "2px solid #ddd",
  textAlign: "left",
  fontWeight: "bold",
  backgroundColor: "#f4f4f4",
};

const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid #ddd",
};

const tableRowStyle = {
  borderBottom: "1px solid #ddd",
};

const deleteButtonStyle = {
  backgroundColor: "red",
  color: "white",
  border: "none",
  borderRadius: "4px",
  padding: "5px 10px",
  cursor: "pointer",
};
