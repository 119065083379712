import React, { useState } from 'react';

const AgencySubscriptionModules = () => {
  const [modules, setModules] = useState([
    { id: 1, name: 'Telehealth', description: 'Video consultations and virtual visits.', subscribed: false },
    { id: 2, name: 'Clinical', description: 'Access to clinical records and patient management.', subscribed: true },
    { id: 3, name: 'Messaging', description: 'Secure messaging with patients.', subscribed: true },
    { id: 4, name: 'Engage', description: 'Includes Online Directory, Provider Presence Management, and Scheduling.', subscribed: false },
    { id: 5, name: 'Billing', description: 'Handles client billing and insurance claims processing.', subscribed: true },
    { id: 6, name: 'Client Subscription', description: 'Supports Direct Provider Care services for client subscriptions.', subscribed: false },
    { id: 7, name: 'AI Health Co-Pilot', description: 'Advanced AI assistance for generating progress notes and assessments.', premium: true, subscribed: true },
    { id: 8, name: 'E-Prescribing', description: 'Electronic prescriptions and medication management.', subscribed: false },
    { id: 9, name: 'Voice Scribing', description: 'Voice-to-text transcription for note-taking.', premium: true, subscribed: true },
  ]);

  const handleToggleSubscription = (id) => {
    setModules((prevModules) =>
      prevModules.map((module) =>
        module.id === id ? { ...module, subscribed: !module.subscribed } : module
      )
    );
  };

  const activeModules = modules.filter((module) => module.subscribed).length;
  const inactiveModules = modules.length - activeModules;

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Agency Subscription Modules</h2>
      <p style={styles.moduleCount}>
        Active Modules: {activeModules} / Inactive Modules: {inactiveModules}
      </p>
      <div style={styles.moduleList}>
        {modules.map((module) => (
          <div
            key={module.id}
            style={{
              ...styles.card,
              borderLeftColor: module.subscribed ? '#007a00' : '#a50000',
              justifyContent:'space-between'
            }}
          >
            <div style={{display:'flex'}}>
            <div style={styles.iconContainer}>
              <span role="img" aria-label="icon">🖼️</span>
            </div>
            <div style={styles.content}>
                <div style={{alignSelf:'left'}}>
              <div style={styles.moduleName}>
                {module.name} {module.premium && <span style={styles.premium}>Premium</span>}
              </div>
              <div style={styles.description}>{module.description}</div>
              </div>
            </div>
            </div>
            <button
              style={module.subscribed ? styles.cancelButton : styles.subscribeButton}
              onClick={() => handleToggleSubscription(module.id)}
            >
              {module.subscribed ? 'Cancel Subscription' : 'Subscribe'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#0d223d',
  },
  moduleCount: {
    fontSize: '16px',
    color: '#555',
    marginBottom: '10px',
  },
  moduleList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderLeft: '4px solid',
  },
  iconContainer: {
    width: '40px',
    height: '40px',
    marginRight: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flexGrow: 1,
  },
  moduleName: {
    fontSize: '18px',
    color: '#0d223d',
    margin: '0',
    
    // display: 'flex',
    alignItems: 'left',
  },
  premium: {
    fontSize: '14px',
    color: '#ffaa00',
    marginLeft: '5px',
    fontWeight: 'bold',
  },
  description: {
    fontSize: '14px',
    color: '#555',
  },
  subscribeButton: {
    padding: '8px 12px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#0d223d',
    color: '#fff',
  },
  cancelButton: {
    padding: '8px 12px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#a50000',
    color: '#fff',
  },
};

export default AgencySubscriptionModules;
