import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AllPatientsGet, Changeappointmentsstatus, Getmyclinicpatient } from "../../api/helper";
import { ShowToast } from "../../helpers/ToastService";
import { useSelector } from "react-redux";
import moment from "moment";

const NewRequestspatients = () => {
  const [patientdata, setpatientdata] = useState(null);
  const profiledata = useSelector(state => state?.cookies?.userDetails);
  const navigation = useNavigate();

  const GetPatients = async () => {
    try {
      const data = {
        status: "pending",
        doctorId: profiledata?._id
      }
      const response = await Getmyclinicpatient(data);
      console.log(response?.data.data.response, "=======>>>> =-=-====");
      setpatientdata(response?.data.data.response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetPatients();
  }, []);

  const handlerequest = async (id, status) => {
    // console.log(id,"id====id",status)
    const data = {
      status: status,
      id: id
    }
    try {
      const response = await Changeappointmentsstatus(data)
      // console.log(response?.data?.data,"respo=====")
      ShowToast(response?.data?.message, "success")
      GetPatients()
    } catch (error) {
      console.log(error)
    }
  }

  const getAge = (birthDate) => {
    const birthMoment = moment(birthDate);
    const now = moment();
    const years = now.diff(birthMoment, 'Years');
    if (years < 1) {
      const months = now.diff(birthMoment, 'Months');
      return `${months} Month${months !== 1 ? 's' : ''}`;
    } else if (years === 1) {
      const days = now.diff(birthMoment.add(1, 'Year'), 'Days');
      return `${days} Day${days !== 1 ? 's' : ''}`;
    } else {
      return `${years} Year${years !== 1 ? 's' : ''}`;
    }
  };
  // <img src={require('../assest/Images/userimage@3x.png')}
  return (
    <div className="userTable-container">
      <table className="userTable-table">
        <thead>
          <tr>
            <th className="userTable-th">Image</th>
            <th className="userTable-th">Full Name</th>
            <th className="userTable-th">Status</th>
            <th className="userTable-th">Age</th>
            <th className="userTable-th">Email</th>
            <th className="userTable-th">View Details</th>
            <th className="userTable-th">Confirm / Decline</th>
          </tr>
        </thead>
        <tbody>
          {patientdata && patientdata.length > 0 ? (
            patientdata.map((user, index) => (
              <tr key={index} className="userTable-tr">
                <td className="userTable-td">
                  {user?.patientId?.image ? (
                    <img
                      src={user?.patientId?.image}
                      alt={`${user.name}`}
                      className="userTable-image"
                    />
                  ) : (
                    <img
                      src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                      alt="Default Avatar"
                      className="userTable-image"
                    />
                  )}
                </td>
                <td className="userTable-td">{`${user?.patientId.firstName} ${user?.patientId.lastName}`}</td>
                <td className="userTable-td">{'Subscriber'}</td>
                <td className="userTable-td" >
                  {user?.patientId?.DOB ? getAge(user.patientId.DOB) : "NA"}
                </td>
                <td className="userTable-td">{user?.patientId?.email}</td>
                <td className="userTable-td">
                  <button
                    className="userTable-detailsBtn"
                    onClick={() => navigation("/PatientDetailsDoctor",{state:user})}
                  >
                    Details
                  </button>
                </td>
                <td className="userTable-td">
                  <button className="userTable-declineBtn" onClick={() => {
                    //  handlerequest(user?._id, 'cancelled')
                  }}>Decline</button>
                  <button className="userTable-confirmBtn" onClick={() => {
                    //  handlerequest(user?._id,'confirmed')
                  }}>Confirm</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No patient found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default NewRequestspatients;
