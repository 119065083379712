import Inputbox from "../../Components/Inputbox";
import { format, addDays, subDays, addWeeks, subWeeks } from "date-fns";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Checkbox from "./Checkbox";
import CalenderComponent from "./CalenderComponent";
import { useSelector } from "react-redux";
import { Postappointments } from "../../../api/helper";
import { ShowToast } from "../../../helpers/ToastService";

const { useNavigate, useLocation } = require("react-router-dom");
const { default: Navbar } = require("../../Components/Navbar");
const { default: PatientSidebar } = require("./PatientSidebar");
const { useState } = require("react");

const validationSchema = Yup.object().shape({
  patientName: Yup.string()
    .required("Patient Name is required")
    .min(2, "Patient Name must be at least 2 characters"),
  contactNumber: Yup.string()
    .required("Phone is required")
    .matches(/^[0-9]+$/, "Phone number must be digits")
    .min(10, "Contact Number must be at least 10 digits")
    .max(15, "Contact Number cannot exceed 15 digits"),
  visitingPurpose: Yup.string().required("Visiting Purpose is required"),
});
const BookAppointments = () => {
  const location = useLocation();
  const { providerId } = location.state || {}; 
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const [selectedSlot, setSelectedSlot] = useState({
    starttime: '',
    endtime: ''
  });
  const [activeTab, setActiveTab] = useState("workingHours");
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async(values) => {
    console.log("Selected Slot at Submit:", selectedSlot)
    console.log(values); 
    const data={
      patientId:profiledata?._id,
      doctorId: providerId,
      date:selectedDate,
      timeSlot:{
        startTime: selectedSlot?.starttime || "N/A", 
        endTime: selectedSlot?.endtime || "N/A",
      },
      reason:values?.visitingPurpose,
      name:values?.patientName,
      Total:'10',
      BookingFee:'2',
      GrandTotal:'12',
      contectNo:values?.contactNumber,

    }
    console.log("Final Data Object:", data);
    if(!selectedSlot?.starttime && !selectedSlot?.endtime){
      ShowToast("Please Select Timeslot")
    }else{
      try {
        const respones = await Postappointments(data)
        console.log(respones?.data?.data,"respoense =====")
        ShowToast(respones.data.message, "success");
        reset()
        setSelectedSlot({
          starttime:'',
          endtime:''
        });
      } catch (error) {
        console.log(error)
      }
    }

  };

  const handlenotification = () => {
    setActiveTab("paymentmethod");
    setIsChangePasswordVisible(!isChangePasswordVisible);
  };
  const toggleChangePassword = () => {
    setActiveTab("workingHours");
    setIsChangePasswordVisible(!isChangePasswordVisible);
  };

  const navigate = useNavigate();
  const handleSignUp = () => {
    navigate("/InsurancePatient");
  };



  const timeSlots = [
    { starttime: "10:00", endTime: "10:30" },
    { starttime: "10:30", endTime: "11:00" },
    { starttime: "11:00", endTime: "11:30" },
    { starttime: "11:30", endTime: "12:00" },
    { starttime: "12:00", endTime: "12:30" },
    { starttime: "12:30", endTime: "01:00" },
    { starttime: "01:30", endTime: "02:00" },
    { starttime: "02:00", endTime: "02:30" },
    { starttime: "02:30", endTime: "03:00" },
    { starttime: "03:00", endTime: "03:30" },
    { starttime: "03:30", endTime: "04:00" },
    { starttime: "04:00", endTime: "04:30" },
    { starttime: "04:30", endTime: "05:00" },
    { starttime: "05:00", endTime: "05:30" },
    { starttime: "05:30", endTime: "06:00" },
  ];



  const handleSlotClick = (slot) => {
    setSelectedSlot({
      starttime: slot.starttime,
      endtime: slot.endTime
    });
    console.log("Start Time:",slot.starttime, "End Time:",slot.endTime);
  };

  const handlePrevWeek = () => {
    setCurrentDate(subWeeks(currentDate, 1));
  };

  const handleNextWeek = () => {
    setCurrentDate(addWeeks(currentDate, 1));
  };

  const handleDateClick = (date) => {
    console.log(date, "helllo-==-=====");
    setSelectedDate(date);
  };
  const startOfWeek = subDays(currentDate, currentDate.getDay() - 1);
  const endOfWeek = addDays(startOfWeek, 6);
  const days = [];
  for (let date = startOfWeek; date <= endOfWeek; date = addDays(date, 1)) {
    days.push(new Date(date));
  }
  return (
    <div className="dashboard-container">
      <PatientSidebar />
      <div className="dashboard-content">
        <Navbar />
        <div
          className="dashboard-cards"
          style={{ paddingLeft: 30, paddingRight: 30 }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="schedule-app" style={{ width: "68%" }}>
                <p className="Booking_Details_txt">Booking Details</p>
                <header className="schedule-header">
                  <h1 className="schedule-title">
                    {format(currentDate, "MMMM yyyy")}
                  </h1>
                  <div className="schedule-controls">
                    <button className="schedule-button_two">New Payment</button>
                  </div>
                </header>
                <div className="schedule-main-content">
                  <div className="schedule-left-panel">
                    <div className="schedule-date-picker">
                      <div className="schedule-week-days">
                        <div
                          onClick={handlePrevWeek}
                          style={{ marginRight: 10 }}
                        >
                          <img
                            src={require("../../assest/Images/LeftArrow.png")}
                          />
                        </div>

                        <div
                          className="schedule-week-days"
                          style={{ justifyContent: "space-between" }}
                        >
                          {days.map((date) => (
                            <div
                              key={date.getTime()}
                              className={`schedule-day ${
                                date.toDateString() ===
                                selectedDate.toDateString()
                                  ? "schedule-day-selected"
                                  : ""
                              }`}
                              onClick={() => handleDateClick(date)}
                            >
                              <div
                                style={{
                                  color:
                                    date.toDateString() ===
                                    selectedDate.toDateString()
                                      ? "white"
                                      : "#697585",
                                }}
                              >
                                {format(date, "d")}
                              </div>
                              <div
                                style={{
                                  color:
                                    date.toDateString() ===
                                    selectedDate.toDateString()
                                      ? "white"
                                      : "#697585",
                                }}
                              >
                                {format(date, "EEE")}
                              </div>
                            </div>
                          ))}
                        </div>

                        <div
                          onClick={handleNextWeek}
                          style={{ marginLeft: 10 }}
                        >
                          <img
                            src={require("../../assest/Images/rightarrow.png")}
                          />
                        </div>
                      </div>
                    </div>

                    <h1
                      className="schedule-title"
                      style={{ textAlign: "left", paddingBottom: 20 }}
                    >
                      Slot Booking
                    </h1>
                    <div>
                      {activeTab === "workingHours" && (
                        <div className="grid-container">
                        {timeSlots.map((slot) => (
  <div
    key={slot.starttime}
    className={
      selectedSlot.starttime === slot.starttime 
        ? "time-slot_two selected"
        : "time-slot_two"
    }
    onClick={() => handleSlotClick(slot)}
  >
    {slot.starttime} - {slot.endTime}
  </div>
))}
                        </div>
                      )}
                      {activeTab === "notifications" && (
                        <div>
                          {isChangePasswordVisible && (
                            <div
                              style={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                zIndex: 1000,
                              }}
                            ></div>
                          )}
                        </div>
                      )}
                    </div>
                    <h1
                      className="schedule-title"
                      style={{
                        textAlign: "left",
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                    >
                      Appointment For
                    </h1>

                    <div style={{ width: "50%" }}>
                      <Inputbox
                        label="Patient Name"
                        lebalstyle={{ color: "#011434" }}
                        style={{
                          color: "#011434",
                          borderColor: "#011434",
                          background: "#D9D9D94D",
                        }}
                        type="text"
                        placeholder="Enter Patient Name"
                        name="patientName"
                        register={register} // Pass the register function
                      />
                      {errors.patientName && (
                        <span className="error-message">
                          {errors.patientName.message}
                        </span>
                      )}

                      <Inputbox
                        label="Contact Number"
                        lebalstyle={{ color: "#011434" }}
                        style={{
                          color: "#011434",
                          borderColor: "#011434",
                          background: "#D9D9D94D",
                        }}
                        type="text"
                        placeholder="Enter Number"
                        name="contactNumber"
                        register={register} // Pass the register function
                      />
                      {errors.contactNumber && (
                        <span className="error-message">
                          {errors.contactNumber.message}
                        </span>
                      )}

                      <Inputbox
                        label="Visiting Purpose"
                        lebalstyle={{ color: "#011434" }}
                        style={{
                          color: "#011434",
                          borderColor: "#011434",
                          background: "#D9D9D94D",
                          height: 104,
                        }}
                        type="text"
                        placeholder="Visiting Purpose"
                        name="visitingPurpose"
                        register={register} // Pass the register function
                      />
                      {errors.visitingPurpose && (
                        <span className="error-message">
                          {errors.visitingPurpose.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div></div> */}
                </div>
              </div>

              <div
                className="schedule-right-panel"
                style={{ width: "30%", paddingLeft: 40, paddingRight: 15 }}
              >
                <CalenderComponent />

                <p style={{ paddingTop: 50 }} className="Booking_txt">
                  Booking Detail
                </p>
                <p className="Date_text">
                  Date : July 16, 2024 Thursday Time : 01:00 to 1:30 PM
                </p>
                <p
                  className="Date_text"
                  style={{ color: "#011434", paddingTop: 10 }}
                >
                  Booking Deposit Fee
                </p>
                <div className="display_txt_booking">
                  <p className="Booking_Fee_txt" style={{ color: "gray" }}>
                    Total
                  </p>
                  <p className="Booking_10_txt">£ 10.00</p>
                </div>
                <div className="display_txt_booking">
                  <p className="Booking_Fee_txt" style={{ color: "gray" }}>
                    Booking Fee
                  </p>
                  <p className="Booking_10_txt">£ 2.00</p>
                </div>
                <div className="display_txt_booking" style={{ paddingTop: 30 }}>
                  <p className="Booking_10_txt" style={{ color: "gray" }}>
                    Grand Total
                  </p>
                  <p className="Booking_10_txt">£ 12.00</p>
                </div>
                <p
                  className="Date_text"
                  style={{ color: "#011434", paddingTop: 40 }}
                >
                  Description
                </p>
                <p className="the_booking_txt" style={{ color: "011434" }}>
                  {" "}
                  "The booking deposit fee, secures your appointment. Clinics
                  will inform you of any further charges for assessment, and
                  treatment before they are carried out. The booking deposit fee
                  is still charged if you fail to attend an appointment. "
                </p>

                <button
                  style={{ marginTop: 20 }}
                  className={
                    activeTab === "paymentmethod"
                      ? "active-tab1"
                      : "inactive-tab1"
                  }
                  // onClick={handlenotification}
                  type="submit"
                >
                  Book Appointment
                </button>
              </div>

              {activeTab === "paymentmethod" && (
                <div>
                  {isChangePasswordVisible && (
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1000,
                      }}
                    >
                      <div
                        className="HelpSupport_div"
                        style={{
                          maxWidth: "40%",
                          paddingLeft: 50,
                          paddingRight: 50,
                        }}
                      >
                        <div className="Contact_Us_txt">Payment Method</div>

                        <div
                          className="div_Payment_Method"
                          style={{ backgroundColor: "#D9D9D9" }}
                        >
                          <img
                            className="image_life-insurance"
                            src={require("../../assest/Images/life-insurance.png")}
                          />
                          <div className="div_text_payment_method">
                            <p className="text_Insurance_payment">Insurance</p>
                            <p className="text_Bill_payment">
                              Bill the client's insurance plan
                            </p>
                          </div>
                          <Checkbox />
                        </div>

                        <div
                          className="div_Payment_Method"
                          style={{ backgroundColor: "#D9D9D9" }}
                        >
                          <img
                            src={require("../../assest/Images/Payment-card.png")}
                          />
                          {/* <img src={require('../../assest/Images/subscription.png')}/> */}
                          <div className="div_text_payment_method">
                            <p className="text_Insurance_payment">Insurance</p>
                            <p className="text_Bill_payment">
                              Bill the client's insurance plan
                            </p>
                          </div>
                          <Checkbox />
                        </div>

                        <div
                          className="div_Payment_Method"
                          style={{ backgroundColor: "#D9D9D9" }}
                        >
                          <img
                            src={require("../../assest/Images/subscription.png")}
                          />
                          <div className="div_text_payment_method">
                            <p className="text_Insurance_payment">Insurance</p>
                            <p className="text_Bill_payment">
                              Bill the client's insurance plan
                            </p>
                          </div>
                          <Checkbox />
                        </div>

                        {/* <div   style={{ alignSelf: 'center', display: 'flex',width:'100%',justifyContent:'center',marginTop:10 }}>
              <div className="savebuttonn1" onClick={toggleChangePassword}>Submit</div>
            </div> */}

                        <div
                          style={{
                            alignSelf: "center",
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            marginTop: 20,
                            marginBottom: 20,
                          }}
                        >
                          <div className="savebuttonn1" onClick={handleSignUp}>
                            Submit
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default BookAppointments;
