import { useNavigate } from "react-router-dom";
import {
  GetDoctorrequest,
  GetDoctorsRequest,
  UpdateRequest,
} from "../../../api/helper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShowToast } from "../../../helpers/ToastService";
import Loader from "../Components/Loader";
const DoctorRequestsdata = () => {
  const [requestdata, setrequestdata] = useState(null);
  const profiledata = useSelector((state) => state?.cookies?.userDetails);
  const navigation = useNavigate();
  const { isLoading } = useSelector((store) => store.sliceReducer);
  const dispatch = useDispatch();

console.log(isLoading,"isLoading====")

  const FetchDoctorrequest = async () => {

    try {
      const response = await GetDoctorsRequest({ status: "pending, request" });
      // console.log(response, "heloo-----");
      setrequestdata(response?.data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchDoctorrequest();
  }, []);

  const HandleConfirm = async (status, id) => {
    // console.log(status, id, "------pppppp");
    const data = {
      status: status,
    };

    try {
      const response = await UpdateRequest(data, id);
      console.log(response?.data);
      ShowToast(response?.data?.message, "success");
      FetchDoctorrequest();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="userTable-container">
        {isLoading && <Loader/>}
      <table className="userTable-table">
        <thead>
          <tr>
            <th className="userTable-th">Image</th>
            <th className="userTable-th">Name</th>
            <th className="userTable-th">Specialization</th>
            <th className="userTable-th">Phone Number</th>
            <th className="userTable-th">Email</th>
            <th className="userTable-th">View Details</th>
            <th className="userTable-th">Confirm / Decline</th>
          </tr>
        </thead>
        <tbody>
        {requestdata && requestdata.length > 0 ? (
  requestdata?.map((user, index) => {


    return (
      <tr key={index} className="userTable-tr">
        <td className="userTable-td">
          {user?.image ? (
            <img
              src={user?.image}
              className="userTable-image"
            />
          ) : (
            <img
              src={
                "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
              }
              className="userTable-image"
            />
          )}
        </td>
        <td className="userTable-td">{user?.firstName + " " + user?.lastName}</td>
        <td className="userTable-td">  {user?.categoryId?.length === 1
    ? user.categoryId[0]?.name
    : user?.categoryId?.length === 2
    ? `${user.categoryId[0]?.name}, ${user.categoryId[1]?.name}`
    : `${user.categoryId[0]?.name}, ${user.categoryId[1]?.name}...`}</td>
        <td className="userTable-td">{user?.phone}</td>
        <td className="userTable-td">{user?.email}</td>
        <td className="userTable-td">
          <button
            className="userTable-detailsBtn"
            onClick={() => navigation("/DoctorDetailsclinic", { state: { data: user } })}
          >
            Details
          </button>
        </td>
        <td className="userTable-td">
          {user.status === "pending" ? (
            <button className="userTable-declineBtn">Pending</button>
          ) : (
            <>
              <button
                className="userTable-declineBtn"
                onClick={() => HandleConfirm("denied", user?._id)}
              >
                Decline
              </button>
              <button
                className="userTable-confirmBtn"
                onClick={() => HandleConfirm("approved", user?._id)}
              >
                Confirm
              </button>
            </>
          )}
        </td>
      </tr>
    );
  })
) : (
  <div className="userTable-tr">
    <div className="userTable-td" colSpan="5">
      No Request found
    </div>
  </div>
)}

        </tbody>
      </table>
    </div>
  );
};

export default DoctorRequestsdata;
