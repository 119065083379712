
// import React, { useState } from 'react'
// import Calendar from 'react-calendar'
// // import 'react-calendar/dist/Calendar.css';



// const CalenderComponent = () => {
//      // const [value, onChange] = useState<Value>(new Date());
//     // const ValuePiece = Date | null;
//    // const Value = ValuePiece | [ValuePiece, ValuePiece];

//   return (
//     <div className='calendar-container'>
//     <Calendar
//     className={"calendar-container"}
//     // onChange={onChange} value={value}
//     />
//   </div>
//   )
// }

// export default CalenderComponent

import React, { useState } from "react";
import { format, addMonths, subMonths } from "date-fns";

const CalenderComponent = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const weekdays = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

  const renderHeader = () => {
    return (
      <div className="calendar-header">
        <button onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}>
          &lt;
        </button>
        <h2>{format(currentMonth, "MMMM yyyy")}</h2>
        <button onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}>
          &gt;
        </button>
      </div>
    );
  };

  const renderWeekdays = () => {
    return (
      <div className="weekdays-row">
        {weekdays.map((day) => (
          <div key={day} className="weekday">
            {day}
          </div>
        ))}
      </div>
    );
  };

  const renderDays = () => {
    const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
    const startDay = startOfMonth.getDay() === 0 ? 7 : startOfMonth.getDay();
    const daysInMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();

    const calendarDays = [];
    for (let i = 1; i < startDay; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="blank-day"></div>);
    }
    for (let date = 1; date <= daysInMonth; date++) {
      calendarDays.push(
        <div key={date} className={date === new Date().getDate() ? "calendar-day highlighted" : "calendar-day"}>
          {date}
        </div>
      );
    }
    return <div className="days-grid">{calendarDays}</div>;
  };

  return (
    <div className="custom-calendar">
      {renderHeader()}
      {renderWeekdays()}
      {renderDays()}
    </div>
  );
};

export default CalenderComponent;
