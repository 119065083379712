import React, { useState } from "react";
import { AppleIcon, Eyecloseicon } from "../assest/SvgImages";
import Inputbox from "../Components/Inputbox";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form"
import { userLoginProcess } from "../../helpers/helper";
import { Store } from "../../redux";
import { Box, Modal } from "@mui/material";
import { setLoginType } from "../../redux/cookiesReducer";
import { ShowToast } from "../../helpers/ToastService";

const LoginScreen = () => {
  const navigation = useNavigate();
  const [showloagout, setshowloagout] = useState(false);
  const [chooserole, setchooserole] = useState(false);
  const [password, setPassword] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // console.log(selectedRole,'====selectedRole')
    if (!selectedRole) {
      return ShowToast("Please Select Role", "info");
    }
    userLoginProcess(data, navigation);
  };

  const handelEye = () => {
    setPassword(!password);
  };
  const handleClose = () => {
    setshowloagout(false);
  };
  const handleClosechooserole = () => {
    setchooserole(false);
  };
  const [selectedRole, setSelectedRole] = useState(null);
  const [type, setType] = useState(false);

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };
  const handleLoginRoleSelect = (role) => {
    setSelectedRole(role);
    Store.dispatch(setLoginType(role));
    setchooserole(false);
  };
  const navigate = useNavigate();

  const handleNext = () => {
    if (selectedRole === "Patient") {
      navigate("/signupPatient");
    } else if (selectedRole === "Doctor") {
      navigate("/signup");
    } else if (selectedRole === "Clinic") {
      navigate("/agencyRegistration");
      // navigate('/signup');
    }
  };

  const loginSelect = () => {
    // Store.dispatch(setLoginType(selectedRole))
    navigate("/login");
  };

  return (
    <div style={{ width: "100%", display: "flex", height: "100vh" }}>
      <div
        style={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#041C32",
          flexDirection: "column",
        }}
      >
        <img
          src={require("../assest/Images/mindwellnewlogo.png")}
          style={{ height: "50%", width: "50%" }}
        />
        <div
          style={{
            color: "white",
            fontWeight: "bold",
            fontSize: "20px",
            marginTop: "20px",
          }}
        >
          Your trusted platform for mental health services.
        </div>
      </div>
      <div
        style={{
          width: "50%",
          paddingRight: "10%",
          paddingLeft: "10%",
          paddingTop: "2%",
          paddingBottom: "2%",
          overflowY: "auto",
        }}
      >
        <h2>Welcome back!</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="Email-label" style={{ color: "#273f5b" }}>
            Choose Role
          </label>
          <div
            onClick={() => {
              setchooserole(true);
            }}
            style={{
              borderWidth: 1,
              borderColor: "#01143480",
              borderStyle: "solid",
              textAlign: "left",
              padding: 10,
              backgroundColor: "white",
              borderRadius: 5,
            }}
          >
            {!selectedRole ? (
              <span style={{ color: "gray" }}>Choose Role</span>
            ) : (
              `${selectedRole}`
            )}
          </div>
          <div className="input-container">
            <label className="Email-label">Email / Unique ID</label>
            <input
              type="text"
              placeholder="Enter Email"
              {...register("email", { required: true })}
            />
            {errors.email && (
              <div className="errorMessage">Email / Unique ID is required</div>
            )}
          </div>
          <div className="input-container">
          <label className="Email-label">Password</label>
          <input type={password?"text":"password"} placeholder="Enter Password" {...register("password", { required: true })} />
          <span className={errors.password ? "password-icon_two" : "password-icon"}  onClick={handelEye} ><Eyecloseicon/></span>
            {errors.password && <div className="errorMessage"  >Password is required</div>}
            <Link to={'/forget'} className="forgot-password">
              Forgot Password?
            </Link>
          </div>
          
          <input className="login-button" type="submit" />

          {/* <button className="login-button" >
            Next
          </button> */}
        </form>

        <div className="or-login-with">
          <p>Or Login with</p>
          <div className="social-icons">
            <div className="AppleLogo-div">
              <AppleIcon />
            </div>
            <div className="AppleLogo-div">
              <img
                src={require("../assest/Images/FacbookIcon.png")}
                alt="Facebook"
                style={{ height: 60, width: 60 }}
              />
            </div>
            <div className="AppleLogo-div">
              <img
                src={require("../assest/Images/GoogleIcon.png")}
                alt="Google"
                style={{ height: 60, width: 60 }}
              />
            </div>
          </div>
        </div>
        <div className="dont-have-account">
          Don’t have an Account?
          <div
            className="Register-txt"
            onClick={() => {
              setshowloagout(true);
            }}
          >
            Register
          </div>
        </div>
      </div>
      <Modal
        open={showloagout}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="login-box">
            <div style={{alignItems:'flex-end',justifyContent:'flex-end',display:'flex',marginBottom:10,cursor:'pointer',}} onClick={handleClose}><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqgtxjCOy_ZhfNNQG0c_OWnEsAzq8x7f3NLA&s" style={{height:20,width:20}}/></div>
            <h4 style={{ color: "white", marginBottom: 20 }}>
              {" "}
              Sign up as Patient, Doctor or Clinic{" "}
            </h4>

            <div
              className={`login-option ${
                selectedRole === "Clinic" ? "selected" : ""
              }`}
              onClick={() => handleRoleSelect("Clinic")}
            >
              <img
                src={require("../assest/Images/hospitalimg.png")}
                alt="Hospital Image"
              />

              <span>Clinic / Agency</span>
              <input
                type="checkbox"
                checked={selectedRole === "Clinic"}
                readOnly
              />
            </div>
            <div
              className={`login-option ${
                selectedRole === "Doctor" ? "selected" : ""
              }`}
              onClick={() => handleRoleSelect("Doctor")}
            >
              <img
                src={require("../assest/Images/doctorimg.png")}
                alt="Doctor"
              />
              <span>Doctor</span>
              <input
                type="checkbox"
                checked={selectedRole === "Doctor"}
                readOnly
              />
            </div>
            <div
              className={`login-option ${
                selectedRole === "Patient" ? "selected" : ""
              }`}
              onClick={() => handleRoleSelect("Patient")}
            >
              <img
                src={require("../assest/Images/Patientimg.png")}
                alt="Patient"
              />
              <span>Patient</span>
              <input
                type="checkbox"
                checked={selectedRole === "Patient"}
                readOnly
              />
            </div>
            <button
              className="login-button"
              onClick={type ? () => loginSelect() : () => handleNext()}
            >
              Next
            </button>
            {/* <div className="login-footer">
          <span>Already have an Account?</span>
          <a style={{ cursor: "pointer" }} onClick={() => {
            loginSelect()
            // navigate('/login')
          }} >Login</a>
        </div> */}
          </div>
        </Box>
      </Modal>

      <Modal
        open={chooserole}
        onClose={handleClosechooserole}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="login-box">
            <h4 style={{ color: "white", marginBottom: 20 }}>
              {" "}
              Login as Patient, Doctor or Clinic{" "}
            </h4>

            <div
              className={`login-option ${
                selectedRole === "Clinic" ? "selected" : ""
              }`}
              onClick={() => handleLoginRoleSelect("Clinic")}
            >
              <img
                src={require("../assest/Images/hospitalimg.png")}
                alt="Hospital Image"
              />

              <span>Clinic / Agency</span>
              <input
                type="checkbox"
                checked={selectedRole === "Clinic"}
                readOnly
              />
            </div>
            <div
              className={`login-option ${
                selectedRole === "Doctor" ? "selected" : ""
              }`}
              onClick={() => handleLoginRoleSelect("Doctor")}
            >
              <img
                src={require("../assest/Images/doctorimg.png")}
                alt="Doctor"
              />
              <span>Doctor</span>
              <input
                type="checkbox"
                checked={selectedRole === "Doctor"}
                readOnly
              />
            </div>
            <div
              className={`login-option ${
                selectedRole === "Patient" ? "selected" : ""
              }`}
              onClick={() => handleLoginRoleSelect("Patient")}
            >
              <img
                src={require("../assest/Images/Patientimg.png")}
                alt="Patient"
              />
              <span>Patient</span>
              <input
                type="checkbox"
                checked={selectedRole === "Patient"}
                readOnly
              />
            </div>
            {/* <button className="login-button" onClick={type ? ()=>loginSelect() :()=>handleNext() }>Next</button> */}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default LoginScreen;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  // ali
};
